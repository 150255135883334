/**
 * 注释
 * TODO
 */
export enum MsgCallStatus {
  OUTGOING = 1, // waiting
  INCOMING, // waiting
  RINGING, // waiting
  CONNECTED, // keeping
  IDLE, // end
  ACCEPTED // waiting
}
