import { BasicLogger } from '@rongcloud/engine';
import { IRCTrackBitrate } from '../../interfaces';
import { RCTrackKind } from '../../tracks/RCTrack';
import { SdpSemantics } from './ASdpStrategy';
import ASdpBuilder from './ASdpBuilder';
import { RCLoggerTag } from '../../enums/RCLoggerTag';

export default class PlanBSdpBuilder extends ASdpBuilder {
  constructor(
    logger: BasicLogger,
    public readonly SDP:string,
  ) {
    super(SDP, SdpSemantics.PLANB, logger);
  }

  /**
   * 它采用 SDP 标头和音频和视频流并将它们连接到一个字符串中
   * @returns SDP 标头和音频和视频流的字符串。
   */
  public stringify():string {
    const sdpSource:string[] = [this.SDPHeader];
    const sdp:string[] = [...this.audioStreams, ...this.videoStreams];
    sdp.forEach((item) => {
      const matchMid = item.match(/\ba=mid:(video|audio)\b/);
      // 移除不需要发布资源的 ssrc
      // 订阅或者取消订阅或者取消发布时将 SSRC 删除，这样可以保证每次重新发布资源 SSRC 会发生变化
      const sdpItem = ASdpBuilder.clearInactiveOrRecvonly(item);
      if (matchMid && matchMid[1] === RCTrackKind.AUDIO) {
        sdpSource[1] = sdpItem;
      }
      if (matchMid && matchMid[1] === RCTrackKind.VIDEO) {
        sdpSource[2] = sdpItem;
      }
    });
    const streamsJoin = sdpSource.join('');
    return ASdpBuilder.trimBlankLine(streamsJoin);
  }

  /**
   * @overwrite 因为 Plan-b 设置是针对整个 m=audio ，所以不需要单独设置
   */
  public setAudioBitrateWithStreamId(bitrate: IRCTrackBitrate, streamId: string): ASdpBuilder {
    this._logger.warn(RCLoggerTag.L_FUNCTION_IS_UNAVAILABLE, 'plan-b not support set Audio Bitrate With Stream Id');
    return this;
  }

  /**
   * @overwrite 因为 Plan-b 设置是针对整个 m=video ，所以不需要单独设置
   */
  public setVideoBitrateWithStreamId(bitrate: IRCTrackBitrate, streamId: string): ASdpBuilder {
    this._logger.warn(RCLoggerTag.L_FUNCTION_IS_UNAVAILABLE, 'plan-b not support set Video Bitrate With Stream Id');
    return this;
  }
}
