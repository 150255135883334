import { AbsCodec, forEach } from '@rongcloud/engine';
import { RTCMode } from '../enums/RTCMode';
import { RTCJoinType } from '../enums/RTCJoinType';
import { RTCKeyMaps, RTCPB } from './proto';
import { IRTCUserData, IJoinRTCRoomData } from './interface';
import { int64ToTimestamp } from '../../helper';
import { IRCRTCReportData } from '../interfaces';
import { RCReportType } from '../enums/RCPolarisReporter';

export const encodeRtcInput = (codec: AbsCodec<RTCKeyMaps>, roomType: RTCMode, broadcastType?: number, joinType?: RTCJoinType, innerUserDatas?: IRTCUserData, outerUserDatas?: IRTCUserData, supportNtf?: boolean) => {
  const innerDatas = innerUserDatas
    ? Object.keys(innerUserDatas).map((key) => codec.encode(RTCPB.RtcValueInfo, { key, value: innerUserDatas[key] }, true))
    : undefined;
  const outerDatas = outerUserDatas
    ? Object.keys(outerUserDatas).map((key) => codec.encode(RTCPB.RtcValueInfo, { key, value: outerUserDatas[key] }, true))
    : undefined;
  return codec.encode(RTCPB.RtcInput, {
    roomType,
    broadcastType,
    joinType,
    innerDatas,
    outerDatas,
    supportNtf,
  });
};

export const decodeRtcUserListOutput = (codec: AbsCodec<RTCKeyMaps>, buffer: Uint8Array | string): IJoinRTCRoomData => {
  const rtcInfos = codec.decode(RTCPB.RtcUserListOutput, <Uint8Array>buffer);
  // 多端对齐 OfflineKickTime 默认为 60 S (单位为秒)
  const {
    users: list, token, sessionId, roomInfo, entries, offlineKickTime = 60, codeFormat, roomCreateTime, userJoinTime, version,
  } = rtcInfos;
  const users: any = {};
  forEach(list, (item: any) => {
    const { userId, userData } = item;
    const tmpData: any = {};
    forEach(userData, (data: any) => {
      const { key, value } = data;
      tmpData[key] = value;
    });
    users[userId] = tmpData;
  });
  return {
    users,
    token,
    sessionId,
    roomInfo,
    kvEntries: entries,
    offlineKickTime,
    codeFormat,
    roomCreateTime: int64ToTimestamp(roomCreateTime),
    userJoinTime: int64ToTimestamp(userJoinTime),
    version,
  };
};

/**
 * 编码 http3 上报 R1-R4 数据
*/

export const encodeRtcHttp3Report = (codec: AbsCodec<RTCKeyMaps>, report: IRCRTCReportData, reportType: string) => {
  const {
    type, appKey, sessionId, roomId, roomCreateTime, userId, userRole, joinTime,
    signalDataCenter, r1Info, r2Info, r3Info, r4Info,
  } = report;
  const commonData = {
    type, appKey, sessionId, roomId, roomCreateTime, userId, userRole, joinTime, signalDataCenter,
  };

  if (reportType === RCReportType.R1 && r1Info) {
    const innerR1Info = codec.encode(RTCPB.RtcPBR1Info, r1Info, true);
    return codec.encode(RTCPB.RtcPBPolaris, {
      ...commonData,
      r1Info: innerR1Info,
    });
  }
  if (reportType === RCReportType.R2 && r2Info) {
    const innerR2Info = codec.encode(RTCPB.RtcPBR2Info, r2Info, true);
    return codec.encode(RTCPB.RtcPBPolaris, {
      ...commonData,
      r2Info: innerR2Info,
    });
  }
  /**
   * r3Info: { ..., r3Info: info: { ..., streams: []}}
  */
  if ((reportType === RCReportType.R3 && r3Info)) {
    const { info } = r3Info;
    const { streams } = info;
    let innerStreams = Array.isArray(streams) && streams.map((stream: any) => stream && codec.encode(RTCPB.RtcPBStreamInfo, stream, true));
    let innerInfo = codec.encode(RTCPB.RtcPBStatusInfo, { ...info, streams: innerStreams }, true);
    let innterR3Info = codec.encode(RTCPB.RtcPBR3Info, { info: innerInfo }, true);
    return codec.encode(RTCPB.RtcPBPolaris, {
      ...commonData,
      r3Info: innterR3Info,
    });
  }

  if ((reportType === RCReportType.R4 && r4Info)) {
    const { info } = r4Info;
    const { streams } = info;
    let innerStreams = Array.isArray(streams) && streams.map((stream: any) => stream && codec.encode(RTCPB.RtcPBStreamInfo, stream, true));
    let innerInfo = codec.encode(RTCPB.RtcPBStatusInfo, { ...info, streams: innerStreams }, true);
    let innerR4Info = codec.encode(RTCPB.RtcPBR4Info, { info: innerInfo }, true);
    return codec.encode(RTCPB.RtcPBPolaris, {
      ...commonData,
      r4Info: innerR4Info,
    });
  }
  return {};
};
