import { BasicLogger } from '@rongcloud/engine';
import { SdpSemantics } from './ASdpStrategy';
import ASdpBuilder from './ASdpBuilder';

export default class UnifiedPlanSdpBuilder extends ASdpBuilder {
  constructor(
    logger: BasicLogger,
    SDP:string,
  ) {
    super(SDP, SdpSemantics.UNIFIEDPLAN, logger);
  }

  /**
   * 它采用 SDP 标头和音频和视频流并将它们连接到一个字符串中
   * @returns SDP 标头和音频和视频流的字符串。
   */
  public stringify():string {
    const sdpSource:string[] = [this.SDPHeader];
    const sdp:string[] = [...this.audioStreams, ...this.videoStreams];
    sdp.forEach((item) => {
      const matchMid = item.match(/\ba=mid:(\d+)\b/);
      // 移除不需要发布资源的 ssrc
      // 订阅或者取消订阅或者取消发布时将 SSRC 删除，这样可以保证每次重新发布资源 SSRC 会发生变化
      if (matchMid && matchMid[1]) {
        sdpSource[+matchMid[1] + 1] = item;
      }
    });
    // TODO: 重置数据
    this.SDPHeader = '';
    this.videoStreams = [];
    this.audioStreams = [];
    // 对空行进行处理
    const streamsJoin = sdpSource.join('');
    return ASdpBuilder.trimBlankLine(streamsJoin);
  }
}
