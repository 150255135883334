/* 定义命令类型 */
export enum RCCommandKind {
  /**
   * 发信令取消加入房间之前的资源
   */
  UNPUBLISH_PREV = 'UnpublishPrevCommand',
  /**
   * 拉取房间内数据
   */
  PULL_RTCROOM_STATUS = 'PullRTCRoomStatusCommand',
  /**
   * 加入房间
   */
  JOINROOM = 'JoinRoomCommand',
  /**
   * 解析远端资源数据
   */
  PARSE_REMOTERES = 'ParseRemoteResCommand',
  /**
   * 解析远端人员数据
   */
  PARSE_USERSTATE = 'ParseUserStateCommand',
  /**
   * 本端 track 禁用/启用
   */
  LOCAL_TRACK_MUTE = 'LocalTrackMuteCommand',
  /**
   * 发布资源
   */
  PUBLISH = 'PublishCommand',
  /**
   * 恢复 ice 连接
   */
  RETRY_EXCHANGE = 'RetryExchangeCommand',
  /**
   * 取消发布
   */
  UNPUBLISH = 'UnpublishCommand',
  /**
   * 订阅
   */
  SUBSCRIBE = 'SubscribeCommand',
  /**
   * 取消订阅
   */
  UNSUBSCRIBE = 'UnsubscribeCommand',
  /**
   * 全量订阅
   */
  UPDATE_SUBSCRIBE_LIST = 'UpdateSubscribeListCommand',
  /**
   * im 重连房间内人员、资源处理
   */
  ON_SIGNAL_RECONNECTED = 'OnSignalReconnectedCommand',
  /**
   * 退出副房间
   */
  LEAVE_OTHER_ROOM = 'LeaveOtherRoomCommand',
  /**
   * 主播、观众切换身份
   */
  RTC_IDENTITY_CHANGE = 'RTCIdentityChangeCommand',
  /**
   * 开/关 CDN
   */
  ENABLE_INNER_CDN = 'EnableInnerCDNCommand',
  /**
   * 携带副房间信息重新发 /exchange 请求
   */
  EXCHANGE_WITH_PUSH_OTHER_ROOM = 'ExchangeWithPushOtherRoomCommand',
  /**
   * 发送 MCU 请求
   */
  MCUCONFIG_FLUSH = 'MCUConfigFlushCommand',
  // 未定议的
  UNKNOWN= 'Unknown',
}
