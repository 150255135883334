import {
  ErrorCode, ILogger,
} from '@rongcloud/engine';
import { RCRTCPingResult } from '../enums/RCRTCPingResult';
import { RCLoggerStatus, RCLoggerTag } from '../enums/RCLoggerTag';
import { RTCContext } from '../codec/RTCContext';
import { RTCMode } from '../enums/RTCMode';

/**
 * rtcping 间隔
 */
const PING_GAP = 5 * 1000;
/**
 * rtcping 超时时间
 */
const PING_TIMEOUT = 5 * 1000;

/**
 * RTCPing 类，在下发的 ping 超时时间 _offlineKickTime 内，未能 Ping 成功则认为 ping 失败
 */
export default class Pinger {
  /**
   * 记录最近一次成功的 Ping 时间戳
   */
  private _latestTimestamp = Date.now()

  private readonly _logger: ILogger

  constructor(
    private readonly _context: RTCContext,
    private _roomId: string,
    private _roomMode: RTCMode,
    private readonly _gap: number = PING_GAP,
  ) {
    this._logger = this._context.logger;
  }

  /**
   * Ping 失败回调，当失败次数超出 `MAX_FAILED` 时，该方法将被调用
   */
  public onFailed!: (byServer: boolean) => void

  /**
   * 单次 ping 结果
   */
  public onPingResult!: (result: RCRTCPingResult, dataVersion?: number) => void

  private _started = false

  private _timer: any = null

  /**
   * 启动 Ping
   */
  start(offlineKickTime: number) {
    if (this._started) {
      return;
    }

    // 设置最小 offlineKickTime 值
    if (offlineKickTime < 15 * 1000) {
      offlineKickTime = 15000;
    }

    this._logger.info(RCLoggerTag.L_PINGER_START_O, JSON.stringify({
      interval: this._gap,
      offlineKickTime,
    }));

    this._started = true;
    this._checkAlive(offlineKickTime);
  }

  private _sendPing(traceId: string): Promise<{code: number, data?: { version: number } }> {
    return new Promise((resolve) => {
      this._context.rtcPing(this._roomId, this._roomMode)
        .then(resolve)
        .catch((error) => {
          this._logger.error(RCLoggerTag.L_PINGER_SEND_RTCPING_R, JSON.stringify({
            status: RCLoggerStatus.FAILED,
            msg: error,
          }), traceId);
          resolve({ code: ErrorCode.UNKNOWN });
        });
      setTimeout(resolve, PING_TIMEOUT, ErrorCode.TIMEOUT);
    });
  }

  private async _checkAlive(offlineKickTime: number): Promise<void> {
    const { logger } = this._context;
    const traceId = logger.createTraceId()!;
    const { code, data } = await this._sendPing(traceId);
    const now = Date.now();

    // ping 成功
    if (code === ErrorCode.SUCCESS) {
      this._latestTimestamp = now;
      this.onPingResult(RCRTCPingResult.SUCCESS, data!.version);
      // 延迟递归
      this._timer = setTimeout(() => this._checkAlive(offlineKickTime), this._gap);
      return;
    }

    logger.warn(RCLoggerTag.L_PINGER_SEND_RTCPING_R, JSON.stringify({
      roomId: this._roomId,
      status: RCLoggerStatus.FAILED,
      code,
    }), traceId);

    this.onPingResult(RCRTCPingResult.FAIL);

    // 超出 _offlineKickTime 未 ping 成功，或用户已不存在于房间内时，通知客户离线
    if (code === 40003 || now - this._latestTimestamp > offlineKickTime) {
      this.stop();
      this.onFailed(code === 40003);
      return;
    }

    // 延迟发 rtcping，避免调用栈递归阻塞
    this._timer = setTimeout(() => this._checkAlive(offlineKickTime), 500);
  }

  stop() {
    if (!this._started) {
      return;
    }

    this._logger.info(RCLoggerTag.L_PINGER_STOP_O);

    this._started = false;

    if (this._timer) {
      clearTimeout(this._timer);
      this._timer = null;
    }
  }
}
