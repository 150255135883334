import {
  ConversationType, ErrorCode, EventEmitter, ILogger, IPushConfig as IEnginePushConfig, IReceivedMessage, IRuntime, ISendMsgOptions, RTCPluginContext,
} from '@rongcloud/engine';
import { MemberModifyType } from './enums/MemberModifyType';
import { RCCallErrorCode } from './enums/RCCallCode';
import { RCCallMessageType } from './enums/RCCallMessageType';
import { IUserProfile } from './interfaces/IStateMachine';
import {
  IAcceptMsgContent, IAcceptMsgOptions, ICallMsgOption, IHungupMsgContent, IHungupMsgOptions,
  IInviteMsgContent, IInviteMsgOptions, IMediaModifyMsgContent, IMediaModifyMsgOptions, IMemberModifyMsgContent,
  IMemberModifyMsgOptions, IMsgListener, IRingingMsgContent, IRingingMsgOptions,
} from './interfaces/IMessageHandler';
import { Platform } from './enums/Platform';
import { getCallDeviceId } from '../helper';
import { IOfflineRecord, OfflineRecorder } from './OfflineRecorder';
import { RCCallStateMachine } from '..';

const callMsgTypes = ['RC:VCAccept', 'RC:VCRinging', 'RC:VCSummary', 'RC:VCHangup', 'RC:VCInvite', 'RC:VCModifyMedia', 'RC:VCModifyMem'];

type MsgListenerKeys = keyof IMsgListener

interface IMsgBufferItem {
  markTime: number,
  msg: IReceivedMessage
}

const EngineErrorCodeToCallErrorCode: { [key: number]: RCCallErrorCode } = {
  [ErrorCode.REJECTED_BY_BLACKLIST]: RCCallErrorCode.REJECTED_BY_BLACKLIST,
  [ErrorCode.NOT_IN_GROUP]: RCCallErrorCode.NOT_IN_GROUP,
};

/**
 * 消息接收处理: 在线消息、离线消息
 * 发送消息处理: 发送不同类型消息封装
 */
export class CallMessageHandler extends EventEmitter {
  private _watchers: IMsgListener = {}

  private _userInfo: IUserProfile = {}

  private _msgBufferList: IMsgBufferItem[] = []

  private _hadHandleMsgTimer: boolean = false

  private _offlineRecorder!: OfflineRecorder

  private _deviceId: string = ''

  constructor(
    private readonly _context: RTCPluginContext,
    private readonly _runtime: IRuntime,
    private readonly _logger: ILogger,
    /**
     * 离线消息处理时间间隔
     */
    private readonly _offlineMsgItv: number = 60 * 1000,
    private readonly _getStateMachine: (callId: string) => RCCallStateMachine | null,
  ) {
    super();
    // deviceId
    this._deviceId = getCallDeviceId(_runtime);
    // 处理消息收发
    this._context.onmessage = this._onMessage.bind(this);
    // 处理离线消息记录
    this._offlineRecorder = new OfflineRecorder(this._context, this._logger, (record: IOfflineRecord) => {
      this._logger.info('_', `[CallMessageHandler] offlineRecorder -> ${JSON.stringify(record)}`);
      this._watchers.onOfflineRecord && this._watchers.onOfflineRecord(record);
    });
  }

  private _onMessage(message: IReceivedMessage) {
    const isCallMsg = callMsgTypes.includes(message.messageType);
    if (isCallMsg) {
      this._logger.debug('_', `[CallMessageHandler] _onMessage -> call message: ${JSON.stringify(message)}`);
      // 通过遍历 bufferList 查找要插入的节点
      try {
        const markTime = Date.now();
        const { sentTime: currentMsgSentTime } = message;
        let insertIndex = 0;
        // 需遍历数组全部项，故使用 forEach, findIndex 找到第一个符合条件的就会终止
        this._msgBufferList.forEach(({ msg: { sentTime } }, index) => {
          if (currentMsgSentTime >= sentTime) {
            // insertIndex 需为当前符合条件元素的后一位，故 +1
            insertIndex = index + 1;
          }
        });
        this._msgBufferList.splice(insertIndex, 0, {
          markTime,
          msg: message,
        });
        this._logger.warn('_', `onMessage -> msgBufferList: ${this._msgBufferList.length}`);
      } catch (error) {
        this._logger.error('_', `[CallMessageHandler] splice buffer msg error -> ${(error as Error).message}`);
      }
      // 启动消息处理
      this._handleBufferMsgs();
      return true;
    }
    return false;
  }

  /**
   * 在线消息抛给状态机处理
   */
  private _onRecvOnlineCallMsg(message: IReceivedMessage) {
    this._logger.info('_', `onMessage -> _onRecvOnlineCallMsg: ${message.messageType}`);
    const { content: { callId } } = message;
    // 在线消息直接抛出
    switch (message.messageType) {
      case RCCallMessageType.VCInvite:
        this._watchers.onInvite && this._watchers.onInvite(message);
        break;
      case RCCallMessageType.VCRinging:
        super.emit(`${callId}onRinging`, message);
        break;
      case RCCallMessageType.VCAccept:
        super.emit(`${callId}onAccept`, message);
        break;
      case RCCallMessageType.VCModifyMem:
        // 收到人员变更抛出 onInvite 生成状态机实例
        this._watchers.onInvite && this._watchers.onInvite(message);
        break;
      case RCCallMessageType.VCModifyMedia:
        super.emit(`${callId}onMediaModify`, message);
        break;
      case RCCallMessageType.VCHangup:
        super.emit(`${callId}onHungup`, message);
        break;
      default:
        this._logger.warn('_', `[CallMessageHandler] onRecvOnlineCallMsg -> unexpected message: ${JSON.stringify(message)}`);
        break;
    }
  }

  /**
   * 消息 buffer 列表处理逻辑
   * 1、每 20ms 检查一次 buffer list
   * 2、取出已经延迟 200 的消息列表进行消费 | 无延迟 200ms 内消息直接递归
   * 3、消费分为 离线消息消费逻辑、在线消息消费逻辑，消费后递归
  */
  private _handleBufferMsgs() {
    // 消息 buffer 列表长度为 0 时加锁
    if (this._msgBufferList.length === 0 || this._hadHandleMsgTimer) {
      this._logger.warn('_', '_handleBufferMsgs return');
      return;
    }
    // 需要加锁，收到多次消息后，可能会起多个 timer
    this._hadHandleMsgTimer = true;
    setTimeout(() => {
      // 取出大于 200 ms 消息列表
      const currentTime = Date.now();
      const buffers = this._msgBufferList.filter((item) => currentTime - item.markTime >= 200);
      this._logger.debug('_', `[CallMessageHandler] handleBufferMsgs -> lists over 200ms : ${JSON.stringify(buffers.map(({ msg: { messageUId, isOffLineMessage, content: { callId } } }) => ({ messageUId, isOffLineMessage, callId })))}`);
      if (buffers.length === 0) {
        // 没有延迟 200ms 的消息，消费逻辑解锁并递归执行
        this._hadHandleMsgTimer = false;
        this._handleBufferMsgs();
        return;
      }

      if (buffers[0].msg.isOffLineMessage) {
        // 当第一条消息为离线消息时，直接从 buffer 中取出所有离线消息，进行消息
        let offlineBuffers = this._msgBufferList.filter((item) => item.msg.isOffLineMessage);

        // 离线消息处理逻辑
        do {
          const {
            conversationType, messageType, sentTime, senderUserId, content: { callId: inviteCallId, inviteUserIds },
          } = offlineBuffers[0].msg;

          const isInviteMsgType = [RCCallMessageType.VCInvite, RCCallMessageType.VCModifyMem].includes(messageType as RCCallMessageType);

          // 计算当前时间与消息发送时间差
          const delayTime = this._context.getServerTime() - sentTime;
          const isLessThanOfflineMsgItv = delayTime < this._offlineMsgItv;

          if (!isLessThanOfflineMsgItv) {
            this._logger.warn('_', `offline msg delayTime: ${delayTime}ms`);
          }

          if (isInviteMsgType) { // 取出的第一条消息为 invite | memModify
            // 取出相同 CallId 消息列表
            const taskMsgList: IReceivedMessage[] = [];
            for (let i = 0; i < offlineBuffers.length; i++) {
              const item = offlineBuffers[i].msg;
              const { content: { callId: otherCallId } } = item;
              if (inviteCallId === otherCallId) {
                taskMsgList.push(item);
              } else {
                break;
              }
            }
            this._logger.warn('_', `taskMsgList length: ${taskMsgList.length}`);

            if (taskMsgList.length > 0) { // 防止 taskMsgList 为 0
              // 找出 msgBufferList 中已消费的消息最大 index
              const delIndex = this._msgBufferList.findIndex((item) => item.msg.messageUId === taskMsgList[taskMsgList.length - 1].messageUId);
              // 删除消费过 msgBufferList 的消息
              this._msgBufferList = this._msgBufferList.slice(delIndex + 1);

              // 找出 offlineMsgs 中已消费的消息最大 index
              const delOfflineIndex = offlineBuffers.findIndex((item) => item.msg.messageUId === taskMsgList[taskMsgList.length - 1].messageUId);
              // 删除消费过 offlineBuffers 的消息
              offlineBuffers = offlineBuffers.slice(delOfflineIndex + 1);
            }
            /**
             * 单聊未结束通话判断
             * 如果消息在 60s 内，判断是否未成对（只有 invite 或只有 invite 或 ringing 成对抛给 离线记录器，未成对抛给状态机
             */
            const isUnfinishedPrivateCall: boolean = (() => {
              if (conversationType !== ConversationType.PRIVATE) return false;
              const isOnlyInvite = taskMsgList.length === 1;
              const hasInviteAndRinging = taskMsgList.every((item) => [RCCallMessageType.VCInvite, RCCallMessageType.VCModifyMedia, RCCallMessageType.VCRinging].includes(item.messageType as RCCallMessageType));
              return isOnlyInvite || hasInviteAndRinging;
            })();
            /**
             * 群聊未结束通话判断
             * 通过 list 中 invite 和 memberModify 消息中取出所有参与通话的 userId
             * 遍历 list 找出所有 hangup 的 sendUserId
             *     总人数 - 挂断人数 > 1  且剩余人数包含自己，认为通话未挂断进状态机
             *     总人数 - 挂断人数 <= 1 通话挂断，进离线消息处理器
             *     主叫直接挂断且其他人未接听直接进离线处理器
             */
            const isUnfinishedGrpCall: boolean = (() => {
              if (conversationType !== ConversationType.GROUP) return false;
              let isUnfinished = false;
              let noOneAccept = true;
              let allUserIds = [senderUserId, ...inviteUserIds];
              let isCallerHungup = false;
              for (let i = 0; i < taskMsgList.length; i++) {
                const { senderUserId: taskMsgSenderUserId, messageType } = taskMsgList[i];
                // 只要自己挂断直接跳出循环，进离线消息
                if (messageType === RCCallMessageType.VCHangup && taskMsgSenderUserId === this._context.getCurrentId()) {
                  break;
                }
                // 找出所有挂断的人员，以及通话发起者是否挂断
                if (messageType === RCCallMessageType.VCHangup) {
                  isCallerHungup = senderUserId === taskMsgSenderUserId;
                  allUserIds = allUserIds.filter((id) => taskMsgSenderUserId !== id);
                }
                // 判断是否无人接听
                if (messageType === RCCallMessageType.VCAccept) {
                  noOneAccept = false;
                }
              }
              // 不是主叫挂断且无人接听，且剩余人数大于 1
              if (!(noOneAccept && isCallerHungup) && allUserIds.length > 1) {
                isUnfinished = true;
              }
              return isUnfinished;
            })();

            if (isLessThanOfflineMsgItv && (isUnfinishedPrivateCall || isUnfinishedGrpCall)) {
              taskMsgList.forEach(this._onRecvOnlineCallMsg, this);
            }
            this._offlineRecorder.onRecvOfflineMsgs(taskMsgList);
          } else {
            if (isLessThanOfflineMsgItv && this._getStateMachine(inviteCallId)) {
              this._onRecvOnlineCallMsg(offlineBuffers[0].msg);
            } else {
              this._logger.debug('_', `[CallMessageHandler] unexcepted offline msg -> ${JSON.stringify(offlineBuffers[0].msg)}`);
            }
            offlineBuffers.shift();
            this._msgBufferList.shift();
          }
        } while (offlineBuffers.length > 0);
      } else {
        // 在线消息处理逻辑
        buffers.forEach(({ msg }) => {
          this._onRecvOnlineCallMsg(msg!);
        });
        // 找出 msgBufferList 中已消费的消息最大 index
        const delCount = buffers.length;
        // 删除消费过的消息
        this._msgBufferList.splice(0, delCount);
        this._logger.debug('_', `timer online msg handle -> delCount: ${delCount} msgBufferList: ${this._msgBufferList.length}`);
      }
      this._hadHandleMsgTimer = false;
      this._handleBufferMsgs();
    }, 20);
  }

  registerEventListener(listener: IMsgListener) {
    Object.assign(this._watchers, listener);
  }

  registerStateMachineEvent(callId: string, funcName: MsgListenerKeys, event: (...args: any[]) => void) {
    const eventType = callId + funcName;
    super.on(eventType, event);
  }

  unregisterStateMachineEvent(callId: string) {
    ['onRinging', 'onAccept', 'onHungup', 'onMediaModify'].forEach((funcName) => {
      const eventType = callId + funcName;
      super.removeAll(eventType);
    });
  }

  registerUserInfo(userInfo: IUserProfile) {
    this._userInfo = userInfo;
  }

  /**
   * 发送 IM 消息
   */
  private async _sendCallMessage(options: ICallMsgOption): Promise<{ code: RCCallErrorCode, message?: IReceivedMessage }> {
    this._logger.debug('_', `CallMessageHandler] sendCallMesage -> message: ${JSON.stringify(options)}`);
    const {
      channelId, conversationType, targetId, content, messageType, directionalUserIdList, pushConfig,
    } = options;
    const sendOpts: ISendMsgOptions = {
      channelId,
      messageType,
      content,
      directionalUserIdList,
    };

    if ([RCCallMessageType.VCInvite, RCCallMessageType.VCModifyMem, RCCallMessageType.VCHangup].includes(messageType)) {
      const newPushConfig = pushConfig || { pushTitle: '', pushContent: '', pushData: '' };
      newPushConfig.androidConfig = Object.assign(pushConfig?.androidConfig || {}, {
        categoryHW: 'VOIP',
        categoryVivo: 'IM',
      });
      newPushConfig.iOSConfig = Object.assign(newPushConfig.iOSConfig || {}, {
        apnsCollapseId: content.callId,
      });
      newPushConfig.disablePushTitle = false;
      newPushConfig.forceShowDetailContent = false;
      sendOpts.pushConfig = newPushConfig as IEnginePushConfig;
    }
    const { code, data: message } = await this._context.sendMessage(conversationType, targetId, sendOpts);
    if (code !== ErrorCode.SUCCESS) {
      this._logger.error('_', `CallMessageHandler] sendCallMesage error -> code: ${code}`);
      return {
        code: EngineErrorCodeToCallErrorCode[code] || RCCallErrorCode.SEND_MSG_ERROR,
      };
    }
    return { code: RCCallErrorCode.SUCCESS, message };
  }

  /**
   * 发送邀请消息
   */
  async sendInvite(options: IInviteMsgOptions) {
    const {
      roomType, channelId, conversationType, targetId, callId, mediaType, inviteUserIds, extra, pushConfig,
    } = options;
    this._logger.warn('_', 'CallMessageHandler] sendCallMesage sendInvite', JSON.stringify(options));
    this._watchers.sendAccept && this._watchers.sendAccept({ callId });
    const content: IInviteMsgContent = {
      platform: Platform.WEB,
      deviceId: this._deviceId,
      callId,
      roomType,
      extra,
      engineType: 4,
      channelInfo: { Id: callId, Key: '' },
      mediaType,
      inviteUserIds,
      observerUserIds: [],
      user: this._userInfo,
    };

    if (pushConfig) {
      pushConfig.pushData = JSON.stringify({
        mediaType,
        userIdList: inviteUserIds,
        callId,
      });
    }

    return this._sendCallMessage({
      channelId,
      conversationType,
      targetId,
      content,
      messageType: RCCallMessageType.VCInvite,
      directionalUserIdList: conversationType === ConversationType.GROUP ? inviteUserIds : [targetId],
      pushConfig,
    });
  }

  /**
   * 发送人员变更消息
   */
  async sendMemeberModify(options: IMemberModifyMsgOptions) {
    const {
      channelId, conversationType, targetId, callId, mediaType, inviteUserIds, callerId,
      existedUserPofiles, directionalUserIdList, extra, pushConfig,
    } = options;
    const content: IMemberModifyMsgContent = {
      platform: Platform.WEB, // TODO 与 IM 一致
      deviceId: this._deviceId,
      callId,
      extra,
      engineType: 4,
      channelInfo: { Id: callId, Key: '' },
      mediaType,
      inviteUserIds,
      observerUserIds: [],
      user: this._userInfo,
      caller: callerId,
      modifyMemType: MemberModifyType.ADD,
      existedUserPofiles,
    };

    if (pushConfig) {
      pushConfig.pushData = JSON.stringify({
        mediaType,
        userIdList: inviteUserIds,
        callId,
      });
    }
    return this._sendCallMessage({
      channelId,
      conversationType,
      targetId,
      content,
      messageType: RCCallMessageType.VCModifyMem,
      directionalUserIdList,
      pushConfig,
    });
  }

  /**
   * 发送响铃消息
   */
  sendRinging(options: IRingingMsgOptions) {
    const {
      channelId, conversationType, targetId, callId, userIds,
    } = options;
    const content: IRingingMsgContent = {
      platform: Platform.WEB,
      deviceId: this._deviceId,
      callId,
      user: this._userInfo,
    };
    return this._sendCallMessage({
      channelId,
      conversationType,
      targetId,
      content,
      messageType: RCCallMessageType.VCRinging,
      directionalUserIdList: userIds,
    });
  }

  /**
   * 发送同意接听消息
   */
  sendAccept(options: IAcceptMsgOptions) {
    const {
      channelId, conversationType, targetId, callId, mediaType, userIds,
    } = options;
    const content: IAcceptMsgContent = {
      platform: Platform.WEB,
      deviceId: this._deviceId,
      callId,
      mediaType,
      user: this._userInfo,
    };
    return this._sendCallMessage({
      channelId,
      conversationType,
      targetId,
      content,
      messageType: RCCallMessageType.VCAccept,
      directionalUserIdList: userIds,
    });
  }

  /**
   * 发送挂断消息
   */
  sendHungup(options: IHungupMsgOptions) {
    const {
      channelId, conversationType, targetId, callId, reason, userIds, pushConfig,
    } = options;
    const content: IHungupMsgContent = {
      platform: Platform.WEB,
      deviceId: this._deviceId,
      callId,
      reason,
      user: this._userInfo,
    };

    if (pushConfig) {
      pushConfig.pushData = JSON.stringify({
        callId, reason,
      });
    }

    return this._sendCallMessage({
      channelId,
      conversationType,
      targetId,
      content,
      messageType: RCCallMessageType.VCHangup,
      pushConfig,
      directionalUserIdList: userIds,
    });
  }

  /**
   * 发送媒体变更消息
   */
  sendMediaModify(options: IMediaModifyMsgOptions) {
    const {
      channelId, conversationType, targetId, callId, mediaType, userIds,
    } = options;
    const content: IMediaModifyMsgContent = {
      platform: Platform.WEB,
      deviceId: this._deviceId,
      callId,
      mediaType,
      user: this._userInfo,
    };
    return this._sendCallMessage({
      channelId,
      conversationType,
      targetId,
      content,
      messageType: RCCallMessageType.VCModifyMedia,
      directionalUserIdList: userIds,
    });
  }
}
