import { EventEmitter, IRuntime } from '@rongcloud/engine';

const string10to64 = (number: number) => {
  const chars = '0123456789abcdefghigklmnopqrstuvwxyzABCDEFGHIGKLMNOPQRSTUVWXYZ+/'.split('');
  const radix = chars.length + 1;
  let qutient = +number;
  const arr = [];
  do {
    const mod = qutient % radix;
    qutient = (qutient - mod) / radix;
    arr.unshift(chars[mod]);
  } while (qutient);
  return arr.join('');
};

const getUUID = () => 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
  const r = Math.random() * 16 | 0;
  const v = c === 'x' ? r : (r & 0x3 | 0x8);
  return v.toString(16);
});

/* 获取 22 位的 UUID */
export const getUUID22 = () => {
  let uuid: string | number = getUUID();
  uuid = `${uuid.replace(/-/g, '')}0`;
  uuid = parseInt(uuid, 16);
  uuid = string10to64(uuid);
  if (uuid.length > 22) {
    uuid = uuid.slice(0, 22);
  }
  return uuid;
};

/**
 * 生成随机 id 字符串
 */
export const generateRandomId = (): string => {
  const random = Math.floor(Math.random() * 1000);
  let uuid = getUUID22();
  uuid = uuid.replace(/\//g, '0');
  const info = [uuid, Date.now(), random];
  return info.join('_');
};

export const timerSetTimeout = (func: Function, timeout: number): number => setTimeout(func, timeout) as number;

export const timerInterval = (fun: Function, itv: number): number => setInterval(fun, itv) as number;

export const eventEmitter = new EventEmitter();

/**
 * call deviceId 存储在 sessionStorage 中
 */
export const getCallDeviceId = (runtime: IRuntime) => {
  const key = 'RCCallDeviceId';
  let uuid = runtime.sessionStorage.getItem(key);
  if (!uuid) {
    uuid = getUUID22();
    runtime.sessionStorage.setItem(key, uuid);
  }
  return uuid;
};
