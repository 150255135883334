class EventEmitter {
  private list: {[propName: string]: any[]} = {}

  public on(event: string, fun:(data?:any)=>void) {
    (this.list[event] || (this.list[event] = [])).push(fun);
    return this;
  }

  public once(event: string, fun:(data?:any)=>void) {
    const on = (data?:any) => {
      this.off(event, on);
      fun.call(this, data);
    };
    on.fun = fun;
    this.on(event, on);
  }

  public off(event: string, fun?:(data?:any)=>void) {
    const funs = this.list[event];
    if (!funs) {
      return false;
    }
    if (!fun) {
      // 如果没有传 fn 的话，就会将 event 值对应缓存列表中的 fun 都清空
      funs && (funs.length = 0);
    } else {
      // 若有 fun，遍历缓存列表，看看传入的 fn 与哪个函数相同，如果相同就直接从缓存列表中删掉即可
      let cb;
      for (let i = 0, { length } = funs; i < length; i++) {
        cb = funs[i];
        if (cb === fun || cb.fun === fun) {
          funs.splice(i, 1);
          break;
        }
      }
    }
  }

  public emit(event: string, data?:any) {
    // 第一个参数是对应的 event 值，直接用数组的 shift 方法取出
    const funs = [...this.list[event]];

    // 如果缓存列表里没有 fun 就返回 false
    if (!funs || funs.length === 0) {
      return false;
    }
    // 遍历 event 值对应的缓存列表，依次执行 fn
    funs.forEach((fun) => {
      fun.call(this, data);
    });
  }
}
export {
  EventEmitter,
};
