import { ConversationType, ILogger } from '@rongcloud/engine';
import {
  IMediaModifyInfo,
  IMemberModifyInfo,
  ISenderInfo,
  IStateChangeInfo,
  IUserData,
  IUserStateChangeInfo,
  IInviteOptions,
  RCCallEndReason,
  RCCallErrorCode,
  RCCallMediaType,
  RCCallSessionState,
  RCCallStateMachine,
  RCCallUserState,
} from '@rc-embed/call-engine';
import {
  IRCRTCStateReport,
  RCKickReason,
  RCLocalTrack,
  RCRemoteAudioTrack,
  RCRemoteTrack,
  RCRemoteVideoTrack,
  RCRTCClient,
  RCRTCCode,
  RCRTCPingResult,
  RCRTCRoom,
  IMicphoneAudioProfile,
} from '@rongcloud/plugin-rtc';

import { ProduceTypes } from './enums';

import {
  IDeviceChangeParams,
  IMediaStreamConstraints,
  IMuteUser,
  IRCCallSessionOptions,
  ISessionListener,
  IValidationResult,
} from './interface';
import eventEmitter from './eventEmitter';
import {
  validateListener, validateMediaStreamConstraints, validateUserIds, validateExtra, validatePushTitle, validatePushContent, validatePushConfig,
} from './validation';
import { Timer } from './timer';

export class RCCallSession {
  /**
   * RTC房间实例
   */
  private _room!: RCRTCRoom

  /**
   * 用户传进来的 对session的监听 (要在RCCallClient的_onInvite里判断，要求执行完onSession必须注册session的监听，所以这里是public)
   */
  public _listener: ISessionListener | null = null

  /**
   * RTC订阅、发布重试的次数
   */
  private readonly _RETRYCOUNT: number = 2

  /**
   * 加入房间定时器
   */

  private joinRoomTimer: any = null

  constructor(

    /**
     * 状态机实例
     */
    private _stateMachine: RCCallStateMachine,

    /**
     * rtc实例
     */
    private readonly _rtcClient: RCRTCClient,

    private readonly _logger: ILogger,

    /**
     * session的其它选项
     */
    private _options: IRCCallSessionOptions = {},

  ) {
    // 监听状态机
    this._stateMachine.registerEventListener({
      /**
       * 用户状态变更
       * @param info
       */
      onUserStateChange: ({ user, reason }: IUserStateChangeInfo) => {
        this._logger.info('_', `[RCCallSession onUserStateChange] userId->${user?.userId} state->${user?.state} reason->${reason}`);
      },

      /**
       * 房间状态变更
       * @param
       */
      onStateChange: async (info: IStateChangeInfo) => {
        const { state, reason } = info;
        this._logger.info('_', `[RCCallSession onStateChange] : state->${state} reason->${reason}`);

        // 如果在通话中，就加房间
        if (state === RCCallSessionState.KEEPING) {
          const roomId: string = this._stateMachine.getCallId();
          this._logger.info('_', `[RCCallSession onStateChange] roomId: ${roomId}`);
          try {
            // 加房间
            await this._joinRoom(roomId);
          } catch (error) {
            this._exceptionClose(RCCallEndReason.NETWORK_ERROR);
            this._logger.error('_', `[RCCallSession onStateChange] joinRoom throw exception roomId -> ${roomId}`);
            console.error(error);
          }

          /**
           *  以下三条只要满足一条，状态会变成RCCallSessionState.END
           *  1、本端用户自己主动挂断
           *  2、服务端把本端用户踢出RTC房间
           *  3、房间里小于2个人
           */
        } else if (state === RCCallSessionState.END) {
          // 还未加入房间就挂断
          if (!this._room) {
            // 销毁本地流，关闭摄像头
            this._options.localTracks && this._destroyTracks(this._options.localTracks);
            const summaryInfo = this._stateMachine.getSummary();
            eventEmitter.emit('sessionClose', { session: this, summaryInfo });
            return;
          }

          this._options.localTracks && this._destroyTracks(this._options.localTracks);
          this._logger.info('_', '[RCCallSession onStateChange] localTracks destroyed');
          this._leaveRoom();
          this._room = null as unknown as RCRTCRoom;
        }
      },

      /**
       * 收到响铃
       * @param sender 发起用户信息
       */
      onRinging: (sender: ISenderInfo) => {
        this._logger.info('_', `[RCCallSession onRinging]sender: sender.userId -> ${sender.userId}`);

        try {
          // 通知用户响铃
          this._listener!.onRinging(sender, this);
        } catch (error) {
          this._logger.error('_', '[RCCallSession onRinging] method exception -> onRinging');
          console.error(error);
        }
      },

      /**
         * 当远端用户同意接听
         */
      onAccept: (sender: ISenderInfo) => {
        this._logger.info('_', `[RCCallSession onAccept]sender: sender.userId -> ${sender.userId}`);
        try {
          // 通知本端，远端用户已接听
          this._listener!.onAccept(sender, this);
        } catch (error) {
          this._logger.error('_', '[RCCallSession onAccept] method exception -> onAccept');
          console.error(error);
        }
      },

      /**
         * 当有远端用户挂断
         */
      onHungup: (sender: ISenderInfo, reason: RCCallEndReason) => {
        this._logger.info('_', `[RCCallSession onHungup]sender: sender.userId -> ${sender.userId} reason->${reason}`);
        try {
          // 通知本端，远端用户已挂断
          this._listener!.onHungup(sender, reason, this);
        } catch (error) {
          this._logger.error('_', '[RCCallSession onHungup] method exception -> onHungup');
          console.error(error);
        }
      },

      /**
       * 收到人员变更
       * @param sender 发起用户信息
       */
      onMemberModify: ({ sender, invitedUsers }: IMemberModifyInfo) => {
        this._logger.info('_', `[RCCallSession onMemberModify] sender.userId -> ${sender.userId}`);
        try {
          // 通知用户人员变更
          this._listener!.onMemberModify(sender, invitedUsers, this);
        } catch (error) {
          this._logger.error('_', '[RCCallSession onMemberModify] method exception -> onMemberModify');
          console.error(error);
        }
      },

      /**
       * 收到通话类型变更 (通话降级)
       * @param sender 发起用户信息
       */
      onMediaModify: ({ sender, mediaType }: IMediaModifyInfo) => {
        this._logger.info('_', `[RCCallSession onMediaModify]sender: sender.userId -> ${sender.userId} mediaType: ${mediaType}`);
        if (mediaType === RCCallMediaType.AUDIO) {
          // 远端收到通话降级通知后，远端执行降级通话(不发消息)
          this._setMediaTypeToAudio();
        }
        try {
          this._listener!.onMediaModify(sender, mediaType, this);
        } catch (error) {
          this._logger.error('_', '[RCCallSession onMediaModify] method exception -> onMediaModify');
          console.error(error);
        }
      },
      /**
       * 是否跨appkey
       * @param sender 发起用户信息
       */
      crossAppkey: (isCrossAppkey: boolean) => {
        this._logger.info('_', `[RCCallSession crossAppkey] 是否跨 appkey: ${isCrossAppkey}`);
        this._options.isCrossAppkey = isCrossAppkey;
      },
    });

    /**
     * 设置挂断的推送信息
     */
    this._stateMachine.setHungupPushConfig(this._options.hungupPushConfig || { pushTitle: '', pushContent: '' });
  }

  /**
   *  加入房间
   */
  private async _joinRoom(roomId: string): Promise<{ code: RCCallErrorCode }> {
    let callBack;
    try {
      // 加房间
      if (this._options.isCrossAppkey) {
        callBack = await this._rtcClient.joinCrossRTCRoom(roomId, this._options.joinType);
      } else {
        callBack = await this._rtcClient.joinRTCRoom(roomId, this._options.joinType);
      }

      const { code, userIds, room } = callBack;

      if (code !== RCRTCCode.SUCCESS) {
        // 如果音视频服务未开通
        if (code === RCRTCCode.NOT_OPEN_VIDEO_AUDIO_SERVER) {
          this._exceptionClose(RCCallEndReason.SERVICE_NOT_OPENED);
          // 己方其他端已在通话中
        } if (code === RCRTCCode.SIGNAL_JOIN_RTC_ROOM_REFUSED) {
          this._exceptionClose(RCCallEndReason.OTHER_CLIENT_IN_CALL);
        } else {
          this._exceptionClose(RCCallEndReason.NETWORK_ERROR);
        }

        this._logger.info('_', `[RCCallClient _joinRoom] join room failed: roomId -> ${roomId} RCRTCCode -> ${code}`);
        return { code: RCCallErrorCode.JOIN_ROOM_ERROR };
      }

      /**
       * 群聊本端加入房间后，更新人员状态为通话中
       */
      const conversationType = this._stateMachine.getConversationType();
      conversationType === ConversationType.GROUP && this._stateMachine.userJoin([this._rtcClient.getCurrentId()]);

      /**
       * 针对私有云 @rongcloud/plugin-rtc@5.1.10-enterprise.7 增加补丁，
       * 私有云升完 RTC sdk 版本后删掉此补丁
       * 加完房间后，对方挂断了，需退出房间
       */
      if (this._stateMachine.getState() === RCCallSessionState.END) {
        await this._rtcClient.leaveRoom(room!);
        this._room = null as unknown as RCRTCRoom;
        return { code: RCCallErrorCode.SUCCESS };
      }

      // 被叫方加入房间成功，但主叫方在加入房间前离线
      if (userIds!.length < 1) {
        this.joinRoomTimer = new Timer(() => {
          this._exceptionClose(RCCallEndReason.REMOTE_NETWORK_ERROR);
        }, 60000);
      }
      this._room = room as RCRTCRoom;
    } catch (error) {
      this._exceptionClose(RCCallEndReason.NETWORK_ERROR);
      this._logger.error('_', `[RCCallSession _joinRoom] _rtcClient.joinRTCRoom throw exception roomId -> ${roomId}`);
      console.error(error);
      return { code: RCCallErrorCode.JOIN_ROOM_ERROR };
    }

    // 房间上注册监听事件
    this._registerRoomEventListener();

    // 注册房间质量数据监听器
    this._registerReportListener();

    try {
      // 订阅远程的流，把远程的流抛给用户
      await this._subscribeInRoomRemoteTrack();
    } catch (error) {
      // 结束通话session
      this._exceptionClose(RCCallEndReason.SUBSCRIBE_ERROR);
      this._logger.error('_', `[RCCallSession _joinRoom] _subscribeInRoomRemoteTrack Exception roomId -> ${roomId}`);
      console.error(error);
      return { code: RCCallErrorCode.JOIN_ROOM_ERROR };
    }

    try {
      // 往房间里发布本地资源
      await this._publish();
    } catch (error) {
      // 结束通话session
      this._exceptionClose(RCCallEndReason.PUBLISH_ERROR);
      this._logger.error('_', `[RCCallSession _joinRoom] _publish Exception roomId -> ${roomId}`);
      console.error(error);
      return { code: RCCallErrorCode.JOIN_ROOM_ERROR };
    }
    return { code: RCCallErrorCode.SUCCESS };
  }

  /**
   * (初始化房间的时候) 订阅远程的流，把远程的流抛给用户
   */
  private async _subscribeInRoomRemoteTrack() {
    // 获取所有远程已发布的音视频资源列表
    const tracks: RCRemoteTrack[] = this._room.getRemoteTracks();
    if (tracks.length) {
      const { code } = await this._subscribeRetry(tracks, this._options.isAllowSubscribeRetry, this._RETRYCOUNT);
      if (code !== RCRTCCode.SUCCESS) {
        this._exceptionClose(RCCallEndReason.SUBSCRIBE_ERROR);
        this._logger.error('_', `[RCCallSession _subscribeInRoomRemoteTrack] Resource subscription failed roomId -> ${this._stateMachine.getCallId()} RTC code -> ${code}`);
      }
    }
  }

  /**
   * 可以重试的订阅
   * @param params.tracks tracks
   * @param params.isAllowSubscribeRetry 是否允许重试
   * @param params.count 允许重试的次数
   */
  private async _subscribeRetry(tracks: RCRemoteTrack[], isAllowSubscribeRetry: boolean = false, count: number = 0): Promise<{ code: RCRTCCode }> {
    const { code } = await this._room.subscribe(tracks);
    if (code !== RCRTCCode.SUCCESS) {
      try {
        this._listener!.onTrackSubscribeFail && this._listener!.onTrackSubscribeFail(code, this);
      } catch (error) {
        this._logger.error('_', '[RCCallSession] _listener.onTrackSubscribeFail exception');
        console.error(error);
      }

      // 如果不允许重试，直接返回
      if (!isAllowSubscribeRetry) {
        return { code };
      }
      if (count > 0) {
        count--;
        return this._subscribeRetry(tracks, isAllowSubscribeRetry, count);
      }
    }
    return { code };
  }

  /**
   * 发布本地资源的逻辑
   *
   */
  private async _publish() {
    const tracks = this._options.localTracks!;
    const { code } = await this._publishRetry(tracks, this._options.isAllowPublishRetry, this._RETRYCOUNT);

    // 若资源发布失败
    if (code !== RCRTCCode.SUCCESS) {
      this._exceptionClose(RCCallEndReason.PUBLISH_ERROR);
      this._logger.info('_', `[RCCallSession _publist] Resource publishing failed: roomId -> ${this._stateMachine.getCallId()} RCRTCCode -> ${code}`);
      return;
    }

    // 如果是主动发起的呼叫，已提前抛出了资源, 被动呼叫，这里才需要抛出
    if (this._options.produceType === ProduceTypes.CALLEE) {
      // 向外抛出本地流, 通知业务层trackReady
      this._notifyTrackReady(tracks);
    }
  }

  /**
   * 可以重试的发布
   * @param params.tracks tracks
   * @param params.isAllowPublishRetry 是否允许重试
   * @param params.count 允许重试的次数
   */
  private async _publishRetry(tracks: RCLocalTrack[], isAllowPublishRetry: boolean = false, count: number = 0): Promise<{ code: RCRTCCode }> {
    const { code } = await this._room.publish(tracks);
    if (code !== RCRTCCode.SUCCESS) {
      try {
        this._listener!.onTrackPublishFail && this._listener!.onTrackPublishFail(code, this);
      } catch (error) {
        this._logger.error('_', '[RCCallSession] _listener.onTrackPublishFail exception');
        console.error(error);
      }

      // 如果不允许重试，直接返回
      if (!isAllowPublishRetry) {
        return { code };
      }
      if (count > 0) {
        count--;
        return this._publishRetry(tracks, isAllowPublishRetry, count);
      }
    }
    return { code };
  }

  /**
   * 退出房间
   */
  private async _leaveRoom() {
    try {
      // 退出房间
      const callBack = await this._rtcClient.leaveRoom(this._room);
      // 成功退出房间，触发RCCallClient实例上的onSessionClose监听，抛给用户信息
      this._logger.info('_', `[RCCallSession _leaveRoom] Successfully exited the room code: ${callBack.code}`);
    } catch (error) {
      this._logger.error('_', '[RCCallSession _leaveRoom] leaveRoom throw exception');
      console.error(error);
    } finally {
      const summaryInfo = this._stateMachine.getSummary();
      eventEmitter.emit('sessionClose', { session: this, summaryInfo });
    }
  }

  /**
   * 出现异常后要处理的逻辑,
   * @param endReason 原因
   */
  private _exceptionClose(endReason: RCCallEndReason) {
    // 销毁本地流
    this._options.localTracks && this._destroyTracks(this._options.localTracks);

    // 结束状态机
    this._stateMachine.close(endReason);
  }

  /**
   * 用户调用的，注册session上的监听
   */
  public registerSessionListener(listener: ISessionListener): void {
    // 先校验listener, 如果不通过，会trow error
    const conclusion: IValidationResult = validateListener(listener);
    if (!conclusion.result) {
      throw new Error(`[RCCallSession registerSessionListener] ${conclusion.msg}`);
    }
    this._listener = { ...listener };
  }

  /**
   * 调RTC API 获得本地流
   */
  private async _getLocalTrackCore(mediaType: RCCallMediaType, constraints?: IMediaStreamConstraints): Promise<{ code: RCCallErrorCode, tracks?: RCLocalTrack[] }> {
    // 检测是否能够获得本地流
    if (mediaType === RCCallMediaType.AUDIO) {
      const { code, track } = await this._rtcClient.createMicrophoneAudioTrack('RongCloudRTC', constraints && constraints.audio && { ...constraints.audio });
      if (code !== RCRTCCode.SUCCESS) {
        this._logger.error('_', `[RCCallSession _getLocalTrackCore] get Audio local tracks failed RCT code -> ${code}`);
        return { code: RCCallErrorCode.GET_LOCAL_AUDIO_TRACK_ERROR };
      }
      this._logger.info('_', '[RCCallSession _getLocalTrackCore] successfully get Audio local tracks');
      return { code: RCCallErrorCode.SUCCESS, tracks: [track!] };
    }
    const { code, tracks } = await this._rtcClient.createMicrophoneAndCameraTracks('RongCloudRTC', constraints && { ...constraints });
    if (code !== RCRTCCode.SUCCESS) {
      this._logger.error('_', `[RCCallSession _getLocalTrackCore] get Audio and Video local tracks failed RCT code -> ${code}`);
      return { code: RCCallErrorCode.GET_LOCAL_AUDIO_AND_VIDEO_TRACK_ERROR };
    }
    this._logger.info('_', '[RCCallSession _getLocalTrackCore] successfully get audio and video local tracks');
    return { code: RCCallErrorCode.SUCCESS, tracks };
  }

  private async _getLocalTrack(mediaType: RCCallMediaType, constraints?: IMediaStreamConstraints): Promise<{ code: RCCallErrorCode, tracks?: RCLocalTrack[] }> {
    // 并且是获得音视频, 并且 （如果获得音视频不成功，允许降级获得音频）
    if (this._options.isAllowDemotionGetStream && mediaType === RCCallMediaType.AUDIO_VIDEO) {
      const { code, tracks } = await this._getLocalTrackCore(RCCallMediaType.AUDIO_VIDEO, constraints);

      // 如果音视频不能获得，就降级获得音频
      if (code !== RCCallErrorCode.SUCCESS) {
        const { code, tracks } = await this._getLocalTrackCore(RCCallMediaType.AUDIO, constraints);
        if (code !== RCCallErrorCode.SUCCESS) {
          // 获取资源失败，需要调状态机state 为 end
          this._exceptionClose(RCCallEndReason.GET_MEDIA_RESOURCES_ERROR);
          return { code };
        }
        return { code, tracks };
      }
      return { code, tracks };
    }
    const { code: _code, tracks } = await this._getLocalTrackCore(mediaType, constraints);
    if (_code !== RCCallErrorCode.SUCCESS) {
      // 获取资源失败，需要调状态机state 为 end
      this._exceptionClose(RCCallEndReason.GET_MEDIA_RESOURCES_ERROR);

      return { code: _code };
    }
    return { code: _code, tracks };
  }

  /**
   * 通话中更换音频设备
   */
  public async changeAudioDevice(audioConstraints?: IMicphoneAudioProfile): Promise<{ code: RCCallErrorCode }> {
    // 新设备的track
    const recentTracks: RCLocalTrack[] = [];

    // 整理后的本地track
    const localTracks: RCLocalTrack[] = [];
    const { code, track } = await this._rtcClient.createMicrophoneAudioTrack('RongCloudRTC', audioConstraints);
    if (code !== RCRTCCode.SUCCESS) {
      this._logger.error('_', `[RCCallSession changeDevice] get local Audio tracks failed RCTLib code -> ${code}`);
      return { code: RCCallErrorCode.GET_LOCAL_AUDIO_TRACK_ERROR };
    }

    this._options.localTracks && this._options.localTracks.forEach((track: RCLocalTrack) => {
      if (track.isAudioTrack()) {
        // 之前的音频都销毁，（RTCLib SDK内部会在 addLocalTrack 清理同类型轨道数据，所以这里注释掉）
        // track.destroy()
      } else {
        // 只把之前的视频留下
        localTracks.push(track);
      }
    });

    recentTracks.push(track!);

    // 加上本地新产生的音频
    localTracks.push(track!);
    this._options.localTracks = localTracks;
    // 通知业务层trackReady
    this._notifyTrackReady(recentTracks);

    // 如果当前已加入房间，发布新流
    if (this._room) {
      // 发布新流
      const { code } = await this._room.publish(recentTracks);
      if (code !== RCRTCCode.SUCCESS) {
        return { code: RCCallErrorCode.AUDIO_PUBLISH_ERROR };
      }
    }
    return { code: RCCallErrorCode.SUCCESS };
  }

  /**
   * 群呼叫中继续邀请
   * @param userIds 被邀请用户 ID 列表
   * @param options.extra 消息的扩展信息
   * @deprecated 5.1.2 废弃 options.pushTitle 通知的标题
   * @deprecated 5.1.2 废弃 options.pushContent 通知内容
   */
  public async invite(userIds: string[], options: IInviteOptions = {}): Promise<{ code: RCCallErrorCode }> {
    const { extra = '' } = options;
    const pushConfig = this._options.callPushConfig ? this._options.callPushConfig : options.pushConfig;
    const conclusion: IValidationResult[] = [validateUserIds(userIds), validateExtra(extra)];
    if (pushConfig) {
      const { code, errorMsg } = validatePushConfig(pushConfig);
      if (code !== RCCallErrorCode.SUCCESS) {
        this._logger.error('_', `[RCCallSession invite] param error, errorMsg: ${errorMsg}`);
        return { code };
      }
    }
    const messages: string[] = [];
    const result = conclusion.every((obj: IValidationResult) => {
      !obj.result && messages.push(obj.msg!);
      return obj.result;
    });

    if (!result) {
      throw new Error(`[RCCallClient invite] ${messages.join('\n')}`);
    }

    const { code } = await this._stateMachine.invite(userIds, {
      extra, pushConfig,
    });
    return { code };
  }

  /**
   * 同意接听
   */
  public async accept(constraints?: IMediaStreamConstraints): Promise<{ code: RCCallErrorCode }> {
    const conclusion: IValidationResult = validateMediaStreamConstraints(constraints!);
    if (!conclusion.result) {
      throw new Error(`[RCCallSession accept] ${conclusion.msg}`);
    }

    // 接听之前，先挂断当前之外的session，现阶段不允许用户先择接听session，事先会在状态机内部挂断，这里抛出去，会清理其它的seesion
    eventEmitter.emit('hungupOtherSession', { session: this });
    const mediaType = this._stateMachine.getMediaType();
    const { code: _code, tracks } = await this._getLocalTrack(mediaType, constraints);
    if (_code !== RCCallErrorCode.SUCCESS) {
      return { code: _code };
    }
    this._options.localTracks = tracks;

    // 发送接听的消息
    const { code } = await this._stateMachine.accept();
    if (code !== RCCallErrorCode.SUCCESS) {
      this._logger.error('_', `[RCCallSession accept]Send accept message failed -> code: ${code}`);
      return { code };
    }
    return { code };
  }

  /**
   * 挂断
   */
  public async hungup(): Promise<{ code: RCCallErrorCode; }> {
    // const summaryInfo = this._stateMachine.getSummary()
    // eventEmitter.emit('sessionClose', { session: this, summaryInfo })
    return this._stateMachine.hungup();
  }

  /**
   * 通话媒体变更
   *  @param mediaType RCCallMediaType.AUDIO 改为音频通话 | RCCallMediaType.AUDIO_VIDEO 改为音视频通话
   */
  public async _changeMediaType(mediaType: RCCallMediaType): Promise<{ code: RCCallErrorCode }> {
    const { code } = await this._stateMachine.changeMediaType(mediaType);
    if (code !== RCCallErrorCode.SUCCESS) {
      this._logger.error('_', `[RCCallSession _changeMediaType] change media type fail code-> ${code}`);
    }
    return { code };
  }

  /**
   * 获得本地视频
   */
  private _getLocalVideoTracks(): RCLocalTrack[] {
    let localVideoTracks: RCLocalTrack[] = [];
    if (!this._room) {
      return localVideoTracks;
    }
    if (this._options.localTracks) {
      localVideoTracks = this._options.localTracks.filter((track) => track.isVideoTrack());
    }
    return localVideoTracks;
  }

  /**
   * 获得本地音频
   */
  private _getLocalAudioTracks(): RCLocalTrack[] {
    let localAudiotracks: RCLocalTrack[] = [];
    if (!this._room) {
      return localAudiotracks;
    }
    if (this._options.localTracks) {
      localAudiotracks = this._options.localTracks.filter((track) => track.isAudioTrack());
    }
    return localAudiotracks;
  }

  /**
   * 把通话的MediaType升级到音视频
   */
  private async _setMediaTypeToAudioAndVideo() {
    // 获得本端视频资源
    const { code, track } = await this._rtcClient.createCameraVideoTrack();
    if (code !== RCRTCCode.SUCCESS) {
      return { code: RCCallErrorCode.GET_LOCAL_AUDIO_AND_VIDEO_TRACK_ERROR };
    }

    // 发布本端视频资源
    const { code: _code } = await this._room.publish([track!]);

    // 若资源发布失败
    if (_code !== RCRTCCode.SUCCESS) {
      this._logger.error('_', `[RCCallSession _enableVideo] Resource publishing failed: RCRTCCode -> ${code}`);
      return;
    }

    // 通知业务层trackReady
    this._notifyTrackReady([track!]);

    // 发消息
    this._changeMediaType(RCCallMediaType.AUDIO_VIDEO);
  }

  /**
   * 把通话的MediaType降级到音频
   * @param isSendMesssage 是否需要发消息, 默认发消息
   */
  private async _setMediaTypeToAudio() {
    const tracks: RCLocalTrack[] = this._getLocalVideoTracks();
    if (tracks.length) {
      // 禁用视频
      tracks.forEach((track: RCLocalTrack) => {
        track.mute();
      });

      // 取消发布视频
      const { code } = await this._room.unpublish(tracks);
      if (code !== RCRTCCode.SUCCESS) {
        this._logger.error('_', `[RCCallSession disableVideo] unpublish failed -> ${code}`);
      }

      // 关闭摄像头
      this._destroyTracks(tracks);
    }
  }

  /**
   * 通话降级，目前需求只做通话降级，音视频可以降级为音频，音频不能升到音视频, 发消息成功才算降级成功
   *
   */
  public async descendAbility(): Promise<{ code: RCCallErrorCode }> {
    const { code } = await this._changeMediaType(RCCallMediaType.AUDIO);
    if (code === RCCallErrorCode.SUCCESS) {
      this._setMediaTypeToAudio();
    }
    return { code };
  }

  /**
   * 禁用视频track
   */
  public async disableVideoTrack(): Promise<{ code: RCCallErrorCode }> {
    if (!this._room) {
      this._logger.error('_', `[RCCallSession disableAudioTrack] Room missing audio track -> ${RCCallErrorCode.NOT_IN_ROOM_ERROR}`);
      return { code: RCCallErrorCode.NOT_IN_ROOM_ERROR };
    }

    const tracks: RCLocalTrack[] = this._getLocalVideoTracks();
    if (!tracks.length) {
      this._logger.error('_', `[RCCallSession disableVideoTrack] Room missing video track -> ${RCCallErrorCode.MISSING_VIDEO_TRACK_ERROR}`);
      return { code: RCCallErrorCode.MISSING_VIDEO_TRACK_ERROR };
    }

    // 禁用视频
    tracks.forEach((track: RCLocalTrack) => {
      track.mute();
    });

    // 如果不需关闭摄像头
    if (!this._options.isOffCameraWhenVideoDisable) {
      return { code: RCCallErrorCode.SUCCESS };
    }

    // 取消发布视频
    const { code } = await this._room.unpublish(tracks);
    if (code !== RCRTCCode.SUCCESS) {
      this._logger.error('_', `[RCCallSession disableVideo] unpublish failed -> ${code}`);

      return { code: RCCallErrorCode.UNPUBLISH_VIDEO_ERROR };
    }

    tracks.forEach((track: RCLocalTrack) => {
      // 关闭摄像头
      track.destroy();
    });

    return { code: RCCallErrorCode.SUCCESS };
  }

  /**
   * 启用视频track
   */
  public async enableVideoTrack(): Promise<{ code: RCCallErrorCode }> {
    if (!this._room) {
      this._logger.error('_', `[RCCallSession disableAudioTrack] Room missing audio track -> ${RCCallErrorCode.NOT_IN_ROOM_ERROR}`);
      return { code: RCCallErrorCode.NOT_IN_ROOM_ERROR };
    }

    // 如果不需关闭摄像头
    if (!this._options.isOffCameraWhenVideoDisable) {
      const tracks: RCLocalTrack[] = this._getLocalVideoTracks();
      if (!tracks.length) {
        this._logger.error('_', `[RCCallSession EnableVideoTrack] Room missing video track -> ${RCCallErrorCode.MISSING_VIDEO_TRACK_ERROR}`);
        return { code: RCCallErrorCode.MISSING_VIDEO_TRACK_ERROR };
      }

      // 启用视频
      tracks.forEach((track: RCLocalTrack) => {
        track.unmute();
      });
      return { code: RCCallErrorCode.SUCCESS };
    }

    // 获得本端视频资源
    const { code, track } = await this._rtcClient.createCameraVideoTrack();
    if (code !== RCRTCCode.SUCCESS) {
      this._logger.error('_', `[RCCallSession EnableVideoTrack] Get Resource failed: RCRTCCode -> ${code}`);
      return { code: RCCallErrorCode.GET_LOCAL_VIDEO_TRACK_ERROR };
    }
    const localTracks: RCLocalTrack[] = [];
    this._options.localTracks && this._options.localTracks.forEach((track: RCLocalTrack) => {
      if (track.isVideoTrack()) {
        // 之前的视频都销毁
        track.destroy();
      } else {
        // 只留下之前的音频
        localTracks.push(track);
      }
    });

    // 加上本地新产生的视频
    localTracks.push(track!);
    this._options.localTracks = localTracks;

    // 为了触发对方的onVideoMuteChange 先禁用
    track!.mute();

    // 发布本端视频资源
    const { code: _code } = await this._room.publish([track!]);

    // 若资源发布失败
    if (_code !== RCRTCCode.SUCCESS) {
      this._logger.error('_', `[RCCallSession EnableVideoTrack] Resource publishing failed: RCRTCCode -> ${code}`);
      return { code: RCCallErrorCode.VIDEO_PUBLISH_ERROR };
    }

    // 启用
    track!.unmute();

    // 通知业务层trackReady
    this._notifyTrackReady([track!]);
    return { code: RCCallErrorCode.SUCCESS };
  }

  /**
   * 禁用音频track
   */
  public async disableAudioTrack() {
    if (!this._room) {
      this._logger.error('_', `[RCCallSession disableAudioTrack] Room missing audio track -> ${RCCallErrorCode.NOT_IN_ROOM_ERROR}`);
      return { code: RCCallErrorCode.NOT_IN_ROOM_ERROR };
    }

    const tracks: RCLocalTrack[] = this._getLocalAudioTracks();

    // 禁用音频
    tracks.forEach((track: RCLocalTrack) => {
      track.mute();
    });
  }

  /**
   * 启用音频track
   */
  public async enableAudioTrack() {
    if (!this._room) {
      this._logger.error('_', `[RCCallSession disableAudioTrack] Room missing audio track -> ${RCCallErrorCode.NOT_IN_ROOM_ERROR}`);
      return { code: RCCallErrorCode.NOT_IN_ROOM_ERROR };
    }

    const tracks: RCLocalTrack[] = this._getLocalAudioTracks();

    if (!tracks.length) {
      this._logger.error('_', `[RCCallSession disableAudioTrack] Room missing audio track -> ${RCCallErrorCode.MISSING_VIDEO_TRACK_ERROR}`);
      return { code: RCCallErrorCode.MISSING_VIDEO_TRACK_ERROR };
    }

    // 启用音频
    tracks.forEach((track: RCLocalTrack) => {
      track.unmute();
    });
  }

  /**
   * 销毁本地流
   */
  private _destroyTracks(tracks: RCLocalTrack[]) {
    tracks.forEach((track: RCLocalTrack) => {
      track.destroy();
    });
  }

  /**
   * 向外抛出本地流
   */
  private _notifyTrackReady(tracks: RCLocalTrack[] | RCRemoteTrack[]) {
    tracks.forEach((track: RCLocalTrack | RCRemoteTrack) => {
      try {
        this._listener!.onTrackReady(track, this);
      } catch (error) {
        this._logger.error('_', '[RCCallSession _notifyTrackReady] _listener onTrackReady exception');
        console.error(error);
      }
    });
  }

  /**
   * 房间上注册事件
   */
  private _registerRoomEventListener() {
    this._room.registerRoomEventListener(
      {
        /**
         * 本端被踢出房间时触发
         * @description 被踢出房间可能是由于服务端超出一定时间未能收到 rtcPing 消息，所以认为己方离线。
         * 另一种可能是己方 rtcPing 失败次数超出上限，故而主动断线
         * @param byServer
         * 当值为 false 时，说明本端 rtcPing 超时
         * 当值为 true 时，说明本端收到被踢出房间通知
         */
        onKickOff: (byServer: boolean, state?: RCKickReason | undefined) => {
          const currentUserId: string = this._rtcClient.getCurrentId();
          this._stateMachine.userLeave([currentUserId]);

          if (!byServer) {
            this._exceptionClose(RCCallEndReason.NETWORK_ERROR);
          } else {
            if (state === RCKickReason.SERVER_KICK) {
              this._exceptionClose(RCCallEndReason.KICKED_BY_SERVER);
            }
            if (state === RCKickReason.OTHER_KICK) {
              this._exceptionClose(RCCallEndReason.OTHER_CLIENT_JOINED_CALL);
            }
          }
        },
        /**
         * 接收到房间信令时回调，用户可通过房间实例的 `sendMessage(name, content)` 接口发送信令
         * @param name 信令名
         * @param content 信令内容
         * @param senderUserId 发送者 Id
         * @param messageUId 消息唯一标识
         */
        onMessageReceive(name: string, content: any, senderUserId: string, messageUId: string) {
        },
        /**
         * 监听房间属性变更通知
         * @param name
         * @param content
         */
        onRoomAttributeChange(name: string, content: string) {
        },
        /**
         * 发布者禁用/启用音频
         * @param audioTrack RCRemoteAudioTrack 类实例
         */
        onAudioMuteChange: (audioTrack: RCRemoteAudioTrack) => {
          this._logger.info('_', `[RCCallSession onAudioMuteChange] userId->${audioTrack.getUserId()} muted -> ${audioTrack.isOwnerMuted()}`);
          const muteUser: IMuteUser = {
            userId: audioTrack.getUserId(),
            muted: audioTrack.isOwnerMuted(),
            kind: 'audio',
            trackId: audioTrack.getTrackId(),
          };
          try {
            // 通知给业务
            this._listener!.onAudioMuteChange(muteUser, this);
          } catch (error) {
            this._logger.error('_', '[RCCallSession onAudioMuteChange] Missing listening method -> onTrackMuteChange');
            console.error(error);
          }
        },
        /**
         * 发布者禁用/启用视频
         * @param videoTrack RCRemoteVideoTrack 类实例对象
         */
        onVideoMuteChange: (videoTrack: RCRemoteVideoTrack) => {
          this._logger.info('_', `[RCCallSession onVideoMuteChange]userId->${videoTrack.getUserId()} muted -> ${videoTrack.isOwnerMuted()}`);
          const muteUser: IMuteUser = {
            userId: videoTrack.getUserId(),
            muted: videoTrack.isOwnerMuted(),
            kind: 'video',
            trackId: videoTrack.getTrackId(),
          };

          try {
            // 通知给业务
            this._listener!.onVideoMuteChange(muteUser, this);
          } catch (error) {
            this._logger.error('_', '[RCCallSession onVideoMuteChange] Missing listening method -> onVideoMuteChange');
            console.error(error);
          }
        },
        /**
         * 房间内其他用户新发布资源时触发
         * 如需获取加入房间之前房间内某个用户发布的资源列表，可使用 room.getRemoteTracksByUserId('userId') 获取
         * @param tracks 新发布的音轨与视轨数据列表，包含新发布的 RCRemoteAudioTrack 与 RCRemoteVideoTrack 实例
         */
        onTrackPublish: async (tracks: RCRemoteTrack[]) => {
          // 退出房间后，还会走到这？？，所以判断一下，没有room不执行订阅
          if (this._room) {
            // 按业务需求选择需要订阅资源，通过 room.subscribe 接口进行订阅
            const { code } = await this._room.subscribe(tracks);
            if (code !== RCRTCCode.SUCCESS) {
              this._logger.error('_', `[RCCallSession onTrackPublish] subscribe failed RTCCode ->${code}`);
            }
          }
        },
        /**
         * 房间用户取消发布资源
         * @param tracks 被取消发布的音轨与视轨数据列表
         * @description 当资源被取消发布时，SDK 内部会取消对相关资源的订阅，业务层仅需处理 UI 业务
         */
        onTrackUnpublish: (tracks: RCRemoteTrack[]) => {

        },
        /**
         * 订阅的音视频流通道已建立, track 已可以进行播放
         * @param track RCRemoteTrack 类实例
         */
        onTrackReady: (track: RCRemoteTrack) => {
          const mediaType = this._stateMachine.getMediaType();

          // 有时对方没有降级成功，扔抛过来视频，这时的视频不对外抛出
          if (mediaType === RCCallMediaType.AUDIO && track.isVideoTrack()) {
            return;
          }

          // 执行用户的onTrackReady监听
          this._notifyTrackReady([track]);
        },
        /**
         * 人员加入
         * @param userIds 加入的人员 id 列表
         */
        onUserJoin: (userIds: string[]) => {
          // 有人加入清除定时器
          if (this.joinRoomTimer) {
            this.joinRoomTimer.stop();
          }
          this._stateMachine.userJoin(userIds);
        },
        /**
         * 人员退出
         * @param userIds
         */
        onUserLeave: (userIds: string[]) => {
          this._logger.info('_', `[RCCallSession onUserLeave] listening onUserLeave userIds -> ${userIds?.join(',')}`);
          this._stateMachine.userLeave(userIds);
        },
        /**
         * RTC 每次 Ping 结果
         */
        onPing: (result: RCRTCPingResult) => {
          this._logger.info('_', `[RCCallSession onPing]${result}`);
          try {
            // 通知给业务
            this._listener!.onPing && this._listener!.onPing(result, this);
          } catch (error) {
            this._logger.error('_', '[RCCallSession onPing] listening onPing exception');
            console.error(error);
          }
        },
      },
    );
  }

  /**
   * 注册房间质量数据监听器
   */
  private _registerReportListener() {
    // 注册房间质量数据监听器
    this._room.registerReportListener({
      /**
       * 用于接收状态数据报告
       * @param report
       */
      onStateReport: (report: IRCRTCStateReport) => {
        try {
          this._listener!.onRTCStateReport && this._listener!.onRTCStateReport(report, this);
        } catch (error) {
          this._logger.error('_', '[RCCallSession onStateReport] listener onStateReport exception');
          console.error(error);
        }
      },

      /**
       * ~ICE 连接状态变更通知~
       * @since version 5.1.5
       */
      onICEConnectionStateChange: (state: RTCIceConnectionState) => {
        try {
          this._listener!.onICEConnectionStateChange && this._listener!.onICEConnectionStateChange(state, this);
        } catch (error) {
          this._logger.error('_', '[RCCallSession onICEConnectionStateChange] onICEConnectionStateChange exception');
          console.error(error);
        }
      },

    });
  }

  /**
   *  通话唯一标识
   */
  public getSessionId(): string {
    return this._stateMachine.getCallId();
  }

  /**
   *  获取房间当前会话 Id，当房间内已无成员时房间会回收，重新加入时 sessionId 将更新，(用户录制资源用的)
   */
  public getRTCSessionId(): string | null {
    return this._room ? this._room.getSessionId() : null;
  }

  /**
   *  目标 ID，单呼对方人员 Id, 群呼群组 Id
   */
  public getTargetId(): string {
    return this._stateMachine.getTargetId();
  }

  /**
   *  获取会话类型
   */
  public getConversationType(): ConversationType {
    return this._stateMachine.getConversationType();
  }

  /**
   *  组织 ID
   */
  public getChannelId(): string {
    return this._stateMachine.getChannelId();
  }

  /**
   * 房间人员列表，不包含本端信息
   */
  public getRemoteUsers(): IUserData[] {
    return this._stateMachine.getRemoteUsers();
  }

  /**
 * 房间人员列表，不包含本端信息
 */
  public getUsers(): IUserData[] {
    return this._stateMachine.getRemoteUsers();
  }

  /**
   * 获取人员状态
   */
  public getUserState(userId: string): RCCallUserState {
    if (!userId || typeof userId !== 'string') {
      throw new Error('userId is required, must be of type \'string\'');
    }
    return this._stateMachine.getUserState(userId);
  }

  /**
   * 获取session状态
   */
  public getState(): RCCallSessionState {
    return this._stateMachine.getState();
  }

  /**
   * 获得会话发起者id
   */
  public getCallerId(): string {
    return this._stateMachine.getCallerId();
  }

  /**
   * 获得mediaType
   */
  public getMediaType(): RCCallMediaType {
    return this._stateMachine.getMediaType();
  }
}
