/**
 * 自定义合流布局时，背景图片填充方式
 */
export enum BackgroundPictureFillMode {
  /**
   * 裁剪（默认）
   */
  CROP = 1,
  /**
   * 不裁剪
   */
  WHOLE = 2
}
