export enum RCCallSessionState {
  /**
   * 等待建立连接
   */
  WAITING,
  /**
   * 会话维持中
   */
  KEEPING,
  /**
   * 会话已结束
   */
  END
}
