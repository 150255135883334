import {
  BasicLogger, ConversationType, IReceivedMessage, IRuntime, RTCPluginContext,
} from '@rongcloud/engine';
import { RCCallStateMachine } from './core/StateMachine';
import {
  ICallStateMachineWatchers, IEndSummary, IInvitedUsers, IMediaModifyInfo, IMemberModifyInfo, ISenderInfo, IStateChangeInfo, IUserProfile, IUserStateChangeInfo, IUserData, IInviteOptions,
} from './core/interfaces/IStateMachine';
import { RCCallErrorCode } from './core/enums/RCCallCode';
import { RCCallUserState } from './core/enums/RCCallUserState';
import { RCCallSessionState } from './core/enums/RCCallSessionState';
import { RCCallEndReason } from './core/enums/RCCallEndReason';
import { RCCallMediaType } from './core/enums/RCCallMediaType';
import { CallMessageHandler } from './core/MessageHandler';
import { eventEmitter, generateRandomId, getCallDeviceId } from './helper';
import { RCCallLanguage } from './core/enums/RCCallLang';
import { ICallEngineOptions, ICallEngineWatchers } from './core/interfaces/ICallEngine';
import { RCCallMessageType } from './core/enums/RCCallMessageType';
import { IInviteMsgContent } from './core/interfaces/IMessageHandler';
import { Local } from './core/locale';
import { IOfflineRecord } from './core/OfflineRecorder';
import { RCCrossCallType } from './core/enums/RCCrossCallType';
import { IPushConfig } from '../src/interface';

class RCCallEngine {
  private _stateMachine: { [callId: string]: RCCallStateMachine } = {}

  private _callMsgHandler: CallMessageHandler

  /**
   * 初始化
   */
  constructor(
    /**
     * engine PlguinContext 实例
     */
    private readonly _context: RTCPluginContext,
    /**
     * 运行时相关
     */
    private readonly _runtime: IRuntime,
    /**
     * engine 日志模块实例，由 CallLib 层初始化
     */
    private readonly _logger: BasicLogger,
    /**
     * 监听方法
     */
    private readonly _watchers: ICallEngineWatchers,
    /**
     * 其他配置项
     */
    private readonly _options: ICallEngineOptions,
  ) {
    this._logger.warn('_', `RCCallEngine Version: ${__VERSION__} CommitId: ${__COMMIT_ID__}`);
    // 设置 DeviceId
    getCallDeviceId(_runtime);
    // 监听 IM 消息
    this._callMsgHandler = new CallMessageHandler(this._context, this._runtime, this._logger, this._options.offlineMsgItv, this._getStateMachine.bind(this));
    // 注册消息模块监听
    this._callMsgHandler.registerEventListener({
      onInvite: this._onInvite.bind(this),
      sendAccept: this._handleSendAccept.bind(this),
      onOfflineRecord: this._watchers.onOfflineRecord,
    });
    // 监听状态机关闭
    eventEmitter.on('onStateMachineClose', (callId: string) => {
      delete this._stateMachine[callId];
    });
    // 设置语言
    Local.set(_options.lang);
  }

  private _onInvite(msg: IReceivedMessage) {
    const {
      channelId, conversationType, targetId, content, messageType, senderUserId: suid, pushConfig,
    } = msg;
    this._logger.warn('_', `RCCallEngine _onInvite:targetId ${targetId} senderUserId: ${suid}`);
    const {
      mediaType, callId, extra, roomType,
    } = content as IInviteMsgContent;
    let senderUserId: string;
    if (roomType !== RCCrossCallType.RCCallRoomTypeAcrossCall) {
      senderUserId = suid;
    } else {
      [, senderUserId] = suid.split('_');
    }
    const crtUserId = this._context.getCurrentId();
    if (this._context.getCurrentId() === senderUserId) { // 多端处理
      return;
    }

    /**
     * 群聊通话有其他端处理时，不再处理此通话中的邀请消息
     * 条件1: 此人已被邀请通话
     * 条件2: 本端无此通话的状态机
     */
    if (messageType === RCCallMessageType.VCModifyMem) {
      const isKeeping = content.existedUserPofiles.some((item: { userId: string }) => (item.userId === crtUserId));
      if (isKeeping && !this._stateMachine[callId]) {
        return;
      }
    }

    const stateMachine = this._stateMachine[callId];
    if (!stateMachine) {
      this._stateMachine[callId] = new RCCallStateMachine(
        this._context,
        this._runtime,
        this._logger,
        this._callMsgHandler,
        channelId!,
        conversationType,
        targetId,
        mediaType,
        callId,
      );
      this._logger.info('_', `[RCCallEngine] RCCallStateMachine successfully created -> callId: ${callId}`);

      if (messageType === RCCallMessageType.VCInvite) {
        // 状态机内部处理 invite 消息
        this._stateMachine[callId].__onInvite(msg);
      } else if (messageType === RCCallMessageType.VCModifyMem) {
        this._stateMachine[callId].__onMemberModify(msg);
      }
      this._watchers.onInvite(this._stateMachine[callId], extra);

      const hasOtherStateMachine = Object.keys(this._stateMachine).filter((otherCallId) => callId !== otherCallId).length > 0;
      if (hasOtherStateMachine && !(this._options.isAllowAcceptNewCall || false)) {
        this._stateMachine[callId].__handleInviteInSession();
      }
    } else if (messageType === RCCallMessageType.VCModifyMem) {
      this._stateMachine[callId].__onMemberModify(msg);
    }
  }

  /**
   * 允许接听新的通话时，接听完新的通话后，挂断其他通话
   */
  private _handleSendAccept(info: { callId: string }) {
    if (this._options.isAllowAcceptNewCall) {
      const { callId } = info;
      for (const id in this._stateMachine) {
        if (callId !== id) {
          this._stateMachine[id].hungup();
          delete this._stateMachine[id];
        }
      }
    }
  }

  /**
   * 根据 callId 获取状态机
  */
  private _getStateMachine(callId: string): RCCallStateMachine | null {
    return this._stateMachine[callId];
  }

  /**
   * 注册用户信息, 发送 call 消息时用户信息携带
   */
  registerUserInfo(userInfo: IUserProfile) {
    this._logger.debug('_', `[RCCallEngine] registerUserInfo -> userInfo: ${JSON.stringify(userInfo)}`);

    this._callMsgHandler.registerUserInfo(userInfo);
  }

  /**
   * 单呼
   * @param channelId 组织 ID
   * @param targetId  对方 ID
   * @param mediaType 媒体类型
   * @param extra 消息扩展信息
   * @param pushConfig 推送配置
   */
  async call(
    channelId: string,
    targetId: string,
    mediaType: number,
    extra: string = '',
    pushConfig: IPushConfig,
    isCrossAppkey = false,
  ): Promise<{ code: RCCallErrorCode, stateMachine?: RCCallStateMachine }> {
    this._logger.debug('_', `[RCCallEngine] call -> args: ${JSON.stringify({
      channelId, targetId, mediaType, extra, pushConfig,
    })}`);
    const callId = generateRandomId();
    const hasStateMachine = Object.keys(this._stateMachine).length > 0;
    if (hasStateMachine) {
      return { code: RCCallErrorCode.STATE_MACHINE_EXIT };
    }
    this._stateMachine[callId] = new RCCallStateMachine(
      this._context,
      this._runtime,
      this._logger,
      this._callMsgHandler,
      channelId,
      ConversationType.PRIVATE,
      targetId,
      mediaType,
      callId,
    );
    const { code } = await this._stateMachine[callId].__call([targetId], extra, pushConfig, isCrossAppkey);
    if (code === RCCallErrorCode.SUCCESS) {
      return {
        code: RCCallErrorCode.SUCCESS,
        stateMachine: this._stateMachine[callId],
      };
    }
    return { code };
  }

  /**
   * 群呼
   * @param channelId 组织 ID
   * @param targetId  群组 ID
   * @param mediaType 媒体类型
   * @param userIds 被邀请人员列表
   * @param extra 消息扩展信息
   * @param pushConfig 推送配置
   */
  async callInGroup(
    channelId: string,
    targetId: string,
    mediaType: number,
    userIds: string[],
    extra: string = '',
    pushConfig: IPushConfig,
  ): Promise<{ code: RCCallErrorCode, stateMachine?: RCCallStateMachine }> {
    this._logger.debug('_', `[RCCallEngine] callInGroup -> args: ${JSON.stringify({ channelId, targetId, mediaType })}`);
    const callId = generateRandomId();
    const hasStateMachine = Object.keys(this._stateMachine).length > 0;
    if (hasStateMachine) {
      return { code: RCCallErrorCode.STATE_MACHINE_EXIT };
    }
    this._stateMachine[callId] = new RCCallStateMachine(
      this._context,
      this._runtime,
      this._logger,
      this._callMsgHandler,
      channelId,
      ConversationType.GROUP,
      targetId,
      mediaType,
      callId,
    );
    const { code } = await this._stateMachine[callId].__call(userIds, extra, pushConfig);
    if (code === RCCallErrorCode.SUCCESS) {
      return {
        code: RCCallErrorCode.SUCCESS,
        stateMachine: this._stateMachine[callId],
      };
    }
    return { code };
  }

  /**
   * 销毁当前的状态机
   */
  destroy() {
    this._logger.debug('_', '[RCCallEngine] destroy');
    this._stateMachine = {};
  }
}

export {
  RCCallEngine,
  RCCallStateMachine, RCCallErrorCode,
  RCCallUserState,
  RCCallSessionState,
  RCCallEndReason,
  RCCallMediaType,
  RCCallLanguage,
};

export type {
  ICallStateMachineWatchers, IEndSummary,
  ICallEngineWatchers,
  IUserStateChangeInfo,
  IStateChangeInfo,
  ISenderInfo,
  IMemberModifyInfo,
  IMediaModifyInfo,
  IInvitedUsers,
  IUserData,
  IUserProfile,
  IOfflineRecord,
  IInviteOptions,
};
