import { RCLoggerTag } from '../enums/RCLoggerTag';
import { RCRTCCode } from '../enums/RCRTCCode';
import { Invoker } from '../Invoker';
import { Store } from '../Store';
import { RCRemoteTrack } from '../tracks/RCRemoteTrack';
import { BaseCommand, ICommandResult } from './BaseCommand';
import { CommandEvent, CommandExecuteContext } from './CommandExecuteContext';
import { UnsubscribeCommand } from './UnsubscribeCommand';

export class OnRemoteUserUnpubCommand extends BaseCommand {
  constructor(
    private tracks: RCRemoteTrack[],
  ) {
    super();
  }

  async execute(executeCtx: CommandExecuteContext, store: Store, invoker: Invoker): Promise<ICommandResult> {
    const { logger } = executeCtx;
    // 需要替业务层取消订阅，业务层只需关注 UI 变化
    const { tracks } = this;
    const traceId = logger.createTraceId()!;
    logger.info(RCLoggerTag.L_USER_LEFT_UNSUBSCRIBE_O, undefined, traceId);
    await new UnsubscribeCommand(tracks, traceId).execute(executeCtx, store, invoker);
    tracks.forEach((item) => {
      item.__innerDestroy();
      store.removeRemoteTrack(item.getTrackId());
    });

    executeCtx.emit(CommandEvent.TRACKS_UNPUBLISH, tracks);

    return { code: RCRTCCode.SUCCESS };
  }
}
