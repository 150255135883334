import { ErrorCode } from '@rongcloud/engine';
import { buildPlusMessage, buildTotalURIMessageContent, getTrackId } from '../../helper';
import { RCRTCMessageType } from '../enums/inner/RCRTCMessageType';
import { RCCommandKind } from '../enums/RCCommandKind';
import { RCLoggerStatus, RCLoggerTag } from '../enums/RCLoggerTag';
import { RCRTCCode } from '../enums/RCRTCCode';
import { IPublishedResource, IResourceEnabled } from '../interfaces';
import { Invoker } from '../Invoker';
import { Store } from '../Store';
import { RCLocalTrack } from '../tracks/RCLocalTrack';
import { BaseCommand, ICommandResult } from './BaseCommand';
import { CommandExecuteContext } from './CommandExecuteContext';

export class LocalTrackMuteCommand extends BaseCommand {
  constructor(
    private localTrack: RCLocalTrack,
  ) {
    super();
  }

  get kind(): RCCommandKind {
    return RCCommandKind.LOCAL_TRACK_MUTE;
  }

  async execute(executeCtx: CommandExecuteContext, store: Store, invoker: Invoker): Promise<ICommandResult> {
    const { logger, context } = executeCtx;
    const { localTrack } = this;

    const trackId = localTrack.getTrackId();
    const { crtUserId, roomId } = store;

    const enabled = !localTrack.isLocalMuted();

    const traceId = logger.createTraceId();
    logger.info(RCLoggerTag.L_LOCAL_TRACK_SET_LOCAL_MUTED_SPREAD_T, JSON.stringify({
      enabled,
      trackId: this.localTrack.getTrackId(),
    }), traceId);

    // 本地资源，需同步房间状态
    const localResource: IResourceEnabled[] = [{ resourceId: trackId, enabled }];

    // 计算更新后的全量资源数据
    const publishedList = store.getResourcesByUserId(crtUserId)!;

    // 增量数据
    const plusList: IPublishedResource[] = [];

    for (const item of publishedList) {
      const id = getTrackId(item);
      const index = localResource.findIndex((item) => item.resourceId === id);
      if (index >= 0) {
        const { enabled } = localResource[index];
        item.state = enabled ? 1 : 0;
        plusList.push(item);
        break;
      }
    }

    const code = await context.setRTCTotalRes(
      roomId,
      [buildPlusMessage(RCRTCMessageType.MODIFY, plusList)],
      buildTotalURIMessageContent(publishedList),
      RCRTCMessageType.TOTAL_CONTENT_RESOURCE,
    );

    if (code !== ErrorCode.SUCCESS) {
      logger.error(RCLoggerTag.L_LOCAL_TRACK_SET_LOCAL_MUTED_SPREAD_R, JSON.stringify({
        status: RCLoggerStatus.FAILED,
        code,
        msg: 'signal error',
      }), traceId);
      return { code };
    }

    logger.info(RCLoggerTag.L_LOCAL_TRACK_SET_LOCAL_MUTED_SPREAD_R, JSON.stringify({
      status: RCLoggerStatus.SUCCESSED,
    }), traceId);

    return { code: RCRTCCode.SUCCESS };
  }
}
