import { RCCommandKind } from '../enums/RCCommandKind';
import { RCLoggerStatus, RCLoggerTag } from '../enums/RCLoggerTag';
import { RCRTCCode } from '../enums/RCRTCCode';
import { Invoker } from '../Invoker';
import { RCLivingPKHandler } from '../room/RCLivingPKHandler';
import { Store } from '../Store';
import { BaseCommand, ICommandResult } from './BaseCommand';
import { CommandExecuteContext } from './CommandExecuteContext';
import { ExchangeCommand } from './ExchangeCommand';
import { createExchangeParams } from './helper';

export class ExchangeWithPushOtherRoomCommand extends BaseCommand {
  constructor(
    private roomId: string,
    private roomPKHandler?: RCLivingPKHandler,
    private traceId?: string,
  ) {
    super();
  }

  /**
   * 携带 pushOtherRooms 与 mediaServer 重新交互
   */
  private async _exchangeWithPushOtherRoom(executeCtx: CommandExecuteContext, store: Store, invoker: Invoker) {
    const { logger, peer } = executeCtx;
    /**
     * 如果己方未发布过资源，无需发请求
     */
    const pubTracks = peer.getLocalTracks();
    if (!pubTracks.length) {
      logger.info(RCLoggerTag.L_EXCHANGE_WITH_PUSHOTHERROOM_R, JSON.stringify({
        status: RCLoggerStatus.SUCCESSED,
        msg: 'have not published resource',
      }), this.traceId);
      return;
    }

    const pc = peer;

    const subscribeList = store.getSubscribedList();
    const reqBody = await createExchangeParams(subscribeList, false, pc, store);

    const pushOtherRooms = executeCtx.getPushOtherRooms();
    reqBody.pushOtherRooms = pushOtherRooms;

    const resp = await new ExchangeCommand(reqBody, this.traceId, true).execute(executeCtx, store, invoker);

    if (resp.code !== RCRTCCode.SUCCESS) {
      logger.error(RCLoggerTag.L_EXCHANGE_WITH_PUSHOTHERROOM_R, JSON.stringify({
        status: RCLoggerStatus.FAILED,
        code: resp.code,
        pushOtherRooms,
      }), this.traceId);
      return;
    }

    const { sdp: answer, resultCode } = resp.data!.data!;
    if (resultCode !== RCRTCCode.SUCCESS) {
      logger.error(RCLoggerTag.L_EXCHANGE_WITH_PUSHOTHERROOM_R, JSON.stringify({
        status: RCLoggerStatus.FAILED,
        code: resultCode,
        pushOtherRooms,
      }), this.traceId);
      return;
    }

    logger.info(RCLoggerTag.L_EXCHANGE_WITH_PUSHOTHERROOM_R, JSON.stringify({
      status: RCLoggerStatus.SUCCESSED,
      pushOtherRooms,
    }), this.traceId);

    pc.setRemoteAnswer(answer.sdp);
  }

  get kind(): RCCommandKind {
    return RCCommandKind.EXCHANGE_WITH_PUSH_OTHER_ROOM;
  }

  async execute(executeCtx: CommandExecuteContext, store: Store, invoker: Invoker): Promise<ICommandResult> {
    const { logger } = executeCtx;
    const { roomId } = this;

    logger.info(RCLoggerTag.L_EXCHANGE_WITH_PUSHOTHERROOM_T, roomId);

    // 离开 PK 房间后，如果参与过连麦，pushOtherRooms 需去掉退出的连麦房间配置，重新和 mediaServer 交互
    if (!this.roomPKHandler) {
      logger.error(RCLoggerTag.L_EXCHANGE_WITH_PUSHOTHERROOM_R, JSON.stringify({
        status: RCLoggerStatus.FAILED,
        msg: '_roomPKHandler not exist',
      }), this.traceId);
      return { code: RCRTCCode.SUCCESS };
    }
    const PKInfo = this.roomPKHandler.getPKInfo(roomId);
    if (!PKInfo) {
      logger.warn(RCLoggerTag.L_EXCHANGE_WITH_PUSHOTHERROOM_R, JSON.stringify({
        status: RCLoggerStatus.FAILED,
        msg: 'PKInfo not exist',
      }), this.traceId);
      return { code: RCRTCCode.SUCCESS };
    }
    await this._exchangeWithPushOtherRoom(executeCtx, store, invoker);
    return { code: RCRTCCode.SUCCESS };
  }
}
