/**
 * 自定义上报北极星数据响应 code
*/
export enum RCSendCode {
  // 不上报
  NOT_REPORT = -1,
  // http 上报成功
  REPORT_SUCCESS = 10000,
  // http 上报失败
  REPORT_FAIL = 0,
  // pb 错误
  PB_ERROR = 40001,
}

/**
 * 上报类型
*/
export enum RCReportType {
  R1 = 'R1',
  R2 = 'R2',
  R3 = 'R3',
  R4 = 'R4'
}
