import { BasicLogger } from '@rongcloud/engine';
import { RCTrack } from './RCTrack';

export abstract class RCRemoteTrack extends RCTrack {
  constructor(logger: BasicLogger, tag: string, userId: string, kind: 'audio' | 'video', roomId?: string) {
    super(logger, tag, userId, kind, false, roomId);
  }

  /**
   * 根据房间数据更新状态
   * @param value
   */
  __innerSetRemoteMuted(bool: boolean) {
    this._remoteMuted = bool;
  }

  private _isSubscribed: boolean = false

  __innerSetSubscribed(bool: boolean) {
    this._isSubscribed = bool;
  }

  /**
   * 查看是否已订阅了该远端资源
   * @returns
   */
  public isSubscribed(): boolean {
    return this._isSubscribed;
  }
}

export class RCRemoteAudioTrack extends RCRemoteTrack {
  constructor(logger: BasicLogger, tag: string, userId: string, roomId?: string) {
    super(logger, tag, userId, 'audio', roomId);
  }

  /**
   * 获取播放时指定的输出音量。若当前未播放，则返回 0。
   * 注意：该音量为对媒体输出设备设定的输出音量，与当前音频内容的 audioLevel 是不同概念。
   * @since - v5.6.12
   */
  getVolume(): number {
    return (this._element?.volume || 0) * 100;
  }
}

export class RCRemoteVideoTrack extends RCRemoteTrack {
  constructor(logger: BasicLogger, tag: string, userId: string, roomId?: string) {
    super(logger, tag, userId, 'video', roomId);
  }

  private _isSubTiny: boolean = false

  __innerSetIsTinyTrack(bool: boolean) {
    this._isSubTiny = bool;
  }

  /**
   * 是否订阅的小流
   */
  public isSubTiny(): boolean {
    return this._isSubTiny;
  }
}
