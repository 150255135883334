import {
  assert,
  IPluginGenerator, IRuntime, isHttpUrl, isNumber, PluginContext, RTCPluginContext, VersionManage, LogL, AssertRules,
} from '@rongcloud/engine';

import RCRTCClient from './core/RCRTCClient';
import RCMediaStreamCapture from './core/RCMediaStreamCapture';
import RCAudienceClient from './core/RCAudienceClient';
import RCLivingRoom from './core/room/RCLivingRoom';
import RCRTCRoom from './core/room/RCRTCRoom';
import {
  RCLocalTrack, RCLocalAudioTrack, RCLocalVideoTrack, RCCameraVideoTrack, RCMicphoneAudioTrack, RCScreenVideoTrack,
  RCLocalFileTrack, RCLocalFileVideoTrack, RCLocalFileAudioTrack, RCScreenAudioTrack,
} from './core/tracks/RCLocalTrack';
import { RCRemoteAudioTrack, RCRemoteTrack, RCRemoteVideoTrack } from './core/tracks/RCRemoteTrack';
import {
  isValidLocation, transResolution, transFrameRate, parseTrackId, ifSupportLocalFileTrack, browserInfo, ifSupportScreenShare,
} from './helper';
import {
  IPublishedResource, IPublishAttrs, IRCRTCInitOptions, IRCRTCReportListener, IRoomEventListener, ISubscribeAttr, IVideoProfile,
  ICameraVideoProfile, IMicphoneAudioProfile, IRCRTCStateReport, IRCCandidatePairStat, IRCTrackStat, IPKInviteInfo,
  IPKInviteAnswerInfo, IPKEndInfo, IReqResPKOptions, IPubSuccessRes, ILiveAudioState, ICreateLocalTrackOptions, IJoinResCDNInfo,
  IAudioLevelChangeHandler, IScreenVideoProfile,
} from './core/interfaces';
import RCMCUConfigBuilder from './core/room/RCMCUConfigBuilder';
import { IMCUOutputConfig, IMCUOutputVideoConfig } from './core/service';
import { RCFrameRate } from './core/enums/RCFrameRate';
import { RCResolution } from './core/enums/RCResolution';
import { RCMediaType } from './core/enums/RCMediaType';
import { BackgroundPictureFillMode } from './core/enums/BackgroundPictureFillMode';
import { MixLayoutMode } from './core/enums/MixLayoutMode';
import { MixVideoRenderMode } from './core/enums/MixVideoRenderMode';
import { RCLivingType } from './core/enums/RCLivingType';
import { RCRTCCode } from './core/enums/RCRTCCode';
import { RCTrack } from './core/tracks/RCTrack';
import RCAbstractRoom from './core/room/RCAbstractRoom';
import RCAudienceLivingRoom, { IAudienceRoomEventListener } from './core/room/RCAudienceLivingRoom';
import { RCInnerCDNPullIsHttps } from './core/enums/RCInnerCDNPullIsHttps';
import { RCInnerCDNPullKind } from './core/enums/RCInnerCDNPullKind';
import { RCRTCLiveRole } from './core/enums/RCRTCLiveRole';
import { RTCJoinType } from './core/enums/RTCJoinType';
import { RCRTCPingResult } from './core/enums/RCRTCPingResult';
import { RCKickReason } from './core/enums/RCKickType';
import { IRoomPKEventListener } from './core/room/RCLivingPKHandler';
import { RCVideoBitrate, RCAudioBitrate } from './core/enums/RCBitrate';
import { desc, keymaps } from './core/codec/proto';
import { RTCContext } from './core/codec/RTCContext';
import { RCLoggerTag } from './core/enums/RCLoggerTag';
import { LogSource } from './core/enums/qualityReport/logger';
import { IRTCUserData } from './core/codec/interface';
import { RC3ANoiseWorklet } from './core/tracks/RC3ANoiseWorklet';
/**
 * RTC 插件生成器
 * @public
 */
export const installer: IPluginGenerator<RCRTCClient, IRCRTCInitOptions> = {
  tag: 'RCRTC',
  verify(runtime: IRuntime) {
    if (!isValidLocation) {
      console.error('Please use the https protocol or use `http://localhost` to open the page!');
      return false;
    }

    VersionManage.add('plugin-rtc', __VERSION__);
    if (!VersionManage.validEngine(__REQUIRED_ENGINE_VERSION__)) {
      console.error(`The current engine version '${VersionManage.getInfo().engine}' error, plugin-rtc required engine version at least '${__REQUIRED_ENGINE_VERSION__}'.`);
      return false;
    }

    return true;
  },
  setup(context: PluginContext, runtime: IRuntime, options: IRCRTCInitOptions = {}) {
    const logger = context.createLogger('RCRTC', LogSource.RTC);
    options.logLevel && logger.setOutputLevel(options.logLevel);

    logger.warn(RCLoggerTag.L_INDEX_INSTALL_RTC_PLUGIN_O, JSON.stringify({
      'RCRTC Version': __VERSION__,
      Commit: __COMMIT_ID__,
      'browserInfo.browser': browserInfo.browser,
      'browserInfo.supportsUnifiedPlan': browserInfo.supportsUnifiedPlan,
      'browserInfo.version': browserInfo.version,
    }));

    // mediaServer 地址为空时，使用导航下发地址
    // 非有效 http URL 地址或页面为 https 协议而 value 为 http 协议时无效
    // 其他情况均不检测，以便于 Electron 下自定义协议能够通过检测
    assert('options', options, AssertRules.OBJECT, true);
    assert('options.mediaServer', options.mediaServer, (value) => !(!isHttpUrl(value) || (location.protocol === 'https:' && !/^https/.test(value))));
    assert('options.timeout', options.timeout, (value) => isNumber(value) && value >= 5000 && value <= 30000);
    assert('options.pingGap', options.pingGap, (value) => isNumber(value) && value >= 3000 && value <= 10000);

    if (options.AAAWorklet) {
      assert('options.AAAWorklet', options.AAAWorklet, AssertRules.OBJECT, true);
      const { scriptUrl, wasmUrl } = options.AAAWorklet;
      assert('AAAWorklet.scriptUrl', scriptUrl, AssertRules.STRING, true);
      assert('AAAWorklet.wasmUrl', wasmUrl, AssertRules.STRING, true);

      RC3ANoiseWorklet.SCRIPT_URL = scriptUrl;
      RC3ANoiseWorklet.WASM_URL = wasmUrl;
    }
    return new RCRTCClient(new RTCContext(context as RTCPluginContext, logger, context.createCodec(keymaps, desc)), runtime, options);
  },
};

/**
 * 预定义的资源 tag
 */
export const RCTag = {
  /**
   * 默认流 Tag 定义
   */
  DEFAULT: 'RongCloudRTC',
};

export {
  RCRTCClient,
  RCMediaStreamCapture,
  RCAudienceClient,
  RCAbstractRoom,
  RCLivingRoom,
  RCAudienceLivingRoom,
  RCMCUConfigBuilder,
  RCRTCRoom,
  RCMediaType,
  RCRTCCode,
  RCLivingType,

  type IPublishAttrs,
  type IPublishedResource,
  type ISubscribeAttr,
  type IRoomEventListener,
  type IRCRTCReportListener,
  type IRCRTCStateReport,
  type ILiveAudioState,
  type IRCCandidatePairStat,
  type IRCTrackStat,
  type IRCRTCInitOptions,
  type IMCUOutputVideoConfig,
  type IMCUOutputConfig,
  MixVideoRenderMode,
  BackgroundPictureFillMode,
  MixLayoutMode,

  type IAudioLevelChangeHandler,
  type ICreateLocalTrackOptions,
  type IVideoProfile,
  type ICameraVideoProfile,
  type IMicphoneAudioProfile,
  type IScreenVideoProfile,
  RCFrameRate,
  RCKickReason,
  RCResolution,

  RCTrack,
  RCLocalTrack,
  RCLocalAudioTrack,
  RCLocalVideoTrack,
  RCMicphoneAudioTrack,
  RCCameraVideoTrack,
  RCScreenVideoTrack,
  RCScreenAudioTrack,
  RCLocalFileTrack,
  RCLocalFileAudioTrack,
  RCLocalFileVideoTrack,
  RCRemoteTrack,
  RCRemoteAudioTrack,
  RCRemoteVideoTrack,

  RCInnerCDNPullIsHttps,
  RCInnerCDNPullKind,
  RCRTCPingResult,
  RTCJoinType,
  type IRTCUserData,
  type IJoinResCDNInfo,

  RCRTCLiveRole,

  type IPKInviteInfo,
  type IReqResPKOptions,
  type IPKInviteAnswerInfo,
  type IPKEndInfo,
  type IRoomPKEventListener,

  type IPubSuccessRes,
  RCVideoBitrate,
  RCAudioBitrate,
  type IAudienceRoomEventListener,
};

export { device } from './device';

export const helper = {
  transResolution,
  transFrameRate,
  parseTrackId,
  ifSupportLocalFileTrack,
  ifSupportScreenShare,
};
