import { gzip as pakoGzip, ungzip as pakoUnGzip } from 'pako';

function gzip(data: Uint8Array|string, options?: object): Uint8Array {
  return <Uint8Array> pakoGzip(data, options);
}

function ungzip(data: Uint8Array, options?: object): Uint8Array|string {
  return <Uint8Array|string> pakoUnGzip(data, options);
}

export { gzip, ungzip };
