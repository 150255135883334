/**
 * 直播角色
 */
export enum RCRTCLiveRole {
  /**
   * 主播降级
   */
  ANCHOR = 1,
  /**
   * 观众升级
   */
  AUDIENCE = 2
}
