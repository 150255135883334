import {
  BasicLogger, isHttpUrl, isString, isUndefined,
} from '@rongcloud/engine';
import { RCRTCCode } from './enums/RCRTCCode';
import {
  ICameraVideoProfile, ICreateLocalTrackOptions, IMicphoneAudioProfile, IScreenVideoProfile,
} from './interfaces';
import {
  RCCameraVideoTrack, RCLocalAudioTrack, RCLocalFileAudioTrack, RCLocalFileTrack, RCLocalFileVideoTrack, RCLocalTrack, RCLocalVideoTrack,
  RCMicphoneAudioTrack, RCScreenAudioTrack, RCScreenVideoTrack,
} from './tracks/RCLocalTrack';
import {
  ifSupportLocalFileTrack, ifSupportScreenShare, isValidResolution, isValidTag, transFrameRate, transResolution,
} from '../helper';
import { RCLoggerStatus, RCLoggerTag } from './enums/RCLoggerTag';
import { RCResolution } from './enums/RCResolution';
import { RCFrameRate } from './enums/RCFrameRate';
import { RTCContext } from './codec/RTCContext';

export default class RCMediaStreamCapture {
  protected readonly _logger: BasicLogger;

  constructor(
    protected readonly _context: RTCContext,
  ) {
    this._logger = _context.logger;
  }

  private _isElectron: boolean = /Electron/.test(navigator.userAgent)

  private async _getMediaStream(constraints: any, method: 'getUserMedia' | 'getDisplayMedia' = 'getUserMedia'): Promise<{ code: RCRTCCode, stream?: MediaStream }> {
    try {
      const constraintsConfig = this.setConstraintsConfig(constraints);
      const stream: MediaStream = await navigator.mediaDevices[method as 'getUserMedia'](constraintsConfig);
      return { code: RCRTCCode.SUCCESS, stream };
    } catch (error: any) {
      this._logger.error(RCLoggerTag.L_GET_MEDIA_STREAM_E, JSON.stringify({
        name: error.name,
        message: error.message,
      }));

      if ((error as DOMException).message === 'Permission denied') {
        return { code: RCRTCCode.BROWSER_PERMISSION_DENIED };
      }
      if ((error as DOMException).message === 'Permission denied by system') {
        return { code: RCRTCCode.SYSTEM_PERMISSION_DENIED };
      }
    }
    return { code: method === 'getUserMedia' ? RCRTCCode.GET_USER_MEDIA_FAILED : RCRTCCode.GET_DISPLAY_MEDIA_FAILED };
  }

  private _getMediaStreamErrorReason(code: RCRTCCode) {
    let errorMsg = '';
    if (code === RCRTCCode.BROWSER_PERMISSION_DENIED) {
      errorMsg = 'Permission denied';
    }
    if (code === RCRTCCode.SYSTEM_PERMISSION_DENIED) {
      errorMsg = 'Permission denied by system';
    }
    return errorMsg;
  }

  /**
   * 如果用户设置了音频约束为true，那么我们将音频约束设置为一个空对象，
   * 然后我们将检查浏览器是否支持noiseSuppression、autoGainControl和echoCancellation约束，
   * 如果支持，那么我们将设置音频约束为真
   * @param {any} constraints - 约束参数与 getUserMedia 方法中的约束参数相同。
   * @returns 返回值是约束对象。
   */
  private setConstraintsConfig(constraints: any): any {
    if (constraints?.audio && !this._isElectron) {
      if (typeof constraints.audio === 'boolean') {
        constraints.audio = {};
      }
      // 获取 mediaDevices 支持的约束条件, 如果硬件支持开启则开启
      const supported = navigator.mediaDevices.getSupportedConstraints?.();
      // 降噪
      if (supported?.noiseSuppression) {
        constraints.audio.noiseSuppression = !!supported.noiseSuppression;
      }
      // 增益
      if (supported?.autoGainControl) {
        constraints.audio.autoGainControl = !!supported.autoGainControl;
      }
      // 回声消除
      if (supported?.echoCancellation) {
        constraints.audio.echoCancellation = !!supported.echoCancellation;
      }
      this._logger.info(RCLoggerTag.L_SET_AUDIO_CONSTRAINTS_O, `browser supported -> ${JSON.stringify(supported)}`);
    }
    return constraints;
  }

  /**
   * 从麦克风中捕获音轨数据
   * @param tag
   * @param options
   * @returns
   */
  async createMicrophoneAudioTrack(tag: string = 'RongCloudRTC', options?: IMicphoneAudioProfile): Promise<{ code: RCRTCCode, track?: RCMicphoneAudioTrack}> {
    if (!isValidTag(tag)) {
      this._logger.error(RCLoggerTag.L_RTC_CLIENT_CREATE_MICROPHONE_AUDIO_TRACK_O, JSON.stringify({
        status: RCLoggerStatus.FAILED,
        code: RCRTCCode.INVALID_TAGS,
        msg: 'invalid tag',
      }));

      return { code: RCRTCCode.INVALID_TAGS };
    }

    const userId = this._context.getCurrentId();
    if (!userId) {
      this._logger.error(RCLoggerTag.L_RTC_CLIENT_CREATE_MICROPHONE_AUDIO_TRACK_O, JSON.stringify({
        status: RCLoggerStatus.FAILED,
        code: RCRTCCode.INVALID_USER_ID,
        msg: 'invalid IM connection，invalid userId',
      }));

      return { code: RCRTCCode.INVALID_USER_ID };
    }

    const { stream, code } = await this._getMediaStream({
      audio: {
        deviceId: options?.micphoneId,
        sampleRate: options?.sampleRate,
        noiseSuppression: options?.noiseSuppression,
        echoCancellation: options?.echoCancellation,
        autoGainControl: options?.autoGainControl,
      },
    });
    if (code !== RCRTCCode.SUCCESS) {
      this._logger.error(RCLoggerTag.L_RTC_CLIENT_CREATE_MICROPHONE_AUDIO_TRACK_O, JSON.stringify({
        status: RCLoggerStatus.FAILED,
        code,
        msg: this._getMediaStreamErrorReason(code),
      }));

      return { code };
    }
    const audioTrack = stream!.getAudioTracks()[0];
    const localAudioTrack = new RCMicphoneAudioTrack(this._logger, tag, userId, audioTrack);

    this._logger.info(RCLoggerTag.L_RTC_CLIENT_CREATE_MICROPHONE_AUDIO_TRACK_O, JSON.stringify({
      status: RCLoggerStatus.SUCCESSED,
      trackId: localAudioTrack.getTrackId(),
    }));

    return { code, track: localAudioTrack };
  }

  /**
   * 由摄像头捕获视轨数据
   * @param tag
   * @param options
   * @returns
   */
  async createCameraVideoTrack(tag: string = 'RongCloudRTC', options?: ICameraVideoProfile): Promise<{code: RCRTCCode, track?: RCCameraVideoTrack}> {
    if (!isValidTag(tag)) {
      this._logger.error(RCLoggerTag.L_RTC_CLIENT_CREATE_CAMERA_VIDEO_TRACK_O, JSON.stringify({
        status: RCLoggerStatus.FAILED,
        code: RCRTCCode.INVALID_TAGS,
        msg: 'invalid tag',
      }));

      return { code: RCRTCCode.INVALID_TAGS };
    }

    const userId = this._context.getCurrentId();
    if (!userId) {
      this._logger.error(RCLoggerTag.L_RTC_CLIENT_CREATE_CAMERA_VIDEO_TRACK_O, JSON.stringify({
        status: RCLoggerStatus.FAILED,
        code: RCRTCCode.INVALID_TAGS,
        msg: 'invalid IM connection，invalid userId',
      }));

      return { code: RCRTCCode.INVALID_USER_ID };
    }

    const resolution: RCResolution = isValidResolution(options?.resolution) ? options!.resolution : RCResolution.W640_H480;
    const { width, height } = transResolution(resolution);
    const { stream, code } = await this._getMediaStream({
      video: {
        deviceId: options?.cameraId,
        frameRate: transFrameRate(options?.frameRate || RCFrameRate.FPS_15),
        width,
        height,
        facingMode: options?.faceMode,
      },
    });
    if (code !== RCRTCCode.SUCCESS) {
      this._logger.error(RCLoggerTag.L_RTC_CLIENT_CREATE_CAMERA_VIDEO_TRACK_O, JSON.stringify({
        status: RCLoggerStatus.FAILED,
        code: RCRTCCode.INVALID_TAGS,
        msg: this._getMediaStreamErrorReason(code),
      }));

      return { code };
    }
    const videoTrack = stream!.getVideoTracks()[0];
    const cameraTrack = new RCCameraVideoTrack(this._logger, tag, userId, videoTrack);

    this._logger.info(RCLoggerTag.L_RTC_CLIENT_CREATE_CAMERA_VIDEO_TRACK_O, JSON.stringify({
      status: RCLoggerStatus.SUCCESSED,
      trackId: cameraTrack.getTrackId(),
    }));

    return { code, track: cameraTrack };
  }

  /**
   * 通过摄像头与麦克风采集音视频轨道数据
   * @param tag
   * @param options
   * @returns
   */
  async createMicrophoneAndCameraTracks(tag: string = 'RongCloudRTC', options?: { audio?: IMicphoneAudioProfile, video?: ICameraVideoProfile }): Promise<{code: RCRTCCode, tracks: RCLocalTrack[]}> {
    const tracks: RCLocalTrack[] = [];

    if (!isValidTag(tag)) {
      this._logger.error(RCLoggerTag.L_RTC_CLIENT_CREATE_MICROPHONE_AND_CAMERA_TRACKS_O, JSON.stringify({
        status: RCLoggerStatus.FAILED,
        code: RCRTCCode.INVALID_TAGS,
        msg: 'invalid tag',
      }));

      return { code: RCRTCCode.INVALID_TAGS, tracks };
    }

    const userId = this._context.getCurrentId();
    if (!userId) {
      this._logger.error(RCLoggerTag.L_RTC_CLIENT_CREATE_MICROPHONE_AND_CAMERA_TRACKS_O, JSON.stringify({
        status: RCLoggerStatus.FAILED,
        code: RCRTCCode.INVALID_USER_ID,
        msg: 'invalid IM connection，invalid userId',
      }));

      return { code: RCRTCCode.INVALID_USER_ID, tracks };
    }

    const resolution: RCResolution = isValidResolution(options?.video?.resolution) ? options!.video!.resolution : RCResolution.W640_H480;
    const { width, height } = transResolution(resolution);

    const audio = options?.audio;

    const { stream, code } = await this._getMediaStream({
      video: {
        deviceId: options?.video?.cameraId,
        frameRate: transFrameRate(options?.video?.frameRate || RCFrameRate.FPS_15),
        width,
        height,
        facingMode: options?.video?.faceMode,
      },
      audio: {
        deviceId: audio?.micphoneId,
        sampleRate: audio?.sampleRate,
        noiseSuppression: audio?.noiseSuppression,
        echoCancellation: audio?.echoCancellation,
        autoGainControl: audio?.autoGainControl,
      },
    });

    if (code !== RCRTCCode.SUCCESS) {
      this._logger.error(RCLoggerTag.L_RTC_CLIENT_CREATE_MICROPHONE_AND_CAMERA_TRACKS_O, JSON.stringify({
        status: RCLoggerStatus.FAILED,
        code,
        msg: this._getMediaStreamErrorReason(code),
      }));

      return { code, tracks };
    }

    stream!.getTracks().forEach((track) => {
      if (track.kind === 'video') {
        tracks.push(new RCCameraVideoTrack(this._logger, tag, userId, track));
      } else {
        tracks.unshift(new RCMicphoneAudioTrack(this._logger, tag, userId, track));
      }
    });

    this._logger.info(RCLoggerTag.L_RTC_CLIENT_CREATE_MICROPHONE_AND_CAMERA_TRACKS_O, JSON.stringify({
      status: RCLoggerStatus.SUCCESSED,
      trackIds: tracks.map((track) => track.getTrackId()),
    }));

    return { code, tracks };
  }

  /**
   * 创建屏幕共享视频流，默认分辨率 `1280 * 720`，帧率 `15`
   * @param tag 屏幕共享视轨数据标识
   * @param options
   * @description
   * 支持 Electron 平台下通过制定 `chromeMediaSourceId` 的方式获取屏幕共享视频。
   * 参考：https://www.electronjs.org/docs/api/desktop-capturer
   */
  async createScreenVideoTrack(tag: string = 'screenshare', options?: IScreenVideoProfile): Promise<{code: RCRTCCode, track?: RCScreenVideoTrack}> {
    const res = await this._createScreenTracks(tag, false, RCLoggerTag.L_RTC_CLIENT_CREATE_SCREEN_VIDEO_TRACK_O, options);
    if (res.code === RCRTCCode.SUCCESS) {
      return { code: res.code, track: res.tracks![0] as RCScreenVideoTrack };
    }
    return res;
  }

  /**
   * 创建带音频的屏幕共享资源
   * @param tag 屏幕共享视轨数据标识
   * @param options
   * @description electron 中 mac 系统暂不支持屏幕共享采集声音
   * @returns 在可以取到音频的情况下，tracks 中包含音轨和视轨；取不到音视频时 tracks 仅返回视轨
   */
  async createScreenWithAudioTracks(tag: string = 'screenshare', options?: IScreenVideoProfile): Promise<{code: RCRTCCode, tracks?: (RCScreenVideoTrack | RCScreenAudioTrack)[]}> {
    return this._createScreenTracks(tag, true, RCLoggerTag.L_RTC_CLIENT_CREATE_SCREEN_VIDEO_AND_AUDIO_TRACKS_O, options);
  }

  private async _createScreenTracks(tag: string, withAudio: boolean, loggerTag: string, options?: IScreenVideoProfile): Promise<{code: RCRTCCode, tracks?: (RCScreenVideoTrack | RCScreenAudioTrack)[]}> {
    if (!isValidTag(tag)) {
      this._logger.error(loggerTag, JSON.stringify({
        status: RCLoggerStatus.FAILED,
        code: RCRTCCode.INVALID_TAGS,
        msg: 'invalid tag',
      }));

      return { code: RCRTCCode.INVALID_TAGS };
    }

    const userId = this._context.getCurrentId();
    if (!userId) {
      this._logger.error(loggerTag, JSON.stringify({
        status: RCLoggerStatus.FAILED,
        code: RCRTCCode.INVALID_USER_ID,
        msg: 'invalid IM connection，invalid userId',
      }));

      return { code: RCRTCCode.INVALID_USER_ID };
    }

    if (!ifSupportScreenShare()) {
      this._logger.error(RCLoggerTag.L_RTC_CLIENT_CREATE_SCREEN_VIDEO_TRACK_O, JSON.stringify({
        status: RCLoggerStatus.FAILED,
        code: RCRTCCode.BROWSER_NOT_SUPPORT,
        msg: 'browser not support',
      }));

      return { code: RCRTCCode.BROWSER_NOT_SUPPORT };
    }

    const isMac = /macintosh|mac os x/i.test(navigator.userAgent);
    const audio = (this._isElectron && isMac) ? false : withAudio;

    const resolution: RCResolution = isValidResolution(options?.resolution) ? options!.resolution : RCResolution.W1280_H720;

    const { stream, code } = this._isElectron
      ? await this._createElectronScreenStream(withAudio, loggerTag, resolution, options?.chromeMediaSourceId)
      : await this._createWebScreenStream(withAudio, resolution, options?.frameRate);
    if (code !== RCRTCCode.SUCCESS) {
      this._logger.error(loggerTag, JSON.stringify({
        status: RCLoggerStatus.FAILED,
        code,
        msg: this._getMediaStreamErrorReason(code),
      }));

      return { code };
    }
    const videoTrack = stream!.getVideoTracks()[0];
    const screenVideoTrack = new RCScreenVideoTrack(this._logger, tag, userId, videoTrack);
    const screenTracks: (RCScreenVideoTrack | RCScreenAudioTrack)[] = [screenVideoTrack];
    if (audio) {
      const audioTrack = stream!.getAudioTracks()[0];
      audioTrack && screenTracks.push(new RCScreenAudioTrack(this._logger, tag, userId, audioTrack));
    }

    this._logger.info(loggerTag, JSON.stringify({
      status: RCLoggerStatus.SUCCESSED,
      trackIds: screenTracks.map((track) => track.getTrackId()),
    }));

    return { code, tracks: screenTracks };
  }

  /**
   * electron 中取屏幕共享资源
   */
  private async _createElectronScreenStream(withAudio: boolean, loggerTag: string, resolution: RCResolution, chromeMediaSourceId?: string) {
    const isMac = /macintosh|mac os x/i.test(navigator.userAgent);

    /**
     * electron 中 mac 系统暂不支持屏幕共享采集声音
     */
    if (this._isElectron && isMac && withAudio) {
      this._logger.warn(loggerTag, JSON.stringify({
        status: RCLoggerStatus.INFO,
        code: RCRTCCode.MAC_IN_ELECTRON_NOT_SUPPORT_SCREEN_SHARE_WITH_AUDIO,
        msg: 'mac in electron not support screen share with audio',
      }));
    }

    const audio = (this._isElectron && isMac) ? false : withAudio;

    /**
     * electron 平台下 video 的配置项 chromeMediaSourceId 为非必传
     * 传入时，类型为字符串
     */
    if (this._isElectron && !isUndefined(chromeMediaSourceId) && !isString(chromeMediaSourceId)) {
      this._logger.error(loggerTag, JSON.stringify({
        status: RCLoggerStatus.FAILED,
        code: RCRTCCode.PARAMS_ERROR,
        msg: 'params error -> options.chromeMediaSourceId type is string',
      }));

      return { code: RCRTCCode.PARAMS_ERROR };
    }

    const { width, height } = transResolution(resolution);

    const video = {
      mandatory: {
        chromeMediaSourceId,
        chromeMediaSource: 'desktop',
        minWidth: width,
        maxWidth: width,
        minHeight: height,
        maxHeight: height,
      },
    // electron 环境下不可指定 frameRate
    // frameRate: transFrameRate(options?.frameRate || RCFrameRate.FPS_15),
    };

    /**
     * chromeMediaSourceId 为 undefined 时，删掉 video 中的 chromeMediaSourceId 配置
     */
    if (!chromeMediaSourceId && this._isElectron) {
      delete video.mandatory?.chromeMediaSourceId;
    }

    /**
     * electron 中采集声音 video、audio 参数赋值
     */
    let electronWindowAudioConfig = null;
    if (audio && this._isElectron) {
      delete video.mandatory?.chromeMediaSourceId;

      electronWindowAudioConfig = {
        mandatory: {
          chromeMediaSource: 'desktop',
        },
      };
    }

    return this._getMediaStream({ video, audio: electronWindowAudioConfig || audio }, 'getUserMedia');
  }

  /**
   * web 浏览器中取屏幕共享资源
   */
  private async _createWebScreenStream(withAudio: boolean, resolution: RCResolution, frameRate?: RCFrameRate) {
    const { width, height } = transResolution(resolution);
    const video = {
      frameRate: transFrameRate(frameRate || RCFrameRate.FPS_15),
      width,
      height,
    };
    return this._getMediaStream({ video, audio: withAudio }, 'getDisplayMedia');
  }

  /**
   * 创建 RCLocalAudioTrack 实例
   * @param tag
   * @param track
   * @returns
   */
  async createLocalAudioTrack(tag: string, track: MediaStreamTrack): Promise<{code: RCRTCCode, track?: RCLocalAudioTrack}> {
    if (!isValidTag(tag)) {
      this._logger.error(RCLoggerTag.L_RTC_CLIENT_CREATE_LOCAL_AUDIO_TRACK_O, JSON.stringify({
        status: RCLoggerStatus.FAILED,
        code: RCRTCCode.INVALID_TAGS,
        msg: 'invalid tag',
      }));

      return { code: RCRTCCode.INVALID_TAGS };
    }

    if (!track || track.toString() !== '[object MediaStreamTrack]' || track.kind !== 'audio') {
      this._logger.error(RCLoggerTag.L_RTC_CLIENT_CREATE_LOCAL_AUDIO_TRACK_O, JSON.stringify({
        status: RCLoggerStatus.FAILED,
        code: RCRTCCode.CREATE_CUSTOM_TRACK_FAILED,
        msg: 'params track is not MediaStreamTrack or kind is not audio',
      }));

      return { code: RCRTCCode.CREATE_CUSTOM_TRACK_FAILED };
    }
    const userId = this._context.getCurrentId();
    if (!userId) {
      this._logger.error(RCLoggerTag.L_RTC_CLIENT_CREATE_LOCAL_AUDIO_TRACK_O, JSON.stringify({
        status: RCLoggerStatus.FAILED,
        code: RCRTCCode.INVALID_USER_ID,
        msg: 'invalid IM connection，invalid userId',
      }));

      return { code: RCRTCCode.INVALID_USER_ID };
    }

    const localAudioTrack = new RCLocalAudioTrack(this._logger, tag, userId, track);

    this._logger.info(RCLoggerTag.L_RTC_CLIENT_CREATE_LOCAL_AUDIO_TRACK_O, JSON.stringify({
      status: RCLoggerStatus.SUCCESSED,
      trackId: localAudioTrack.getTrackId(),
    }));

    return { code: RCRTCCode.SUCCESS, track: localAudioTrack };
  }

  /**
   * 创建 RCLocalVideoTrack 实例
   * @param tag 视轨数据标识
   * @param track MediaStreamTrack 实例
   * @returns
   */
  async createLocalVideoTrack(tag: string, track: MediaStreamTrack): Promise<{code: RCRTCCode, track?: RCLocalVideoTrack}> {
    if (!isValidTag(tag)) {
      this._logger.error(RCLoggerTag.L_RTC_CLIENT_CREATE_LOCAL_VIDEO_TRACK_O, JSON.stringify({
        status: RCLoggerStatus.FAILED,
        code: RCRTCCode.INVALID_TAGS,
        msg: 'invalid tag',
      }));

      return { code: RCRTCCode.INVALID_TAGS };
    }

    if (!track || !(track instanceof MediaStreamTrack) || track.kind !== 'video') {
      this._logger.error(RCLoggerTag.L_RTC_CLIENT_CREATE_LOCAL_VIDEO_TRACK_O, JSON.stringify({
        status: RCLoggerStatus.FAILED,
        code: RCRTCCode.CREATE_CUSTOM_TRACK_FAILED,
        msg: 'params track is not MediaStreamTrack or kind is not video',
      }));

      return { code: RCRTCCode.CREATE_CUSTOM_TRACK_FAILED };
    }

    const userId = this._context.getCurrentId();
    if (!userId) {
      this._logger.error(RCLoggerTag.L_RTC_CLIENT_CREATE_LOCAL_VIDEO_TRACK_O, JSON.stringify({
        status: RCLoggerStatus.FAILED,
        code: RCRTCCode.INVALID_USER_ID,
        msg: 'invalid IM connection，invalid userId',
      }));

      return { code: RCRTCCode.INVALID_USER_ID };
    }

    const localVideoTrack = new RCLocalVideoTrack(this._logger, tag, userId, track);

    this._logger.info(RCLoggerTag.L_RTC_CLIENT_CREATE_LOCAL_VIDEO_TRACK_O, JSON.stringify({
      status: RCLoggerStatus.SUCCESSED,
      trackId: localVideoTrack.getTrackId(),
    }));

    return { code: RCRTCCode.SUCCESS, track: localVideoTrack };
  }

  private _validateCreateLocalFileTracks(tag: string, file: string | File) {
    if (!isValidTag(tag)) {
      this._logger.error(RCLoggerTag.L_RTC_CLIENT_CREATE_LOCAL_FILE_TRACKS_O, JSON.stringify({
        status: RCLoggerStatus.FAILED,
        code: RCRTCCode.INVALID_TAGS,
        msg: 'invalid tag',
      }));

      return { code: RCRTCCode.INVALID_TAGS, tracks: [] };
    }

    // captureStream 检测
    if (!ifSupportLocalFileTrack()) {
      this._logger.error(RCLoggerTag.L_RTC_CLIENT_CREATE_LOCAL_FILE_TRACKS_O, JSON.stringify({
        status: RCLoggerStatus.FAILED,
        code: RCRTCCode.BROWSER_NOT_SUPPORT,
        msg: 'browser not support',
      }));

      return { code: RCRTCCode.BROWSER_NOT_SUPPORT, tracks: [] };
    }

    const url = file instanceof File ? URL.createObjectURL(file) : file;

    if (!isHttpUrl(url) && !/^blob:/.test(url)) {
      this._logger.error(RCLoggerTag.L_RTC_CLIENT_CREATE_LOCAL_FILE_TRACKS_O, JSON.stringify({
        status: RCLoggerStatus.FAILED,
        code: RCRTCCode.PARAMS_ERROR,
        msg: 'params error -> file',
      }));

      return { code: RCRTCCode.PARAMS_ERROR, tracks: [] };
    }

    const userId = this._context.getCurrentId();
    if (!userId) {
      this._logger.error(RCLoggerTag.L_RTC_CLIENT_CREATE_LOCAL_FILE_TRACKS_O, JSON.stringify({
        status: RCLoggerStatus.FAILED,
        code: RCRTCCode.INVALID_USER_ID,
        msg: 'invalid IM connection，invalid userId',
      }));

      return { code: RCRTCCode.INVALID_USER_ID, tracks: [] };
    }

    return { code: RCRTCCode.SUCCESS, tracks: [], url };
  }

  /**
   * 根据本地或网络媒体文件资源创建 `RCLocalFileTrack` 实例
   * @param tag 资源标识
   * @param file 网络文件地址，或通过 <input type='file'> 获取到的 File 实例
   * @param options 可用于指定 `withoutVideo` 与 `withoutAudio` 以剔除视轨与音轨
   */
  async createLocalFileTracks(tag: string, file: string | File, options?: ICreateLocalTrackOptions): Promise<{ code: RCRTCCode, tracks: RCLocalFileTrack[] }> {
    const validateRes = this._validateCreateLocalFileTracks(tag, file);
    if (validateRes.code !== RCRTCCode.SUCCESS) {
      return validateRes;
    }

    const userId = this._context.getCurrentId();

    return new Promise((resolve) => {
      const video = document.createElement('video');
      if (options?.withoutAudio) {
        video.muted = true;
      }
      video.onloadedmetadata = () => {
        const tracks: RCLocalFileTrack[] = [];

        let mediaStream: MediaStream;
        try {
          const captureStream = (video as any).mozCaptureStream ? 'mozCaptureStream' : 'captureStream';
          mediaStream = (video as any)[captureStream]();
        } catch (error) {
          this._logger.error(RCLoggerTag.L_RTC_CLIENT_CREATE_LOCAL_FILE_TRACKS_O, JSON.stringify({
            status: RCLoggerStatus.FAILED,
            code: RCRTCCode.CREATE_FILE_TRACK_FAILED,
            msg: `captureSteam error -> ${JSON.stringify(error)}`,
          }));
          resolve({ code: RCRTCCode.CREATE_FILE_TRACK_FAILED, tracks });
        }
        const [audioTrack, videoTrack] = RCMediaStreamCapture.getTracksWithOptions(mediaStream!, options);
        audioTrack && tracks.push(new RCLocalFileAudioTrack(this._logger, tag, userId, audioTrack, video));
        videoTrack && tracks.push(new RCLocalFileVideoTrack(this._logger, tag, userId, videoTrack, video));
        if (tracks.length === 0) {
          video.pause();
          video.src = '';
        }
        video.onerror = null;

        this._logger.info(RCLoggerTag.L_RTC_CLIENT_CREATE_LOCAL_FILE_TRACKS_O, JSON.stringify({
          status: RCLoggerStatus.SUCCESSED,
          trackIds: tracks.map((track) => track.getTrackId()),
        }));

        resolve({ code: RCRTCCode.SUCCESS, tracks });
      };
      video.onerror = () => {
        this._logger.error(RCLoggerTag.L_RTC_CLIENT_CREATE_LOCAL_FILE_TRACKS_O, JSON.stringify({
          status: RCLoggerStatus.FAILED,
          code: RCRTCCode.CREATE_FILE_TRACK_FAILED,
          msg: 'Failed to create file stream, video onerror',
        }));

        resolve({ code: RCRTCCode.CREATE_FILE_TRACK_FAILED, tracks: [] });
      };
      video.src = validateRes.url!;
      video.loop = true;
      video.play();
    });
  }

  /**
   * 根据 MediaStream 实例对象创建 RCLocalTrack 实例
   * @param tag 轨道标识
   * @param stream MediaStream 实例
   * @param options 可用于指定 `withoutVideo` 与 `withoutAudio` 以剔除视轨与音轨
   * @returns
   */
  async createLocalTracks(tag: string, stream: MediaStream, options?: ICreateLocalTrackOptions): Promise<{code: RCRTCCode, tracks: RCLocalTrack[]}> {
    const tracks: RCLocalTrack[] = [];

    if (!isValidTag(tag)) {
      this._logger.error(RCLoggerTag.L_RTC_CLIENT_CREATE_LOCAL_TRACKS_O, JSON.stringify({
        status: RCLoggerStatus.FAILED,
        code: RCRTCCode.INVALID_TAGS,
        msg: 'invalid tag',
      }));

      return { code: RCRTCCode.INVALID_TAGS, tracks };
    }

    if (!(stream instanceof MediaStream)) {
      this._logger.error(RCLoggerTag.L_RTC_CLIENT_CREATE_LOCAL_TRACKS_O, JSON.stringify({
        status: RCLoggerStatus.FAILED,
        code: RCRTCCode.PARAMS_ERROR,
        msg: 'params error -> stream',
      }));

      return { code: RCRTCCode.PARAMS_ERROR, tracks };
    }

    const userId = this._context.getCurrentId();
    if (!userId) {
      this._logger.error(RCLoggerTag.L_RTC_CLIENT_CREATE_LOCAL_TRACKS_O, JSON.stringify({
        status: RCLoggerStatus.FAILED,
        code: RCRTCCode.INVALID_USER_ID,
        msg: 'invalid IM connection，invalid userId',
      }));

      return { code: RCRTCCode.INVALID_USER_ID, tracks };
    }

    const [audioTrack, videoTrack] = RCMediaStreamCapture.getTracksWithOptions(stream, options);
    audioTrack && tracks.push(new RCLocalAudioTrack(this._logger, tag, userId, audioTrack));
    videoTrack && tracks.push(new RCLocalVideoTrack(this._logger, tag, userId, videoTrack));

    this._logger.info(RCLoggerTag.L_RTC_CLIENT_CREATE_LOCAL_TRACKS_O, JSON.stringify({
      status: RCLoggerStatus.SUCCESSED,
      trackIds: tracks.map((track) => track.getTrackId()),
    }));

    return { code: RCRTCCode.SUCCESS, tracks };
  }

  /**
   * 它接受一个 MediaStream 和一个可选的选项对象并返回一个 MediaStreamTracks 数组
   * @param {MediaStream} stream - MediaStream - 从中获取曲目的流。
   * @param {ICreateLocalTrackOptions} [options] - ICreateLocalTrackOptions
   * @returns 一组 MediaStreamTrack 对象。
   */
  private static getTracksWithOptions(stream: MediaStream, options?: ICreateLocalTrackOptions): Array<MediaStreamTrack | undefined> {
    const tracks: Array<MediaStreamTrack | undefined> = [];
    tracks[0] = options?.withoutAudio ? undefined : stream.getAudioTracks()[0];
    tracks[1] = options?.withoutVideo ? undefined : stream.getVideoTracks()[0];
    return tracks;
  }
}
