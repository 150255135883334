import { BasicLogger, EventEmitter, IRuntime } from '@rongcloud/engine';
import { RTCContext } from '../codec/RTCContext';
import ReportMediaActionLogger from '../logger/QualityLogger';
import { IPushOtherRooms, RCMediaService } from '../service';
import { ReadableStore } from '../Store';
import RCRTCPeerConnection from '../webrtc/RCRTCPeerConnection';
import PolarisReporter from '../PolarisReporter';

export const CommandEvent = {
  /** PeerConnection 异常关闭 */
  PEER_CONNECTION_CLOSED_BY_EXCEPTION: 'PEER_CONNECTION_CLOSED_BY_EXCEPTION',
  /** 人员加入 */
  USER_JOIN: 'USER_JOIN',
  /** 人员退出 */
  USER_LEAVE: 'USER_LEAVE',
  TRACKS_PUBLISH: 'TRACKS_PUBLISH',
  TRACKS_UNPUBLISH: 'TRACKS_UNPUBLISH',
  AUDIO_MUTE_CHANGE: 'AUDIO_MUTE_CHANGE',
  VIDEO_MUTE_CHANGE: 'VIDEO_MUTE_CHANGE',
  CDN_ENABLE_CHANGE: 'CDN_ENABLE_CHANGE',
  USER_STATE_CHANGE: 'USER_STATE_CHANGE',
};

/**
 * 命令执行上下文
 */
export class CommandExecuteContext extends EventEmitter {
  constructor(
    /**
     * 日志工具
     */
    public readonly logger: BasicLogger,
    /**
     * MediaServer 请求实例
     */
    public readonly service: RCMediaService,
    /**
     * IM 信令上下文
     */
    public readonly context: RTCContext,
    /**
     * 运行时
     */
    public readonly runtime: IRuntime,
    /**
     * PeerConenction 连接实例
     */
    public readonly peer: RCRTCPeerConnection,
    /**
     * 内存只读模块
     */
    public readonly store: ReadableStore,
    /**
     * 北极星统计工具
     */
    public readonly polarisReport: PolarisReporter,
    /**
     * 行为数据采集工具
     */
    public readonly reportMediaActionLogger: ReportMediaActionLogger,
  ) {
    super();
  }

  /**
   * 代理房间实例以获取跨房间连麦时的推送配置
   */
  public getPushOtherRooms!: () => IPushOtherRooms[]
}
