/**
 * 北极星上报 R2 参数
 */
export enum R2Action {
  /**
   * 发布
   */
  PUBLISH = 'publish',
  /**
   * 订阅
   */
  SUBSCRIBE = 'subscribe'
}
