export enum RCResolution {
  W176_H132 = 'W176_H132',
  W176_H144 = 'W176_H144',
  W180_H180 = 'W180_H180',
  W240_H180 = 'W240_H180',
  W240_H240 = 'W240_H240',
  W256_H144 = 'W256_H144',
  W320_H180 = 'W320_H180',
  W320_H240 = 'W320_H240',
  W360_H360 = 'W360_H360',
  W480_H360 = 'W480_H360',
  W480_H480 = 'W480_H480',
  W640_H360 = 'W640_H360',
  W640_H480 = 'W640_H480',
  W720_H480 = 'W720_H480',
  W848_H480 = 'W848_H480',
  W960_H720 = 'W960_H720',
  W1280_H720 = 'W1280_H720',
  W1920_H1080 = 'W1920_H1080',
  W144_H176 = 'W144_H176',
  W144_H256 = 'W144_H256',
  W180_H240 = 'W180_H240',
  W180_H320 = 'W180_H320',
  W240_H320 = 'W240_H320',
  W360_H480 = 'W360_H480',
  W360_H640 = 'W360_H640',
  W480_H640 = 'W480_H640',
  W480_H720 = 'W480_H720',
  W480_H848 = 'W480_H848',
  W720_H1280 = 'W720_H1280',
  W720_H960 = 'W720_H960',
  W1080_H1920 = 'W1080_H1920'
}
