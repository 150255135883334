import { diffPublishResources, getTrackId, parseTrackId } from '../../helper';
import { RCRTCMessageType } from '../enums/inner/RCRTCMessageType';
import { RCCommandKind } from '../enums/RCCommandKind';
import { RCLoggerStatus, RCLoggerTag } from '../enums/RCLoggerTag';
import { RCMediaType } from '../enums/RCMediaType';
import { RCRTCCode } from '../enums/RCRTCCode';
import { RTCMode } from '../enums/RTCMode';
import { IPublishedResource } from '../interfaces';
import { Invoker } from '../Invoker';
import { ICDNUris } from '../service';
import { Store } from '../Store';
import { RCRemoteAudioTrack, RCRemoteTrack, RCRemoteVideoTrack } from '../tracks/RCRemoteTrack';
import { BaseCommand, CommandPriority, ICommandResult } from './BaseCommand';
import { CommandEvent, CommandExecuteContext } from './CommandExecuteContext';
import { OnRemoteUserUnpubCommand } from './OnRemoteUserUnpubCommand';
import { UpdateSubscribeListCommand } from './UpdateSubscribeListCommand';

export type ResourceMsgContent = {
  /**
   * 旧版本兼容参数，当收到非 `RTCMessageName.TOTAL_CONTENT_RESOURCE` 时：
   * * ignore 值为 `true` 表示该消息由 signal server 向旧版本 RTCLib 提供的兼容消息，无需处理
   * * 否则认为该消息是由旧版本 RTCLib 主动发出的增量变更消息，需要处理
   */
  ignore?: boolean,
  /**
   * 发布到房间内的资源列表，`RTCMessageName.TOTAL_CONTENT_RESOURCE` 消息携带全量数据，否则为增量数据
   */
  uris: IPublishedResource[]
  // eslint-disable-next-line camelcase
  cdn_uris?: ICDNUris[]
}

export class ParseRemoteResCommand extends BaseCommand<void> {
  // 解析对比出来的新增发布数据
  private _publishedList: IPublishedResource[] = []

  // 解析对比出来的取消发布数据
  private _unpublishedList: IPublishedResource[] = []

  // 解析对比出来的变更数据
  private _modifiedList: IPublishedResource[] = []

  // 当前资源清单
  private _nowResources: IPublishedResource[] = []

  constructor(
    private msgContent: ResourceMsgContent,
    private messageType: RCRTCMessageType.PUBLISH | RCRTCMessageType.UNPUBLISH | RCRTCMessageType.MODIFY | RCRTCMessageType.TOTAL_CONTENT_RESOURCE,
    private senderId: string,
    /**
     * 是否更新房间数据
     * 如果不更新，代表需合并处理房间数据列表
     * 此时只更新增量数据，对比完增量数据后，更新房间数据，再和原来的房间数据对比，一次抛出事件
     */
    // private isUpdateFullRoomStatus: boolean = true,
    private traceId: string,
    private sentTime?: number,
  ) {
    super();
  }

  get kind(): RCCommandKind {
    return RCCommandKind.PARSE_REMOTERES;
  }

  get priority(): CommandPriority {
    return CommandPriority.NORMAL;
  }

  /**
   * 处理解析出的新增发布
   */
  private async _dealPublishList(executeCtx: CommandExecuteContext, store: Store, invoker: Invoker) {
    const { logger, reportMediaActionLogger } = executeCtx;
    // published 资源包含当前房间已存在资源二次发布，uri 有变更
    const ids = this._nowResources.map(getTrackId);
    // 对方重新发布且己方已订阅的资源
    const subedTracks: RCRemoteTrack[] = [];
    const newTracks: RCRemoteTrack[] = [];
    this._publishedList.forEach((item) => {
      const resourceId = getTrackId(item);
      const index = ids.indexOf(resourceId);
      const { userId, tag, mediaType } = parseTrackId(resourceId);

      if (index > -1) {
        this._nowResources[index] = item;
      } else {
        this._nowResources.push(item);
      }

      let rTrack = store.getRemoteTrack(resourceId);

      // 二次发布的资源，直接更新
      if (rTrack) {
        if (rTrack.isSubscribed()) {
          subedTracks.push(rTrack);
        }
      } else {
        rTrack = mediaType === RCMediaType.AUDIO_ONLY ? new RCRemoteAudioTrack(logger, tag, userId) : new RCRemoteVideoTrack(logger, tag, userId);
        store.addRemoteTrack(rTrack);
        newTracks.push(rTrack);
      }
      rTrack.__innerSetRemoteMuted(item.state === 0);
    });

    // 重新订阅二次发布资源
    if (subedTracks.length) {
      const trackIds = subedTracks.map((item) => item.getTrackId());
      const { code } = await new UpdateSubscribeListCommand(subedTracks, true, this.traceId).execute(executeCtx, store, invoker);
      if (code !== RCRTCCode.SUCCESS) {
        logger.error(RCLoggerTag.L_RESUB_REPUB_RES_R, JSON.stringify({
          status: RCLoggerStatus.FAILED,
          trackIds,
          code,
        }), this.traceId);
      }
    }

    executeCtx.emit(CommandEvent.TRACKS_PUBLISH, newTracks);

    reportMediaActionLogger.reportQualityRecvPubMsgData(this.sentTime!, this.senderId);
  }

  /**
   * 处理解析出的取消发布
   */
  private async _dealUnpublishList(executeCtx: CommandExecuteContext, store: Store, invoker: Invoker) {
    const resIds = this._unpublishedList.map(getTrackId);
    for (let i = this._nowResources.length - 1; i >= 0; i -= 1) {
      const item = this._nowResources[i];
      if (resIds.includes(getTrackId(item))) {
        this._nowResources.splice(i, 1);
      }
    }
    const tracks = this._unpublishedList.map((item) => {
      const trackId = getTrackId(item);
      return store.getRemoteTrack(trackId)!;
    });
    await new OnRemoteUserUnpubCommand(tracks).execute(executeCtx, store, invoker);
  }

  /**
   * 处理解析出的资源变更
   */
  private async _dealModifiedList(executeCtx: CommandExecuteContext, store: Store) {
    const resIds = this._nowResources.map(getTrackId);
    for (const item of this._modifiedList) {
      const id = getTrackId(item);
      // 更新资源 state
      const index = resIds.indexOf(id);
      this._nowResources[index].state = item.state;

      const rTrack = store.getRemoteTrack(id)!;
      rTrack.__innerSetRemoteMuted(item.state === 0);
      rTrack.isAudioTrack() ? executeCtx.emit(CommandEvent.AUDIO_MUTE_CHANGE, rTrack) : executeCtx.emit(CommandEvent.VIDEO_MUTE_CHANGE, rTrack);
    }
  }

  /**
   * 更新本端 CDN 存储信息
   */
  private _updateCDNUris(executeCtx: CommandExecuteContext, store: Store) {
    const CDNUris = this.msgContent.cdn_uris![0];
    // 给业务层抛 CDN 状态
    const changed = store.getCDNUris()?.enableInnerCDN !== CDNUris.enableInnerCDN;
    // 更新 _CDNUris
    store.setCDNUris(CDNUris);

    if (changed) {
      executeCtx.emit(CommandEvent.CDN_ENABLE_CHANGE, !!store.getCDNUris()?.enableInnerCDN);
    }
  }

  async execute(executeCtx: CommandExecuteContext, store: Store, invoker: Invoker): Promise<ICommandResult> {
    const { uris } = this.msgContent;

    this._publishedList = [];
    this._unpublishedList = [];
    this._modifiedList = [];

    let parseData: {
      publishedList: IPublishedResource[],
      unpublishedList: IPublishedResource[]
      modifiedList: IPublishedResource[]
    };

    const userId = this.senderId;
    const { messageType } = this;

    /**
     * 房间内没有某个人时，不再处理此人的资源数据
     */
    if (!store.getRemoteUserIds().includes(userId)) {
      return { code: RCRTCCode.SUCCESS };
    }

    // 当前资源清单
    this._nowResources = store.getResourcesByUserId(userId) || [];

    switch (messageType) {
      case RCRTCMessageType.MODIFY:
        this._modifiedList.push(...uris);
        break;
      case RCRTCMessageType.PUBLISH:
        this._publishedList.push(...uris);
        break;
      case RCRTCMessageType.UNPUBLISH:
        this._unpublishedList.push(...uris);
        break;
      case RCRTCMessageType.TOTAL_CONTENT_RESOURCE:
        // 比对本地资源，找出被移除资源、新增资源、被修改资源
        parseData = diffPublishResources(this._nowResources, uris);
        this._publishedList.push(...parseData.publishedList);
        this._unpublishedList.push(...parseData.unpublishedList);
        this._modifiedList.push(...parseData.modifiedList);
        break;
    }

    this._publishedList.length && await this._dealPublishList(executeCtx, store, invoker);

    this._unpublishedList.length && await this._dealUnpublishList(executeCtx, store, invoker);

    this._modifiedList.length && await this._dealModifiedList(executeCtx, store);

    // 更新房间内 resource
    store.setResourcesByUserId(userId, this._nowResources);

    if (store.roomMode !== RTCMode.LIVE) {
      return { code: RCRTCCode.SUCCESS };
    }

    const content = this.msgContent;
    if (!content.cdn_uris) {
      return { code: RCRTCCode.SUCCESS };
    }

    this._updateCDNUris(executeCtx, store);

    return { code: RCRTCCode.SUCCESS };
  }
}
