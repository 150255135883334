import { BasicLogger } from '@rongcloud/engine';
import IStatParser from './IStatParser';
import { RCMediaType } from '../../enums/RCMediaType';
import { IInnerRCRTCStateReport } from '../../interfaces';
import { getBitrate, getPacketsLostRate } from '../helper';
import { RCLoggerStatus, RCLoggerTag } from '../../enums/RCLoggerTag';
import { ReadableStore } from '../../Store';
import { ASdpStrategy } from '../sdp/ASdpStrategy';

enum ReportTypes {
  TRACK = 'track',
  STREAM = 'stream',
  MEDIASOURCE = 'media-source',
  INBOUNDRTP = 'inbound-rtp',
  OUTBOUNDRTP = 'outbound-rtp',
  REMOTEINBOUNDRTP = 'remote-inbound-rtp',
  PEERCONNECTION = 'peer-connection',
  TRANSPORT = 'transport',
  CERTIFICATE = 'certificate',
  CANDIDATEPAIR = 'candidate-pair',
  REMOTECANDIDATE = 'remote-candidate',
  LOCALCANDIDATE = 'local-candidate',
}

export interface ReportData {
  type: ReportTypes,
  id: string,
  [key: string]: any,
}

export interface StatsData {
  outbound: ReportData[],
  remoteInbound: ReportData[],
  inbound: ReportData[],
  transport: ReportData[],
  connection: ReportData[],
}

export default abstract class AbstractStatParser implements IStatParser {
  constructor(
    private readonly _sdpStrategy: ASdpStrategy,
    protected readonly _rtcPeerConn: RTCPeerConnection,
    private _logger: BasicLogger,
    protected readonly _sdpSemantics: string,
    protected readonly _currentUserId: string,
    /**
     * store 实例
     */
    protected readonly _store?: ReadableStore,
  ) {
  }

  /**
   * 根据 mid 获取 trackId，unified-plan 重写该实现
   * @param mid
   */
  public getTrackIdByMid(mid: string): string | null {
    return this._sdpStrategy.getTrackIdByMid(mid);
  }

  /**
   * 最近的上行发送包数据统计
   */
  protected _latestPacketsSent: {
    [resourceId: string]: {
      packetsLost?: number, packetsSent?: number, crtPacketsSent?: number
    }
  } = {}

  /**
   * 最近的上行发送字节数统计
   */
  protected _latestBytesSent: {
    [resourceId: string]: {
      bytesSent: number, timestamp: number
    }
  } = {}

  /**
   * 最近的下行接收字节数统计
   */
  protected _latestBytesRecv: {
    [resourceId: string]: {
      bytesRecv: number, timestamp: number
    }
  } = {}

  /**
   * 最近的下行接收包数据统计
   */
  protected _latestPacketsRecv: {
    [resourceId: string]: {
      packetsLost: number, packetsRecv: number
    }
  } = {}

  /**
   * 更新上行码率存储，返回计算出的码率
   * @param resourceId
   * @param bytesSent 本次发送的字节数
   * @param timestamp
   * @returns bitrate
   */
  protected updateBytesSent(resourceId: string, bytesSent: number, timestamp: number) {
    if (this._latestBytesSent[resourceId] && bytesSent < this._latestBytesSent[resourceId].bytesSent) {
      this.clearLatestpacketsSent([resourceId]);
    }

    let dBytes: number;
    let dTime: number;

    if (!this._latestBytesSent[resourceId]) {
      dBytes = bytesSent;
      dTime = 1000;

      // 更新记录
      this._latestBytesSent[resourceId] = { bytesSent, timestamp };
    } else {
      const {
        bytesSent: preBytesSent,
        timestamp: preTimestamp,
      } = this._latestBytesSent[resourceId];
      dBytes = bytesSent - preBytesSent;
      dTime = timestamp - preTimestamp;

      // 更新记录
      this._latestBytesSent[resourceId] = {
        bytesSent,
        timestamp,
      };
    }

    return getBitrate(dTime, dBytes);
  }

  /**
   * 更新下行码率存储，返回计算出的码率
   * @param resourceId
   * @param bytesRecv
   * @param timestamp
   * @returns bitrate
   */
  protected updateBytesRecv(resourceId: string, bytesRecv: number, timestamp: number) {
    if (this._latestBytesRecv[resourceId] && bytesRecv < this._latestBytesRecv[resourceId].bytesRecv) {
      this.clearLatestPacketsRecv([resourceId]);
    }

    let dBytes: number;
    let dTime: number;

    if (!this._latestBytesRecv[resourceId]) {
      dBytes = bytesRecv;
      dTime = 1000;

      // 更新记录
      this._latestBytesRecv[resourceId] = { bytesRecv, timestamp };
    } else {
      const {
        bytesRecv: preBytesRecv,
        timestamp: preTimestamp,
      } = this._latestBytesRecv[resourceId];
      dBytes = bytesRecv - preBytesRecv;
      dTime = timestamp - preTimestamp;

      // 更新记录
      this._latestBytesRecv[resourceId] = {
        bytesRecv,
        timestamp,
      };
    }

    return getBitrate(dTime, dBytes);
  }

  /**
   * 更新上行丢包总数，返回计算出的丢包率
   * 计算丢包率
   * 上行数据统计中，packageLost 的统计具有延时性
   * 会导致瞬时的 packetsLost - prePacketsLost 值大于 packetsSent - prePacketsSent，从而丢包率可能大于 1
   * 因此此处计算只在 packetsLost - prePacketsLost !== 0 时计算丢包率，其他时间丢包为 0
   * packetsSent 只在 packetsLost 有变化时更新
   */
  protected updateSenderPacketsLost(resourceId: string, packetsLost: number, packetsSent: number): number {
    let packetsLostRate: number;
    // 存在 this._latestPacketsSent[resourceId] 中只包含 crtPacketsSent 的情况
    if (!Object.prototype.hasOwnProperty.call(this._latestPacketsSent[resourceId], 'packetsSent')) {
      packetsLostRate = getPacketsLostRate(packetsLost, packetsSent);

      // 更新记录
      this._latestPacketsSent[resourceId].packetsLost = packetsLost;
      this._latestPacketsSent[resourceId].packetsSent = packetsSent;
    } else {
      const {
        packetsLost: prePacketsLost, packetsSent: prePacketsSent,
      } = this._latestPacketsSent[resourceId];
      packetsLostRate = getPacketsLostRate(packetsLost, packetsSent, prePacketsLost, prePacketsSent);

      // 更新记录
      this._latestPacketsSent[resourceId].packetsLost = packetsLost;
      this._latestPacketsSent[resourceId].packetsSent = prePacketsLost === packetsLost ? prePacketsSent : packetsSent;
    }
    return packetsLostRate;
  }

  /**
   * 更新下行丢包总数，返回计算出的丢包率
   */
  protected updateReceiverPacketsLost(resourceId: string, packetsLost: number, packetsReceived: number) : number {
    let packetsLostRate: number;
    if (!this._latestPacketsRecv[resourceId]) {
      packetsLostRate = getPacketsLostRate(packetsLost, packetsReceived);
    } else {
      const {
        packetsLost: prePacketsLost, packetsRecv: prePacketsRecv,
      } = this._latestPacketsRecv[resourceId];
      packetsLostRate = getPacketsLostRate(packetsLost, packetsReceived + packetsLost, prePacketsLost, prePacketsRecv + prePacketsLost);
    }
    this._latestPacketsRecv[resourceId] = {
      packetsLost,
      packetsRecv: packetsReceived,
    };

    return packetsLostRate;
  }

  /**
   * 取消发布后，需把 _latestPacketsSent 中 key 为 resourceId 存储的数据清除掉
   */
  public clearLatestpacketsSent(resourceIds: string[]) {
    resourceIds.forEach((resourceId) => {
      const mediaType = parseInt(resourceId.split('_').pop()!);
      if (mediaType === RCMediaType.VIDEO_ONLY) {
        const tinyResourceId = `${resourceId}_tiny`;
        delete this._latestPacketsSent[tinyResourceId];
        delete this._latestBytesSent[tinyResourceId];
      }
      delete this._latestPacketsSent[resourceId];
      delete this._latestBytesSent[resourceId];
    });
  }

  /**
   * 取消订阅后，需把 _latestPacketsRecv 中 key 为 resourceId 存储的数据清除掉
   */
  public clearLatestPacketsRecv(resourceIds: string[]) {
    resourceIds.forEach((resourceId) => {
      delete this._latestPacketsRecv[resourceId];
      delete this._latestBytesRecv[resourceId];
    });
  }

  public parseRTCStatsReport(reports: RTCStatsReport): { [key: string]: any } {
    const stats: { [key: string]: any } = {};
    for (const [key, value] of (reports as any).entries()) {
      // 过滤下行数据上报
      if (!/codec/ig.test(value) || !key.includes('RTCCodec_')) {
        stats[key] = value;
      }
    }

    return stats;
  }

  public formatRCRTCStateReport(stats: { [key: string]: any }) : IInnerRCRTCStateReport {
    const reports: IInnerRCRTCStateReport = {
      senders: [],
      receivers: [],
    } as any;

    this._logger.info(RCLoggerTag.L_ABSTRACT_STAT_PARSER_FORMAT_RTC_STATE_REPORT_O, JSON.stringify({
      status: RCLoggerStatus.SUCCESSED,
      stats,
      reports,
    }));

    return reports;
  }

  public getAudioLevelList(stats: { [key: string]: any }) : {trackId: string, audioLevel: number | null}[] {
    const audioLevelList: {
      trackId: string,
      audioLevel: number | null
    }[] = [];
    return audioLevelList;
  }

  /**
   * 从 offer/answer sdp 中查找 ssrc 对应的通道是否可用
   * @param outboundInfo/inboundInfo
   */
  protected isValidTranceiver(info: { [key: string]: any }, type: 'outbound-rtp' | 'inbound-rtp') : boolean {
    const sdp = type === 'outbound-rtp' ? this._rtcPeerConn.currentLocalDescription?.sdp : this._rtcPeerConn.currentRemoteDescription?.sdp;
    const { ssrc } = info;
    const valid = sdp?.split('\r\nm=').some((item) => (item.includes(ssrc) && item.includes('a=inactive')));
    return !valid;
  }

  protected formatStateData(stats: { [key: string]: any }): StatsData {
    const init:StatsData = {
      outbound: [],
      remoteInbound: [],
      inbound: [],
      transport: [],
      connection: [],
    };
    return Object.values(stats).reduce((result: StatsData, item: ReportData) => {
      if (item.type === ReportTypes.REMOTEINBOUNDRTP) {
        result.remoteInbound.push(item);
        return result;
      }

      if (item.type === ReportTypes.INBOUNDRTP) {
        result.inbound.push(item);
        return result;
      }

      if (item.type === ReportTypes.OUTBOUNDRTP) {
        result.outbound.push(item);
        return result;
      }

      if (item.type === ReportTypes.TRANSPORT) {
        result.transport.push(item);
        return result;
      }

      if (item.type === ReportTypes.PEERCONNECTION) {
        result.connection.push(item);
        return result;
      }
      return result;
    }, init);
  }
}
