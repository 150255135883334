/**
 * 直播间类型
 */
export enum RCLivingType {
  /**
   * 音视频直播
   */
  VIDEO = 0,
  /**
   * 音频直播
   */
  AUDIO = 1
}
