import { isBoolean } from '@rongcloud/engine';
import { RCCommandKind } from '../enums/RCCommandKind';
import { RCInnerCDNBroadcast } from '../enums/RCInnerCDNBroadcast';
import { RCInnerCDNModel } from '../enums/RCInnerCDNModel';
import { RCLoggerStatus, RCLoggerTag } from '../enums/RCLoggerTag';
import { RCRTCCode } from '../enums/RCRTCCode';
import { Invoker } from '../Invoker';
import { IMCUConfig, ISetEnableCDN } from '../service/mcu-interface';
import { Store } from '../Store';
import { BaseCommand, ICommandResult } from './BaseCommand';
import { CommandExecuteContext } from './CommandExecuteContext';
import { sendCDNInfoSignal } from './helper';
import { MCUConfigFlushCommand } from './MCUConfigFlushCommand';

export class EnableInnerCDNCommand extends BaseCommand {
  constructor(
    private cdnValues: IMCUConfig,
    private enable: boolean,
  ) {
    super();
  }

  get kind(): RCCommandKind {
    return RCCommandKind.ENABLE_INNER_CDN;
  }

  async execute(executeCtx: CommandExecuteContext, store: Store, invoker: Invoker): Promise<ICommandResult> {
    const { logger, context } = executeCtx;
    const { enable } = this;

    logger.info(RCLoggerTag.L_LIVING_ROOM_ENABLE_INNER_CDN_T, JSON.stringify({
      roomId: store.roomId,
      enable,
    }));

    /**
     * 副房间不可调用
     */
    if (!store.isMainRoom) {
      logger.error(RCLoggerTag.L_LIVING_ROOM_ENABLE_INNER_CDN_R, JSON.stringify({
        status: RCLoggerStatus.FAILED,
        code: RCRTCCode.THE_FUNCTION_IS_DISABLED_IN_PKROOM,
        msg: 'method not available in PK room',
      }));
      return { code: RCRTCCode.THE_FUNCTION_IS_DISABLED_IN_PKROOM };
    }

    if (!isBoolean(enable)) {
      logger.error(RCLoggerTag.L_LIVING_ROOM_ENABLE_INNER_CDN_R, JSON.stringify({
        status: RCLoggerStatus.FAILED,
        code: RCRTCCode.PARAMS_ERROR,
        msg: 'params error -> enable',
      }));
      return { code: RCRTCCode.PARAMS_ERROR };
    }

    store.setCDNEnabel(enable);
    const params: ISetEnableCDN = {
      version: 2,
      output: {
        inCDNModel: enable ? RCInnerCDNModel.OPEN : RCInnerCDNModel.STOP,
      },
    };
    const { code } = await new MCUConfigFlushCommand(params, this.cdnValues).execute(executeCtx, store, invoker);
    if (code !== RCRTCCode.SUCCESS) {
      logger.error(RCLoggerTag.L_LIVING_ROOM_ENABLE_INNER_CDN_R, JSON.stringify({
        status: RCLoggerStatus.FAILED,
        code,
        msg: 'signal error',
      }));
      return { code: RCRTCCode.SIGNAL_ERROR };
    }

    // 判断是否需要扩散 cdn_uris 时
    const CDNUris = store.getCDNUris();
    if (CDNUris && (CDNUris.broadcast !== RCInnerCDNBroadcast.SPREAD)) {
      logger.info(RCLoggerTag.L_LIVING_ROOM_ENABLE_INNER_CDN_R, JSON.stringify({
        status: RCLoggerStatus.SUCCESSED,
        enable,
      }));
      return { code: RCRTCCode.SUCCESS };
    }

    /**
     * 扩散 cdn_uris
     */
    const { code: sendSingalCode } = await sendCDNInfoSignal(context, store);
    if (sendSingalCode === RCRTCCode.SUCCESS) {
      logger.info(RCLoggerTag.L_LIVING_ROOM_ENABLE_INNER_CDN_R, JSON.stringify({
        status: RCLoggerStatus.SUCCESSED,
        enable,
      }));
      return { code: RCRTCCode.SUCCESS };
    }

    logger.error(RCLoggerTag.L_LIVING_ROOM_ENABLE_INNER_CDN_R, JSON.stringify({
      status: RCLoggerStatus.FAILED,
      code: sendSingalCode,
    }));
    return { code: sendSingalCode };
  }
}
