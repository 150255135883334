import { RCRTCCode } from '../enums/RCRTCCode';

export class RC3ANoiseWorklet {
  public static WASM_URL: string = 'https://cdn.ronghub.com/plugin-rtc/wasm/5.0.0-alpha.2/AudioProcessing.wasm';

  public static SCRIPT_URL: string = 'https://cdn.ronghub.com/plugin-rtc/wasm/5.0.0-alpha.2/process-worklet.js';

  private static _maps: Map<string, AudioContext> = new Map();

  static async apply3ANoiseWorklet(track: MediaStreamTrack): Promise<{ code: RCRTCCode, track?: MediaStreamTrack }> {
    const audioCtx = new AudioContext({ sampleRate: 44100 });

    let arrayBuffer: ArrayBuffer;
    try {
      await audioCtx.audioWorklet.addModule(RC3ANoiseWorklet.SCRIPT_URL);

      const resp = await fetch(RC3ANoiseWorklet.WASM_URL, {
        cache: 'force-cache',
        credentials: 'same-origin',
      });
      arrayBuffer = await resp.arrayBuffer();
    } catch (error) {
      return { code: RCRTCCode.LOAD_3A_MODULE_FAILED };
    }

    const source = audioCtx.createMediaStreamSource(new MediaStream([track]));
    const node = new AudioWorkletNode(audioCtx, 'process-worklet', {
      numberOfInputs: 1,
      numberOfOutputs: 1,
      outputChannelCount: [1],
      processorOptions: {
        binary: arrayBuffer,
      },
    });
    const destination = audioCtx.createMediaStreamDestination();
    source.connect(node).connect(destination);

    const newTrack = destination.stream.getAudioTracks()[0];
    RC3ANoiseWorklet._maps.set(track.id, audioCtx);
    return { code: RCRTCCode.SUCCESS, track: newTrack };
  }

  static release3ANoiseWorklet(track: MediaStreamTrack): void {
    const ctx = RC3ANoiseWorklet._maps.get(track.id);
    if (ctx) {
      ctx.close();
      RC3ANoiseWorklet._maps.delete(track.id);
    }
  }
}
