import { RCCommandKind } from '../enums/RCCommandKind';
import { BaseInvoker } from '../Invoker';
import { Store } from '../Store';
import { CommandExecuteContext } from './CommandExecuteContext';

export enum CommandPriority {
  LOW,
  NORMAL,
  HIGH
}

export enum CommandCode {
  Destroy = 40400
}

export type ICommandResult<R=void> = { code: number, data?: R }

/**
 * 命令基类
 * @typeParams R - 返回值类型声明
 * @typeParams T - 命令执行上下文类型
 * @typeParams S - 内存数据缓存模块
 */
export abstract class BaseCommand<R=void, T=CommandExecuteContext, S=Store> {
  /**
   * 事务执行函数
   * @param context - 事务执行上下文
   * @param store - 内存读写模块
   * @param invoker - 任务队列
   */
  abstract execute(context: T, store: S, invoker: BaseInvoker<T, S>): Promise<ICommandResult<R>>

  /**
   * 获取指令优先级，必要时可 override 此函数
   */
  get priority(): CommandPriority {
    return CommandPriority.LOW;
  }

  /**
   * 它返回命令的种类。
   * @returns 命令的种类。
   */
  get kind(): RCCommandKind {
    return RCCommandKind.UNKNOWN;
  }
}
