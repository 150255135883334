/**
 * 错误码，与移动端对齐
 * @description
 * 1. `51000 - 51999` 为 Android 专用段
 * 2. `52000 - 52999` 为 iOS 专用段
 * 3. `53000 - 53199` 为 Web RTC 专用段
 * 4. `53200 - 53499` 为 Web CallLib 专用段
 * *  `53200 - 53299` 为 Web CallEngine 专用端
 * *  `53300 - 53499` 为 Web Call 专用端
 * 5. `53500 - 53999` 为 Web 保留段
 */
export enum RCCallErrorCode {
  /**
   * 成功
   */
  SUCCESS = 10000,
  /**
   * 存在未结束的状态机
   */
  STATE_MACHINE_EXIT = 53200,
  /**
   * 发送 IM 消息失败
   */
  SEND_MSG_ERROR = 53201,
  /**
   * 被对方加入黑名单
   */
  REJECTED_BY_BLACKLIST = 53202,
  /**
   * 当前用户不再群组中
   */
  NOT_IN_GROUP = 53203,

  /**
   * Call 相关
   */
  /**
   * 获得本地音频流失败
   */
  GET_LOCAL_AUDIO_TRACK_ERROR = 53301,

  /**
   * 获得本地视频流失败
   */
  GET_LOCAL_VIDEO_TRACK_ERROR = 53302,

  /**
   * 获得本地音视频流失败
   */
  GET_LOCAL_AUDIO_AND_VIDEO_TRACK_ERROR = 53303,

  /**
   * 加入房间失败
   */
  JOIN_ROOM_ERROR = 53304,

  /**
   * 发布音频失败
   */
  AUDIO_PUBLISH_ERROR = 53305,

  /**
   * 发布视频失败
   */
  VIDEO_PUBLISH_ERROR = 53306,

  /**
   * 发布音视频失败
   */
  AUDIO_AND_VIDEO_PUBLISH_ERROR = 53307,

  /**
   * 查询房间用户信息失败
   */
  QUERY_JOINED_USER_INFO_ERROR = 53308,

  /**
   * 禁用启用视频时，房间内缺少视频流
   */
  MISSING_VIDEO_TRACK_ERROR = 53309,

  /**
   * 取消发布视频失败
   */
  UNPUBLISH_VIDEO_ERROR = 53310,

  /**
   * 会话不是群组
   */
  CONVERSATION_NOT_GROUP_ERROR = 53311,

  /**
  * 不在room中禁用音视频
  */
  NOT_IN_ROOM_ERROR = 53312,

  /**
   * 参数错误
   */
  PARAM_ERROR = 53313
}
