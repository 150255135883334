export enum RCCallMessageType {
  /**
   * 邀请消息
   */
  VCInvite = 'RC:VCInvite',
  /**
   * 响铃消息
   */
  VCRinging = 'RC:VCRinging',
  /**
   * 接听消息
   */
  VCAccept = 'RC:VCAccept',
  /**
   * 挂断消息
   */
  VCHangup = 'RC:VCHangup',
  /**
   * 群呼中 人员变更消息
   */
  VCModifyMem = 'RC:VCModifyMem',
  /**
   * 媒体类型修改消息
   */
  VCModifyMedia = 'RC:VCModifyMedia',
}
