/**
 * RTC 房间加入类型
 */
export enum RTCJoinType {
  /**
   * 踢前一个设备
   */
  KICK = 0,
  /**
   * 当前加入拒绝
   */
  REFUSE = 1,
  /**
   * 两个设备共存
   */
  COEXIST = 2
}
