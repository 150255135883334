import { timerSetTimeout } from './helper';

export class Timer {
  private _timerId: number = 0

  private _startTime: number = 0

  constructor(callback: Function, timeout: number) {
    if (callback) {
      this._timerId = timerSetTimeout(() => {
        callback();
      }, timeout);
    }
    this._startTime = Date.now();
  }

  stop(): {
    startTime: number,
    endTime: number,
    duration: number
    } {
    clearTimeout(this._timerId);

    const endTime = Date.now();
    let duration = endTime - this._startTime;
    if (this._startTime === 0) {
      duration = 0;
    }

    return {
      startTime: this._startTime,
      endTime,
      duration,
    };
  }

  reset() {
    this._startTime = 0;
  }
}
