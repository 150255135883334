import {
  RTCJoinType, LogL, isUndefined, isBoolean, isString, IiOSPushConfig,
} from '@rongcloud/engine';
import { RCCallErrorCode, RCCallLanguage, RCCallMediaType } from '@rc-embed/call-engine';
import { RCResolution } from '@rongcloud/plugin-rtc';

import {
  ISessionListener, IRCCallInitOptions, IValidationResult, IMediaStreamConstraints, IPushConfig,
} from './interface';

function isLanguage(val: string) {
  const values: string[] = Object.values(RCCallLanguage);
  return values.includes(val);
}

function isJoinType(val: number) {
  const values = Object.values(RTCJoinType) as number[];
  return values.includes(val);
}

function isLogLevel(val: number) {
  return [LogL.DEBUG, LogL.INFO, LogL.WARN, LogL.ERROR].includes(val);
}

export const validateCallInitOptions = (options: IRCCallInitOptions): IValidationResult => {
  if (!options) {
    return { result: false, msg: 'Initialization missing parameter -> options' };
  }
  if (typeof options !== 'object') {
    return { result: false, msg: 'Initialization options must be an object' };
  }
  const keyNames: string[] = ['rtcClient', 'onSession', 'onSessionClose'];
  const keys: string[] = Object.keys(options);
  const missingKeys: string[] = [];

  // 校验填项是否都包含，如果哪个不包含就收集起来
  keyNames.forEach((key: string) => {
    if (!keys.includes(key)) {
      missingKeys.push(key);
    }
  });

  // 如果缺少必填的监听函数或对象
  if (missingKeys.length) {
    return { result: false, msg: `Initialization missing parameter -> "${missingKeys.join(',')}"` };
  }
  if (typeof options.rtcClient !== 'object') {
    return { result: false, msg: 'Initialization \'rtcClient\' parameter must be of type \'object\'' };
  }
  if (typeof options.onSession !== 'function') {
    return { result: false, msg: 'Initialization \'onSession\' parameter must be of type \'function\'' };
  }
  if (typeof options.onSessionClose !== 'function') {
    return { result: false, msg: 'Initialization \'onSessionClose\' parameter must be of type \'function\'' };
  }

  // 如果传了isAllowSubscribeRetry 但不是boolean类型
  if (typeof options.isAllowSubscribeRetry !== 'undefined' && typeof options.isAllowSubscribeRetry !== 'boolean') {
    return { result: false, msg: 'Initialization \'isAllowSubscribeRetry\' parameter must be of type \'boolean\'' };
  }

  // 如果传了，但不是boolean类型
  if (typeof options.isAllowPublishRetry !== 'undefined' && typeof options.isAllowPublishRetry !== 'boolean') {
    return { result: false, msg: 'Initialization \'isAllowPublishRetry\' parameter must be of type \'boolean\'' };
  }

  // 如果传了，但不是boolean类型
  if (typeof options.isOffCameraWhenVideoDisable !== 'undefined' && typeof options.isOffCameraWhenVideoDisable !== 'boolean') {
    return { result: false, msg: 'Initialization \'isOffCameraWhenVideoDisable\' parameter must be of type \'boolean\'' };
  }

  // 如果传了，但不是RTCJoinType的枚举
  if (typeof options.joinType !== 'undefined' && !isJoinType(options.joinType!)) {
    return { result: false, msg: 'Initialization \'joinType\' parameter must be of type correct type' };
  }

  // 如果传了，但不是boolean类型
  if (typeof options.isAllowDemotionGetStream !== 'undefined' && typeof options.isAllowDemotionGetStream !== 'boolean') {
    return { result: false, msg: 'Initialization \'isAllowDemotionGetStream\' parameter must be of type \'boolean\'' };
  }

  // 如果传了，但不是RCCallLanguage的枚举
  if (typeof options.lang !== 'undefined' && !isLanguage(options.lang!)) {
    return { result: false, msg: 'Initialization \'lang\' parameter must be of type correct type' };
  }

  if (typeof options.logOutputLevel !== 'undefined' && !isLogLevel(options.logOutputLevel!)) {
    return { result: false, msg: 'Initialization \'logOutputLevel\' parameter must be of type correct type' };
  }
  return { result: true };
};

/**
 * 校验registerSessionListener参数
 */
export const validateListener = (listener: ISessionListener): IValidationResult => {
  if (!listener) {
    return { result: false, msg: 'missing parameter -> listener' };
  }
  if (typeof listener !== 'object') {
    return { result: false, msg: 'listener must be an object' };
  }
  const keyNames: string[] = ['onRinging', 'onAccept', 'onHungup', 'onTrackReady'];
  const keys: string[] = Object.keys(listener);
  const missingKeys: string[] = [];
  keyNames.forEach((key: string) => {
    if (!keys.includes(key)) {
      missingKeys.push(key);
    }
  });
  if (missingKeys.length) {
    return { result: false, msg: `missing parameter -> "${missingKeys.join(',')}"` };
  }
  if (typeof listener.onRinging !== 'function') {
    return { result: false, msg: '\'onRinging\' parameter must be of type \'function\'' };
  }
  if (typeof listener.onAccept !== 'function') {
    return { result: false, msg: '\'onAccept\' parameter must be of type \'function\'' };
  }
  if (typeof listener.onHungup !== 'function') {
    return { result: false, msg: '\'onHungup\' parameter must be of type \'function\'' };
  }
  if (typeof listener.onTrackReady !== 'function') {
    return { result: false, msg: '\'onTrackReady\' parameter must be of type \'function\'' };
  }
  return { result: true };
};

export const validateTargetId = (targetId: string): IValidationResult => {
  if (targetId && typeof targetId === 'string') {
    return { result: true };
  }
  return { result: false, msg: '\'targetId\' parameter is required, must be of type \'string\'' };
};

export const validateMediaType = (mediaType: number): IValidationResult => {
  if (mediaType === RCCallMediaType.AUDIO || mediaType === RCCallMediaType.AUDIO_VIDEO) {
    return { result: true };
  }
  return { result: false, msg: '\'mediaType\' parameter is required, must be of type \'RCCallMediaType\'' };
};
export const validateExtra = (extra: string): IValidationResult => {
  if (typeof extra === 'string') {
    return { result: true };
  }
  return { result: false, msg: '\'extra\' parameter must be of type \'string\'' };
};
export const validatePushTitle = (pushTitle: string): IValidationResult => {
  if (typeof pushTitle === 'string') {
    return { result: true };
  }
  return { result: false, msg: '\'pushTitle\' parameter must be of type \'string\'' };
};
export const validatePushContent = (pushContent: string): IValidationResult => {
  if (typeof pushContent === 'string') {
    return { result: true };
  }
  return { result: false, msg: '\'pushContent\' parameter must be of type \'string\'' };
};

/**
 * 校验 pushConfig
 */
export const validatePushConfig = (pushConfig: IPushConfig) => {
  const pushConfigKeys = ['pushTitle', 'pushContent', 'pushData', 'iOSConfig', 'androidConfig', 'disablePushTitle', 'templateId'];
  const keyIsRight = Object.keys(pushConfig).every((key) => pushConfigKeys.includes(key));
  if (!keyIsRight) {
    return {
      code: RCCallErrorCode.PARAM_ERROR,
      errorMsg: `right key: ${pushConfigKeys.join(',')}`,
    };
  }

  for (let index = 0; index < pushConfigKeys.length; index++) {
    const key = pushConfigKeys[index];
    /**
     * 校验 boolean 类型
     */
    if (key === 'disablePushTitle') {
      if (!isUndefined(pushConfig[key]) && !isBoolean(pushConfig[key])) {
        return {
          code: RCCallErrorCode.PARAM_ERROR,
          errorMsg: `${key} type is boolean`,
        };
      }
    } else if (key === 'pushTitle'
      || key === 'templateId') {
      /**
       * 校验 string 类型
       */
      if (pushConfig[key] && !isString(pushConfig[key])) {
        return {
          code: RCCallErrorCode.PARAM_ERROR,
          errorMsg: `${key} type is string`,
        };
      }
    }
  }
  const { iOSConfig, androidConfig } = pushConfig;

  /**
   * 校验 iOSConfig
   */
  const validateIOSConfigRes = validateIOSConfig(iOSConfig || {});
  if (validateIOSConfigRes.code !== RCCallErrorCode.SUCCESS) {
    return validateIOSConfigRes;
  }

  /**
   * 校验 androidConfig
   */
  const validateAndroidConfigRes = validateAndroidConfig(androidConfig || {});
  if (validateAndroidConfigRes.code !== RCCallErrorCode.SUCCESS) {
    return validateAndroidConfigRes;
  }

  return { code: RCCallErrorCode.SUCCESS };
};

const validateIOSConfig = (iOSConfig: IiOSPushConfig) => {
  const { threadId, richMediaUri } = iOSConfig || {};
  if (threadId && !isString(threadId)) {
    return {
      code: RCCallErrorCode.PARAM_ERROR,
      errorMsg: 'iOSConfig.threadId type is string',
    };
  }

  if (richMediaUri && !isString(richMediaUri)) {
    return {
      code: RCCallErrorCode.PARAM_ERROR,
      errorMsg: 'iOSConfig.richMediaUri type is string',
    };
  }
  return { code: RCCallErrorCode.SUCCESS };
};

const validateAndroidConfig = (androidConfig: any) => {
  const androidConfigKeys = ['notificationId', 'channelIdMi', 'miLargeIconUrl', 'channelIdHW', 'categoryHW', 'importanceHW', 'imageUrlHW', 'channelIdOPPO', 'typeVivo', 'categoryVivo', 'fcmChannelId', 'fcmCollapseKey', 'fcmImageUrl', 'importanceHonor', 'imageUrlHonor'];
  const keyIsRight = Object.keys(androidConfig).every((key) => androidConfigKeys.includes(key));
  if (!keyIsRight) {
    return {
      code: RCCallErrorCode.PARAM_ERROR,
      errorMsg: `right key: ${androidConfigKeys.join(',')}`,
    };
  }

  for (let index = 0; index < androidConfigKeys.length; index++) {
    const key = androidConfigKeys[index];
    if (key === 'importanceHW' || key === 'importanceHonor') {
      if (androidConfig[key] && androidConfig[key] !== 'LOW' && androidConfig[key] !== 'NORMAL') {
        return {
          code: RCCallErrorCode.PARAM_ERROR,
          errorMsg: `androidConfig.${key} value is NORMAL|LOW`,
        };
      }
    } else if (androidConfig[key] && !isString(androidConfig[key])) {
      /**
       * 校验 string 类型
       */
      return {
        code: RCCallErrorCode.PARAM_ERROR,
        errorMsg: `androidConfig.${key} type is string`,
      };
    }
  }
  return { code: RCCallErrorCode.SUCCESS };
};

export const validateUserIds = (userIds: string[]): IValidationResult => {
  if (!Array.isArray(userIds)) {
    return { result: false, msg: '\'userIds\' parameter is required, must be of type \'string[]\'' };
  }
  if (!userIds.length) {
    return { result: false, msg: '\'userIds\' parameter is required, must be of type \'string[]\'' };
  }
  if (!userIds.every((str) => typeof str === 'string' && str.length > 0)) {
    return { result: false, msg: '\'userIds\' parameter is required' };
  }
  return { result: true };
};

function isRCFrameRate(val: string): boolean {
  const arrs = ['FPS_10', 'FPS_15', 'FPS_24', 'FPS_30'];
  return arrs.includes(val);
}

const isValidResolution = (resolution?: RCResolution): boolean => !!RCResolution[resolution!];

export const validateMediaStreamConstraints = (constraints: IMediaStreamConstraints): IValidationResult => {
  if (constraints && constraints.audio && typeof constraints.audio.micphoneId !== 'undefined' && typeof constraints.audio.micphoneId !== 'string') {
    return { result: false, msg: '\'constraints.audio.micphoneId\' must be of type \'string\'' };
  }
  if (constraints && constraints.audio && typeof constraints.audio.sampleRate !== 'undefined' && typeof constraints.audio.sampleRate !== 'number') {
    return { result: false, msg: '\'constraints.audio.sampleRate\' must be of type \'number\'' };
  }
  if (constraints && constraints.video && typeof constraints.video.cameraId !== 'undefined' && typeof constraints.video.cameraId !== 'string') {
    return { result: false, msg: '\'constraints.video.cameraId\' must be of type \'string\'' };
  }
  // if (constraints && constraints.video && typeof constraints.video.faceMode !== 'undefined' && constraints.video.cameraId !== 'user' && constraints.video.faceMode !== 'environment') {
  //   return { result: false, msg: '\'constraints.video.cameraId\' must be  \'user\' or \'environment\'' }
  // }

  if (constraints && constraints.video && typeof constraints.video.frameRate !== 'undefined' && typeof constraints.video.frameRate !== 'string') {
    return { result: false, msg: '\'constraints.video.frameRate\' must be of type \'string\'' };
  }

  if (constraints && constraints.video && typeof constraints.video.frameRate !== 'undefined' && !isRCFrameRate(constraints.video.frameRate)) {
    return { result: false, msg: '\'frameRate\' value is out of range' };
  }

  if (constraints && constraints.video && typeof constraints.video.resolution !== 'undefined' && typeof constraints.video.resolution !== 'string') {
    return { result: false, msg: '\'constraints.video.frameRate\' must be of type \'string\'' };
  }

  if (constraints && constraints.video && typeof constraints.video.resolution !== 'undefined' && !isValidResolution(constraints.video.resolution)) {
    return { result: false, msg: '\'resolution\' value is out of range' };
  }
  if (constraints && constraints.video && (!constraints.video.frameRate || !constraints.video.resolution)) {
    return { result: false, msg: '\'resolution\' and \'resolution\' is required' };
  }

  return { result: true };
};
