import {
  RTCPluginContext, IRuntime, IRTCJoinedInfo, ErrorCode, RTCJoinType, ILogger, BasicLogger,
} from '@rongcloud/engine';
import {
  RCCallEngine, RCCallStateMachine, RCCallErrorCode, RCCallLanguage, RCCallMediaType, IOfflineRecord,
} from '@rc-embed/call-engine';
import { RCRTCClient, RCRTCCode, RCLocalTrack } from '@rongcloud/plugin-rtc';
import {
  IRCCallInGroupParams, IRCCallInitOptions, IRCCallParams, IMediaStreamConstraints, IValidationResult, IPushConfig,
} from './interface';
import { ProduceTypes } from './enums';
import eventEmitter from './eventEmitter';
import { RCCallSession } from './RCCallSession';
import {
  validateListener, validateTargetId, validateMediaType, validateUserIds, validateExtra, validatePushConfig,
} from './validation';

export default class RCCallClient {
  /**
   * rtc实例
   */
  private readonly _rtcClient: RCRTCClient

  /**
   * callEngine层实例
   */
  private readonly _callEngine: RCCallEngine

  /**
   * 其它参数
   */
  private _options: IRCCallInitOptions

  /**
   * session列表
   */
  private _sessionList: RCCallSession[] = []

  /**
   * 移动端呼叫推送配置
   */
  private _callPushConfig?: IPushConfig

  /**
   * 移动端挂断推送配置
   */
  private _hungupPushConfig?: IPushConfig

  constructor(
    private _context: RTCPluginContext,
    private readonly _runtime: IRuntime,
    private readonly _logger: BasicLogger,
    _options: IRCCallInitOptions,
  ) {
    this._rtcClient = _options.rtcClient;

    this._options = { /**
       * 是否允许发布重试， 默认不允许
       */
      isAllowPublishRetry: false,

      /**
       * 是否允许订阅重试，默认不允许
       */
      isAllowSubscribeRetry: false,
      /**
       * 禁用视频时关摄像头, 默认关闭
       */
      isOffCameraWhenVideoDisable: true,
      /**
       * RTC 房间加入类型，默认   RTCJoinType.COEXIST = 2 两个设备共存
       *     RTCJoinType.KICK = 0,踢前一个设备
       *     RTCJoinType.REFUSE = 1,当前加入拒绝
       *     RTCJoinType.COEXIST = 2 两个设备共存
       */
      joinType: RTCJoinType.COEXIST,

      /**
       * 允许降级获得流，获得音视频不成功 ，降级获得音频, 默认不允许
       */
      isAllowDemotionGetStream: false,

      /**
       * 语言设置 (推送), 不传默认为中文
       */
      lang: RCCallLanguage.ZH,
      ..._options,
    };

    // 初始化callEngine, 并监听onInvite
    this._callEngine = new RCCallEngine(this._context, _runtime, this._logger, {

      /**
       * 监听收到invite
       */
      onInvite: this._onInvite.bind(this),

      /**
       * 监听离线消息报告
       */
      onOfflineRecord: this._onOfflineRecord.bind(this),
    }, {

      /**
       * 语言设置 (推送), 不传默认为中文
       */
      lang: this._options.lang || RCCallLanguage.ZH,

    });

    eventEmitter.on('sessionClose', ({ session, summaryInfo }) => {
      // 从sessionList去掉这个关闭的session
      this._removeSession(session);

      try {
        this._options.onSessionClose(session, summaryInfo);
      } catch (error) {
        this._logger.error('_', '[RCCCallClient] options.onSessionClose exception');
        console.log(error);
      }
    });

    // 接听之前挂断其它的session
    eventEmitter.on('hungupOtherSession', ({ session }) => {
      const id = session.getSessionId();
      this._logger.info('_', `[RCCallClient hungupOtherSession] sessionId ready to accept -> ${id}`);
      this._logger.info('_', `[RCCallClient hungupOtherSession] sessionList ->${this._sessionList.map((ses) => ses.getSessionId()).join(',')}`);
      let i = 0;
      while (this._sessionList.length > 1) {
        // 如果与要接听的session不一致
        if (this._sessionList[i].getSessionId() !== id) {
          // 挂断
          this._sessionList[i].hungup();

          // 挂断后删除
          this._sessionList.splice(i, 1);
        } else {
          // 如果是要接听的session，跳过这个索引，所以加1
          i++;
        }
      }
      this._logger.info('_', `[RCCallClient hungupOtherSession] current sessionList length ->${this._sessionList.length}`);
    });
  }

  /**
   * 监听onInvite
   */
  private _onInvite(stateMachine: RCCallStateMachine, extra?: string) {
    this._logger.info('_', '[RCCallClient _onInvite] Received invite message');
    const session = new RCCallSession(stateMachine, this._rtcClient, this._logger, {

      // 是否允许订阅重试
      isAllowSubscribeRetry: this._options.isAllowSubscribeRetry,

      // 是否允许发布重试
      isAllowPublishRetry: this._options.isAllowPublishRetry,

      /**
       * 禁用视频时关摄像头
       */
      isOffCameraWhenVideoDisable: this._options.isOffCameraWhenVideoDisable,

      /**
       * RTC 房间加入类型
       */
      joinType: this._options.joinType,

      // 允许降级获得流，获得音视频不成功 ，降级获得音频, 默认不允许
      isAllowDemotionGetStream: this._options.isAllowDemotionGetStream,

      // 标明是被叫产生的session
      produceType: ProduceTypes.CALLEE,

      callPushConfig: this._callPushConfig,

      hungupPushConfig: this._hungupPushConfig,
    });
    this._logger.info('_', '[RCCallClient _onInvite] Received invite message, successfully created session');

    /**
     * 如果通话的时候不允许接听新的通话，直接挂断， 这些工作在callEngine里完成
     */
    this._sessionList.push(session);

    try {
      // 执行用户API的监听
      this._options.onSession(session, extra);
    } catch (error) {
      this._logger.error('_', '[RCCallClient _options.onSession] onSession exception');
      console.log(error);
    }

    // 必须在onSession里注册session监听事件，这里检测一下有没有注册
    if (session._listener) {
      const conclusion: IValidationResult = validateListener(session._listener);
      if (!conclusion.result) {
        throw new Error(conclusion.msg);
      }
    } else {
      this._logger.error('_', '[RCCallClient _options.onSession] session Must Have Listener');
      throw new Error('[RCCallSession  _options.onSession] session Must Have Listener');
    }
  }

  /**
   * 监听离线消息报告
   * @param record
   */
  public _onOfflineRecord(record: IOfflineRecord) {
    try {
      // 执行用户API的监听
      this._options.onOfflineRecord && this._options.onOfflineRecord(record);
    } catch (error) {
      this._logger.error('_', '[RCCallClient _options.onOfflineRecord] onOfflineRecord exception');
      console.log(error);
    }
  }

  /**
   * 注册用户信息。注册后，在发起邀请或挂断等操作时，会将该信息一并发送给对端
   * @param info.name        用户名称
   * @param info.portraitUri 用户头像信息
   * @param info.extra       预留拓展字段
   */
  public registerUserInfo(info: { name?: string; portraitUri?: string; extra?: string; } = {}) {
    this._callEngine.registerUserInfo(info);
    this._logger.info('_', '[RCCallClient registerUserInfo] successfully register user info data');
  }

  /**
  * 跨App单呼，发送invite消息，回调回来接收stateMachine, 建session
  * @param params.targetId 被呼叫一方的用户 id 必填
  * @param params.mediaType 音频呼叫 or 音视频呼叫  必填
  * @param params.listener (session上的监听) 必填
  * @param params.constraints 获取音频或音视频资源时的参数 可选
  * @param params.channelId 组织 Id 可选
  * @param params.extra 消息扩展信息
  * @deprecated 5.1.2 版本废弃 params.pushTitle 通知的标题
  * @deprecated 5.1.2 版本废弃 params.pushContent 通知的内容
  * @param params.bitrate 需要设置的码率参数
  *
  */
  public async startCrossCall({
    targetId, mediaType = RCCallMediaType.AUDIO, listener, constraints, channelId = '', extra = '', pushTitle = '', pushContent = '', bitrate,
  }: IRCCallParams): Promise<{ code: RCCallErrorCode, session?: RCCallSession }> {
    return this.__call({
      targetId, mediaType, listener, constraints, channelId, extra, pushTitle, pushContent, bitrate, isCrossAppkey: true,
    });
  }

  /**
   * 单呼，发送invite消息，回调回来接收stateMachine, 建session
   * @param params.targetId 被呼叫一方的用户 id 必填
   * @param params.mediaType 音频呼叫 or 音视频呼叫  必填
   * @param params.listener (session上的监听) 必填
   * @param params.constraints 获取音频或音视频资源时的参数 可选
   * @param params.channelId 组织 Id 可选
   * @param params.extra 消息扩展信息
   * @deprecated 5.1.2 版本废弃 params.pushTitle 通知的标题
   * @deprecated 5.1.2 版本废弃 params.pushContent 通知的内容
   * @param params.bitrate 需要设置的码率参数
   *
   */
  public async call({
    targetId, mediaType = RCCallMediaType.AUDIO, listener, constraints, channelId = '', extra = '', pushTitle = '', pushContent = '', bitrate,
  }: IRCCallParams): Promise<{ code: RCCallErrorCode, session?: RCCallSession }> {
    return this.__call({
      targetId, mediaType, listener, constraints, channelId, extra, pushTitle, pushContent, bitrate,
    });
  }

  private async __call({
    targetId, mediaType = RCCallMediaType.AUDIO, listener, constraints, channelId = '', extra = '', pushTitle = '', pushContent = '', bitrate, isCrossAppkey = false,
  }: IRCCallParams): Promise<{ code: RCCallErrorCode, session?: RCCallSession }> {
    const pushConfig = this._callPushConfig ? this._callPushConfig : { pushTitle, pushContent };
    this._logger.info('_', `[RCCallClient call] extra->${extra} pushConfig->${JSON.stringify(pushConfig)}`);
    const conclusion: IValidationResult[] = [validateTargetId(targetId), validateMediaType(mediaType), validateListener(listener), validateExtra(extra)];
    const messages: string[] = [];
    const result = conclusion.every((obj: IValidationResult) => {
      !obj.result && messages.push(obj.msg!);
      return obj.result;
    });
    if (!result) {
      throw new Error(`[RCCallClient call] ${messages.join('\n')}`);
    }

    const { code: validatePushCode, errorMsg } = validatePushConfig(pushConfig);
    if (validatePushCode !== RCCallErrorCode.SUCCESS) {
      this._logger.error('_', `[RCCallClient call] param error, errorMsg: ${errorMsg}`);
      return { code: validatePushCode };
    }

    let localTracks: RCLocalTrack[] = [];

    const { code: _code, tracks } = await this._getLocalTrack(mediaType, constraints);
    if (_code !== RCCallErrorCode.SUCCESS) {
      return { code: _code };
    }
    localTracks = tracks!;

    localTracks.forEach((track) => {
      // 设置码率
      if (track.isAudioTrack() && bitrate?.audio) {
        track.setBitrate(bitrate?.audio);
      }
      if (track.isVideoTrack() && bitrate?.video) {
        track.setBitrate(bitrate?.video?.max, bitrate?.video?.min, bitrate?.video?.start);
      }
      // 向外抛出本地流
      listener.onTrackReady(track);
    });

    // 调用callEngine的call返回一个状态机的实例
    const { code, stateMachine } = await this._callEngine.call(channelId, targetId, mediaType, extra, pushConfig, isCrossAppkey);
    if (code === RCCallErrorCode.SUCCESS && stateMachine) {
      this._logger.info('_', '[RCCallClient call] successfully created state machine');
      const session = new RCCallSession(stateMachine, this._rtcClient, this._logger, {
        localTracks,

        // 是否允许订阅重试
        isAllowSubscribeRetry: this._options.isAllowSubscribeRetry,

        // 是否允许订阅重试
        isAllowPublishRetry: this._options.isAllowPublishRetry,

        /**
         * 禁用视频时关摄像头
         */
        isOffCameraWhenVideoDisable: this._options.isOffCameraWhenVideoDisable,

        /**
         * RTC 房间加入类型
         */
        joinType: this._options.joinType,

        // 允许降级获得流，获得音视频不成功 ，降级获得音频, 默认不允许
        isAllowDemotionGetStream: this._options.isAllowDemotionGetStream,

        // 标明是主叫产生的session
        produceType: ProduceTypes.CALLER,

        isCrossAppkey,

        callPushConfig: this._callPushConfig,

        hungupPushConfig: this._hungupPushConfig,
      });

      // session上注册监听事件
      session.registerSessionListener(listener);

      this._sessionList.push(session);
      this._logger.info('_', `[RCCallClient call] successfully created session object, sessionId: ${session.getSessionId()}`);
      return { code, session };
    }
    this._logger.error('_', `[RCCallClient call] call failed code ->: ${code}`);
    localTracks.forEach((track) => {
      // 禁用视频
      track.mute();

      // 关闭摄像头
      track.destroy();
    });

    return { code };
  }

  /**
   * 发起群组呼叫
   * @param params.targetId 群组 Id 必填
   * @param params.userIds 被呼叫的群内成员 Id 必填
   * @param params.mediaType 音频呼叫 or 音视频呼叫 必填
   * @param params.listener (session上的监听) 必填
   * @param params.constraints 获取音频或音视频资源时的参数 可选
   * @param params.channelId 组织 Id 可选
   * @param params.extra 消息扩展信息 可选
   * @deprecated 5.1.2 版本废弃 params.pushTitle 通知的标题
   * @deprecated 5.1.2 版本废弃 params.pushContent 通知的内容
   * @param params.bitrate 需要设置的码率参数
   */
  public async callInGroup({
    targetId, userIds, mediaType = RCCallMediaType.AUDIO, listener, constraints, channelId = '', extra = '', pushTitle = '', pushContent = '', bitrate,
  }: IRCCallInGroupParams): Promise<{ code: RCCallErrorCode, session?: RCCallSession }> {
    const pushConfig = this._callPushConfig ? this._callPushConfig : { pushTitle, pushContent };
    const conclusion: IValidationResult[] = [validateTargetId(targetId), validateUserIds(userIds), validateMediaType(mediaType), validateListener(listener), validateExtra(extra)];
    const messages: string[] = [];
    const result = conclusion.every((obj: IValidationResult) => {
      !obj.result && messages.push(obj.msg!);
      return obj.result;
    });

    if (!result) {
      throw new Error(`[RCCallClient callInGroup] ${messages.join('\n')}`);
    }

    const { code: validatePushCode, errorMsg } = validatePushConfig(pushConfig);
    if (validatePushCode !== RCCallErrorCode.SUCCESS) {
      this._logger.error('_', `[RCCallClient call] param error, errorMsg: ${errorMsg}`);
      return { code: validatePushCode };
    }

    let localTracks: RCLocalTrack[] = [];

    const { code: _code, tracks } = await this._getLocalTrack(mediaType, constraints);
    if (_code !== RCCallErrorCode.SUCCESS) {
      return { code: _code };
    }
    localTracks = tracks!;

    localTracks.forEach((track) => {
      // 设置码率
      if (track.isAudioTrack() && bitrate?.audio) {
        track.setBitrate(bitrate?.audio);
      }
      if (track.isVideoTrack() && bitrate?.video) {
        track.setBitrate(bitrate?.video?.max, bitrate?.video?.min, bitrate?.video?.start);
      }
      // 向外抛出本地流
      listener.onTrackReady(track);
    });

    // 往组里发消息
    const { code, stateMachine } = await this._callEngine.callInGroup(channelId, targetId, mediaType, userIds, extra, pushConfig);
    if (code === RCCallErrorCode.SUCCESS && stateMachine) {
      this._logger.info('_', '[RCCallClient callInGroup] successfully created state machine');
      const session = new RCCallSession(stateMachine, this._rtcClient, this._logger, {
        localTracks,

        // 是否允许订阅重试
        isAllowSubscribeRetry: this._options.isAllowSubscribeRetry,

        // 是否允许发布重试
        isAllowPublishRetry: this._options.isAllowPublishRetry,

        /**
         * 禁用视频时关摄像头
         */
        isOffCameraWhenVideoDisable: this._options.isOffCameraWhenVideoDisable,

        /**
         * RTC 房间加入类型
         */
        joinType: this._options.joinType,

        // 允许降级获得流，获得音视频不成功 ，降级获得音频, 默认不允许
        isAllowDemotionGetStream: this._options.isAllowDemotionGetStream,

        // 标明是主叫产生的session
        produceType: ProduceTypes.CALLER,

        callPushConfig: this._callPushConfig,

        hungupPushConfig: this._hungupPushConfig,
      });

      // session上注册监听事件
      session.registerSessionListener(listener);
      this._sessionList.push(session);
      this._logger.info('_', `[RCCallClient callInGroup] successfully created session object, sessionId: ${session.getSessionId()}`);
      return { code, session };
    }
    this._logger.info('_', `[RCCallClient callInGroup] callInGroup failed code -> ${code}`);
    localTracks.forEach((track) => {
      // 禁用视频
      track.mute();

      // 关闭摄像头
      track.destroy();
    });

    return { code };
  }

  /**
   * 调RTC API 获得本地流
   */
  private async _getLocalTrackCore(mediaType: RCCallMediaType, constraints?: IMediaStreamConstraints): Promise<{ code: RCCallErrorCode, tracks?: RCLocalTrack[] }> {
    // 检测是否能够获得本地流
    if (mediaType === RCCallMediaType.AUDIO) {
      const { code, track } = await this._rtcClient.createMicrophoneAudioTrack('RongCloudRTC', constraints && constraints.audio && { ...constraints.audio });
      if (code !== RCRTCCode.SUCCESS) {
        this._logger.error('_', `[RCCallClient _getTrack] get Audio local tracks failed RCT code -> ${code}`);
        return { code: RCCallErrorCode.GET_LOCAL_AUDIO_TRACK_ERROR };
      }
      this._logger.info('_', '[RCCallClient _getTrack] successfully get Audio local tracks');
      return { code: RCCallErrorCode.SUCCESS, tracks: [track!] };
    }
    const { code, tracks } = await this._rtcClient.createMicrophoneAndCameraTracks('RongCloudRTC', constraints && { ...constraints });
    if (code !== RCRTCCode.SUCCESS) {
      this._logger.error('_', `[RCCallClient _getTrack] get Audio and Video local tracks failed RCT code -> ${code}`);
      return { code: RCCallErrorCode.GET_LOCAL_AUDIO_AND_VIDEO_TRACK_ERROR };
    }
    this._logger.info('_', '[RCCallClient _getTrack] successfully get audio and video local tracks');
    return { code: RCCallErrorCode.SUCCESS, tracks };
  }

  private async _getLocalTrack(mediaType: RCCallMediaType, constraints?: IMediaStreamConstraints): Promise<{ code: RCCallErrorCode, tracks?: RCLocalTrack[] }> {
    // 如果是允许降级获得流，并且是获得音视频
    if (this._options.isAllowDemotionGetStream && mediaType === RCCallMediaType.AUDIO_VIDEO) {
      const { code, tracks } = await this._getLocalTrackCore(RCCallMediaType.AUDIO_VIDEO, constraints);

      // 如果音视频不能获得，就降组获得音频
      if (code !== RCCallErrorCode.SUCCESS) {
        const { code, tracks } = await this._getLocalTrackCore(RCCallMediaType.AUDIO, constraints);
        if (code !== RCCallErrorCode.SUCCESS) {
          return { code };
        }
        return { code, tracks };
      }
      return { code, tracks };
    }
    const { code: _code, tracks } = await this._getLocalTrackCore(mediaType, constraints);
    if (_code !== RCCallErrorCode.SUCCESS) {
      return { code: _code };
    }
    return { code: _code, tracks };
  }

  /**
   * 从sessionList删除某个session
   */
  private _removeSession(session: RCCallSession) {
    const id = session.getSessionId();
    this._sessionList = this._sessionList.filter((session) => session.getSessionId() !== id);
  }

  /**
   * 获取己方其他端加入通话（已加入 RTC 房间）的用户信息
   */
  public async getJoinedRoomInfo(): Promise<{ code: RCCallErrorCode, data?: IRTCJoinedInfo[] }> {
    const { code, data } = await this._context.getRTCJoinedUserInfo(this._context.getCurrentId());
    if (code !== ErrorCode.SUCCESS) {
      this._logger.error('_', `getJoinedUserInfo error code: ${code}`);
      return { code: RCCallErrorCode.QUERY_JOINED_USER_INFO_ERROR };
    }

    return { code: RCCallErrorCode.SUCCESS, data };
  }

  /**
   * 设置呼叫、挂断推送数据
   * @param callPushConfig 呼叫推送配置
   * @param hungupPushConfig 挂断推送配置
   * @description callLib 会内置 IPushConfig 中 pushData 的赋值，业务层无需关注 pushData 字段值
   */
  public setPushConfig(callPushConfig: IPushConfig, hungupPushConfig: IPushConfig) {
    const validateRes = [callPushConfig, hungupPushConfig].map((item) => validatePushConfig(item));

    for (const { code, errorMsg } of validateRes) {
      if (code !== RCCallErrorCode.SUCCESS) {
        this._logger.error('_', `[RCCallClient setPushConfig] param error, errorMsg: ${errorMsg}`);
        return { code };
      }
    }

    this._callPushConfig = callPushConfig;
    this._hungupPushConfig = hungupPushConfig;
  }
}
