import { RCInnerCDNBroadcast } from '../enums/RCInnerCDNBroadcast';
import { RTCMode } from '../enums/RTCMode';
import { Invoker } from '../Invoker';
import { IMediaServerQualityData } from '../logger/IQualityReportData';
import { IExchangeReqBody, IExchangeResponse } from '../service';
import { Store } from '../Store';
import { BaseCommand, ICommandResult } from './BaseCommand';
import { CommandExecuteContext } from './CommandExecuteContext';
import { setRoomCDNInfo } from './helper';

export class ExchangeCommand extends BaseCommand<{ data?: IExchangeResponse, qualityMsList?: IMediaServerQualityData[] }> {
  constructor(
    private reqBody: IExchangeReqBody,
    private traceId?: string,
    private isNeedUpdateMsas?: boolean,
  ) {
    super();
  }

  async execute(executeCtx: CommandExecuteContext, store: Store, invoker: Invoker): Promise<ICommandResult<{ data?: IExchangeResponse, qualityMsList?: IMediaServerQualityData[] }>> {
    const { service, context } = executeCtx;
    const resp = await service.exchange(store.getRTCRequestHeader(), this.reqBody, this.traceId!, this.isNeedUpdateMsas);
    const { code, data, qualityMsList } = resp;

    const res = { code, data: { data, qualityMsList } };

    const pullUrl = data?.urls?.pull_url;
    if (store.roomMode === RTCMode.LIVE && pullUrl) {
      /**
       * 自动模式下:
       * 无 pull_url 字段
       * /exchange 完需根据 broadcast 字端判断是否扩散 cdn_uris 数据，设置房间 cdn_uris 数据
       */
      const uris = JSON.parse(pullUrl);
      store.setCDNUris(uris);

      if (uris?.broadcast === RCInnerCDNBroadcast.SPREAD) {
        store.setCDNEnabel(true);

        const CDNUris = { ...uris, enableInnerCDN: true };
        setRoomCDNInfo(context, store.roomId, CDNUris);
      }
    }

    return res;
  }
}
