import {
  ErrorCode, validate,
} from '@rongcloud/engine';
import {
  buildPlusMessage, buildTotalURIMessageContent, getTrackIdFromAttr,
} from '../../helper';
import { R2Action } from '../enums/inner/R2Action';
import { R2Status } from '../enums/inner/R2Status';
import { RCRTCMessageType } from '../enums/inner/RCRTCMessageType';
import { RCCommandKind } from '../enums/RCCommandKind';
import { RCLoggerStatus, RCLoggerTag } from '../enums/RCLoggerTag';
import { RCRTCCode } from '../enums/RCRTCCode';
import {
  IPublishedResource, IPubSuccessRes, IPubTaskRes,
} from '../interfaces';
import { Invoker } from '../Invoker';
import { IMediaServerQualityData, RCRTCResourceAction } from '../logger/IQualityReportData';
import { Store } from '../Store';
import { RCLocalTrack } from '../tracks/RCLocalTrack';
import { BaseCommand } from './BaseCommand';
import { CommandExecuteContext } from './CommandExecuteContext';
import { ExchangeCommand } from './ExchangeCommand';
import { createExchangeParams } from './helper';

export class UnpublishCommand extends BaseCommand<IPubSuccessRes> {
  constructor(
    private tracks: RCLocalTrack[],
  ) {
    super();
  }

  get kind(): RCCommandKind {
    return RCCommandKind.UNPUBLISH;
  }

  private _traceId!: string

  /**
   * 行为开始时间
   */
  private _actionStartTime: number = Date.now()

  /**
   * track 对应的 mediaServer 信息
   */
  private _trackMediaMap: {[trackId: string]: IMediaServerQualityData[] } = {}

  private async __unpublish(executeCtx: CommandExecuteContext, store: Store, invoker: Invoker, tracks: RCLocalTrack[]): Promise<IPubTaskRes> {
    const {
      peer, polarisReport, logger, reportMediaActionLogger,
    } = executeCtx;

    const resourceIds = tracks.map((item) => item.getTrackId());
    // 过滤无效参数，避免重复有异常数据导致其他端解析失败
    const unpublishList = resourceIds.map(store.getPublishedResourceByTrackId.bind(store)).filter((item) => !!item);
    if (unpublishList.length === 0) {
      return { code: RCRTCCode.SUCCESS, tracks };
    }

    // 移除 RTCPeerConnection 中添加的轨道数据
    resourceIds.forEach((id) => peer.removeLocalTrackById(id));

    // 北极星上报
    polarisReport.sendR2(R2Action.PUBLISH, R2Status.END, resourceIds);

    // 客户端主动调用 api 发请求时，清除 ice 断线重连的定时器
    peer.clearReTryExchangeTimer();

    const subscribeList = store.getSubscribedList();

    const reqBody = await createExchangeParams(subscribeList, false, peer, store);
    /**
     * 直播房间需携带 pushOtherRooms 信息
     */
    const pushOtherRooms = executeCtx.getPushOtherRooms();
    pushOtherRooms.length && (reqBody.pushOtherRooms = pushOtherRooms);

    const result = await new ExchangeCommand(reqBody, this._traceId, true).execute(executeCtx, store, invoker);

    /**
     * 存储每一个 track 对应的 mediaServer 请求信息
     */
    tracks.forEach((track) => {
      const trackId = track.getTrackId();
      this._trackMediaMap[trackId] = result.data!.qualityMsList || [];
    });

    if (result.code !== RCRTCCode.SUCCESS) {
      // 连通率相关埋点-取消发布资源结束
      reportMediaActionLogger.reportPubOrSubQualityData(RCRTCResourceAction.UNPUB, this._actionStartTime, tracks, result.code, this._trackMediaMap);

      logger.error(RCLoggerTag.L_ABSTRACT_ROOM_UNPUBLISH_P, JSON.stringify({
        status: RCLoggerStatus.FAILED,
        code: result.code,
        trackIds: tracks.map(getTrackIdFromAttr),
        msg: 'exchange failed',
      }), this._traceId);

      return { code: result.code, tracks };
    }

    const { resultCode, message } = result.data!.data!;
    if (resultCode !== RCRTCCode.SUCCESS) {
      reportMediaActionLogger.reportPubOrSubQualityData(RCRTCResourceAction.UNPUB, this._actionStartTime, tracks, result.data!.data!.resultCode, this._trackMediaMap);

      logger.error(RCLoggerTag.L_ABSTRACT_ROOM_UNPUBLISH_P, JSON.stringify({
        status: RCLoggerStatus.FAILED,
        code: resultCode,
        trackIds: tracks.map(getTrackIdFromAttr),
        msg: `exchange inner failed: ${message}`,
      }), this._traceId);

      return { code: resultCode, tracks };
    }

    return { code: result.code, ...result.data!, tracks };
  }

  private async _dealUnpublishRes(executeCtx: CommandExecuteContext, store: Store, unpubRes: IPubTaskRes): Promise<IPubSuccessRes> {
    const {
      context, logger, peer, reportMediaActionLogger,
    } = executeCtx;
    const crtUserId = context.getCurrentId();
    const { roomId } = store;

    // 取消发布资源数据
    const unpublishList: (IPublishedResource | undefined)[] = [];

    // mcu 数据
    let crtMcuPublishList: IPublishedResource[] = [];

    const { tracks, code, data } = unpubRes;
    if (code !== RCRTCCode.SUCCESS) {
      logger.error(RCLoggerTag.L_ABSTRACT_ROOM_UNPUBLISH_R, JSON.stringify({
        code,
        msg: 'unpublish failed',
      }), this._traceId);

      return { code };
    }

    if (!data) {
      logger.info(RCLoggerTag.L_ABSTRACT_ROOM_UNPUBLISH_R, JSON.stringify({
        status: RCLoggerStatus.SUCCESSED,
        msg: 'unpublish list is empty ',
      }));
      return { code: RCRTCCode.SUCCESS };
    }

    const { mcuPublishList } = data;

    /**
     * 获取取消发布资源的 trackIds、资源数据
     */
    const unPubResourceIds = tracks.map((track) => track.getTrackId());
    // 过滤无效参数，避免重复有异常数据导致其他端解析失败
    const list = unPubResourceIds.map(store.getPublishedResourceByTrackId.bind(store)).filter((item) => !!item) as IPublishedResource[];
    unpublishList.push(...list);

    // 当前直播间 mcuPublist
    const newMcuPublishList: IPublishedResource[] = mcuPublishList
      ? mcuPublishList.map((item) => ({
        tag: item.msid.split('_').pop()!,
        state: 1,
        ...item,
      }))
      : [];
    crtMcuPublishList = newMcuPublishList;

    const publishedList = store.getResourcesByUserId(crtUserId)!;

    // 取消发布后的差集
    const dList = publishedList.filter((item) => !unpublishList.includes(item));

    const signalStartTime = Date.now();
    // 通知房间内成员
    const singalCode = await context.setRTCTotalRes(
      roomId,
      [buildPlusMessage(RCRTCMessageType.UNPUBLISH, unpublishList as IPublishedResource[])],
      buildTotalURIMessageContent(dList),
      RCRTCMessageType.TOTAL_CONTENT_RESOURCE,
      buildTotalURIMessageContent(crtMcuPublishList),
    );

    reportMediaActionLogger.reportPubOrSubQualityData(RCRTCResourceAction.UNPUB, this._actionStartTime, tracks, singalCode, this._trackMediaMap, [{
      dur: Date.now() - signalStartTime,
      cod: singalCode,
    }]);

    if (singalCode !== ErrorCode.SUCCESS) {
      logger.error(RCLoggerTag.L_ABSTRACT_ROOM_UNPUBLISH_R, JSON.stringify({
        status: RCLoggerStatus.FAILED,
        code: singalCode,
        msg: 'send unpublish notification failed',
      }), this._traceId);

      return { code: RCRTCCode.SIGNAL_ERROR };
    }

    logger.info(RCLoggerTag.L_ABSTRACT_ROOM_UNPUBLISH_R, JSON.stringify({
      status: RCLoggerStatus.SUCCESSED,
      code: RCRTCCode.SUCCESS,
      msg: 'unpublish success',
      unPubResourceIds,
    }), this._traceId);

    /**
     * 设置 answer
     */
    const { sdp: answer } = unpubRes.data!;
    await peer.setRemoteAnswer(answer.sdp);

    // 更新发布数据
    store.setResourcesByUserId(crtUserId, dList);

    return { code: RCRTCCode.SUCCESS };
  }

  async execute(executeCtx: CommandExecuteContext, store: Store, invoker: Invoker): Promise<IPubSuccessRes> {
    const { logger } = executeCtx;
    this._actionStartTime = Date.now();
    const { crtUserId } = store;
    const { tracks } = this;

    this._traceId = logger.createTraceId()!;

    logger.info(RCLoggerTag.L_ABSTRACT_ROOM_UNPUBLISH_T, JSON.stringify({
      trackIds: tracks.map(getTrackIdFromAttr),
    }));

    // 参数有效性验证
    const valid = validate('tracks', tracks, () => tracks.every((track) => track.getUserId() === crtUserId && track instanceof RCLocalTrack), true);

    if (!valid) {
      logger.error(RCLoggerTag.L_ABSTRACT_ROOM_UNPUBLISH_R, JSON.stringify({
        status: RCLoggerStatus.FAILED,
        code: RCRTCCode.PARAMS_ERROR,
        msg: 'params error -> tracks',
      }));
      return { code: RCRTCCode.PARAMS_ERROR };
    }

    const unpubRes = await this.__unpublish(executeCtx, store, invoker, tracks);

    const result = await this._dealUnpublishRes(executeCtx, store, unpubRes);
    return result;
  }
}
