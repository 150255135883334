/**
 * 挂断原因
 * @description
 * 根据原有 HangupReason 设计，己方原因与对方原因有相差 10 的差距.
 * 现有本地原因取值范围: 1 ~ 10, 远端原因取值范围: 11 ~ 20.
 * 为便于 HangupReason 扩展，保留 100 以内的取值.
 * 需要再次扩展时，己方原因使用: 21 ~ 30, 对应对方原因使用: 31 ~ 40,
 * 以此类推，
 * 己方原因使用: 41 ~ 50, 对方原因使用: 51 ~ 60,
 * 己方原因使用: 61 ~ 70, 对方原因使用: 71 ~ 80,
 * 己方原因使用: 71 ~ 90, 对方原因使用: 91 ~ 100.
 *
 * 各平台独有字段范围
 * Android 201 ~ 299
 * iOS     301 ~ 399
 * Web     401 ~ 499
 * 详细文档：https://gitbook.rongcloud.net/rtc-docs/#/rtc-client/ios/analysis/calllib/HangupReason
 */
export enum RCCallEndReason {
  /**
   * 己方取消已发出的通话请求
   */
  CANCEL = 1,
  /**
   * 己方拒绝收到的通话请求
   */
  REJECT = 2,
  /**
   * 己方挂断
   */
  HANGUP = 3,
  /**
   * 己方忙碌
   */
  BUSY_LINE = 4,
  /**
   * 己方未接听
   */
  NO_RESPONSE = 5,
  /**
   * 己方不支持当前音视频引擎
   */
  ENGINE_UNSUPPORTED = 6,
  /**
   * 己方网络错误
   */
  NETWORK_ERROR = 7,
  /**
   * 己方摄像头资源获取失败，可能是权限原因
   */
  GET_MEDIA_RESOURCES_ERROR = 8,
  /**
   * 己方资源发布失败
   */
  PUBLISH_ERROR = 9,
  /**
   * 己方订阅资源失败
   */
  SUBSCRIBE_ERROR = 10,
  /**
   * 对方取消发出的通话请求
   */
  REMOTE_CANCEL = 11,
  /**
   * 对方拒绝收到的通话请求
   */
  REMOTE_REJECT = 12,
  /**
   * 通话过程中对方挂断
   */
  REMOTE_HANGUP = 13,
  /**
   * 对方忙碌
   */
  REMOTE_BUSY_LINE = 14,
  /**
   * 对方未接听
   */
  REMOTE_NO_RESPONSE = 15,
  /**
   * 对方引擎不支持
   */
  REMOTE_ENGINE_UNSUPPORTED = 16,
  /**
   * 对方网络错误
   */
  REMOTE_NETWORK_ERROR = 17,
  /**
   * 对方摄像头资源获取失败，可能是权限原因
   */
  REMOTE_GET_MEDIA_RESOURCE_ERROR = 18,
  /**
   * 远端资源发布失败
   */
  REMOTE_PUBLISH_ERROR = 19,
  /**
   * 远端订阅资源失败
   */
  REMOTE_SUBSCRIBE_ERROR = 20,
  /**
   * 己方其他端已加入新通话
   */
  OTHER_CLIENT_JOINED_CALL = 21,
  /**
   * 己方其他端已在通话中
   */
  OTHER_CLIENT_IN_CALL = 22,
  /**
   * 己方被禁止通话
   */
  KICKED_BY_SERVER = 23,
  /**
   * 己方接听系统通话（移动端特有）
  */
  ACCEPT_SYSTEM_CALL = 24,
  /**
   * 远端其他端已加入新通话
   */
  REMOTE_OTHER_CLIENT_JOINED_CALL = 31,
  /**
   * 远端其他端已在通话中
   */
  REMOTE_OTHER_CLIENT_IN_CALL = 32,
  /**
   * 远端被禁止通话
   */
  REMOTE_KICKED_BY_SERVER = 33,
  /**
   * 远端接听系统通话（移动端特有）
  */
  REMOTE_ACCEPT_SYSTEM_CALL = 34,
  /**
   * 其他端接听
   */
  ACCEPT_BY_OTHER_CLIENT = 101,
  /**
   * 其他端挂断
   */
  HANGUP_BY_OTHER_CLIENT = 102,
  /**
   * 己方被对方加入黑名单
   */
  ADDED_TO_BLACKLIST = 103,
  /**
   * 音视频服务未开通
   */
  SERVICE_NOT_OPENED = 104
}

/**
 * 己方原因转为对方原因
 */
export const CallRemoteEndReason: { [key: number]: RCCallEndReason } = {
  [RCCallEndReason.CANCEL]: RCCallEndReason.REMOTE_CANCEL,
  [RCCallEndReason.REJECT]: RCCallEndReason.REMOTE_REJECT,
  [RCCallEndReason.HANGUP]: RCCallEndReason.REMOTE_HANGUP,
  [RCCallEndReason.BUSY_LINE]: RCCallEndReason.REMOTE_BUSY_LINE,
  [RCCallEndReason.NO_RESPONSE]: RCCallEndReason.REMOTE_NO_RESPONSE,
  [RCCallEndReason.ENGINE_UNSUPPORTED]: RCCallEndReason.REMOTE_ENGINE_UNSUPPORTED,
  [RCCallEndReason.NETWORK_ERROR]: RCCallEndReason.REMOTE_NETWORK_ERROR,
  [RCCallEndReason.GET_MEDIA_RESOURCES_ERROR]: RCCallEndReason.REMOTE_GET_MEDIA_RESOURCE_ERROR,
  [RCCallEndReason.PUBLISH_ERROR]: RCCallEndReason.REMOTE_PUBLISH_ERROR,
  [RCCallEndReason.SUBSCRIBE_ERROR]: RCCallEndReason.REMOTE_SUBSCRIBE_ERROR,
  [RCCallEndReason.OTHER_CLIENT_JOINED_CALL]: RCCallEndReason.REMOTE_OTHER_CLIENT_JOINED_CALL,
  [RCCallEndReason.OTHER_CLIENT_IN_CALL]: RCCallEndReason.REMOTE_OTHER_CLIENT_IN_CALL,
  [RCCallEndReason.KICKED_BY_SERVER]: RCCallEndReason.REMOTE_KICKED_BY_SERVER,
  [RCCallEndReason.REMOTE_NO_RESPONSE]: RCCallEndReason.NO_RESPONSE,
  [RCCallEndReason.ACCEPT_SYSTEM_CALL]: RCCallEndReason.REMOTE_ACCEPT_SYSTEM_CALL,
};
