import { RCStreamType } from '../enums/inner/RCStreamType';
import { RCMediaType } from '../enums/RCMediaType';

export interface IBaseQualityData {
  /**
   * 执行耗时(ms)
   * 仅掉线无 dur，其余都有
   */
  dur?: number,
  /**
   * 房间 id
   */
  rid: string,
  /**
   * client session ID
   */
  csnid: string,
  /**
   * 服务返回的session ID
   */
  snid: string,
  /**
   * 开始执行时间
   */
  time: number,
  /**
   * userId
   */
  uid: string,
}

export interface INetworkQualityData {
  /**
   * 运营商，例如 中国联通
   */
  cr: string,
  /**
   * 网络类型，例如 4G
   */
  net: string,
}

/**
 * 资源数据定义
 */
interface IResourceQualityData {
  /**
   * 资源ID
   */
  resid: string,
  /**
   * 音视频类型
   */
  mtp: RCMediaType,
  /**
   * 大小流
   */
  smct: RCStreamType,
  /**
   * 流类型
   * 0 RTC 流，1 CDN 流，2 MCU 流，3 自定义
   */
  stp: number
}

/**
 * mediaServer 交互数据
 */
export interface IMediaServerQualityData {
  rsid: string, // request Id 请求ID
  rsdur: number, // HTTP 请求耗时， 单位毫秒
  msa: string, // 媒体服务器地址
  rscod: number // 请求错误码
}

/**
 * signal 交互数据
 */
export interface ISignalQualityData {
  dur: number, // HTTP 请求耗时， 单位毫秒
  cod: number, // 各请求错误码
}

/**
 * 订阅资源
 * level: -2
 * tag: RTC-subscribe-S
 */
export interface ISubscribeQualityReportData extends IBaseQualityData, INetworkQualityData, IResourceQualityData {
  /**
   * 执行返回 code 码
   * 5xxx 客户端错误码; 4xxx服务端错误码
   */
  cod: number,
  msas: IMediaServerQualityData[],
}

/**
 * 取消订阅
 * level: -2
 * tag: RTC-unsubscribe-S
 */
export interface IUnsubscribeQualityReportData extends IBaseQualityData, IResourceQualityData {
  /**
   * 执行返回 code 码
   * 5xxx 客户端错误码; 4xxx服务端错误码
   */
  cod: number,
  msas: IMediaServerQualityData[],
}

/**
 * 发布资源、取消发布
 * level: -2
 * tag: RTC-publishRtc-S、RTC-unpublishRtc-S
 */
export interface IPubOrUnpubQualityReportData extends IBaseQualityData, INetworkQualityData, IResourceQualityData {
  /**
   * 执行返回 code 码
   * 5xxx 客户端错误码; 4xxx服务端错误码
   */
  cod: number,
  msas?: IMediaServerQualityData[],
  sigs?: ISignalQualityData[],
}

/**
 * ice 连接状态
 * checking --> connected
 * checking --> failed
 * connected --> disconnected
 * connected --> failed
 * disconnected --> connected
 * disconnected --> failed
 * level: -2
 * tag : RTC-iceStatus-S
 */
export interface IIceStatusQualityReportData extends IBaseQualityData, INetworkQualityData {
  pices: number, // 前次 ICE 的状态，要统计的状态变化间下面的描述
  ices: number, // 当前 ICE 的状态，要统计的状态变化间下面的描述
  rcdts: any[], // 远端 SDP 中的 candidate 地址[{ip:"cdt(地址:端口)",nttp:"网络类型"}]
  lcdts: any[], // 本地 SDP 中的 candidate 地址[{ip:"cdt(地址:端口)",ettp:"以太网类型",nttp:"网络类型"}]
  cncdts?: any[], // 只有在变成 connected 状态时才有该字段
}

/**
 * 加入房间
 * level: -2
 * tag: RTC-joinRoomRtc-S
 */
export interface IJoinRoomQualityReportData extends IBaseQualityData, INetworkQualityData {
  cod: number, // 5xxx 客户端错误码; 4xxx服务端错误码
  rtp: number, // 0：会议 2：直播
}

/**
 * 异常退出房间
 * level: -2
 * tag: RTC-forcedOffline-S
 */
export interface IKickedQualityReportData extends IBaseQualityData, INetworkQualityData {
  cod: number, // 掉线原因错误码
}

/**
 * 发布或订阅成功到ICE首次连接成功
 * level: -2
 * tag: RTC-pub_ice_connected-S、RTC-sub_ice_connected-S
 * dur 耗时: 单位 ms，在成功的回调里判断 ICE 状态，如果是已连接则耗时应该为 0
 */
export interface IIceFirstConnectedQualityReportData extends IBaseQualityData, INetworkQualityData {}

/**
 * 收到其他端发布通知
 * level: -2
 * tag: RTC-recv_pub_msg-S
 */
export interface IRecvPubMsgQualityReportData extends IBaseQualityData, INetworkQualityData{
  puid: string, // 发布资源的用户ID
}

export enum RCRTCResourceAction {
  PUB = 'pub',
  UNPUB = 'unpub',
  SUB = 'sub',
  UNSUB = 'unsub'
}
