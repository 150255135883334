/**
 * ice连接状态对应的连通率上报值
 */
export enum ICEConnectionState {
  new = 0,
  checking = 1,
  connected = 2,
  completed = 3,
  failed = 4,
  disconnected = 5,
  closed = 6,
}
