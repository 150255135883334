export enum RCCallUserState {
  /**
   * 用户不存在于通话中
   */
  NONE = 0,
  /**
   * 等待接听
   */
  WAITING,
  /**
   * 通话中
   */
  KEEPING,
}
