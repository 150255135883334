/**
 * 产生session的场景
 */
export enum ProduceTypes {
  /**
   * 主叫
   */
  CALLER = 1,

  /**
   * 被叫
   */
  CALLEE = 2
}
