import { BasicLogger, ErrorCode, RTCMode } from '@rongcloud/engine';
import { ReportKickReason } from '../enums/qualityReport/KickReason';
import {
  IKickedQualityReportData, IJoinRoomQualityReportData, IIceStatusQualityReportData,
  IRecvPubMsgQualityReportData, IBaseQualityData, INetworkQualityData, ISignalQualityData,
  IPubOrUnpubQualityReportData, IIceFirstConnectedQualityReportData, IMediaServerQualityData, RCRTCResourceAction,
} from './IQualityReportData';
import { RCLoggerTag } from '../enums/RCLoggerTag';
import { parseTrackId } from '../../helper';
import { RCLocalVideoTrack, RCLocalTrack } from '../tracks/RCLocalTrack';
import { ISubscribeAttr, IRCCandidatePairStat } from '../interfaces';
import { ICEConnectionState } from '../enums/qualityReport/ICEConnectionState';
import { RCKickReason } from '../enums/RCKickType';
import { RCRTCCode } from '../enums/RCRTCCode';
import { RCStreamType } from '../enums/inner/RCStreamType';
import { RCRemoteTrack } from '../tracks/RCRemoteTrack';

export default class ReportMediaActionLogger {
  /**
   * 埋点上报的基础数据
   */
  private _baseReportData!: IBaseQualityData

  /**
   * 埋点上报的网络数据
   */
  private _networkReportData!: INetworkQualityData

  /**
   * 最新发布或订阅成功的时间
   */
  private _pubOrSubSuccessTime!: number

  /**
   * 记录每一条 peerConnection 当前的连接状态和更新时间
   */
  private _peerCStatus?: { status: RTCIceConnectionState, time: number }

  constructor(
    private readonly _logger: BasicLogger,
    private _roomId: string,
    private _clientSessionId: string,
    private _userId: string,
  ) {
    this._baseReportData = {
      rid: this._roomId,
      csnid: this._clientSessionId,
      snid: '',
      time: Date.now(),
      uid: this._userId,
    };
    this._networkReportData = {
      cr: 'unknown',
      net: 'unknown',
    };
  }

  /**
   * 加房间
   */
  public reportQualityJoinRoomData = (start: number, code: ErrorCode, roomType: RTCMode, sessionId?: string) => {
    this._baseReportData = Object.assign(this._baseReportData, { snid: sessionId });

    const data: IJoinRoomQualityReportData = {
      ...this._baseReportData,
      ...this._networkReportData,
      cod: code,
      time: start,
      dur: Date.now() - start,
      rtp: roomType,
    };
    this._logger.__statistics(RCLoggerTag.L_QS_JOIN_ROOM_S, JSON.stringify(data));
  };

  /**
   * 异常掉线
   */
  public reportQualityKickedData(kickType: RCKickReason) {
    const data: IKickedQualityReportData = {
      ...this._baseReportData,
      ...this._networkReportData,
      time: Date.now(),
      cod: kickType === RCKickReason.OTHER_KICK ? ReportKickReason.OTHER_KICK : ReportKickReason.SERVER_KICK,
    };
    this._logger.__statistics(RCLoggerTag.L_QS_KICKED_S, JSON.stringify(data));
  }

  /**
   * 发布资源、取消发布、订阅、取消订阅
   */
  public reportPubOrSubQualityData(type: RCRTCResourceAction, start: number, tracks: (RCLocalTrack | ISubscribeAttr | RCRemoteTrack)[], code: ErrorCode | RCRTCCode, msas?: {[trackId: string]: IMediaServerQualityData[] }, sigs?: ISignalQualityData[]) {
    tracks.forEach((item) => {
      const track = (item instanceof RCLocalTrack || item instanceof RCRemoteTrack) ? item : item.track;
      const trackId = track.getTrackId();
      const { mediaType } = parseTrackId(trackId);

      let isTiny = false;
      if (track instanceof RCLocalTrack) {
        isTiny = track.isVideoTrack() ? (track as RCLocalVideoTrack).__isTiny?.() : false;
      } else {
        isTiny = (item as ISubscribeAttr).subTiny || false;
      }

      const data: IPubOrUnpubQualityReportData = {
        ...this._baseReportData,
        ...this._networkReportData,
        resid: trackId,
        mtp: mediaType,
        smct: isTiny ? RCStreamType.TINY : RCStreamType.NORMAL,
        stp: track.getTag() !== 'Rongcloud' ? 1 : 0,
        cod: code,
        msas: msas ? msas[trackId] : [],
        sigs,
        time: Date.now(),
        dur: Date.now() - start,
      };

      switch (type) {
        case 'pub':
          this._logger.__statistics(RCLoggerTag.L_QS_PUBLISH_S, JSON.stringify(data));
          break;
        case 'unpub':
          this._logger.__statistics(RCLoggerTag.L_QS_UNPUBLISH_S, JSON.stringify(data));
          break;
        case 'sub':
          this._logger.__statistics(RCLoggerTag.L_QS_SUBSCRIBE_S, JSON.stringify(data));
          break;
        case 'unsub':
          this._logger.__statistics(RCLoggerTag.L_QS_UNSUBSCRIBE_S, JSON.stringify(data));
          break;
      }
    });
  }

  /**
   * 收到其他端发布通知
   */
  public reportQualityRecvPubMsgData(sentTime: number, senderUserId: string) {
    const data: IRecvPubMsgQualityReportData = {
      ...this._baseReportData,
      ...this._networkReportData,
      puid: senderUserId,
      time: Date.now(),
      dur: Date.now() - sentTime,
    };

    this._logger.__statistics(RCLoggerTag.L_QS_RECV_PUB_MSG_S, JSON.stringify(data));
  }

  /**
   * 设置最新发布或订阅成功的时间
   */
  public setPubOrSubSuccessTime(time: number) {
    this._pubOrSubSuccessTime = time;
  }

  /**
   * 发布或订阅成功到 ICE 首次连接成功
   * 一个 peerConnection 只会触发一次
   */
  public reportIceFirstConnectData(type: string) {
    const data: IIceFirstConnectedQualityReportData = {
      ...this._baseReportData,
      ...this._networkReportData,
      time: this._pubOrSubSuccessTime,
      dur: Date.now() - this._pubOrSubSuccessTime,
    };

    const tag = (type === 'pub') ? RCLoggerTag.L_QS_PUB_ICE_STATUS_S : RCLoggerTag.L_QS_SUB_ICE_STATUS_S;
    this._logger.__statistics(tag, JSON.stringify(data));
  }

  /**
   * ice 连接状态统计
   */
  public recordQualityIceStatusData(iceCandidatePair: IRCCandidatePairStat, status: RTCIceConnectionState, time: number) {
    const { status: oldStatus, time: OldTime } = this._peerCStatus || {};
    if (!OldTime) {
      this._peerCStatus = {
        status,
        time,
      };
      return;
    }

    const {
      IP, port, networkType, remoteIP, remotePort,
    } = iceCandidatePair;
    const data: IIceStatusQualityReportData = {
      ...this._baseReportData,
      ...this._networkReportData,
      dur: time - OldTime,
      time,
      pices: ICEConnectionState[oldStatus!],
      ices: ICEConnectionState[status],
      rcdts: [{ ip: `${remoteIP}:${remotePort}` }],
      lcdts: [{ ip: `${IP}:${port}`, nttp: networkType }],
      // 需要时再确认
      cncdts: [],
    };

    this._logger.__statistics(RCLoggerTag.L_QS_ICE_STATUS_S, JSON.stringify(data));

    this._peerCStatus = {
      status,
      time,
    };
  }
}
