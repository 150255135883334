interface SsrcMedia {
  ssrc: number;
  attribute?: string;
  value?: string;
}

interface Msid {
  stream: string;
  track: string;
}

interface Mline {
  kind: string;
  port: number;
  protocol: string;
  fmt: string;
}

/* 它是一堆解析 SDP 字符串的静态函数 */
export default class SDPUtils {
  /* 将 SDP 拆分为多个部分。 */
  static splitSections(blob: string) {
    const parts = blob.split('\nm=');
    return parts.map((part, index) => {
      const temp = index > 0 ? `m=${part}` : part;
      return `${temp.trim()}\r\n`;
    });
  }

  /**
   * 它需要一个 SDP 文本块并返回一个仅包含媒体部分的 SDP 文本数组
   * @param {string} blob - 您要解析的 SDP 字符串。
   * @returns 字符串数组。
   */
  static getMediaSections(blob: string): string [] {
    const sections = SDPUtils.splitSections(blob);
    sections.shift();
    return sections;
  }

  /* 解析 extmap 属性的 SDP 标头。 */
  static parseExtmap(line: string) {
    const parts = line.substr(9).split(' ');
    return {
      id: parseInt(parts[0], 10),
      direction: parts[0].indexOf('/') > 0 ? parts[0].split('/')[1] : 'sendrecv',
      uri: parts[1],
    };
  }

  /* 将 SDP 字符串拆分为字符串数组。 */
  static splitLines(blob: string) {
    return blob.trim().split('\n').map((line) => line.trim());
  }

  /**
   * 它接受一个媒体部分并返回一个对象，其中包含媒体部分的种类、端口、协议和格式
   * @param {string} mediaSection - SDP的媒体部分。
   * @returns 具有以下属性的对象：
   *   kind：媒体的类型（音频、视频等）
   *   端口：端口号
   *   协议：使用的协议
   *   fmt：媒体的格式
   */
  static parseMLine(mediaSection: string): Mline {
    const lines = SDPUtils.splitLines(mediaSection);
    const parts = lines[0].substr(2).split(' ');
    return {
      kind: parts[0],
      port: parseInt(parts[1], 10),
      protocol: parts[2],
      fmt: parts.slice(3).join(' '),
    };
  }

  static matchPrefix(blob: string, prefix: string): string[] {
    return SDPUtils.splitLines(blob).filter((line) => line.indexOf(prefix) === 0);
  }

  /**
   * 它从 SDP 媒体部分解析 msid 属性
   * @param {string} mediaSection - SDP的媒体部分。
   * @returns 具有两个属性的对象：流和轨道。
   */
  static parseMsid(mediaSection: string): Msid {
    let parts;
    const spec = SDPUtils.matchPrefix(mediaSection, 'a=msid:');
    if (spec.length === 1) {
      parts = spec[0].substr(7).split(' ');
      return { stream: parts[0], track: parts[1] };
    }
    const planB = SDPUtils.matchPrefix(mediaSection, 'a=ssrc:')
      .map((line) => SDPUtils.parseSsrcMedia(line))
      .filter((msidParts) => msidParts.attribute === 'msid');
    if (planB.length > 0) {
      if (planB[0].value) {
        parts = planB[0].value.split(' ');
        return { stream: parts[0], track: parts[1] };
      }
    }
    return { stream: '', track: '' };
  }

  /**
   * 它接受一个字符串并返回一个对象
   * @param {string} line - 我们正在解析的 SDP 行。
   */
  static parseSsrcMedia(line: string): SsrcMedia {
    const sp = line.indexOf(' ');
    const parts: SsrcMedia = {
      ssrc: parseInt(line.substr(7, sp - 7), 10),
    };
    const colon = line.indexOf(':', sp);
    if (colon > -1) {
      parts.attribute = line.substr(sp + 1, colon - sp - 1);
      parts.value = line.substr(colon + 1);
    } else {
      parts.attribute = line.substr(sp + 1);
    }
    return parts;
  }

  /**
   * 它从 SDP 的媒体部分返回中间值
   * @param {string} mediaSection - SDP的媒体部分。
   * @returns 媒体部分的中间。
   */
  static getMid(mediaSection: string) {
    const mid = SDPUtils.matchPrefix(mediaSection, 'a=mid:')[0];
    if (mid) {
      return mid.substr(6);
    }
  }
}
