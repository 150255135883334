import { IRuntime } from '@rongcloud/engine';
import { IRCRTCInitOptions } from '../interfaces';
import RCMediaService from '../service/RCMediaService';
import RCAbstractRoom from './RCAbstractRoom';
import { RTCContext } from '../codec/RTCContext';
import { RTCMode } from '../enums/RTCMode';

/**
 * 普通音视频房间
 */
export default class RCRTCRoom extends RCAbstractRoom {
/**
 * 类的构造函数。
 * @param {RTCContext} context - RTC上下文
 * @param {IRuntime} runtime - 运行时
 * @param {string} roomId - 房间号
 * @param {RCMediaService} service - RCMediaService
 * @param {IRCRTCInitOptions} initOptions - IRCRTCInitOptions
 * @param {boolean} [isUpgrade] - 是否升级房间，默认为false
 * @param {boolean} [isMainRoom] - 无论是主房间，主房间是用户进入的房间，子房间是用户进入主房间后进入的房间。
 * @param {string} [_clientSessionId] - 当前房间的clientSessionId，用于区分当前房间和其他房间。
 * @param {RTCMode} roomType - RTCMode = RTCMode.RTC,
 */
  constructor(
    context: RTCContext,
    runtime: IRuntime,
    roomId: string,
    service: RCMediaService,
    initOptions: IRCRTCInitOptions,
    isUpgrade?: boolean,
    isMainRoom?: boolean,
    _clientSessionId?: string,
    roomType: RTCMode = RTCMode.RTC,
  ) {
    super(context, runtime, roomId, roomType, service, initOptions, isUpgrade, isMainRoom, _clientSessionId);
  }
}
