import { BasicLogger, ErrorCode } from '@rongcloud/engine';
import { BaseCommand, CommandPriority, ICommandResult } from './command/BaseCommand';
import { RCRTCCode } from './enums/RCRTCCode';
import { ReadableStore, Store } from './Store';
import { RCLoggerTag } from './enums/RCLoggerTag';
import { CommandExecuteContext } from './command/CommandExecuteContext';

export type QueueItem<T, S, R> = { command: BaseCommand<R, T, S>, resolve: (value: ICommandResult<R> | PromiseLike<ICommandResult<R>>) => void };

/**
 * @typeParams T - 事务执行上下文类型
 * @typeParams S - 内存读写模块类型
 */
export class BaseInvoker<T, S> {
  constructor(
    protected readonly _logger: BasicLogger,
    /**
     * 内存数据管理实例
     */
    protected readonly _context: T,
    /**
     * 内存读写模块
     */
    protected readonly _store: S,
    /**
     * 命令终止时返回的错误码定义
     */
    private abortCode: RCRTCCode,
  ) {}

  // command 队列
  private _queue: QueueItem<T, S, any>[] = []

  // 锁
  private _busy: boolean = false

  private _next() {
    this._busy = false;
    this._execute();
  }

  private async _execute() {
    if (this._isDestroyed || this._busy || this._queue.length === 0) {
      return;
    }
    this._busy = true;
    const { command, resolve } = this._queue.shift()!;
    let res: ICommandResult<unknown>;
    const traceId = this._logger.createTraceId();
    try {
      /**
       * 说明：由于是先弹出 再执行 Command，所以不存在 在队列中正在执行的任务
       */
      this._logger.info(RCLoggerTag.L_INVOKER_EXECUTE_T, `kind: ${command.kind}, length: ${this._queue.length}`, traceId);
      res = await command.execute(this._context, this._store, this);
    } catch (error: any) {
      console.error(error);

      this._logger.error(RCLoggerTag.L_INVOKER_EXECUTE_R, command.kind, traceId);
      this._next();
      return;
    }

    const { code } = res;

    if (code !== RCRTCCode.SUCCESS && code !== ErrorCode.SUCCESS) {
      this._logger.warn(RCLoggerTag.L_INVOKER_EXECUTE_R, `code: ${code}, kind: ${command.kind}`, traceId);
    } else {
      this._logger.info(RCLoggerTag.L_INVOKER_EXECUTE_R, `kind: ${command.kind}, Done`, traceId);
    }

    resolve(res);
    this._next();
  }

  push<R>(command: BaseCommand<R, T, S>): Promise<ICommandResult<R>> {
    return new Promise((resolve) => {
      this._logger.info(RCLoggerTag.L_INVOKER_PUSH_O, JSON.stringify({
        isDestroyed: this._isDestroyed,
        kind: command.kind,
        length: this._queue.length,
      }));

      // 房间已销毁
      if (this._isDestroyed) {
        resolve({
          code: RCRTCCode.NOT_IN_ROOM,
        });
        return;
      }

      // 根据 command 优先级确定其在队列中的位置
      const { priority } = command;
      // 优化队列输入权重为 0 时直接插入队列最未端即可
      if (priority === CommandPriority.LOW) {
        this._queue.push({ command, resolve });
      } else {
        // 有序队列从前往后查找
        const index = this._queue.findIndex((item) => item.command.priority < priority);
        const postion = index === -1 ? this._queue.length - 1 : index;
        this._queue.splice(postion, 0, { command, resolve });
      }

      setTimeout(() => {
        this._execute();
      }, 0);
    });
  }

  private _isDestroyed = false

  isDestroyed(): boolean {
    return this._isDestroyed;
  }

  destroy() {
    if (this._isDestroyed) {
      return;
    }

    this._logger.info(RCLoggerTag.L_INVOKER_DESTROY_O);

    this._isDestroyed = true;

    // 清空队列
    this._queue.forEach((item) => {
      item.resolve({ code: this.abortCode });
    });
    this._queue.length = 0;
  }
}

/**
 * 房间任务队列管理
 */
export class Invoker extends BaseInvoker<CommandExecuteContext, Store> {
  constructor(
    context: CommandExecuteContext,
    store: Store,
  ) {
    super(context.logger, context, store, RCRTCCode.ROOM_HAS_BEEN_DESTROYED);
  }

  /**
   * 获取 store 存储实例，返回值类型 `ReadableStore`，避免非 command 定义中修改内存
   */
  public get store(): ReadableStore {
    return this._context.store;
  }

  destroy(): void {
    super.destroy();
    this._store.removeRemoteTracks();
  }
}
