// rtc 的 PB 定义，用于向前兼容不具备 PB 数据编解码能力的 RTCLib 版本
/* eslint-disable camelcase */
/**
 * signal 服务 RTC 业务的接口定义
 */
export const RTC_API = {
  rtcRJoin_data: 'rtcRJoin_data',
  rtcRExit: 'rtcRExit',
  rtcRInfo: 'rtcRInfo',
  rtcUData: 'rtcUData',
  rtcUDel: 'rtcUDel',
  rtcSetData: 'rtcSetData',
  /** 全量资源 URI 变更接口 */
  userSetData: 'userSetData',
  rtcQryData: 'rtcQryData',
  rtcDelData: 'rtcDelData',
  rtcToken: 'rtcToken',
  rtcUserState: 'rtcUserState',
  rtcUList: 'rtcUList',
  /** RTC 直播观众加房间 */
  viewerJoinR: 'viewerJoinR',
  /** RTC 直播观众退出房间 */
  viewerExitR: 'viewerExitR',
  /** RTC 观众端 kv 通知拉取 (kv 为主播加入/退出人员列表、发布/取消发布的资源) */
  rtcPullKv: 'rtcPullKv',
  /** RTC 直播身份切换 */
  rtcIdentityChange: 'rtcIdentityChange',
  /** 直播连麦邀请 */
  rtcInvite: 'rtcInvite',
  /** 直播连麦取消邀请 */
  rtcCancelInvite: 'rtcCancelInvite',
  /** 直播连麦邀请响应 */
  rtcInviteAnswer: 'rtcInviteAnswer',
  /** 结束直播连麦邀请 */
  rtcEndInvite: 'rtcEndInvite',
  /** RTC 查询用户信息（是否在房间内） */
  rtcQueryJoined: 'rtcQueryJoined',
  /** 拉取 rtc 和 living room 的资源状态 */
  rtcPullRoomStatus: 'rtcPullRoomStatus',
  /** 上报融云SDK信息 */
  reportsdk: 'reportsdk',
  /** rtcping */
  rtcPing: 'rtcPing',
};

export enum RTCPB {
  RtcInput = 'RtcInput',
  RtcOutput = 'RtcOutput',
  RtcUserListOutput = 'RtcUserListOutput',
  SetUserStatusInput = 'SetUserStatusInput',
  RtcSetDataInput = 'RtcSetDataInput',
  RtcUserSetDataInput = 'RtcUserSetDataInput',
  RtcDataInput = 'RtcDataInput',
  RtcSetOutDataInput = 'RtcSetOutDataInput',
  MCFollowInput = 'MCFollowInput',
  RtcTokenOutput = 'RtcTokenOutput',
  RtcQryOutput = 'RtcQryOutput',
  RtcQryUserOutDataInput = 'RtcQryUserOutDataInput',
  RtcUserOutDataOutput = 'RtcUserOutDataOutput',
  RtcQueryListInput = 'RtcQueryListInput',
  RtcRoomInfoOutput = 'RtcRoomInfoOutput',
  RtcValueInfo = 'RtcValueInfo',
  RtcKeyDeleteInput = 'RtcKeyDeleteInput',
  RtcNotifyMsg = 'RtcNotifyMsg', // rtc 房间通知拉取
  RtcPullKV = 'RtcPullKV',
  RtcKVOutput = 'RtcKVOutput',
  RtcQueryUserJoinedInput = 'RtcQueryUserJoinedInput', // rtc 已加入房间用户信息
  RtcQueryUserJoinedOutput = 'RtcQueryUserJoinedOutput', // rtc 已加入房间用户信息
  RtcViewerJoinedOutput = 'RtcViewerJoinedOutput', // 观众加房间返回数据
  RtcInviteInput = 'RtcInviteInput', // rtc 连麦 pk 邀请
  RtcCancelInviteInput = 'RtcCancelInviteInput', // rtc 连麦 pk 邀请取消
  RtcInviteAnswerInput = 'RtcInviteAnswerInput', // rtc 连麦 pk 邀请应答
  RtcEndInviteInput = 'RtcEndInviteInput', // rtc 连麦 pk 结束
  RtcRoomStatusInput = 'RtcRoomStatusInput', // rtc 和 living room 拉房间资源状态输入
  RtcRoomStatusOutput = 'RtcRoomStatusOutput', // rtc 和 living room 拉房间资源状态输出
  RtcReportSDKInput = 'RtcReportSDKInput', // rtc 上报SDK版本
  RtcPBPolaris = 'RCRTCPBPolaris', // 北极星质量上报数据
  RtcPBR1Info = 'RCRTCPBR1Info', // R1
  RtcPBR2Info = 'RCRTCPBR2Info', // R2
  RtcPBR3Info = 'RCRTCPBR3Info', // R3
  RtcPBR4Info = 'RCRTCPBR4Info', // R4
  RtcPBStatusInfo = 'RCRTCPBStatusInfo', // R3 R4 共用信息
  RtcPBStreamInfo = 'RCRTCPBStreamInfo' // R3 R4 上下行流信息
}

export const keymaps = {
  [RTCPB.RtcInput]: ['roomType', 'broadcastType', 'extraInnerData', 'needSysChatroom', 'identityChangeType', 'joinType', 'innerDatas', 'outerDatas', 'supportNtf'],
  [RTCPB.RtcOutput]: ['version'],
  [RTCPB.RtcUserListOutput]: ['users', 'token', 'sessionId', 'roomInfo', 'version'],
  [RTCPB.SetUserStatusInput]: ['status'],
  [RTCPB.RtcSetDataInput]: ['interior', 'target', 'key', 'value', 'objectName', 'content'],
  [RTCPB.RtcUserSetDataInput]: ['valueInfo', 'objectName', 'content'],
  [RTCPB.RtcDataInput]: ['interior', 'target', 'key', 'objectName', 'content'],
  [RTCPB.RtcSetOutDataInput]: ['target', 'valueInfo', 'objectName', 'content'],
  [RTCPB.MCFollowInput]: ['state'],
  [RTCPB.RtcTokenOutput]: ['rtcToken'],
  [RTCPB.RtcQryOutput]: ['outInfo'],
  [RTCPB.RtcQryUserOutDataInput]: ['userId'],
  [RTCPB.RtcUserOutDataOutput]: ['user'],
  [RTCPB.RtcQueryListInput]: ['order'],
  [RTCPB.RtcRoomInfoOutput]: ['roomId', 'roomData', 'userCount', 'list'],
  [RTCPB.RtcValueInfo]: ['key', 'value'],
  [RTCPB.RtcKeyDeleteInput]: ['key'],
  [RTCPB.RtcNotifyMsg]: ['type', 'time', 'roomId'],
  [RTCPB.RtcPullKV]: ['timestamp', 'roomId'],
  [RTCPB.RtcKVOutput]: ['entries', 'bFullUpdate', 'syncTime'],
  [RTCPB.RtcQueryUserJoinedInput]: ['userId'],
  [RTCPB.RtcQueryUserJoinedOutput]: ['info'],
  // RtcViewerJoinedOutput 无上行调用，所以不需要 map 映射？
  [RTCPB.RtcInviteInput]: ['invitedUserId', 'timeoutTime', 'invitedRoomId', 'inviteInfo', 'inviteSessionId'],
  [RTCPB.RtcCancelInviteInput]: ['invitedUserId', 'invitedRoomId', 'inviteInfo', 'inviteSessionId'],
  [RTCPB.RtcInviteAnswerInput]: ['inviteUserId', 'answerCode', 'inviteRoomId', 'inviteSessionId', 'content', 'key', 'value'],
  [RTCPB.RtcEndInviteInput]: ['inviteRoomId', 'inviteSessionId', 'inviteContent', 'inviteRoomKeys'],
  [RTCPB.RtcRoomStatusInput]: ['version'],
  [RTCPB.RtcRoomStatusOutput]: ['bFullStatus', 'version', 'usersData', 'roomStatus'],
  [RTCPB.RtcReportSDKInput]: ['sdkInfo'],
  [RTCPB.RtcPBPolaris]: ['type', 'appKey', 'sessionId', 'roomId', 'roomCreateTime', 'userId', 'userRole', 'joinTime', 'signalDataCenter', 'r1Info', 'r2Info', 'r3Info', 'r4Info'],
  [RTCPB.RtcPBR1Info]: ['joinTime', 'rtcVersion', 'imVersion', 'platform', 'device', 'os', 'browserName', 'browserVersion'],
  [RTCPB.RtcPBR2Info]: ['joinTime', 'sendTime', 'rtcActionType', 'rtcActionStatus', 'trackId'],
  [RTCPB.RtcPBR3Info]: ['info'],
  [RTCPB.RtcPBR4Info]: ['info'],
  [RTCPB.RtcPBStatusInfo]: ['joinTime', 'receiveTime', 'totalBitRate', 'appCPUUsage', 'systemCPUUsage', 'systemCPUFrequency', 'networkStatus', 'googleRTTNetwork', 'ipAddress', 'useReceiveBroadBand', 'useSendBroadBand', 'packetLossCount', 'streams'],
  [RTCPB.RtcPBStreamInfo]: ['trackId', 'codeName', 'volume', 'samplingRate', 'bitRate', 'packetLossRate', 'frameRate', 'resolution', 'blockTime', 'jitterData', 'nAckCount', 'pliCount', 'googleRTTTotalTime', 'isReceiveFirstFrameRate', 'codeType', 'isEnabled'],
} as const;

export type RTCKeyMaps = typeof keymaps

export const desc = `
package Modules;
message probuf {
  enum JoinType {
    KICK = 0; //踢前一个设备
    REFUSE = 1; //当前加入拒绝
    COEXIST = 2; //两个设备共存
  }
  enum targetType {
    ROOM =1 ;
    PERSON = 2;
  }
  message ${RTCPB.SetUserStatusInput}
  {
    optional int32 status=1;
  }
  message ${RTCPB.MCFollowInput}
  {
    required string state = 1;
  }
  message ${RTCPB.RtcQueryListInput}{
    optional int32 order=1;
  }
  message ${RTCPB.RtcKeyDeleteInput}{
    repeated string key=1;
  }
  message ${RTCPB.RtcValueInfo}{
    required string key=1;
    required string value=2;
  }
  message RtcUserInfo{
    required string userId=1;
    repeated ${RTCPB.RtcValueInfo} userData=2; //用户资源信息
  }
  message ${RTCPB.RtcRoomStatusInput}{ // rtc 和 living room 拉房间资源状态使用的参数
    required int64 version =1; //当前客户端最大的房间状态的version
  }
  message ${RTCPB.RtcRoomStatusOutput}{ // rtc 和 living room 拉房间资源状态返回的数据
    required bool bFullStatus =1; //标识此次给的数据是否是全量数据
    required int64 version =2; //当前获取后房间的最大版本号
    repeated RtcUserInfo usersData=3;//如果是全量数据,这个不为空,给的是房间全部的人以及发布的资源
    repeated RtcRoomStatus roomStatus=4;//房间增量数据
  }
  message RtcRoomStatus{ //房间状态变动
    required string userId = 1;//用户ID
    required int32 event = 2;//房间状态变更的event(人员加入/退出/离线/发布资源)   (0:加入;1:离开;2:离线;3:资源变动) 
    required int64 time = 3;//本次变更的版本号
    repeated RtcValueInfo userData = 4;//用户变更的资源(event为0或者3时可能有值)
    optional int32 switchRoleType = 5;//如果是切换角色导致的加入房间或退出房间的类型
    optional string extra = 6;
  }
  message ${RTCPB.RtcUserListOutput}{
    repeated RtcUserInfo users=1;
    optional string token=2;
    optional string sessionId=3;
    repeated RtcValueInfo roomInfo = 4; //房间key value
    repeated RtcKVEntity entries = 5;//直播KV属性对象集合
    optional int64 syncTime = 6;//所有属性的最大时间戳（下次拉取KV时用）。
    optional int32 offlineKickTime = 7;//server超时踢人时间，默认60s
    repeated string codeFormat = 8; //当前房间用户支持的编码交集
    optional int64 roomCreateTime = 9;//房间创建时间
    optional int64 userJoinTime = 10;//用户加入房间
    optional int64 version = 11;//当前房间的版本号
  }
  message RtcRoomInfoOutput{
    optional string roomId = 1;
    repeated ${RTCPB.RtcValueInfo} roomData = 2;
    optional int32 userCount = 3;
    repeated RtcUserInfo list=4;
  }
  message ${RTCPB.RtcInput}{
    required int32 roomType=1;
    optional int32 broadcastType=2;
    optional RtcValueInfo extraInnerData = 3;
    optional bool needSysChatroom = 4; //是否需要同步聊天室
    optional IdentityChangeType identityChangeType = 5; //身份变更类型
    optional JoinType joinType = 6; // 加入房间类型
    repeated ${RTCPB.RtcValueInfo} innerDatas = 7; //用户内部inner数据，角色或者连麦信息等
    repeated ${RTCPB.RtcValueInfo} outerDatas = 8; //用户内部outer数据，自定义数据结构RtcInput。
    optional bool supportNtf = 9; //是否支持房间状态的变更通过通知拉取的方式获取
  }
  message ${RTCPB.RtcQryOutput}{
    repeated ${RTCPB.RtcValueInfo} outInfo=1;
  }
  message ${RTCPB.RtcDataInput}{
    required bool interior=1;
    required targetType target=2;
    repeated string key=3;
    optional string objectName=4;
    optional string content=5;
  }
  message ${RTCPB.RtcSetDataInput}{
    required bool interior=1;
    required targetType target=2;
    required string key=3;
    required string value=4;
    optional string objectName=5;
    optional string content=6;
  }
  message ${RTCPB.RtcUserSetDataInput} {
    repeated ${RTCPB.RtcValueInfo} valueInfo = 1;
    required string objectName = 2;
    repeated ${RTCPB.RtcValueInfo} content = 3;
  }
  message RtcQryInput{
    required bool isInterior=1;
    required targetType target=2;
    repeated string key=3;
  }
  message RtcDelDataInput{
    repeated string key=1;
    required bool isInterior=2;
    required targetType target=3;
  }
  message RtcOutput
  {
    optional int32 nothing=1;
    optional int64 version=2;
  }
  message ${RTCPB.RtcTokenOutput}{
    required string rtcToken=1;
  }
  message ${RTCPB.RtcSetOutDataInput}{
    required targetType target=1;
    repeated ${RTCPB.RtcValueInfo} valueInfo=2;
    optional string objectName=3;
    optional string content=4;
  }
  message ${RTCPB.RtcQryUserOutDataInput}{
    repeated string userId = 1;
  }
  message ${RTCPB.RtcUserOutDataOutput}{
    repeated RtcUserInfo user = 1;
  }
  message ${RTCPB.RtcNotifyMsg} 
  {
    required int32 type= 1;   //(通知类型 1:rtc房间状态KV变更通知)
    optional int64 time= 2;   //消息产生时间
    optional string roomId=3; //主播房间id
  }
  message ${RTCPB.RtcPullKV}
  {
    required int64 timestamp = 1;
    required string roomId = 2;
  }
  message RtcKVEntity 
  {
    required string key = 1;
    required string value = 2;
    optional int32 status = 3;
    optional int64 timestamp = 4;
    optional string uid = 5;
  }
  message ${RTCPB.RtcKVOutput}
  {
    repeated RtcKVEntity entries = 1;
    optional bool bFullUpdate = 2;
    optional int64 syncTime = 3; 
  }
  enum IdentityChangeType 
  {
    AnchorToViewer = 1; //1为主播变观众
    ViewerToAnchor = 2; //2为观众变主播
  }
  message ${RTCPB.RtcQueryUserJoinedInput}
  {
    required string userId = 1;
  }
  message RtcJoinedInfo
  {
    required string deviceId = 1; //设备ID
    required string roomId = 2;   //加入的房间ID
    optional int64 joinTime = 3;  //加入的时间
  }
  message ${RTCPB.RtcQueryUserJoinedOutput}
  {
    repeated RtcJoinedInfo info = 1;
  }
  message ${RTCPB.RtcViewerJoinedOutput}
  {
    required string rtcToken=1;
    repeated RtcKVEntity entries = 2;//KV属性对象集合
    optional int64 syncTime = 3;//所有属性的最大时间戳（下次拉取KV时用）。
    optional string sessionId=4;
    optional int64 roomCreateTime = 5;//房间创建时间
    optional int64 userJoinTime = 6;//用户加入房间
  }
  message ${RTCPB.RtcInviteInput}
  {
    required string invitedUserId=1;    //被邀请的人的uid
    optional int32  timeoutTime=2;       //邀请超时时间(秒),默认三十秒
    required string invitedRoomId=3;    //被邀请的房间
    required string inviteInfo=4;       //邀请的信息(包含邀请人,被邀请人,各自userid)客户端定义好.协议栈和server透传
    required string inviteSessionId= 5; //客户端保证唯一性(建议roomid_userid_时间戳_随机数)
  }
  message ${RTCPB.RtcCancelInviteInput}
  {
    required string invitedUserId=1;   //被邀请的人的uid
    required string invitedRoomId=2;   //被邀请的房间
    required string inviteInfo=3;      //取消邀请的信息(包含邀请人,被邀请人,各自userid)客户端定义好.协议栈和server透传
    required string inviteSessionId=4; //邀请的sessionId,客户端发起邀请时产生
  }
  message ${RTCPB.RtcInviteAnswerInput}
  {
    required string inviteUserId=1;    //邀请的人的uid
    required int32  answerCode=2;      //是否接受连麦邀请.0为拒绝,1为接受
    required string inviteRoomId=3;    //邀请的房间
    required string inviteSessionId=4; //邀请的sessionId
    required string content=5;         //需要转发的content
    optional string key=6;             //如果接受的话,需要加这个字段,放在room级别的k和v,新加入房间的能拉取到
    optional string value=7;           //如果接受的话,需要加这个字段,放在room级别的k和v,新加入房间的能拉取到
  }
  message ${RTCPB.RtcEndInviteInput}
  {
    required string inviteRoomId=1;    //邀请的房间
    required string inviteSessionId=2; //邀请的sessionId
    required string inviteContent=3;   //结束连麦的信息(需要透传给房间内其他人)
    repeated string inviteRoomKeys=4;  //需要删除连麦的信息的key
  }
  message ${RTCPB.RtcReportSDKInput} {
    required string sdkInfo=1; // 用户集成的 sdk 信息,json 格式 {"web-rtc": "4.0.3.7"}
  }
  // ---- 北极星数据上报 -------
  message ${RTCPB.RtcPBPolaris} {
    required string type = 1;//上报数据类型 R1-R4
    required string appKey = 2;
    required string sessionId = 3;//房间通话id
    required string roomId = 4;
    required int64 roomCreateTime = 5;//房间创建时间
    required string userId = 6;
    required int32 userRole = 7;
    required int64 joinTime = 8;
    required string signalDataCenter = 9;//signal数据中心
    optional ${RTCPB.RtcPBR1Info} r1Info = 10;
    optional ${RTCPB.RtcPBR2Info} r2Info = 11;
    optional ${RTCPB.RtcPBR3Info} r3Info = 12;
    optional ${RTCPB.RtcPBR4Info} r4Info = 13;
  }
  message ${RTCPB.RtcPBR1Info} {
    required int64 joinTime = 1;//用户加入房间的时间
    required string rtcVersion = 2;//Rtc版本号
    required string imVersion = 3;//IM SDK 版本
    required string platform = 4;//平台(如：Android、iOS、Web)
    required string device = 5; //设备信息(手机名称+型号)
    required string os = 6;//系统版本(手机系统版本)
    optional string browserName = 7; //浏览器名称, 没有用 -1 表示
    optional string browserVersion = 8;//浏览器版本, 没有用 -1 表示
    optional int64 receiveTime = 9; // 发送数据的时间
  }
  message ${RTCPB.RtcPBR2Info} {
    required int64 joinTime = 1;
    required int64 sendTime = 2;
    required string rtcActionType = 3;//发布类型  subscribe : 订阅   publish : 发布
    required string rtcActionStatus = 4;//发布状态  begin : 开始   tend : 结束
    repeated string trackId = 5;
    optional int64 receiveTime = 6; // 发送数据的时间
  }
  message ${RTCPB.RtcPBR3Info} {
    required ${RTCPB.RtcPBStatusInfo} info = 1;
  }
  message ${RTCPB.RtcPBR4Info} {
    required ${RTCPB.RtcPBStatusInfo} info = 1;
  }
  message ${RTCPB.RtcPBStatusInfo} {
    optional int64 joinTime = 1;//用户加入房间的时间
    optional int64 receiveTime = 2;//用户发布数据流的时间（毫秒），暂时由客户端生成，后期如果有问题服务端做兜底处理
    optional int64 totalBitRate = 3;//总码率
    optional float appCPUUsage = 4;//APP CPU占用率
    optional float systemCPUUsage = 5;////系统 CPU占用率
    optional float systemCPUFrequency = 6;//系统CPU频率 (安卓8.0以上)
    optional string networkStatus = 7;//实时网络状态(2g、3g、4g、wifi)
    optional int32 googleRTTNetwork = 8;//Goog rtt 网络延迟
    optional string ipAddress = 9;//ip地址
    optional string useReceiveBroadBand = 10; //可接收带宽(单位: bit)
    optional string useSendBroadBand = 11;//可用发送带宽(单位: bit)
    optional string packetLossCount = 12;//丢包数
    repeated ${RTCPB.RtcPBStreamInfo} streams = 13;
  }
  message ${RTCPB.RtcPBStreamInfo} {
    required string trackId = 1;
    optional string codeName = 2;//编解码名称
    optional string volume = 3;//音频播放音量
    optional int32 samplingRate = 4; //音频采样率
    optional float bitRate = 5;//下行码率
    optional float packetLossRate = 6;
    optional int32 frameRate = 7;//视频接收帧率(单位:fps)
    optional string resolution = 8;//视频分辨率(格式:640*480)
    optional int64 blockTime = 9;//接收卡顿(间隔时间, 单位: ms)
    optional int32 jitterData = 10;//jitter数据抖动
    optional string nAckCount = 11;//nack数量
    optional string pliCount = 12; //PLI请求数
    optional int64 googleRTTTotalTime = 13; //googRtt往返时长
    optional int32 isReceiveFirstFrameRate = 14;//第一个关键帧是否正常收到
    optional string codeType = 15;//编码方式
    optional int32 isEnabled = 16; //当前流的状态是否可用
  }
  // ---- 北极星数据上报 -------
}
`;
