/**
 * 流状态
 */
export enum TrackState {
  /**
   * 不可用
   */
  DISABLE = 0,
  /**
   * 可用
   */
  ENABLE = 1,
}
