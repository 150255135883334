export enum RCKickReason {
  /**
   * Server 主动踢（掉 Server API 踢出）
   */
  SERVER_KICK = 1,
  /**
   * 其他设备加入房间后，本端被踢
   */
  OTHER_KICK = 2,
  /**
   * im 断开连接
   */
  IM_DISCONNECTED = 3,
  /**
   * im 退出登录
   */
  IM_LOGOUT = 4,
  /**
   * 用户在其他设备连接 im，被挤下线
   */
  OTHER_CLIENT_IM_CONNECTED = 5,
}
