import {
  EventEmitter, BasicLogger,
} from '@rongcloud/engine';
import {
  IRCRTCReportListener,
  IInnerRCRTCStateReport,
  IRCRTCStateReport,
  IRCTrackStat,
  ISendTrackState,
  IRecvTrackState,
  ILiveAudioState,
} from '../interfaces';
import { isNull, parseTrackId } from '../../helper';

import { RCLocalTrack } from '../tracks/RCLocalTrack';
import { RCRemoteTrack } from '../tracks/RCRemoteTrack';
import { ASdpStrategy } from './sdp/ASdpStrategy';
import { PlanBStrategy } from './sdp/PlanBStrategy';
import { UnifiedPlanStrategy } from './sdp/UnifiedPlanStrategy';
import { RCMediaType } from '../enums/RCMediaType';
import IStatParser from './stat-parser/IStatParser';

import { RCLoggerStatus, RCLoggerTag } from '../enums/RCLoggerTag';
import { RCEncoder } from '../enums/RCEncoder';
import { handleAudioLevel } from './helper';
import { ReadableStore } from '../Store';
import PolarisReporter from '../PolarisReporter';

/**
 * PC 实例管理类
 */
export default class RCRTCPeerConnection extends EventEmitter {
  static __INNER_EVENT_TRACK_READY__: string = 'inner-track-ready'

  static __INNER_AUDIOLEVEL_CHANGE__: string = 'inner-audio-level-change'

  static __INNER_ICE_STATE_CHANGE__: string = 'inner-ice-state-change'

  static __INNER_ICE_CONNECTED__: string = 'inner-ice-connected'

  private readonly _rtcPeerConn: RTCPeerConnection

  private readonly _sdpStrategy: ASdpStrategy

  public reportParser: IStatParser | null

  private pubLocalTracks: { [trackId: string]: RCLocalTrack } = {}

  private _reTryExchangeTimer: any = null

  // peerConnection stats 计时器
  private _reportStatsTimer: any = null

  // 上报上下行数据至北极星定时器
  private _reportR3R4ToPolarisTimer: any = null

  private _isDestroyed: boolean = false

  // 格式化后的质量数据
  private _formatStatsData?: IInnerRCRTCStateReport | null = null

  constructor(
    private _logger: BasicLogger,
    /**
     * _reTryExchange 方法
     */
    private readonly _reTryExchange: () => void,
    /**
     * 当前用户 id
     */
    private readonly _currentUserId: string,
    /**
     * store 实例
     */
    private readonly _store: ReadableStore,
    /**
     * 北极星上传实例
     */
    private readonly _polarisReport?: PolarisReporter,
    /**
     * 是否是房间内观众
     */
    private readonly _isRoomAudience: boolean = false,
  ) {
    super();

    const sdpSemantics = ASdpStrategy.getSdpSemantics();
    const peer = this._rtcPeerConn = new (RTCPeerConnection as any)({ sdpSemantics });
    this._sdpStrategy = sdpSemantics === 'plan-b' ? new PlanBStrategy(this._logger, peer) : new UnifiedPlanStrategy(this._logger, peer);
    // ice 代理状态，及与 ice 服务的连接状态监听
    this._rtcPeerConn.oniceconnectionstatechange = this._onICEConnectionStateChange.bind(this);
    // transport 的聚合状态
    this._rtcPeerConn.onconnectionstatechange = this._onConnectionStateChange.bind(this);
    this._rtcPeerConn.ontrack = this._onTrackReady.bind(this);

    this.reportParser = this._sdpStrategy.getStatParsr(this._rtcPeerConn, sdpSemantics, this._currentUserId, this._store);
  }

  getLocalTracks(): RCLocalTrack[] {
    return Object.values(this.pubLocalTracks);
  }

  private _onConnectionStateChange() {
    // new 新建连接
    // 至少有一个的ICE传输组件（RTICETransport或RTCDTLTransport对象）处于new状态，
    // 并且不是以下状态：checking、connecting、failed、disconnected，
    // 或者所有连接都处于closed状态.

    // connecting 连接中
    // 一个或多个ICE传输组件目前正在建立连接；
    // 也就是说，iceConnectionState正在checking或connected，并不是closed状态

    // connected 已连接
    // 至少有一个ICE传输组件connected或completed状态
    // 所有ICE连接要么在使用中（connected或completed），要么closed；

    // disconnected 断开
    // 至少一个ICE传输组件处于断开状态，
    // 其他都不是failed、connecting或checking状态

    // failed 连接失败
    // ICE传输组件处于failed状态.

    // closed 关闭
    // RTCPeerConnection关闭
    this._logger.info(RCLoggerTag.L_RTC_PEER_CONNECTION_CONNECTION_STATE_S, `state: ${this._rtcPeerConn.connectionState}`);
  }

  private startAutoExecute(gap: number) {
    this.stopAutoExecute();
    this._reportStatsTimer = setTimeout(() => {
      this._reportHandle();
      this.startAutoExecute(gap);
    }, gap);
  }

  private stopAutoExecute() {
    clearTimeout(this._reportStatsTimer);
  }

  private async _onICEConnectionStateChange() {
    // * "new": 建立ICE连接 - ICE 代理正在搜集地址或者等待远程候选可用。
    // * "checking": 收集候选 - ICE 代理已收到至少一个远程候选，并进行校验，无论此时是否有可用连接；同时可能还在继续收集候选。
    // * "connected": 匹配到可用候选 - 已为连接的所有组件找到可用的本地和远程候选配对，并且已建立连接。此时仍然会继续测试远程候选以便发现更优的连接。同时可能在继续收集候选。
    // * "completed": 匹配完成，连接建立 - ICE代理已经发现了可用的连接，不再测试远程候选。
    // * "failed": 没有合适候选 - ICE候选测试了所有远程候选没有发现匹配的候选；也可能有些候选中发现了一些可用连接。
    // * "disconnected": 网络波动或者串流断开 - RTPeerConnection至少有一个组件连接失败。这可能是一个暂时的状态，可以自我恢复。
    // * "closed": 断开 ICE - ICE代理关闭，不再应答任何请求。
    this._logger.info(RCLoggerTag.L_RTC_PEER_CONNECTION_ICE_CONNECTION_STATE_S, JSON.stringify({
      connectionState: this._rtcPeerConn.connectionState,
    }));

    const formatData = await this._getStatsData();
    const iceCandidatePair = formatData ? this._createRCRTCStateReport(formatData).iceCandidatePair : null;

    const { iceConnectionState } = this._rtcPeerConn;
    this.emit(RCRTCPeerConnection.__INNER_ICE_STATE_CHANGE__, { status: iceConnectionState, time: Date.now(), iceCandidatePair });

    if (iceConnectionState === 'connected') {
      this.emit(RCRTCPeerConnection.__INNER_ICE_CONNECTED__, this._firstConnectFromPub);
      // 开启 peerConnection stats 统计定时器
      this.startAutoExecute(1000);
    }

    // ICE 连接中断后，需要尝试重新走 exchange 流程以恢复
    if (iceConnectionState === 'failed' || iceConnectionState === 'disconnected') {
      this._reTryExchange();
      this._reTryExchangeTimer = setInterval(this._reTryExchange, 15 * 1000);
    }
    // ICE 变更通知
    try {
      this._reportListener?.onICEConnectionStateChange?.(iceConnectionState);
    } catch (error) {
      this._logger.error(RCLoggerTag.L_RTC_PEER_CONNECTION_ICE_CONNECTION_STATE_REPORT_R, JSON.stringify({
        status: RCLoggerStatus.FAILED,
        mag: error,
      }));
    }
  }

  private _onTrackReady(evt: RTCTrackEvent) {
    /**
     * signal 和 mediaServer 不同步时，signal 有资源，mediaServer 没资源，
     * 订阅 mediaServer 不存在的资源时，对应 answer sdp 中的通道没有 ssrc，
     * ontrack 会被触发，但 RTCTrackEvent 对象中的 streams 为 []，需兼容
     */
    if (!evt.streams.length) {
      return;
    }

    // 更新 transceiver 与 trackId 的订阅关系
    const msid = evt.streams[0].id;
    const { track } = evt.receiver;
    const trackId = [msid, track.kind === 'audio' ? RCMediaType.AUDIO_ONLY : RCMediaType.VIDEO_ONLY].join('_');
    // 在 原生的 track 上添加一个不可修改的属性 trackId
    Object.defineProperty(track, 'trackId', {
      value: trackId,
      writable: false,
      enumerable: false,
      configurable: false,
    });
    this._updateRecvTransceiverMap(trackId, evt.transceiver);

    this.emit(RCRTCPeerConnection.__INNER_EVENT_TRACK_READY__, evt);
  }

  /**
   * @deprecated use RCLocalTrack.setBitrate instead of setBitrate
   */
  public async setBitrate(max: number, min: number, start?: number) {
    this._sdpStrategy.setBitrate(max, min, start);

    this._logger.info(RCLoggerTag.L_RTC_PEER_CONNECTION_SET_BITRATE_O, JSON.stringify({
      status: RCLoggerStatus.SUCCESSED,
      max,
      min,
      start,
    }));
  }

  /**
   * `createOffer` 是一个创建报价并记录报价 SDP 和 SDP 语义的函数
   * @param {boolean} iceRestart - 布尔值
   * @returns 具有两个属性的对象：
   *   - sdp：SDP 字符串
   *   - 语义：SDP 语义
   */
  public async createOffer(iceRestart: boolean) {
    const offer = await this._sdpStrategy.createOffer(iceRestart);

    this._logger.info(RCLoggerTag.L_RTC_PEER_CONNECTION_CREATE_OFFER_O, JSON.stringify({
      offerSDP: offer.sdp,
      sdpDemantics: offer.semantics,
    }));

    return offer;
  }

  private _firstConnectFromPub: boolean = false;

  /**
   * @param answer
   * @param fromPub 是否来自于发布动作，用来对外通知连接成功后统计
   */
  public async setRemoteAnswer(answer: string, fromPub: boolean = false) {
    this._firstConnectFromPub = fromPub;
    return this._sdpStrategy.setRemoteAnswer(answer);
  }

  getLocalTrack(trackId: string): RCLocalTrack | null {
    return this.pubLocalTracks[trackId] || null;
  }

  /**
   * 它将本地轨道添加到对等连接。
   * @param {RCLocalTrack} track - 要添加的本地轨道。
   */
  addLocalTrack(track: RCLocalTrack) {
    this.pubLocalTracks[track.getTrackId()] = track;
    this._sdpStrategy.addLocalTrack(track);

    // 避免重复添加事件监听
    track.off(RCLocalTrack.__INNER_EVENT_MUTED_CHANGE__, this._onLocalTrackMuted, this);
    track.off(RCLocalTrack.__INNER_EVENT_DESTROY__, this._onLocalTrackDestroied, this);
    track.on(RCLocalTrack.__INNER_EVENT_MUTED_CHANGE__, this._onLocalTrackMuted, this);
    track.on(RCLocalTrack.__INNER_EVENT_DESTROY__, this._onLocalTrackDestroied, this);

    this._logger.info(RCLoggerTag.L_RTC_PEER_CONNECTION_ADD_LOCAL_TRACK_O, `trackId: ${track.getTrackId()}`);
  }

  /**
   * 按 ID 删除本地轨道
   * @param {string} trackId - 要移除的轨道的 ID。
   * @returns 正在删除的轨道。
   */
  removeLocalTrackById(trackId: string) {
    const track = this.getLocalTrack(trackId);
    if (!track) {
      this._logger.warn(RCLoggerTag.L_RTC_PEER_CONNECTION_REMOVE_LOCAL_TRACK_BY_ID_O, JSON.stringify({
        status: RCLoggerStatus.FAILED,
        msg: 'track not found',
        trackId,
      }));

      return;
    }

    this._logger.info(RCLoggerTag.L_RTC_PEER_CONNECTION_REMOVE_LOCAL_TRACK_BY_ID_O, JSON.stringify({
      status: RCLoggerStatus.SUCCESSED,
      trackId,
    }));

    this.removeLocalTrack(track);
  }

  /**
   * 它会删除所有本地Track。
   */
  removeAllLocalTrack() {
    Object.keys(this.pubLocalTracks).forEach((id) => {
      // 小流不可先于大流执行 removeLocalTrackById，否则可能存在小流被当做大流进而不可被销毁
      /_tiny$/.test(id) || this.removeLocalTrackById(id);
    });
  }

  /**
   * 从对等连接中删除本地轨道。
   * @param {RCLocalTrack} track - 要删除的本地轨道。
   */
  removeLocalTrack(track: RCLocalTrack) {
    const trackId = track.getTrackId();
    delete this.pubLocalTracks[trackId];
    this._sdpStrategy.removeLocalTrack(track);
    track.__innerSetPublished(false);

    track.off(RCLocalTrack.__INNER_EVENT_MUTED_CHANGE__, this._onLocalTrackMuted, this);
    track.off(RCLocalTrack.__INNER_EVENT_DESTROY__, this._onLocalTrackDestroied, this);

    // 尝试移除并销毁 tinyTrack
    const tinyId = `${trackId}_tiny`;
    const tinyTrack = this.getLocalTrack(tinyId);
    if (tinyTrack) {
      this._sdpStrategy.removeLocalTrack(tinyTrack);
      delete this.pubLocalTracks[tinyId];
      tinyTrack.destroy();
    }

    this._logger.info(RCLoggerTag.L_RTC_PEER_CONNECTION_REMOVE_LOCAL_TRACK_O, JSON.stringify({
      trackId,
    }));
  }

  private _updateRecvTransceiverMap(trackId: string, transceiver: RTCRtpTransceiver) {
    this._sdpStrategy.updateRecvTransceiverMap(trackId, transceiver);
  }

  updateSubRemoteTracks(remoteTracks: RCRemoteTrack[]) {
    this._sdpStrategy.updateSubRemoteTracks(remoteTracks);
  }

  /**
   * 获取当前已发布视频流信息
   */
  getOutboundVideoInfo() {
    return this._sdpStrategy.getOutboundVideoInfo();
  }

  private _onLocalTrackMuted(track: RCLocalTrack, resolve: Function) {
    // 修改已发布的小流状态
    const tinyTrack = this.getLocalTrack(`${track.getTrackId()}_tiny`);
    if (tinyTrack) {
      tinyTrack.__innerGetMediaStreamTrack()!.enabled = !track.isLocalMuted();
    }
    this.emit(RCLocalTrack.__INNER_EVENT_MUTED_CHANGE__, track, resolve);
  }

  private _onLocalTrackDestroied(track: RCLocalTrack) {
    this.emit(RCLocalTrack.__INNER_EVENT_DESTROY__, track);
  }

  private _reportListener: IRCRTCReportListener | null = null

  /**
   * 注册连接数据监控，开启质量数据上报定时器
   * @param listener
   */
  registerReportListener(listener: IRCRTCReportListener | null) {
    this._reportListener = listener;
  }

  /**
   * 组装上行质量报告数据
   */
  private _createSenderReport(senders: ISendTrackState[]) {
    const pickSender = senders.filter((item: ISendTrackState) => item.trackId).map((item: ISendTrackState) => {
      const {
        trackId, kind, packetsLostRate, remoteResource, bitrate, jitter, rtt,
      } = item;
      const sender: IRCTrackStat = {
        trackId, kind, packetsLostRate, remoteResource, bitrate, jitter, rtt,
      };
      (item.audioLevel || item.audioLevel === 0) && (sender.audioLevel = item.audioLevel);
      item.frameWidth && (sender.frameWidth = item.frameWidth);
      item.frameHeight && (sender.frameHeight = item.frameHeight);
      item.frameRate && (sender.frameRate = item.frameRate);
      return { ...sender };
    });
    return pickSender;
  }

  /**
   * 组装下行质量报告数据
   */
  private _createReceiverReport(receivers: IRecvTrackState[]) {
    const pickReceivers = receivers.filter((item: IRecvTrackState) => item.trackId).map((item: IRecvTrackState) => {
      const {
        trackId, kind, packetsLostRate, remoteResource, bitrate, jitter,
      } = item;
      const receiver: IRCTrackStat = {
        trackId, kind, packetsLostRate, remoteResource, bitrate, jitter,
      };
      (item.audioLevel || item.audioLevel === 0) && (receiver.audioLevel = item.audioLevel);
      item.frameWidth && (receiver.frameWidth = item.frameWidth);
      item.frameHeight && (receiver.frameHeight = item.frameHeight);
      item.frameRate && (receiver.frameRate = item.frameRate);
      return { ...receiver };
    });
    return pickReceivers;
  }

  private _createRCRTCStateReport(data: IInnerRCRTCStateReport): IRCRTCStateReport {
    const {
      timestamp, iceCandidatePair, senders, receivers,
    } = data;

    if (iceCandidatePair?.totalPacketsLost) {
      delete iceCandidatePair?.totalPacketsLost;
    }
    for (const key in iceCandidatePair) {
      isNull(iceCandidatePair[key]) && delete iceCandidatePair[key];
    }
    const pickSender: IRCTrackStat[] = this._createSenderReport(senders);

    const pickReceivers: IRCTrackStat[] = this._createReceiverReport(receivers);

    return {
      timestamp,
      iceCandidatePair,
      senders: pickSender,
      receivers: pickReceivers,
    };
  }

  /**
   * 获取 peerConnection stats 数据并格式化
   * @returns 返回格式化后的数据
   */
  private async _getStatsData() {
    this._formatStatsData = null;
    let data: {
      [key: string]: any
    } | null | undefined = null;
    const reports: any = await this._rtcPeerConn.getStats();
    /**
     * 解析 stats 数据
     */
    data = this.reportParser?.parseRTCStatsReport(reports);

    /**
     * 获取 report 中的 iceCandidatePair、senders、receivers 中的所有字段
     */
    const formatData = this.reportParser?.formatRCRTCStateReport(data!);
    this._formatStatsData = formatData;

    return formatData;
  }

  /**
   * 通知用户质量数据、peerConnection 北极星数据上报
   */
  private async _reportHandle() {
    const formatData = await this._getStatsData();
    if (!formatData) {
      return;
    }

    /**
     * 组装质量数据，给业务层上报
     */
    const reportData = this._createRCRTCStateReport(formatData);
    try {
      this._reportListener?.onStateReport?.(JSON.parse(JSON.stringify(reportData)));
    } catch (error: any) {
      this._logger.error(RCLoggerTag.L_ABSTRACT_ROOM_CALL_APP_LISTENER_O, error?.stack);
    }

    /**
     * 获取音量数据，给业务层上报
     */
    const audioLevelList = [...formatData.receivers, ...formatData.senders].filter((item) => (item.kind === 'audio')).map((item) => ({
      trackId: item.trackId,
      audioLevel: item.audioLevel,
    }));
    this.emit(RCRTCPeerConnection.__INNER_AUDIOLEVEL_CHANGE__, audioLevelList);

    /**
     * 仅观众加房间上报合流音源信息
     */
    if (this._isRoomAudience && this._reportListener?.onReportLiveAudioStates) {
      const audioStateList = this._getLiveAudioState();
      try {
        audioStateList.length && this._reportListener?.onReportLiveAudioStates?.(audioStateList);
      } catch (error: any) {
        this._logger.error(RCLoggerTag.L_ABSTRACT_ROOM_CALL_APP_LISTENER_O, error?.stack);
      }
    }
  }

  /**
   * 获取合流音源信息
   */
  private _getLiveAudioState() {
    const audioStateList: RTCRtpContributingSource[] = [];
    this._rtcPeerConn.getReceivers().forEach((receiver) => {
      const sources = receiver.getContributingSources();
      sources.length && audioStateList.push(...sources);
    });

    return audioStateList.map((audioState) => {
      const { source: ssrc, audioLevel } = audioState;
      const trackId = this._store.getTrackIdBySSRC(ssrc)!;
      if (!trackId) {
        return {} as ILiveAudioState;
      }
      const { userId } = parseTrackId(trackId);
      return {
        audioLevel: handleAudioLevel(audioLevel!),
        userId,
        trackId,
      };
    }).filter((item) => !!item.userId);
  }

  /**
   * 北极星上报 R3、R4 数据
   */
  private async _sendR3R4Data(): Promise<void> {
    const formatData = this._formatStatsData;
    if (!formatData) {
      return;
    }

    const { senders, receivers } = formatData;

    /**
     * 组装北极星上报 R3、R4 数据并发送
     */
    senders.length && await this._polarisReport?.sendR3Data(formatData);
    receivers.length && await this._polarisReport?.sendR4Data(formatData);
  }

  /**
   * 2s 给北极星上报一次 R3、R4
   */
  public async __reportR3R4ToPolaris() {
    clearTimeout(this._reportR3R4ToPolarisTimer);
    this._reportR3R4ToPolarisTimer = setTimeout(async () => {
      this._sendR3R4Data().then(() => {
        this.__reportR3R4ToPolaris();
      });
    }, 2000);
  }

  getRTCPeerConn() {
    return this._rtcPeerConn;
  }

  /**
   * 它返回一个 RTCRtpCodecCapability 对象数组，第一个元素是与传入的编码器类型匹配的元素
   * @param {RCEncoder} encoder - RCEncoder.H264
   * @returns 正在返回 RTCRtpCodecCapability[] 数组。
   */
  private getCapabilitiesCodes(encoder: RCEncoder = RCEncoder.H264): RTCRtpCodecCapability[] {
    let capCodes = <RTCRtpCodecCapability[]> RTCRtpSender.getCapabilities('video')?.codecs;
    let cap:any = null;
    switch (encoder) {
      case RCEncoder.VP8:
      case RCEncoder.VP9:
        cap = capCodes.find((item) => item.mimeType.match(encoder));
        break;
      case RCEncoder.H264:
        cap = capCodes.find((item) => item.mimeType.match(encoder) && item.sdpFmtpLine?.match('42e01f'));
        break;
    }
    capCodes = capCodes.filter((item) => item !== cap);
    capCodes = [cap, ...capCodes];
    return capCodes;
  }

  /**
   * 它为视频流设置编码器首选项。
   * @param {RCEncoder} [encoder] - RC编码器
   */
  public async setEncoderPreferences(encoder?: RCEncoder) {
    const pc = this.getRTCPeerConn();
    const codecCap = this.getCapabilitiesCodes(encoder);
    this._logger.info(RCLoggerTag.L_SET_ENCODER_PREFERENCES_S, JSON.stringify({
      status: RCLoggerStatus.SUCCESSED,
      codecCap,
    }));
    pc.getTransceivers().forEach((t) => {
      if (t.sender.track?.kind !== 'video') return;
      t.setCodecPreferences(codecCap);
    });
  }

  /**
   * 关闭 RTCPeerConnection 连接。其生命周期：
   * 1. 连接生命周期与房间实例一致（包含观众加房间），仅在退出房间时进行关闭。
   * 2. 观众客户端（观众不加房间）订阅时创建，取消订阅时销毁
   */
  destroy() {
    this.clear();
    this.clearReTryExchangeTimer();
    clearTimeout(this._reportR3R4ToPolarisTimer);

    // 停止计时
    this.stopAutoExecute();

    this.registerReportListener(null);
    // 关闭 pc 连接
    this._rtcPeerConn.close();
    this._isDestroyed = true;

    // 销毁解析 stats 实例
    this.reportParser = null;

    this._logger.info(RCLoggerTag.L_RTC_PEER_CONNECTION_DESTROY_O, JSON.stringify({
      status: RCLoggerStatus.SUCCESSED,
    }));
  }

  clearReTryExchangeTimer() {
    clearInterval(this._reTryExchangeTimer);
    this._reTryExchangeTimer = null;
  }

  isDestroyed() {
    return this._isDestroyed;
  }
}
