/**
 * 通话媒体类型
 */
export enum RCCallMediaType {
  /**
   * 音频通话
   */
  AUDIO = 1,
  /**
   * 视频通话
   */
  AUDIO_VIDEO
}
