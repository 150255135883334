/**
 * 媒体资源类型
 */
export enum RCMediaType {
  /**
   * 音频流
   */
  AUDIO_ONLY = 0,
  /**
   * 视频流
   */
  VIDEO_ONLY = 1,
  /**
   * 音视频混合流，只在 web 端存在混合流的情况
   */
  AUDIO_VIDEO = 2
}
