import { IRCTrackBitrate } from '../interfaces';

export enum RCAudioBitrate {
  /**
   * 标清音质
   */
  Speech = 'Speech',
  /**
   * 音乐音质
   */
  Music = 'Music',
  /**
   * 音乐高清音质
   */
  MusicHigh = 'MusicHigh'
}

export const getAudioBitrate: { [ key in RCAudioBitrate ]: IRCTrackBitrate } = {
  [RCAudioBitrate.Music]: { max: 32 * 2, min: 32 * 2 },
  [RCAudioBitrate.MusicHigh]: { max: 32 * 2 * 2, min: 32 * 2 * 2 },
  [RCAudioBitrate.Speech]: { max: 32, min: 32 },
};

export enum RCVideoBitrate {
  /**
   * Bitrate { max: 150, min: 80 }
   */
  LEVEL0 = 'LEVEL0',

  /**
   * Bitrate { max: 280, min: 120 }
   */
  LEVEL1 = 'LEVEL1',

  /**
   * Bitrate { max: 650, min: 150 }
   */
  LEVEL2 = 'LEVEL2',

  /**
   * Bitrate { max: 1000, min: 200 }
   */
  LEVEL3 = 'LEVEL3',

  /**
   * Bitrate { max: 2200, min: 250 }
   */
  LEVEL4 = 'LEVEL4',

  /**
   * Bitrate { max: 4000, min: 400 }
   */
  LEVEL5 = 'LEVEL5'
}

export const getVideoBitrate: { [ key in RCVideoBitrate ]: IRCTrackBitrate } = {
  [RCVideoBitrate.LEVEL0]: { max: 150, min: 80 },
  [RCVideoBitrate.LEVEL1]: { max: 280, min: 120 },
  [RCVideoBitrate.LEVEL2]: { max: 650, min: 150 },
  [RCVideoBitrate.LEVEL3]: { max: 1000, min: 200 },
  [RCVideoBitrate.LEVEL4]: { max: 2200, min: 250 },
  [RCVideoBitrate.LEVEL5]: { max: 4000, min: 400 },
};
