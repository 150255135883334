import { ErrorCode } from '@rongcloud/engine';
import { RCLivingType } from '../enums/RCLivingType';
import { RTCIdentityChangeType } from '../enums/inner/RTCIdentityChangeType';
import { Invoker } from '../Invoker';
import { Store } from '../Store';
import { BaseCommand } from './BaseCommand';
import { UnpublishPrevCommand } from './UnpublishPrevCommand';
import { IJoinRTCRoomData } from '../codec/interface';
import { RCCommandKind } from '../enums/RCCommandKind';
import { CommandExecuteContext } from './CommandExecuteContext';

export class RTCIdentityChangeCommand extends BaseCommand<IJoinRTCRoomData> {
  constructor(
    private livingType: RCLivingType,
    private traceId: string,
  ) {
    super();
  }

  get kind(): RCCommandKind {
    return RCCommandKind.RTC_IDENTITY_CHANGE;
  }

  async execute(executeCtx: CommandExecuteContext, store: Store, invoker: Invoker): Promise<{ code: ErrorCode; data?: IJoinRTCRoomData }> {
    const { context } = executeCtx;
    const { code, data } = await context.rtcIdentityChange(store.roomId, RTCIdentityChangeType.ViewerToAnchor, this.livingType);
    if (code !== ErrorCode.SUCCESS || !data) {
      return { code };
    }

    store.initWithRoomData(data, this.traceId);

    const selfRes = store.getResourcesByUserId(store.crtUserId)!;
    /*
     * 加入房间后，若房间中已存在己方发布的资源，表示之前未能完成正常退出流程
     * 需先清除房间内的己方资源，通知房间内其他人己方已取消当前资源的发布
     * 该步骤没有必要与 MediaServer 的交互，因后续资源变更交互为全量交互
     */
    selfRes.length > 0 && invoker.push(new UnpublishPrevCommand());

    const CDNUris = data.roomInfo.filter((item) => item.key === 'cdn_uris')[0]?.value;
    CDNUris && store.setCDNUris(JSON.parse(CDNUris)[0]);

    return { code, data };
  }
}
