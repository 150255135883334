import {
  isArray, validate,
} from '@rongcloud/engine';
import { getTrackIdFromAttr } from '../../helper';
import { R2Action } from '../enums/inner/R2Action';
import { R2Status } from '../enums/inner/R2Status';
import { RCLoggerStatus, RCLoggerTag } from '../enums/RCLoggerTag';
import { RCRTCCode } from '../enums/RCRTCCode';
import { ISubscribeAttr } from '../interfaces';
import { Invoker } from '../Invoker';
import { Store } from '../Store';
import { RCRemoteTrack } from '../tracks/RCRemoteTrack';
import { BaseCommand } from './BaseCommand';
import { UpdateSubscribeListCommand } from './UpdateSubscribeListCommand';
import { RCCommandKind } from '../enums/RCCommandKind';
import { CommandExecuteContext } from './CommandExecuteContext';

/**
 * 订阅事务，结果返回部分失败列表与 code
 */
export class SubscribeCommand extends BaseCommand<ISubscribeAttr[]> {
  constructor(
    private readonly tracks: (RCRemoteTrack | ISubscribeAttr)[],
    private readonly forceReq?: boolean,
    private traceId?: string,
  ) {
    super();
  }

  get kind(): RCCommandKind {
    return RCCommandKind.SUBSCRIBE;
  }

  async execute(executeCtx: CommandExecuteContext, store: Store, invoker: Invoker): Promise<{ code: RCRTCCode, data?: ISubscribeAttr[] }> {
    const { logger, polarisReport } = executeCtx;
    // 取消房间状态检查，房间销毁后，invoker 应直接清空，避免后续执行动作
    // const roomStatusCode = this._assertRoomDestroyed()
    // if (roomStatusCode) {
    //   logger.error(`subscribe failed, room has been destroyed -> roomId: ${this._roomId}`)
    //   return { code: RCRTCCode.ROOM_HAS_BEEN_DESTROYED }
    // }
    const { tracks, forceReq } = this;

    logger.info(RCLoggerTag.L_ABSTRACT_ROOM_SUBSCRIBE_T, JSON.stringify({
      trackIds: tracks.map(getTrackIdFromAttr),
      forceReq,
    }), this.traceId);

    if (!validate('tracks', tracks, () => isArray(tracks) && tracks.length > 0 && tracks.every((item) => item instanceof RCRemoteTrack || item.track instanceof RCRemoteTrack), true)) {
      logger.error(RCLoggerTag.L_ABSTRACT_ROOM_SUBSCRIBE_R, JSON.stringify({
        status: RCLoggerStatus.FAILED,
        code: RCRTCCode.PARAMS_ERROR,
        msg: 'params error -> tracks',
      }), this.traceId);
      return { code: RCRTCCode.PARAMS_ERROR };
    }

    const crtSubList = store.getSubscribedList().map((item) => ({ ...item }));

    const attrs: ISubscribeAttr[] = tracks.map((item) => (item instanceof RCRemoteTrack ? { track: item } : item));

    let changed = false;
    const R2TrackIds: string[] = [];

    attrs.forEach((item) => {
      const trackId = item.track.getTrackId();
      R2TrackIds.push(trackId);

      const crt = crtSubList.find((tmp) => tmp.track.getTrackId() === trackId);
      if (crt && crt.subTiny === item.subTiny) {
        return;
      }

      if (crt) {
        crt.subTiny = item.subTiny;
      } else {
        crtSubList.push(item);
      }
      changed = true;
    });

    if (!changed && !forceReq) {
      logger.warn(RCLoggerTag.L_ABSTRACT_ROOM_SUBSCRIBE_R, JSON.stringify({
        status: RCLoggerStatus.SUCCESSED,
        msg: 'repeat subscribe',
      }), this.traceId);
      return { code: RCRTCCode.SUCCESS };
    }

    // 北极星上报
    polarisReport.sendR2(R2Action.SUBSCRIBE, R2Status.BEGIN, R2TrackIds);

    return new UpdateSubscribeListCommand(crtSubList, true, this.traceId).execute(executeCtx, store, invoker);
  }
}
