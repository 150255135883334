import {
  BasicLogger,
  isArray, validate,
} from '@rongcloud/engine';
import { getTrackIdFromAttr } from '../../helper';
import { RCRTCCode } from '../enums/RCRTCCode';
import { ISubscribeAttr } from '../interfaces';
import { Invoker } from '../Invoker';
import { Store } from '../Store';
import { RCRemoteTrack, RCRemoteVideoTrack } from '../tracks/RCRemoteTrack';
import { BaseCommand } from './BaseCommand';
import { ExchangeCommand } from './ExchangeCommand';
import { createExchangeParams, subscribeListIsChange } from './helper';
import { RCLoggerStatus, RCLoggerTag } from '../enums/RCLoggerTag';
import { RCCommandKind } from '../enums/RCCommandKind';
import { IMediaServerQualityData, RCRTCResourceAction } from '../logger/IQualityReportData';
import { CommandExecuteContext } from './CommandExecuteContext';
import { IExchangeResSubscribeItem } from '../service';
import ReportMediaActionLogger from '../logger/QualityLogger';

export class UpdateSubscribeListCommand extends BaseCommand<ISubscribeAttr[]> {
  constructor(
    private readonly tracks: (RCRemoteTrack | ISubscribeAttr)[],
    private readonly forceReq?: boolean,
    private traceId?: string,
    private readonly fromCommand: RCRTCResourceAction = RCRTCResourceAction.SUB,
    /**
     * 本次取消订阅 tracks
     */
    private readonly unsubTracks?: RCRemoteTrack[],
  ) {
    super();
  }

  /**
   * 行为开始时间
   */
  private _actionStartTime: number = Date.now()

  /**
   * track 对应的 mediaServer 信息
   */
  private _trackMediaMap: {[trackId: string]: IMediaServerQualityData[] } = {}

  get kind(): RCCommandKind {
    return RCCommandKind.UPDATE_SUBSCRIBE_LIST;
  }

  /**
   * 处理订阅成功的数据
   */
  private _dealSubscribeResult(store: Store, logger: BasicLogger, attrs: ISubscribeAttr[], subscribedList?: IExchangeResSubscribeItem[]) {
    // 获取真正订阅成功的资源
    const subSuccessTrackIds = subscribedList?.map((item) => `${item.msid}_${item.mediaType}`);
    const subSuccessList = attrs.filter((item) => subSuccessTrackIds?.includes(item.track.getTrackId()));
    const failedList = attrs.filter((item) => !subSuccessTrackIds?.includes(item.track.getTrackId()));

    logger.info(RCLoggerTag.L_ABSTRACT_ROOM_SUBSCRIBE_R, JSON.stringify({
      status: RCLoggerStatus.SUCCESSED,
      subSuccessTrackIds,
      failedList,
    }), this.traceId);

    // 更新 remoteTrack.isSubscribed、remoteTrack.isTinyTrack
    const remoteTracks = store.getRemoteTracks();
    for (const trackId in remoteTracks) {
      const subed = subSuccessList.some((item) => item.track.getTrackId() === trackId);
      remoteTracks[trackId].__innerSetSubscribed(subed);

      const isTinyTrack = subSuccessList.some((item) => (item.track.getTrackId() === trackId && item.subTiny));
      remoteTracks[trackId].isVideoTrack() && ((remoteTracks[trackId] as RCRemoteVideoTrack).__innerSetIsTinyTrack(isTinyTrack));
    }

    // 更新本地订阅关系
    store.resetSubscribedList(subSuccessList);

    return failedList;
  }

  /**
   * 去重、转化参数格式为 ISubscribeAttr
   */
  private _getParams(store: Store, logger: BasicLogger, tracks: (RCRemoteTrack | ISubscribeAttr)[]) {
    let attrs: ISubscribeAttr[] = tracks.map((item) => (item instanceof RCRemoteTrack ? { track: item } : { ...item }));

    // resourceId 去重，并做数据深拷贝
    const map: { [trackId: string]: boolean } = {};
    attrs = attrs.filter((res) => {
      const trackId = res.track.getTrackId();
      // 已不在远端资源列表中，无需订阅/取消订阅
      const track = store.getRemoteTrack(trackId);
      if (!track) {
        logger.warn(RCLoggerTag.L_UPDATE_SUBSCRIBELIST_COMMAND_P, `track cannot found in room -> trackId: ${trackId}`, this.traceId);
        return false;
      }
      if (map[trackId]) {
        return false;
      }
      map[trackId] = true;
      return true;
    }).map((item) => ({ ...item }));
    return attrs;
  }

  private _validateParams(logger: BasicLogger, tracks: (RCRemoteTrack | ISubscribeAttr)[]) {
    if (!validate('tracks', tracks, () => isArray(tracks) && tracks.every((res) => res instanceof RCRemoteTrack || res.track instanceof RCRemoteTrack), true)) {
      logger.warn(RCLoggerTag.L_UPDATE_SUBSCRIBELIST_COMMAND_R, JSON.stringify({
        status: RCLoggerStatus.FAILED,
        code: RCRTCCode.PARAMS_ERROR,
        msg: 'tracks is invalid',
      }), this.traceId);
      return { code: RCRTCCode.PARAMS_ERROR };
    }
    return { code: RCRTCCode.SUCCESS };
  }

  private _reportPubOrSubQualityData(reportMediaActionLogger: ReportMediaActionLogger, resultCode: number, oprateTracks?: (ISubscribeAttr | RCRemoteTrack)[]) {
    oprateTracks?.length && reportMediaActionLogger.reportPubOrSubQualityData(this.fromCommand, this._actionStartTime, oprateTracks, resultCode, this._trackMediaMap);
  }

  async execute(executeCtx: CommandExecuteContext, store: Store, invoker: Invoker): Promise<{ code: RCRTCCode, data?: ISubscribeAttr[] }> {
    const { logger, peer, reportMediaActionLogger } = executeCtx;
    this._actionStartTime = Date.now();
    const { tracks, forceReq } = this;
    const { roomId } = store;

    logger.info(RCLoggerTag.L_UPDATE_SUBSCRIBELIST_COMMAND_T, `roomId: ${roomId}, forceReq: ${forceReq}, trackIds: ${tracks.map(getTrackIdFromAttr)}`, this.traceId);

    const { code: validateParamsCode } = this._validateParams(logger, tracks);
    if (validateParamsCode !== RCRTCCode.SUCCESS) {
      return { code: validateParamsCode };
    }

    const attrs = this._getParams(store, logger, tracks);

    const crtSubList = store.getSubscribedList().map((item) => ({ ...item }));

    if (!forceReq) {
      /**
       * 检查订阅列表是否改变
       */
      const changed = subscribeListIsChange(attrs, crtSubList);

      if (!changed) {
        logger.warn(RCLoggerTag.L_UPDATE_SUBSCRIBELIST_COMMAND_R, JSON.stringify({
          status: RCLoggerStatus.SUCCESSED,
          msg: 'subscribe list unchanged',
        }), this.traceId);
        return { code: RCRTCCode.SUCCESS };
      }

      // crtSubList 中剩余内容为取消订阅资源
      if (crtSubList.length) {
        // 防止取消订阅操作失败，提前更新本地订阅关系
        const unsubscribeTracks = crtSubList.map((item) => item.track);
        logger.warn(RCLoggerTag.L_CLEAR_UNSUBSCRIBE_LIST_O, JSON.stringify({
          list: unsubscribeTracks.map((track) => track.getTrackId()),
        }), this.traceId);
        const newSubList = store.getSubscribedList()
          .filter((item) => unsubscribeTracks.every((track) => track.getTrackId() !== item.track.getTrackId()));
        store.resetSubscribedList(newSubList);
      }
    }

    // 发送上下行数据至北极星
    peer.__reportR3R4ToPolaris();
    // 客户端主动调用 api 发请求时，清除 ice 断线重连的定时器
    peer.clearReTryExchangeTimer();

    peer.updateSubRemoteTracks(attrs.map((item) => item.track));

    const reqBody = await createExchangeParams(attrs, false, peer, store);
    /**
      * 直播房间需携带 pushOtherRooms 信息
      */
    reqBody.pushOtherRooms = executeCtx.getPushOtherRooms();
    const result = await new ExchangeCommand(reqBody, this.traceId, true).execute(executeCtx, store, invoker);

    /**
     * 存储每一个 track 对应的 mediaServer 请求信息
     */
    tracks.forEach((track) => {
      const trackId = track instanceof RCRemoteTrack ? track.getTrackId() : track.track.getTrackId();
      this._trackMediaMap[trackId] = result.data!.qualityMsList || [];
    });

    /**
     * 本次订阅或取消订阅的 tracks
     */
    const oprateTracks = this.fromCommand === RCRTCResourceAction.SUB ? tracks : this.unsubTracks;

    if (result.code !== RCRTCCode.SUCCESS) {
      this._reportPubOrSubQualityData(reportMediaActionLogger, result.code, oprateTracks);

      logger.error(RCLoggerTag.L_UPDATE_SUBSCRIBELIST_COMMAND_R, JSON.stringify({
        status: RCLoggerStatus.FAILED,
        code: result.code,
      }), this.traceId);

      return { code: result.code };
    }
    const {
      sdp: answer, resultCode, subscribedList,
    } = result.data!.data!;
    if (resultCode !== RCRTCCode.SUCCESS) {
      this._reportPubOrSubQualityData(reportMediaActionLogger, resultCode, oprateTracks);

      logger.error(RCLoggerTag.L_UPDATE_SUBSCRIBELIST_COMMAND_R, JSON.stringify({
        status: RCLoggerStatus.FAILED,
        code: resultCode,
      }), this.traceId);

      return { code: resultCode };
    }

    reportMediaActionLogger.setPubOrSubSuccessTime(Date.now());
    const resCode = await peer.setRemoteAnswer(answer.sdp);

    this._reportPubOrSubQualityData(reportMediaActionLogger, resCode, oprateTracks);

    if (resCode !== RCRTCCode.SUCCESS) {
      return { code: resCode };
    }

    const failedList = this._dealSubscribeResult(store, logger, attrs, subscribedList);

    return failedList.length ? { code: RCRTCCode.SUCCESS, data: failedList } : { code: RCRTCCode.SUCCESS };
  }
}
