/**
 * 北极星上报角色
 */
export enum PolarisRole {
  /**
   * 会议参会者、主播
   */
  MeetingOrAnchor = 1,
  /**
   * 观众
   */
  Audience = 2
}
