import { ErrorCode } from '@rongcloud/engine';
import { buildPlusMessage, buildTotalURIMessageContent } from '../../helper';
import { RCRTCMessageType } from '../enums/inner/RCRTCMessageType';
import { RCCommandKind } from '../enums/RCCommandKind';
import { RCLoggerStatus, RCLoggerTag } from '../enums/RCLoggerTag';
import { RCRTCCode } from '../enums/RCRTCCode';
import { Store } from '../Store';
import { BaseCommand, ICommandResult } from './BaseCommand';
import { CommandExecuteContext } from './CommandExecuteContext';

/**
 * 加入房间后，若房间中已存在己方发布的资源，表示之前未能完成正常退出流程
 * 需先清除房间内的己方资源，通知房间内其他人己方已取消当前资源的发布
 * 该步骤没有必要与 MediaServer 的交互，因后续资源变更交互为全量交互
 */
export class UnpublishPrevCommand extends BaseCommand {
  get kind(): RCCommandKind {
    return RCCommandKind.UNPUBLISH_PREV;
  }

  async execute(executeContext: CommandExecuteContext, store: Store): Promise<ICommandResult> {
    const { logger, context } = executeContext;
    const selfRes = store.getResourcesByUserId(store.crtUserId)!;

    const tmpRes = selfRes.slice();
    // 清空已发布资源
    selfRes.length = 0;

    const traceId = logger.createTraceId();
    logger.info(RCLoggerTag.L_UNPUBLISH_PREV_COMMAND_T, JSON.stringify({
      resource: tmpRes,
    }), traceId);

    // 添加请求队列并等待结果
    const code = await context.setRTCTotalRes(
      store.roomId,
      [buildPlusMessage(RCRTCMessageType.UNPUBLISH, tmpRes)],
      buildTotalURIMessageContent([]),
      RCRTCMessageType.TOTAL_CONTENT_RESOURCE,
      buildTotalURIMessageContent([]),
    );
    if (code !== ErrorCode.SUCCESS) {
      logger.error(RCLoggerTag.L_UNPUBLISH_PREV_COMMAND_R, JSON.stringify({
        status: RCLoggerStatus.FAILED,
        code,
      }), traceId);
    } else {
      logger.info(RCLoggerTag.L_UNPUBLISH_PREV_COMMAND_R, JSON.stringify({
        status: RCLoggerStatus.SUCCESSED,
      }), traceId);
    }

    return { code: RCRTCCode.SUCCESS };
  }
}
