import {
  ErrorCode,
} from '@rongcloud/engine';
import { RCLivingType } from '../enums/RCLivingType';
import { RCLoggerStatus, RCLoggerTag } from '../enums/RCLoggerTag';
import { Invoker } from '../Invoker';
import { Store } from '../Store';
import { BaseCommand, CommandPriority, ICommandResult } from './BaseCommand';
import { handleFullRoomData } from './helper';
import { RCCommandKind } from '../enums/RCCommandKind';
import { PublishCommand } from './PublishCommand';
import { SubscribeCommand } from './SubscribeCommand';
import { PullRTCRoomStatusCommand } from './PullRTCRoomStatusCommand';
import { CommandEvent, CommandExecuteContext } from './CommandExecuteContext';
import { RCRTCCode } from '../enums/RCRTCCode';

export class OnSignalReconnectedCommand extends BaseCommand {
  constructor(
    private livingType?: RCLivingType,
  ) {
    super();
  }

  get kind(): RCCommandKind {
    return RCCommandKind.ON_SIGNAL_RECONNECTED;
  }

  /**
   * @override
   */
  get priority(): CommandPriority {
    return CommandPriority.HIGH;
  }

  async execute(executeCtx: CommandExecuteContext, store: Store, invoker: Invoker): Promise<ICommandResult> {
    const {
      logger, peer, context,
    } = executeCtx;
    const { roomId, roomMode } = store;

    const traceId = logger.createTraceId()!;
    logger.info(RCLoggerTag.L_ABSTRACT_ROOM_IM_RECONNECTED_T, undefined, traceId);

    /**
     * 检测 rtcpeerconnection 连接状态是否已变更
     * 电脑息屏后，rtcpeerconnection 状态会被直接修改为 closed 而不触发任何通知，需要主动检测并通知业务层进行业务恢复
     * SDK 无法自行恢复业务，因所需流可能无法自行重新捕获，如屏幕共享流、自定义文件流
     */
    if (peer.getRTCPeerConn().connectionState === 'closed') {
      logger.error(RCLoggerTag.L_ABSTRACT_ROOM_IM_RECONNECTED_R, 'RTCPeerConnection closed. Please rejoin room to restore services', traceId);
      executeCtx.emit(CommandEvent.PEER_CONNECTION_CLOSED_BY_EXCEPTION);
      return { code: RCRTCCode.SUCCESS };
    }

    const { code } = await context.rtcPing(roomId, roomMode);

    /**
     * 不在房间内时,需重新加房间,恢复资源状态
     * ErrorCode 增加 40001 需更新 im 包,暂时转为数字对比
     * 在房间内获取房间内最新数据
     * 私有云 im server 滞后，不能使用拉取房间数据功能
     */
    if (code as number === 40001 || __IS_ENTERPRISE__) {
      const code = await this._joinRoom(executeCtx, store, invoker, traceId);

      /**
       * 加入房间成功后,恢复和 mediaServer 服务之间的连接
       */
      if (code === ErrorCode.SUCCESS) {
        await this._restoreMediaServer(executeCtx, store, invoker);
      }
    } else {
      await new PullRTCRoomStatusCommand(roomId, traceId, context).execute(executeCtx, store, invoker);
    }

    return { code: RCRTCCode.SUCCESS };
  }

  private async _joinRoom(executeCtx: CommandExecuteContext, store: Store, invoker: Invoker, traceId: string) {
    const { context, logger } = executeCtx;

    const { code, data } = await context.joinRTCRoom(store.roomId, store.roomMode, this.livingType);
    if (code !== ErrorCode.SUCCESS) {
      logger.error(RCLoggerTag.L_ABSTRACT_ROOM_IM_RECONNECTED_R, JSON.stringify({
        status: RCLoggerStatus.FAILED,
        code,
        msg: 'im reconnect joinRTCRoom failed',
      }), traceId);
      return;
    }

    if (!data) {
      logger.info(RCLoggerTag.L_ABSTRACT_ROOM_IM_RECONNECTED_R, JSON.stringify({
        status: RCLoggerStatus.FAILED,
        msg: 'im reconnect joinRTCRoom, roomData is empty',
      }), traceId);

      return;
    }

    /**
     * 处理全量的房间数据
     */
    const CDNUris = data.roomInfo.filter((item) => item.key === 'cdn_uris')[0]?.value;
    await handleFullRoomData(data, store, executeCtx, invoker, CDNUris, traceId);

    logger.info(RCLoggerTag.L_ABSTRACT_ROOM_IM_RECONNECTED_R, JSON.stringify({
      status: RCLoggerStatus.SUCCESSED,
      roomData: data,
    }), traceId);

    return code;
  }

  private async _restoreMediaServer(executeCtx: CommandExecuteContext, store: Store, invoker: Invoker) {
    const localTracks = executeCtx.peer.getLocalTracks();
    const subedTracks = Object.values(store.getRemoteTracks()).filter((track) => !!track.isSubscribed());

    /**
     * 单 peerConnection 恢复发布、订阅资源
     */
    localTracks.length && await new PublishCommand(localTracks).execute(executeCtx, store, invoker);
    if (!localTracks.length && subedTracks.length) {
      await new SubscribeCommand(subedTracks, true).execute(executeCtx, store, invoker);
    }
  }
}
