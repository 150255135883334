import { RCCallLanguage } from '../enums/RCCallLang';
import { EN } from './en';
import { ZH } from './zh';

/**
 * CallEngine 全局语言设置，当前仅支持中、英文
 */
export class Local {
  static _lang: RCCallLanguage = RCCallLanguage.ZH

  static set(lang: RCCallLanguage) {
    this._lang = lang;
  }

  static get() {
    if (this._lang === RCCallLanguage.EN) {
      return EN;
    }
    return ZH;
  }
}
