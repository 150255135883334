import {
  IRuntime, ErrorCode, validate,
} from '@rongcloud/engine';
import { RCRTCCode } from '../enums/RCRTCCode';
import { IRCRTCInitOptions, IPublishAttrs, IPubSuccessRes } from '../interfaces';
import { RCMediaService, ICDNUris, IPushOtherRooms } from '../service';
import RCAbstractRoom from './RCAbstractRoom';
import RCMCUConfigBuilder from './RCMCUConfigBuilder';
import { RCLivingType } from '../enums/RCLivingType';
import { RCRTCMessageType } from '../enums/inner/RCRTCMessageType';
import { RCInnerCDNPushMode } from '../enums/RCInnerCDNPushMode';
import {
  getPKInfoByRoomData, getTrackId, parseTrackId,
} from '../../helper';
import { RCLivingPKHandler } from './RCLivingPKHandler';
import { RCRemoteTrack } from '../tracks/RCRemoteTrack';
import { RCLocalTrack } from '../tracks/RCLocalTrack';
import { ResourceMsgContent } from '../command/ParseRemoteResCommand';
import { EnableInnerCDNCommand } from '../command/EnabelInnerCDNCommand';
import { ExchangeWithPushOtherRoomCommand } from '../command/ExchangeWithPushOtherRoomCommand';
import { RTCIdentityChangeCommand } from '../command/RTCIdentityChangeCommand';
import { RCLoggerStatus, RCLoggerTag } from '../enums/RCLoggerTag';
import { RTCContext } from '../codec/RTCContext';
import { RTCMode } from '../enums/RTCMode';
import { RTCJoinType } from '../enums/RTCJoinType';
import { IJoinRTCRoomData, IRTCUserData } from '../codec/interface';

/**
 * 直播房间
 */
export default class RCLivingRoom extends RCAbstractRoom {
  public readonly _mcuConfigBuilder: RCMCUConfigBuilder

  /**
   * 跨房间连麦管理器
   */
  private _roomPKHandler!: RCLivingPKHandler

  /**
   * 一个构造函数。
   * @param {RTCContext} context - RTC上下文，
   * @param {IRuntime} runtime - 运行时
   * @param {string} roomId - 房间号
   * @param {RCMediaService} service - RCMediaService
   * @param {IRCRTCInitOptions} initOptions - IRCRTCInitOptions
   * @param {RCLivingType} _livingType - RCLivingType.LIVE_ROOM,
   * @param {boolean} [isUpgrade] - 是否升级房间，如果为真，则表示房间从会议室升级到直播间，会议室关闭。
   * @param {boolean} [isMainRoom] - 无论是主房间，主房间都是发起呼叫的房间，子房间是接收呼叫的房间。
   * @param {string} [_clientSessionId] - 当前用户的会话 ID。
   */
  constructor(
    context: RTCContext,
    runtime: IRuntime,
    roomId: string,
    service: RCMediaService,
    initOptions: IRCRTCInitOptions,
    private _livingType : RCLivingType,
    isUpgrade?: boolean,
    isMainRoom?: boolean,
    _clientSessionId?: string,
  ) {
    super(context, runtime, roomId, RTCMode.LIVE, service, initOptions, isUpgrade, isMainRoom, _clientSessionId);

    // 初始化 MCUBuilder
    this._mcuConfigBuilder = new RCMCUConfigBuilder(
      this._executeCtx,
      this._invoker,
      this._isValidResourceId.bind(this),
    );
  }

  private _initRoomPKHandler(data: IJoinRTCRoomData) {
    // 初始化 RCLivingPKHandler
    if (this._store.isMainRoom) {
      const PKInfo = getPKInfoByRoomData(this._roomId, data.roomInfo);
      this._roomPKHandler = new RCLivingPKHandler(
        this._invoker,
        PKInfo,
        this._context,
        this._runtime,
        this._service,
        this._initOptions,
        this,
        super._registerPKMsgListener.bind(this),
        this._onJoinedPKRoom.bind(this),
        this._clientSessionId,
      );
    }
  }

  /**
   * @override
   */
  async __innerInit(mode: RTCMode, joinType?: RTCJoinType, livingType?: RCLivingType, innerUserDatas?: IRTCUserData, outerUserDatas?: IRTCUserData, traceId?: string): Promise<{ code: RCRTCCode | ErrorCode, data?: IJoinRTCRoomData }> {
    if (!(validate('livingType', livingType, (value) => value === RCLivingType.AUDIO || value === RCLivingType.VIDEO))) {
      this._logger.error(RCLoggerTag.L_RTC_CLIENT_JOIN_LIVING_ROOM_R, JSON.stringify({
        status: RCLoggerStatus.FAILED,
        code: RCRTCCode.PARAMS_ERROR,
        msg: 'params error -> livingType',
      }), traceId);
      return { code: RCRTCCode.PARAMS_ERROR };
    }
    const { code, data } = await super.__innerInit(mode, joinType, livingType, innerUserDatas, outerUserDatas, traceId);
    if (code === RCRTCCode.SUCCESS && data) {
      this._initRoomPKHandler(data);
    }
    return { code, data };
  }

  async __innerInitByIdentityChange(traceId: string): Promise<ErrorCode> {
    const { code, data } = await this._invoker.push(new RTCIdentityChangeCommand(this._livingType, traceId));
    if (code === ErrorCode.SUCCESS && data) {
      this._initRoomPKHandler(data);
      this._initWithRoomData(data.offlineKickTime);

      this._logger.info(RCLoggerTag.L_RTC_CLIENT_UPGRADE_TO_ANCHOR_ROOM_R, JSON.stringify({
        status: RCLoggerStatus.SUCCESSED,
        roomData: data,
      }), traceId);
    } else {
      this._logger.error(RCLoggerTag.L_RTC_CLIENT_UPGRADE_TO_ANCHOR_ROOM_R, JSON.stringify({
        status: RCLoggerStatus.FAILED,
        code,
      }), traceId);
    }
    return code;
  }

  /**
   * 增量发布资源，若发布的资源 tag 及媒体类型重复，后者将覆盖前者进行发布。
   * @param tracks 待发布的 RCLocalTrack 实例
   * @returns
   */
  async publish(tracks: (RCLocalTrack | IPublishAttrs)[]): Promise<IPubSuccessRes> {
    if (!this._store.isMainRoom) {
      this._logger.error(RCLoggerTag.L_ABSTRACT_ROOM_PUBLISH_R, JSON.stringify({
        status: RCLoggerStatus.FAILED,
        code: RCRTCCode.THE_FUNCTION_IS_DISABLED_IN_PKROOM,
        msg: 'method not available in PK room',
      }));
      return { code: RCRTCCode.THE_FUNCTION_IS_DISABLED_IN_PKROOM };
    }
    return super.publish(tracks);
  }

  async unpublish(tracks: RCLocalTrack[]): Promise<IPubSuccessRes> {
    if (!this._store.isMainRoom) {
      this._logger.error(RCLoggerTag.L_ABSTRACT_ROOM_UNPUBLISH_R, JSON.stringify({
        status: RCLoggerStatus.FAILED,
        code: RCRTCCode.THE_FUNCTION_IS_DISABLED_IN_PKROOM,
        msg: 'method not available in PK room',
      }));
      return { code: RCRTCCode.THE_FUNCTION_IS_DISABLED_IN_PKROOM };
    }
    return super.unpublish(tracks);
  }

  /**
   * resourceId 有效性验证
   * @param resourceId
   */
  protected _isValidResourceId(resourceId: string): boolean {
    const { userId } = parseTrackId(resourceId);
    // 是否是主房间资源
    const isHostRoomResource = !!this._store.getResourcesByUserId(userId)?.find((item) => getTrackId(item) === resourceId);

    // 是否是副房间资源
    let isPKRoomResource = false;

    /**
     * 无副房间时，只验证是否为主房间资源
     */
    const { code, roomPKHandler } = this.getRoomPKHandler();
    if (code !== RCRTCCode.SUCCESS || !roomPKHandler) {
      return isHostRoomResource;
    }

    /**
     * 有副房间时，需验证是否为主房间或副房间资源
     */
    const joinedPKRooms = roomPKHandler.getJoinedPKRooms();
    const PKRoomRemoteTracks: RCRemoteTrack[] = [];
    Object.values(joinedPKRooms).forEach((room) => {
      PKRoomRemoteTracks.push(...room.getRemoteTracks());
    });

    isPKRoomResource = PKRoomRemoteTracks.some((track) => resourceId === track.getTrackId());
    return isHostRoomResource || isPKRoomResource;
  }

  public getLivingType(): RCLivingType {
    return this._livingType;
  }

  /**
   * 获取 MCU 配置构建对象
   */
  public getMCUConfigBuilder(): RCMCUConfigBuilder | { code: RCRTCCode } {
    /**
     * 副房间不可调用
     */
    if (!this._store.isMainRoom) {
      this._logger.error(RCLoggerTag.L_LIVING_ROOM_GET_MCU_CONFIG_BUILDER_O, 'the `getMCUConfigBuilder` is disabled in PK room');
      return { code: RCRTCCode.THE_FUNCTION_IS_DISABLED_IN_PKROOM };
    }
    return this._mcuConfigBuilder;
  }

  /**
   * 开启/停用推 CDN
   */
  public async enableInnerCDN(enable: boolean): Promise<{ code: RCRTCCode }> {
    return this._invoker.push(new EnableInnerCDNCommand(this._mcuConfigBuilder.__innerGetValues(), enable));
  }

  /**
   * 资源变化时触发
   * 直播房间需单独处理 cdn_uris
   */
  protected async _resourceHandle(
    // eslint-disable-next-line camelcase
    content: ResourceMsgContent & { cdn_uris?: ICDNUris[] },
    messageType: RCRTCMessageType.PUBLISH | RCRTCMessageType.UNPUBLISH | RCRTCMessageType.MODIFY | RCRTCMessageType.TOTAL_CONTENT_RESOURCE,
    userId: string,
    sentTime: number,
    traceId: string,
  ) {
    super._resourceHandle(content, messageType, userId, sentTime, traceId);
  }

  /**
   * 返回 CDN 是否可用
   * @returns boolean
   */
  public __getCDNEnable(): boolean {
    return !!this._store.getCDNUris()?.enableInnerCDN;
  }

  /**
   * 返回 CDN 推送模式: 自动 or 手动
   */
  public __getCDNPushMode(): RCInnerCDNPushMode | undefined {
    return this._store.getCDNUris()?.push_mode;
  }

  /**
   * @override
   * 加入 PK 房间回调
   */
  private async _onJoinedPKRoom(roomId: string, traceId: string) {
    /**
     * 加入副房间之后，如果己方发布过资源，且参与连麦，
     * 需携带 pushOtherRooms 与 mediaServer 重新交互
     */
    this._invoker.push(new ExchangeWithPushOtherRoomCommand(roomId, this._roomPKHandler, traceId));
  }

  /**
   * @override
   */
  protected _getPushOtherRoomsParams(): IPushOtherRooms[] {
    const pushOtherRooms: IPushOtherRooms[] = [];

    if (!this.isMainRoom() || !this._roomPKHandler) {
      return pushOtherRooms;
    }

    const joinedPKRooms = this._roomPKHandler.getJoinedPKRooms();
    for (const roomId in joinedPKRooms) {
      const room = joinedPKRooms[roomId];
      const sessionId = room.getSessionId();
      /**
       * 直接加入 PK 房间，无连麦关系时，无 PKInfo 信息
       */
      const pkInfo = this._roomPKHandler.getPKInfo(roomId);
      if (!pkInfo) {
        continue;
      }

      const { inviterUserAutoMix, inviteeUserAutoMix, inviterUserId } = pkInfo;
      const isInviter = (this._context.getCurrentId() === inviterUserId);

      pushOtherRooms.push({
        roomId,
        sessionId,
        autoMix: isInviter ? !!inviterUserAutoMix : !!inviteeUserAutoMix,
      });
    }
    return pushOtherRooms;
  }

  /**
   * 获取 PK 业务处理器
   * @since version 5.3.0
   */
  public getRoomPKHandler(): {code: RCRTCCode, roomPKHandler?: RCLivingPKHandler} {
    /**
     * 副房间不可调用
     */
    if (!this._store.isMainRoom) {
      this._logger.error(RCLoggerTag.L_LIVING_ROOM_GET_ROOM_PK_HANDLER_O, JSON.stringify({
        status: RCLoggerStatus.SUCCESSED,
        code: RCRTCCode.THE_FUNCTION_IS_DISABLED_IN_PKROOM,
        msg: 'method not available in PK room',
      }));

      return {
        code: RCRTCCode.THE_FUNCTION_IS_DISABLED_IN_PKROOM,
      };
    }

    this._logger.info(RCLoggerTag.L_LIVING_ROOM_GET_ROOM_PK_HANDLER_O, JSON.stringify({
      status: RCLoggerStatus.SUCCESSED,
    }));

    return {
      code: RCRTCCode.SUCCESS,
      roomPKHandler: this._roomPKHandler,
    };
  }

  /**
   * 退出所有连麦房间
   */
  protected async _quitAllPKRoom() {
    const PKRooms = this._roomPKHandler.getJoinedPKRooms();
    for (const roomId in PKRooms) {
      this._roomPKHandler.leaveOtherRoom(PKRooms[roomId]);
    }
    this._logger.info(RCLoggerTag.L_LIVING_ROOM_QUIT_ALL_PK_ROOM_O, JSON.stringify({
      status: RCLoggerStatus.SUCCESSED,
      roomIds: Object.keys(PKRooms),
    }));
  }

  /**
   * 是否是主房间
   */
  public isMainRoom() {
    return this._store.isMainRoom;
  }
}
