import {
  ErrorCode, IAsyncRes, IPromiseResult, KVString, RTCPluginContext, ConversationType,
  ISendMsgOptions, IReceivedMessage, IRuntime, HttpMethod, BasicLogger, RCConnectionStatus, Codec,
} from '@rongcloud/engine';
import { int64ToTimestamp } from '../../helper';
import { RTCMode } from '../enums/RTCMode';
import { RTCJoinType } from '../enums/RTCJoinType';
import { RTCApiType } from '../enums/inner/RTCApiType';
import { RTCIdentityChangeType } from '../enums/inner/RTCIdentityChangeType';
import {
  IChrmKVPullData, IPullRTCRoomStatus, IServerRTCKVList, IRCRTCReportData,
} from '../interfaces';
import { RTCKeyMaps, RTCPB, RTC_API } from './proto';
import { decodeRtcUserListOutput, encodeRtcInput, encodeRtcHttp3Report } from './rtc-helper';
import {
  IRTCUserData, IJoinRTCRoomData, IRTCRoomInfo, IRTCUsers, IRtcTokenData, IServerRTCRoomEntry,
  IReqRoomPKOptions, ICancelRoomPKOptions, IResRoomPKOptions, IEndRoomPKOptions, IRTCJoinedInfo, IRTCNaviInfo,
} from './interface';
import { RCRTCCode } from '../enums/RCRTCCode';
import { RCMediaService } from '../service';
import { RCSendCode } from '../enums/RCPolarisReporter';

export class RTCContext {
  // 房间创建的时间，由服务器返回
  public roomCreateTime: number | undefined = 0

  // 当前用户加入房间的时间，由服务器返回
  public userJoinTime: number | undefined = 0

  constructor(
    private context: RTCPluginContext,
    public logger: BasicLogger,
    private codec: Codec<RTCKeyMaps>,
  ) {}

  async joinRTCRoom(roomId: string, mode: RTCMode, broadcastType?: number, joinType?: RTCJoinType, innerUserDatas?: IRTCUserData, outerUserDatas?: IRTCUserData, supportNtf?: boolean): IPromiseResult<IJoinRTCRoomData> {
    const sourceData = encodeRtcInput(this.codec, mode, broadcastType, joinType, innerUserDatas, outerUserDatas, supportNtf);
    const { code, buffer } = await this.context.rtcSignaling(roomId, RTC_API.rtcRJoin_data, true, sourceData);
    let data;
    if (code === ErrorCode.SUCCESS && buffer) {
      data = decodeRtcUserListOutput(this.codec, buffer);
      const {
        users, token, sessionId, roomInfo, kvEntries, offlineKickTime, roomCreateTime, userJoinTime, version,
      } = data;
      this.roomCreateTime = roomCreateTime;
      this.userJoinTime = userJoinTime;
      return {
        code,
        data: {
          users, token, sessionId, roomInfo, kvEntries, offlineKickTime, version,
        },
      };
    }
    return { code, data };
  }

  async quitRTCRoom(roomId: string): Promise<ErrorCode> {
    const sourceData = this.codec.encode(RTCPB.SetUserStatusInput, { status: 0 });
    const { code } = await this.context.rtcSignaling(roomId, RTC_API.rtcRExit, true, sourceData);
    return code;
  }

  async getRTCRoomInfo(roomId: string): IPromiseResult<IRTCRoomInfo> {
    const sourceData = this.codec.encode(RTCPB.RtcQueryListInput, { order: 2 });
    const { code, buffer } = <{code: ErrorCode, buffer?:Uint8Array}> await this.context.rtcSignaling(roomId, RTC_API.rtcRInfo, true, sourceData);
    let data;
    if (code === ErrorCode.SUCCESS && buffer) {
      data = this.codec.decode(RTCPB.RtcRoomInfoOutput, buffer);
    }
    return { code, data };
  }

  /**
   * 移动端用来获取副房间资源
   */
  async getRTCUserInfoList(roomId: string): IPromiseResult<IRTCUsers> {
    // TODO: 确认使用场景
    const sourceData = this.codec.encode(RTCPB.RtcQueryListInput, { order: 2 });
    const { code, buffer } = await this.context.rtcSignaling(roomId, RTC_API.rtcUData, true, sourceData);
    let data: IRTCUsers | undefined;
    if (code === ErrorCode.SUCCESS && buffer) {
      const res = decodeRtcUserListOutput(this.codec, buffer);
      data = { users: res.users };
    }
    return { code, data };
  }

  getRTCUserInfo(roomId: string): IPromiseResult<unknown> {
    // 确定是否有使用到，没有使用即删除
    throw new Error('Method not implemented.');
  }

  async removeRTCUserInfo(roomId: string, keys: string[]): Promise<ErrorCode> {
    const sourceData = this.codec.encode(RTCPB.RtcKeyDeleteInput, { key: keys });
    const { code } = await this.context.rtcSignaling(roomId, RTC_API.rtcUDel, false, sourceData);
    return code;
  }

  async setRTCData(
    roomId: string,
    key: string,
    value: string,
    isInner: boolean,
    apiType: RTCApiType,
    message?: { name: string, content: string },
  ): Promise<ErrorCode> {
    const sourceData = this.codec.encode(RTCPB.RtcSetDataInput, {
      interior: isInner,
      target: apiType,
      key,
      value,
      objectName: message?.name,
      content: message?.content,
    });
    const { code } = await this.context.rtcSignaling(roomId, RTC_API.rtcSetData, false, sourceData);
    return code;
  }

  /**
   * 全量订阅资源修改
   * @param roomId 房间 Id
   * @param message 向前兼容的消息内容
   * @param valueInfo 全量资源数据
   * @param objectName 全量 URI 消息名
   */
  async setRTCTotalRes(roomId: string, messageList: { name: string, content: string }[], valueInfo: string, objectName: string, mcuValInfo?: string, cdnValInfo?: string): Promise<ErrorCode> {
    const params: { objectName: string, content: ArrayBuffer[], valueInfo: ArrayBuffer[] } = {
      objectName,
      content: [],
      valueInfo: [
        this.codec.encode(RTCPB.RtcValueInfo, { key: 'uris', value: valueInfo }, true),
      ],
    };

    messageList.forEach((message) => {
      const encodeData = this.codec.encode(RTCPB.RtcValueInfo, { key: message.name, value: message.content }, true);
      params.content.push(encodeData);
    });

    mcuValInfo && params.valueInfo.push(this.codec.encode(RTCPB.RtcValueInfo, { key: 'mcu_uris', value: mcuValInfo }, true));
    cdnValInfo && params.valueInfo.push(this.codec.encode(RTCPB.RtcValueInfo, { key: 'cdn_uris', value: cdnValInfo }, true));

    const sourceData = this.codec.encode(RTCPB.RtcUserSetDataInput, params);
    const { code } = await this.context.rtcSignaling(roomId, RTC_API.userSetData, true, sourceData);
    return code;
  }

  async setRTCCDNUris(roomId: string, objectName: string, CDNUris: string): Promise<ErrorCode> {
    const sourceData = this.codec.encode(RTCPB.RtcUserSetDataInput, {
      objectName,
      valueInfo: this.codec.encode(RTCPB.RtcValueInfo, { key: 'cdn_uris', value: CDNUris }, true),
    });
    const { code } = await this.context.rtcSignaling(roomId, RTC_API.userSetData, true, sourceData);
    return code;
  }

  async getRTCData(roomId: string, keys: string[], isInner: boolean, apiType: RTCApiType): IPromiseResult<KVString> {
    const sourceData = this.codec.encode(RTCPB.RtcDataInput, { interior: isInner, target: apiType, key: keys });
    const { code, buffer } = <{code: ErrorCode, buffer?:Uint8Array}> await this.context.rtcSignaling(roomId, RTC_API.rtcQryData, true, sourceData);
    if (code !== ErrorCode.SUCCESS || !buffer) {
      return { code };
    }

    const { outInfo } = this.codec.decode(RTCPB.RtcQryOutput, buffer);
    const data: KVString = {};
    outInfo.forEach((item: any) => {
      data[item.key] = item.value;
    });
    return { code, data };
  }

  async removeRTCData(roomId: string, keys: string[], isInner: boolean, apiType: RTCApiType, message?: { name: string, content: string }): Promise<ErrorCode> {
    const sourceData = this.codec.encode(RTCPB.RtcDataInput, {
      interior: isInner,
      target: apiType,
      key: keys,
      objectName: message?.name || '',
      content: message?.content || '',
    });
    const { code } = await this.context.rtcSignaling(roomId, RTC_API.rtcDelData, false, sourceData);
    return code;
  }

  async setRTCOutData(roomId: string, rtcData: unknown, type: number, message: unknown): Promise<ErrorCode> {
    // TODO: 确认使用场景，是否有必要保留此方法
    throw new Error('JSEngine\'s method not implemented.');
  }

  async getRTCOutData(roomId: string, userIds: string[]): IPromiseResult<unknown> {
    // TODO: 确认使用场景，是否有必要保留此方法
    throw new Error('JSEngine\'s method not implemented.');
  }

  async getRTCToken(roomId: string, mode: number, broadcastType?: number): IPromiseResult<IRtcTokenData> {
    const sourceData = encodeRtcInput(this.codec, mode, broadcastType);
    const { code, buffer } = <{code: ErrorCode, buffer?:Uint8Array}> await this.context.rtcSignaling(roomId, RTC_API.rtcToken, true, sourceData);
    if (code !== ErrorCode.SUCCESS || !buffer) {
      return { code };
    }
    const { rtcToken } = this.codec.decode(RTCPB.RtcTokenOutput, buffer);
    return { code, data: { rtcToken } };
  }

  async setRTCState(roomId: string, report: string): Promise<ErrorCode> {
    const sourceData = this.codec.encode(RTCPB.MCFollowInput, { state: report });
    const { code } = await this.context.rtcSignaling(roomId, RTC_API.rtcUserState, true, sourceData);
    return code;
  }

  /**
   * 通过 http 方式上报 rtc state
  */
  async setRTCStateByHttp(logServer: string, runtime: IRuntime, report: IRCRTCReportData, reportType: string): Promise<RCSendCode | RCRTCCode> {
    const sourceData = encodeRtcHttp3Report(this.codec, report, reportType);
    let logServerDomain = logServer;
    if (!/^https:\/\//.test(logServer)) {
      logServerDomain = `https://${logServer}`;
    }
    try {
      const res = await runtime.httpReq({
        url: `${logServerDomain}/rtc/logserver/polaris`,
        body: sourceData,
        method: HttpMethod.POST,
        headers: {
          jwt: RCMediaService.jwtToken,
        },
        timeout: 2 * 1000,
      });
      if (!res.data) {
        throw new Error('http request fail');
      }
      let status = JSON.parse(res.data).resultCode;
      return status;
    } catch (error) {
      return RCSendCode.REPORT_FAIL;
    }
  }

  async getRTCUserList(roomId: string): IPromiseResult<IRTCUsers> {
    const sourceData = this.codec.encode(RTCPB.RtcQueryListInput, { order: 2 });
    const { code, buffer } = await this.context.rtcSignaling(roomId, RTC_API.rtcUList, true, sourceData);
    if (code !== ErrorCode.SUCCESS || !buffer) {
      return { code };
    }
    const resp = decodeRtcUserListOutput(this.codec, buffer);
    return { code, data: resp };
  }

  async joinLivingRoomAsAudience(roomId: string, mode: RTCMode, broadcastType?: number): Promise<IAsyncRes<{token: string, kvEntries: IServerRTCRoomEntry[]}>> {
    const sourceData = encodeRtcInput(this.codec, mode, broadcastType);
    const { code, buffer } = <{code: ErrorCode, buffer?:Uint8Array}> await this.context.rtcSignaling(roomId, RTC_API.viewerJoinR, true, sourceData);
    if (code !== ErrorCode.SUCCESS || !buffer) {
      return { code };
    }
    const {
      rtcToken, entries, roomCreateTime, userJoinTime,
    } = this.codec.decode(RTCPB.RtcViewerJoinedOutput, buffer);
    this.roomCreateTime = int64ToTimestamp(roomCreateTime);
    this.userJoinTime = int64ToTimestamp(userJoinTime);
    return { code, data: { token: rtcToken, kvEntries: entries } };
  }

  async quitLivingRoomAsAudience(roomId: string): Promise<ErrorCode> {
    const sourceData = this.codec.encode(RTCPB.SetUserStatusInput, { status: 0 });
    const { code } = await this.context.rtcSignaling(roomId, RTC_API.viewerExitR, true, sourceData);
    return code;
  }

  async rtcIdentityChange(roomId: string, changeType: RTCIdentityChangeType, broadcastType?: number): Promise<IAsyncRes<IJoinRTCRoomData>> {
    const sourceData = this.codec.encode(RTCPB.RtcInput, {
      roomType: RTCMode.LIVE,
      broadcastType,
      identityChangeType: changeType,
      needSysChatroom: false,
    });
    const { code, buffer } = await this.context.rtcSignaling(roomId, RTC_API.rtcIdentityChange, true, sourceData);
    if (code !== ErrorCode.SUCCESS || !buffer) {
      return { code };
    }
    const data = decodeRtcUserListOutput(this.codec, buffer);
    return { code, data };
  }

  async requestRoomPK(options: IReqRoomPKOptions): Promise<ErrorCode> {
    const {
      invitedRoomId, invitedUserId, inviteSessionId, inviteTimeout, inviteInfo, roomId,
    } = options;
    const sourceData = this.codec.encode(RTCPB.RtcInviteInput, {
      invitedRoomId,
      invitedUserId,
      inviteSessionId,
      timeoutTime: inviteTimeout,
      inviteInfo,
    });
    const { code } = await this.context.rtcSignaling(roomId, RTC_API.rtcInvite, true, sourceData);
    return code;
  }

  async cancelRoomPK(options: ICancelRoomPKOptions): Promise<ErrorCode> {
    const {
      invitedRoomId, invitedUserId, inviteSessionId, inviteInfo, roomId,
    } = options;
    const sourceData = this.codec.encode(RTCPB.RtcCancelInviteInput, {
      invitedRoomId, invitedUserId, inviteSessionId, inviteInfo,
    });
    const { code } = await this.context.rtcSignaling(roomId, RTC_API.rtcCancelInvite, true, sourceData);
    return code;
  }

  async responseRoomPK(options: IResRoomPKOptions): Promise<ErrorCode> {
    const {
      inviteUserId, inviteRoomId, inviteSessionId, content, key, value, agree, roomId,
    } = options;
    const sourceData = this.codec.encode(RTCPB.RtcInviteAnswerInput, {
      inviteUserId,
      inviteRoomId,
      inviteSessionId,
      content,
      key,
      value,
      answerCode: agree ? 1 : 0,
    });
    const { code } = await this.context.rtcSignaling(roomId, RTC_API.rtcInviteAnswer, true, sourceData);
    return code;
  }

  async endRoomPK(options: IEndRoomPKOptions): Promise<ErrorCode> {
    const {
      endRoomId, sessionId, content, keys, roomId,
    } = options;
    const sourceData = this.codec.encode(RTCPB.RtcEndInviteInput, {
      inviteRoomId: endRoomId,
      inviteSessionId: sessionId,
      inviteContent: content,
      inviteRoomKeys: keys,
    });
    const { code } = await this.context.rtcSignaling(roomId, RTC_API.rtcEndInvite, true, sourceData);
    return code;
  }

  async getRTCJoinedUserInfo(userId: string): Promise<IAsyncRes<IRTCJoinedInfo[]>> {
    const sourceData = this.codec.encode(RTCPB.RtcQueryUserJoinedInput, { userId });
    const { code, buffer } = <{code: ErrorCode, buffer?:Uint8Array}> await this.context.rtcSignaling('', RTC_API.rtcQueryJoined, true, sourceData);
    if (code !== ErrorCode.SUCCESS || !buffer) {
      return { code };
    }
    const info: IRTCJoinedInfo[] = this.codec.decode(RTCPB.RtcQueryUserJoinedOutput, buffer).info || [];
    return {
      code,
      data: info.map((item) => ({
        deviceId: item.deviceId,
        roomId: item.roomId,
        joinTime: int64ToTimestamp(item.joinTime),
      })),
    };
  }

  async pullRTCRoomEntry(roomId: string): Promise<IAsyncRes<IChrmKVPullData>> {
    // 不适用增量拉取，直接全量拉取 KV，timestamp 为 0
    const sourceData = this.codec.encode(RTCPB.RtcPullKV, { timestamp: 0, roomId });
    const { code, buffer } = <{code: ErrorCode, buffer?:Uint8Array}> await this.context.rtcSignaling(roomId, RTC_API.rtcPullKv, true, sourceData);
    if (code !== ErrorCode.SUCCESS) {
      return { code };
    }

    const data: IServerRTCKVList = this.codec.decode(RTCPB.RtcKVOutput, buffer!);
    let { entries: kvEntries, syncTime } = data;
    kvEntries = (kvEntries || []).map((entry: IServerRTCRoomEntry) => ({ ...entry, timestamp: int64ToTimestamp(entry.timestamp) }));
    return {
      code,
      data: {
        kvEntries,
        syncTime: int64ToTimestamp(syncTime),
      },
    };
  }

  /**
   * 通知拉取房间数据
   * @param roomId 房间 id
   * @param version 本地最大得房间数据版本号
   */
  async pullRTCRoomStatus(roomId: string, version: number): Promise<{code: ErrorCode, data?: IPullRTCRoomStatus}> {
    const sourceData = this.codec.encode(RTCPB.RtcRoomStatusInput, { version });
    const { code, buffer } = await this.context.rtcSignaling(roomId, RTC_API.rtcPullRoomStatus, true, sourceData);
    if (code !== ErrorCode.SUCCESS) {
      return { code };
    }
    const data: IPullRTCRoomStatus = this.codec.decode(RTCPB.RtcRoomStatusOutput, buffer!);
    return {
      code,
      data,
    };
  }

  decodeRtcNotify(buffer: Uint8Array) {
    const { time, type, roomId } = this.codec.decode(RTCPB.RtcNotifyMsg, buffer);
    return { time: int64ToTimestamp(time), type, roomId };
  }

  getCurrentId() {
    return this.context.getCurrentId();
  }

  getNaviInfo(): IRTCNaviInfo | null {
    return this.context.getNaviInfo();
  }

  getConnectionStatus() {
    return this.context.getConnectionStatus();
  }

  getAppkey() {
    return this.context.getAppkey();
  }

  /** web 端发 rtcPing */
  async webRtcPing(roomId: string, roomMode: RTCMode, broadcastType?: number): Promise<{code: ErrorCode, data?: { version: number }}> {
    const sourceData = this.codec.encode(RTCPB.RtcInput, { roomType: roomMode, broadcastType });
    const { code, buffer } = await this.context.rtcSignaling(roomId, RTC_API.rtcPing, true, sourceData);
    if (code !== ErrorCode.SUCCESS) {
      return { code };
    }
    const data = this.codec.decode(RTCPB.RtcOutput, buffer!);
    data.version = int64ToTimestamp(data.version);
    return { code, data };
  }

  /**
   * 协议栈 rtcping 依赖 imlib 编解码数据
   * web 单独走 imlib 提供的 rtcSignaling 方法，减少对 imlib 的依赖
   */
  rtcPing(roomId: string, roomMode: RTCMode, broadcastType?: number) {
    const isElectron = /Electron/.test(navigator.userAgent);
    if (isElectron) {
      return this.context.rtcPingResVersion(roomId, roomMode, broadcastType);
    }

    return this.webRtcPing(roomId, roomMode, broadcastType);
  }

  sendMessage(conversationType: ConversationType, targetId: string, options: ISendMsgOptions): IPromiseResult<IReceivedMessage> {
    return this.context.sendMessage(conversationType, targetId, options);
  }

  registerRTCSignalListener(listener?: ((buffer: Uint8Array) => void) | undefined): void {
    this.context.registerRTCSignalListener(listener);
  }

  registerConnectionStateChangeListener(listener: (status: RCConnectionStatus, code: ErrorCode) => void) {
    this.context.onconnectionstatechange = listener;
  }

  registerDisconnectListener(listener: () => void) {
    this.context.ondisconnect = listener;
  }

  registerDestroyListener(listener: () => void) {
    this.context.ondestroy = listener;
  }

  registerMessageListener(listener: (message: IReceivedMessage) => boolean) {
    this.context.onmessage = listener;
  }

  getCoreVersion() {
    return this.context.getCoreVersion();
  }

  getPluginContext(): RTCPluginContext {
    return this.context;
  }

  // 上报SDK信息
  reportSDKInfo(versionInfo: {[name: string]: string}): void {
    const sourceData = this.codec.encode(RTCPB.RtcReportSDKInput, { sdkInfo: JSON.stringify(versionInfo) });
    this.context.rtcSignaling('', RTC_API.reportsdk, true, sourceData);
  }
}
