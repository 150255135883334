import { parseTrackId } from '../../../helper';
import { RCMediaType } from '../../enums/RCMediaType';
import { RCRTCCode } from '../../enums/RCRTCCode';
import { RCLocalTrack } from '../../tracks/RCLocalTrack';
import { RCRemoteTrack } from '../../tracks/RCRemoteTrack';
import { RCTrackKind } from '../../tracks/RCTrack';
import {
  ASdpStrategy, IOfferInfo, RtpTransceiverDirection,
} from './ASdpStrategy';
import UnifiedPlanSdpBuilder from './UnifiedPlanSdpBuilder';
import SDPUtils from './SDPUtils';
import { RCLoggerTag } from '../../enums/RCLoggerTag';
import SdpResource from '../SdpResource';

const clearSSRC = (mLine:string): string => {
  const bool = /a=(recvonly|inactive)/.test(mLine);
  if (!bool) {
    return mLine;
  }
  const str = mLine.replace(/\r\na=(ssrc|msid)[^\r\n]+/g, '');
  return str;
};

export class UnifiedPlanStrategy extends ASdpStrategy {
  private _sendTransceiver: { [trackId: string]: RTCRtpTransceiver } = {}

  setBitrate(max: number, min: number, start?: number): void {
    this._logger.warn(RCLoggerTag.L_FUNCTION_IS_UNAVAILABLE, 'the interface named `setBitrate` is invalid while sdpSemantics value is `unified-plan`');
  }

  private _localTracks: { [trackId: string]: RCLocalTrack } = {}

  public getTrackIdByMid(mid: string): string | null {
    for (let trackId in this._sendTransceiver) {
      const transceiver = this._sendTransceiver[trackId];
      if (transceiver.mid === mid) {
        return trackId;
      }
    }
    for (let trackId in this._recvTransceiver) {
      const transceiver = this._recvTransceiver[trackId];
      if (transceiver.mid === mid) {
        return trackId;
      }
    }
    return null;
  }

  /**
   * 该函数用于向对等连接添加本地轨道
   * @param {RCLocalTrack} track - RCLocalTrack
   */
  addLocalTrack(track: RCLocalTrack): void {
    const trackId: string = track.getTrackId();
    const msid = track.getStreamId();
    const msTrack = track.__innerGetMediaStreamTrack()!;

    this._localTracks[trackId] = track;

    // 复用 stream 避免多次重复初始化
    const stream = this._outboundStreams[msid] || (this._outboundStreams[msid] = new MediaStream());

    // 清理同类型轨道数据
    stream.getTracks().forEach((track) => {
      track.kind === msTrack.kind && stream.removeTrack(track);
    });
    stream.addTrack(msTrack);

    const transceiver = this._sendTransceiver[trackId];
    if (transceiver) {
      transceiver.sender.replaceTrack(msTrack);
      transceiver.direction = 'sendonly';
    } else {
      this._sendTransceiver[trackId] = this._peer.addTransceiver(msTrack, { direction: 'sendonly', streams: [stream] });
    }
    const SingleInstance = SdpResource.getInstance();

    SingleInstance.push(trackId, { msid, id: this._outboundStreams[msid].id, kind: track.streamTrack?.kind });
  }

  /**
   * 该函数从对等连接中删除本地轨道
   * @param {RCLocalTrack} track - RCLocalTrack
   * @returns 返回值是函数中执行的最后一条语句返回的值。
   */
  removeLocalTrack(track: RCLocalTrack): void {
    const trackId: string = track.getTrackId();
    const msid = track.getStreamId();

    delete this._localTracks[trackId];

    const transceiver = this._sendTransceiver[trackId];
    if (!transceiver) {
      return;
    }

    if (this._peer.connectionState !== 'closed') {
      transceiver.direction = 'inactive';
      this._peer.removeTrack(transceiver.sender);
      // 发送器 replaceTrack 参数为空/null时，则终止 RTP 发送器
      transceiver.sender.replaceTrack(null);
    }

    const stream = this._outboundStreams[msid];
    const msTracks = track.isAudioTrack() ? stream.getAudioTracks() : stream.getVideoTracks();
    msTracks.forEach((item) => stream.removeTrack(item));

    // 尝试移除小流并销毁
    const tinyTransceiver = this._sendTransceiver[`${trackId}_tiny`];
    if (!tinyTransceiver || (tinyTransceiver.direction === 'inactive')) {
      return;
    }

    if (this._peer.connectionState !== 'closed') {
      tinyTransceiver.direction = 'inactive';
      const { sender } = tinyTransceiver;
      const tinyTrack = sender.track!;
      this._peer.removeTrack(sender);
      // 发送器 replaceTrack 参数为空/null时，则终止 RTP 发送器
      sender.replaceTrack(null);

      const tinyStream = this._outboundStreams[`${msid}_tiny`];
      tinyStream.removeTrack(tinyTrack);
      tinyTrack.stop();
    }
  }

  private _recvAudio: RTCRtpTransceiver[] = []

  private _recvVideo: RTCRtpTransceiver[] = []

  // 当前的 mid 与 trackId 的匹配关系
  private _recvTransceiver: { [trackId: string]: RTCRtpTransceiver } = {}

  private _subedTracks: RCRemoteTrack[] = []

  /**
   * 更新trackId和收发器的映射关系，从候选列表中删除收发器
   * @param {string} trackId - 要添加的轨道的 trackId。
   * @param {RTCRtpTransceiver} transceiver - RTCRtp收发器
   */
  updateRecvTransceiverMap(trackId: string, transceiver: RTCRtpTransceiver) {
    const { mediaType } = parseTrackId(trackId);
    // 更新映射关系
    this._recvTransceiver[trackId] = transceiver;
    // 从备选列表中删除 transceiver
    const arrTransceiver = mediaType === RCMediaType.AUDIO_ONLY ? this._recvAudio : this._recvVideo;
    const index = arrTransceiver.findIndex((item) => item === transceiver);
    index >= 0 && arrTransceiver.splice(index, 1);
  }

  /**
   * 该函数用于更新本地客户端订阅的远程轨道
   * @param {RCRemoteTrack[]} tracks - 要更新的远程轨道数组。
   */
  updateSubRemoteTracks(tracks: RCRemoteTrack[]): void {
    // 减法记录新增订阅
    const addTracks = tracks.slice();
    // 备份旧订阅列表，以便于减法记录被移除订阅
    const removeTracks = this._subedTracks.slice();
    // 记录新订阅关系
    this._subedTracks = tracks.slice();

    for (let i = addTracks.length - 1; i >= 0; i -= 1) {
      const track = addTracks[i];
      const index = removeTracks.findIndex((item) => item === track);
      if (index >= 0) {
        // 当前已存在，不属于新增
        addTracks.splice(i, 1);
        // 新列表中仍存在，说明未被删除
        removeTracks.splice(index, 1);
      }
    }

    // 遍历 removeTracks，将对应的 transceiver.direction = 'inactive'
    removeTracks.length && removeTracks.forEach((item) => {
      const trackId = item.getTrackId();
      item.__innerSetMediaStreamTrack(undefined);
      /**
       * peerConnection ontrack 回调参数 RTCTrackEvent 对象中 streams 为 [] 时，服务端无资源，不会添加 transceiver
       * 需兼容无 transceiver 的情况
       */
      const transceiver = this._recvTransceiver[trackId];
      transceiver && (transceiver.direction = 'inactive');
    });

    const addCount = { audio: 0, video: 0 };
    addTracks.length && addTracks.forEach((item) => {
      const kind = item.isAudioTrack() ? 'audio' : 'video';
      // 查找是否有与该 trackId 绑定的 transceiver
      const transceiver: RTCRtpTransceiver = this._recvTransceiver[item.getTrackId()];
      if (transceiver) {
        transceiver.direction = 'recvonly';
        return;
      }
      // 不存在绑定关系的情况下，记录需要新增多少个 transceiver
      addCount[kind] += 1;
    });

    // 新增 transceiver
    for (let i = this._recvAudio.length; i < addCount.audio; i += 1) {
      this._recvAudio.push(this._peer.addTransceiver('audio', { direction: 'recvonly' }));
    }
    for (let i = this._recvVideo.length; i < addCount.video; i += 1) {
      this._recvVideo.push(this._peer.addTransceiver('video', { direction: 'recvonly' }));
    }
  }

  protected midMsid: { [key: string]: string } = {}

  async createOffer(iceRestart: boolean): Promise<IOfferInfo> {
    const offer = await this._peer.createOffer({ iceRestart });
    let sdp = offer.sdp!;

    for (const msid in this._outboundStreams) {
      /**
       * 更改 SDP 描述中的 msid
       * 将 MediaStream id 更改为 msid
       * 例: ca5dffd8-d6b2-489d-9e4d-4814321efd38 更改为 1001_11
       */
      const streamId = this._outboundStreams[msid].id;
      sdp = sdp.replace(new RegExp(streamId, 'g'), msid);
    }
    /**
     * INFO:
     * 1. 发布流不要干预 setLocalDescription 的 Offer，会导致多次发布时 SSRC 不变更
     * 2. 下行流或者取消流 可以将 SDP 中的 ssrc|msid 删除
     */
    sdp = this.resetOfferSdp(sdp);
    // console.log('reset Offer', sdp)
    // 增加 a=ice-options:renomination 行
    sdp = sdp.replace(/(a=ice-options:trickle)/g, '$1\r\na=ice-options:renomination');

    offer.sdp = sdp;

    await this._peer.setLocalDescription(offer);

    return { type: 'offer', semantics: 'unified-plan', sdp };
  }

  setRemoteAnswer(sdp: string): Promise<RCRTCCode> {
    sdp = this.resetAnswerSdp(sdp);
    const reg = /[\r\n]+\r\n[\r\n]+/g;
    if (reg.test(sdp)) {
      // 服务给回的数据可能包含多余的 \r\n，故过滤一下
      sdp = sdp.replace(reg, '\r\n');
    }
    return super.setRemoteAnswer(sdp);
  }

  /**
   * 上行通道中无 mid 时，从 sdp 中截取
   */
  private _genarateMid(sdp: string, msid: string, kind?: string) {
    let mid = null;
    const sections = SDPUtils.getMediaSections(sdp);
    sections.forEach((item) => {
      SDPUtils.parseExtmap(item);
      const { kind: parseKind } = SDPUtils.parseMLine(item);
      let { stream } = SDPUtils.parseMsid(item);
      const parseMid = SDPUtils.getMid(item);
      if (kind === 'video') {
        if (/tiny$/.test(stream)) {
          stream = stream.replace(/tiny$/ig, '1_tiny');
        } else {
          stream += '_1';
        }
      } else {
        stream += '_0';
      }
      // getMid
      if (stream === msid && kind === parseKind && parseMid) {
        mid = parseMid;
      }
    });
    return mid;
  }

  private _setSenderTransceiverBitrate(sdpBuilder: UnifiedPlanSdpBuilder, msid: string, mid: string, kind?: string) {
    const localTrack = this._localTracks[msid];
    const bitrate = localTrack?.getBitrate();
    if (kind === RCTrackKind.AUDIO && bitrate) {
      sdpBuilder.setAudioBitrateWithMid(bitrate, mid);
    }
    if (kind === RCTrackKind.VIDEO && bitrate) {
      sdpBuilder.setVideoBitrateWithMid(bitrate, mid);
    }
  }

  private _resetSenderTransceiver(sdp: string, sdpBuilder: UnifiedPlanSdpBuilder) {
    for (const msid in this._sendTransceiver) {
      const transceiver: RTCRtpTransceiver = this._sendTransceiver[msid];
      const kind = transceiver.sender.track?.kind;

      let { direction, mid } = transceiver;
      if (!mid) {
        mid = this._genarateMid(sdp, msid, kind);
      }
      if (mid) {
        if (direction === RtpTransceiverDirection.SENDONLY) {
          this._setSenderTransceiverBitrate(sdpBuilder, msid, mid, kind);
        }

        if (direction === RtpTransceiverDirection.INACTIVE) {
          sdpBuilder.clearnSsrcWithMid(mid, RtpTransceiverDirection.INACTIVE);
        }
      }
    }
  }

  private _resetRecvTransceiver(sdpBuilder: UnifiedPlanSdpBuilder) {
    for (const msid in this._recvTransceiver) {
      const transceiver: RTCRtpTransceiver = this._recvTransceiver[msid];
      const { direction, mid } = transceiver;
      if (mid) {
        if (direction === RtpTransceiverDirection.INACTIVE) {
          sdpBuilder.clearnSsrcWithMid(mid, RtpTransceiverDirection.RECVONLY);
        }
      }
    }
  }

  // 重置设置 SDP 信息
  protected resetOfferSdp(sdp: string) {
    const sdpBuilder = new UnifiedPlanSdpBuilder(this._logger, sdp);

    this._resetSenderTransceiver(sdp, sdpBuilder);
    this._resetRecvTransceiver(sdpBuilder);

    return sdpBuilder.stringify();
  }

  // Answer SDP 中添加码率信息
  protected resetAnswerSdp(sdp: string): string {
    // return sdp
    const sdpBuilder = new UnifiedPlanSdpBuilder(this._logger, sdp);
    for (const msid in this._sendTransceiver) {
      /**
       * 通过 Sender 获取发布出去的资源
       * 对于已经发布出去的资源设置码率
       */
      const transceiver: RTCRtpTransceiver = this._sendTransceiver[msid];
      const { mid } = transceiver;
      if (mid) {
        const localTrack = this._localTracks[msid];
        const bitrate = localTrack?.getBitrate();
        const kind = transceiver.sender.track?.kind;
        // 对于已经发布出去的资源设置码率
        if (kind === RCTrackKind.AUDIO && bitrate) {
          sdpBuilder.setAudioBitrateWithMid(bitrate, mid);
        }
        if (kind === RCTrackKind.VIDEO && bitrate) {
          sdpBuilder.setVideoBitrateWithMid(bitrate, mid);
        }
      }
    }

    return sdpBuilder.stringify();
  }
}
