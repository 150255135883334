/**
 * 获取 Microphone 列表
 */
const getMicrophones = async () => {
  const mediaDivices = await navigator.mediaDevices.enumerateDevices();
  return mediaDivices.filter((item) => item.kind === 'audioinput');
};

/**
 * 获取摄像头设备列表
 */
const getCameras = async () => {
  const mediaDevices = await navigator.mediaDevices.enumerateDevices();
  return mediaDevices.filter((item) => item.kind === 'videoinput');
};

/**
 * 获取扬声器设备列表
 */
const getSpeakers = async () => {
  const mediaDevices = await navigator.mediaDevices.enumerateDevices();
  return mediaDevices.filter((item) => item.kind === 'audiooutput');
};

export const device = {
  getCameras,
  getMicrophones,
  getSpeakers,
};
