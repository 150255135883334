import {
  BasicLogger,
  ErrorCode, isArray, validate,
} from '@rongcloud/engine';
import { RCRTCCode } from '../enums/RCRTCCode';
import { BaseCommand } from './BaseCommand';
import {
  IPublishAttrs, IPublishedResource, IPubSuccessRes, IPubTaskRes, IVideoProfile,
} from '../interfaces';
import { Store } from '../Store';
import { RCLocalTrack, RCLocalVideoTrack } from '../tracks/RCLocalTrack';
import {
  buildPlusMessage, buildTotalURIMessageContent, calcTracksNum, diffPublishResources, getTrackId,
  getTrackIdFromAttr, isRepeatPub, transFrameRate, transResolution,
} from '../../helper';
import { RCFrameRate } from '../enums/RCFrameRate';
import { RCResolution } from '../enums/RCResolution';
import { createExchangeParams, getSsrcByStreamIdFromSdp } from './helper';
import RCRTCPeerConnection from '../webrtc/RCRTCPeerConnection';
import { RCRTCMessageType } from '../enums/inner/RCRTCMessageType';
import { R2Action } from '../enums/inner/R2Action';
import { R2Status } from '../enums/inner/R2Status';
import { ExchangeCommand } from './ExchangeCommand';
import { Invoker } from '../Invoker';
import { RCLoggerStatus, RCLoggerTag } from '../enums/RCLoggerTag';
import { RCCommandKind } from '../enums/RCCommandKind';
import { RCInnerCDNBroadcast } from '../enums/RCInnerCDNBroadcast';
import { RCMediaType } from '../enums/RCMediaType';
import { IMediaServerQualityData, RCRTCResourceAction } from '../logger/IQualityReportData';
import { CommandExecuteContext } from './CommandExecuteContext';

/**
 * 资源发布命令
 */
export class PublishCommand extends BaseCommand<IPubSuccessRes> {
  constructor(
    private tracks:(RCLocalTrack | IPublishAttrs)[],
  ) {
    super();
  }

  private _traceId!: string

  private _logger!: BasicLogger

  /**
   * 行为开始时间
   */
  private _actionStartTime: number = Date.now()

  /**
   * track 对应的 mediaServer 信息
   */
  private _trackMediaMap: {[trackId: string]: IMediaServerQualityData[] } = {}

  get kind(): RCCommandKind {
    return RCCommandKind.PUBLISH;
  }

  /**
   * 从 pc 移除当次发布失败的资源
   */
  private _removePubFailedTracks(tracks: (RCLocalTrack | IPublishAttrs)[], pc: RCRTCPeerConnection) {
    tracks.forEach((item) => {
      const track = item instanceof RCLocalTrack ? item : item.track;
      this._logger.debug(RCLoggerTag.L_ABSTRACT_ROOM_PUBLISH_P, `remove pub failed track from peerconnection -> trackId: ${track.getTrackId()}`, this._traceId);
      pc.removeLocalTrackById(track.getTrackId());
    });
  }

  /**
   * 在 peerConneciton 上添加 localTrack
   */
  private async _addLocalTracks(tracks: (RCLocalTrack | IPublishAttrs)[], peer: RCRTCPeerConnection) {
    /*
     * 资源发布应先与 mediaserver 交换资源，建 PeerConnection 通道，后通知房间
     * 资源取消发布则应先通知取消发布，后与 mediaServer 协商取消资源发布
     */
    const tinyTracks = [];
    for (const track of tracks) {
      // 向 RTCPeerConnection 添加轨道数据
      const { track: localTrack, pubTiny } = track instanceof RCLocalTrack ? { pubTiny: false, track } : track;
      // 拷贝生成小流并添加至 RTCPeerConnection
      if (localTrack.isVideoTrack()) {
        if (pubTiny) {
          let cloneTrack;
          try {
            cloneTrack = localTrack.__innerGetMediaStreamTrack()!.clone();
            const rcFrameRate: RCFrameRate = (pubTiny as IVideoProfile).frameRate || RCFrameRate.FPS_15;
            const resolution: RCResolution = (pubTiny as IVideoProfile).resolution || RCResolution.W176_H144;
            const { width, height } = transResolution(resolution);
            const frameRate = transFrameRate(rcFrameRate);
            await cloneTrack.applyConstraints({ width, height, frameRate });
          } catch (error) {
            cloneTrack?.stop();
            this._logger.warn(RCLoggerTag.L_ABSTRACT_ROOM_PUBLISH_P, `pubTiny failed -> id: ${localTrack.getTrackId()}, msg: ${(error as Error).message}`, this._traceId);
            break;
          }
          const tinyTrack = new RCLocalVideoTrack(this._logger, localTrack.getTag(), localTrack.getUserId(), cloneTrack, true);
          tinyTracks.push(tinyTrack);
          peer.addLocalTrack(tinyTrack);
        } else {
          // TODO: try to remove prev tiny track
        }
      }
      peer.addLocalTrack(localTrack);
    }
    return tinyTracks;
  }

  private async __publish(executeCtx: CommandExecuteContext, store: Store, tracks: (RCLocalTrack | IPublishAttrs)[], invoker: Invoker): Promise<IPubTaskRes> {
    const { peer, reportMediaActionLogger } = executeCtx;
    const { roomId } = store;
    /**
     * 转化 (RCLocalTrack | IPublishAttrs)[] 为 RCLocalTrack[]，发布失败时抛出 RCLocalTrack[]
     */
    const localTracks: RCLocalTrack[] = [];
    tracks.forEach((track) => {
      const { track: localTrack } = track instanceof RCLocalTrack ? { track } : track;
      localTracks.push(localTrack);
    });

    /**
     * 将发布的视频流按从小到大进行排序
     */
    tracks.sort((item, nextItem) => {
      const { track: localTrack } = item instanceof RCLocalTrack ? { track: item } : item;
      const { max: maxBitrate } = localTrack.getBitrate();
      const { track: nextLocalTrack } = nextItem instanceof RCLocalTrack ? { track: nextItem } : nextItem;
      const { max: maxNextBitrate } = nextLocalTrack.getBitrate();
      return maxBitrate - maxNextBitrate;
    });

    /**
     * 一个 peerConnection 上行不超过 10 个
     */
    const pubedTrackNum = peer.getLocalTracks().length;
    if (pubedTrackNum + calcTracksNum(tracks, peer) > 10) {
      this._logger.error(RCLoggerTag.L_ABSTRACT_ROOM_PUBLISH_P, JSON.stringify({
        status: RCLoggerStatus.FAILED,
        code: RCRTCCode.PUBLISH_TRACK_LIMIT_EXCEEDED,
        trackIds: tracks.map(getTrackIdFromAttr),
        msg: 'publish track limit exceeded',
      }), this._traceId);
      return { code: RCRTCCode.PUBLISH_TRACK_LIMIT_EXCEEDED, tracks: localTracks };
    }

    this._logger.info(RCLoggerTag.L_ABSTRACT_ROOM_PUBLISH_P, `publish tracks -> roomId: ${roomId}, tracks: ${tracks.map(getTrackIdFromAttr)}`, this._traceId);

    const tinyTracks = await this._addLocalTracks(tracks, peer);

    // 客户端主动调用 api 发请求时，清除 ice 断线重连的定时器
    peer.clearReTryExchangeTimer();

    // 发送 /exchange 请求
    const subscribeList = store.getSubscribedList();

    const reqBody = await createExchangeParams(subscribeList, false, peer, store);
    /**
     * 直播房间需携带 pushOtherRooms 信息
     */
    reqBody.pushOtherRooms = executeCtx.getPushOtherRooms();
    const resp = await new ExchangeCommand(reqBody, this._traceId, true).execute(executeCtx, store, invoker);

    /**
     * 存储每一个 track 对应的 mediaServer 请求信息
     */
    localTracks.forEach((track) => {
      const trackId = track.getTrackId();
      this._trackMediaMap[trackId] = resp.data!.qualityMsList || [];
    });

    if (resp.code !== RCRTCCode.SUCCESS) {
      // 连通率相关埋点-发布资源结束
      reportMediaActionLogger.reportPubOrSubQualityData(RCRTCResourceAction.PUB, this._actionStartTime, localTracks, resp.code, this._trackMediaMap);

      // TODO: 资源发送失败，需要移除已添加至 RTCPeerConnection 中的资源信息
      this._logger.error(RCLoggerTag.L_ABSTRACT_ROOM_PUBLISH_P, JSON.stringify({
        status: RCLoggerStatus.FAILED,
        tracks: tracks.map(getTrackIdFromAttr),
        code: resp.code,
        msg: 'exchange failed',
      }), this._traceId);
      this._removePubFailedTracks(tracks, peer);
      return { code: resp.code, tracks: localTracks };
    }

    const { sdp: answer, resultCode: code, message } = resp.data!.data!;
    if (code !== RCRTCCode.SUCCESS) {
      // 连通率相关埋点-发布资源结束
      reportMediaActionLogger.reportPubOrSubQualityData(RCRTCResourceAction.PUB, this._actionStartTime, localTracks, code, this._trackMediaMap);

      this._logger.error(RCLoggerTag.L_ABSTRACT_ROOM_PUBLISH_P, JSON.stringify({
        status: RCLoggerStatus.FAILED,
        trackIds: tracks.map(getTrackIdFromAttr),
        code,
        msg: `exchange inner failed: ${message}`,
      }), this._traceId);
      this._removePubFailedTracks(tracks, peer);
      return { code, tracks: localTracks };
    }

    // 设置发布成功时间
    reportMediaActionLogger.setPubOrSubSuccessTime(Date.now());

    const resCode = await peer.setRemoteAnswer(answer.sdp, true);
    if (resCode !== RCRTCCode.SUCCESS) {
      // 连通率相关埋点-发布资源结束
      reportMediaActionLogger.reportPubOrSubQualityData(RCRTCResourceAction.PUB, this._actionStartTime, localTracks, resCode, this._trackMediaMap);

      this._logger.error(RCLoggerTag.L_ABSTRACT_ROOM_PUBLISH_P, JSON.stringify({
        status: RCLoggerStatus.FAILED,
        trackIds: tracks.map(getTrackIdFromAttr),
        code: resCode,
        msg: 'set remote answer error',
      }), this._traceId);

      return { code: resCode, tracks: localTracks };
    }

    /**
     * 设置本次发布的小流的 ssrc 和 trackId 的 map
     */
    tinyTracks.length && this._updateTinyTrackIdSSRCMap(store, reqBody.sdp.sdp, tinyTracks);

    return { code, ...resp.data!, tracks: localTracks };
  }

  /**
   * 单独处理小流的 ssrc 和 trackId map
   */
  private _updateTinyTrackIdSSRCMap(store: Store, offerSdp: string, tinyTracks: RCLocalVideoTrack[]) {
    tinyTracks.forEach((track) => {
      const ssrc = getSsrcByStreamIdFromSdp(offerSdp, track.getStreamId(), RCMediaType.VIDEO_ONLY);
      store.setTrackIdSSRCMap(Number(ssrc), track.getTrackId());
    });
  }

  /**
   * 处理批量 /exhcange 的返回数据，作为一次 publish 接口返回
   * @param pubRes 每一个 tag 的发布结果[]
   */
  private async _dealPublishRes(pubRes: IPubTaskRes, store: Store, executeContext: CommandExecuteContext): Promise<IPubSuccessRes> {
    const {
      polarisReport, context, peer, reportMediaActionLogger,
    } = executeContext;
    const userId = store.crtUserId;
    const { roomId } = store;

    let liveUrl: string | undefined = '';

    const oldPublisheList = store.getResourcesByUserId(userId)!;
    const allPublishList = [...oldPublisheList];
    let crtMcuPublishList: IPublishedResource[] = [];

    const { tracks } = pubRes;
    if (pubRes.code !== RCRTCCode.SUCCESS) {
      return { code: pubRes.code };
    }

    const { publishList, urls, mcuPublishList } = pubRes.data!;
    liveUrl = urls?.liveUrl;

    // 本次发布的全量资源数据
    const newPublishList: IPublishedResource[] = publishList.map((item) => ({
      tag: item.msid.split('_').pop()!,
      state: peer.getLocalTrack(getTrackId(item))!.isLocalMuted() ? 0 : 1,
      ...item,
    }));

    /**
       * 新增发布 push 数据，重新发布需覆盖原数据
       */
    newPublishList.forEach((newPub) => {
      const { isInclude, index } = isRepeatPub(newPub, allPublishList);
      if (isInclude) {
        allPublishList.splice(index, 1, newPub);
      } else {
        allPublishList.push(newPub);
      }
    });

    // 当前直播间 mcuPublist
    const newMcuPublishList: IPublishedResource[] = mcuPublishList?.map((item) => ({
      tag: item.msid.split('_').pop()!,
      state: 1,
      ...item,
    })) || [];
    crtMcuPublishList = newMcuPublishList;

    // 计算此次发布的增量资源数据
    const { publishedList: plus } = diffPublishResources(oldPublisheList, allPublishList);

    const CDNUris = { ...store.getCDNUris(), enableInnerCDN: store.getCDNEnable() };
    const CDNValueInfo = (CDNUris && CDNUris.broadcast === RCInnerCDNBroadcast.SPREAD) ? JSON.stringify([CDNUris]) : '';

    const signalStartTime = Date.now();

    // 通知房间成员
    const errorCode = await context.setRTCTotalRes(
      roomId,
      [buildPlusMessage(RCRTCMessageType.PUBLISH, plus)],
      buildTotalURIMessageContent(allPublishList),
      RCRTCMessageType.TOTAL_CONTENT_RESOURCE,
      buildTotalURIMessageContent(crtMcuPublishList),
      CDNValueInfo,
    );

    // 连通率相关埋点-发布资源结束
    reportMediaActionLogger.reportPubOrSubQualityData(RCRTCResourceAction.PUB, this._actionStartTime, tracks, errorCode, this._trackMediaMap, [{
      dur: Date.now() - signalStartTime,
      cod: errorCode,
    }]);

    if (errorCode !== ErrorCode.SUCCESS) {
      // TODO: 确认移动端在发布资源后通知失败的处理逻辑，尽量三端统一
      this._logger.error(RCLoggerTag.L_ABSTRACT_ROOM_PUBLISH_R, JSON.stringify({
        status: RCLoggerStatus.FAILED,
        code: errorCode,
        msg: 'send publish streams notification failed',
      }), this._traceId);
      return { code: RCRTCCode.SIGNAL_ERROR };
    }

    // 更新已发布资源列表
    store.setResourcesByUserId(userId, allPublishList);

    const publishTrackIds = plus.map((item) => getTrackId(item));

    // 北极星数据上报
    polarisReport.sendR2(R2Action.PUBLISH, R2Status.BEGIN, publishTrackIds);

    /**
     * 修改 localTrack 发布状态
     */
    plus.forEach((item) => {
      const localTrack = peer.getLocalTrack(`${item.msid}_${item.mediaType}`);
      localTrack!.__innerSetPublished(true);
    });

    /**
     * 生成 publish 接口的返回数据
     */
    const result: {
      code: RCRTCCode
      liveUrl?: string,
    } = { code: RCRTCCode.SUCCESS };

    liveUrl && (result.liveUrl = liveUrl);

    return result;
  }

  async execute(executeCtx: CommandExecuteContext, store: Store, invoker: Invoker): Promise<IPubSuccessRes> {
    const { logger, peer } = executeCtx;
    this._traceId = logger.createTraceId();
    this._logger = logger;

    this._actionStartTime = Date.now();
    let { tracks } = this;

    this._logger.info(RCLoggerTag.L_ABSTRACT_ROOM_PUBLISH_T, JSON.stringify({
      trackIds: tracks.map(getTrackIdFromAttr),
    }));
    /**
     * 参数检测
     */
    if (!validate('tracks', tracks, () => isArray(tracks) && tracks.length > 0 && tracks.every((item) => item instanceof RCLocalTrack || item.track instanceof RCLocalTrack), true)) {
      this._logger.error(RCLoggerTag.L_ABSTRACT_ROOM_PUBLISH_R, JSON.stringify({
        status: RCLoggerStatus.FAILED,
        code: RCRTCCode.PARAMS_ERROR,
        msg: 'params error -> tracks',
      }), this._traceId);
      return { code: RCRTCCode.PARAMS_ERROR };
    }

    /**
     * 过滤已被销毁的 track
     */
    tracks = tracks.filter((item: (RCLocalTrack | IPublishAttrs)) => {
      const { track } = item instanceof RCLocalTrack ? { track: item } : item;
      const isDestroyed = track.isDestroyed();
      if (isDestroyed) {
        this._logger.warn(RCLoggerTag.L_ABSTRACT_ROOM_PUBLISH_P, `track '${track.getTrackId()}' has beem destroyed`, this._traceId);
      }
      return !isDestroyed;
    });

    if (!tracks.length) {
      this._logger.error(RCLoggerTag.L_ABSTRACT_ROOM_PUBLISH_R, JSON.stringify({
        status: RCLoggerStatus.FAILED,
        code: RCRTCCode.PUBLISH_TRACKS_IS_INVALID,
        msg: 'params tracks is empty',
      }), this._traceId);
      return { code: RCRTCCode.PUBLISH_TRACKS_IS_INVALID };
    }

    // 发送上下行数据至北极星
    peer.__reportR3R4ToPolaris();
    // 客户端主动调用 api 发请求时，清除 ice 断线重连的定时器
    peer.clearReTryExchangeTimer();

    const pubRes: IPubTaskRes = await this.__publish(executeCtx, store, tracks, invoker);
    const result = await this._dealPublishRes(pubRes, store, executeCtx);
    return result;
  }
}
