/**
 * 通知拉取到的房间增量数据列表中每一项数据的动作
 */
export enum PullRoomStatusEvent {
  /**
   * 人员加入
   */
  JOIN = 0,
  /**
   * 人员离开
   */
  LEAVE = 1,
  /**
   * 人员离线
   */
  OFFLINE = 2,
  /**
   * 资源变动
   */
  RESOURCECHANGE = 3
}
