import { BasicLogger } from '@rongcloud/engine';
import {
  getTrackId, int64ToTimestamp, parseRoomData, parseTrackId,
} from '../helper';
import { RCMediaType } from './enums/RCMediaType';
import { IPublishedResource, ISubscribeAttr, RoomData } from './interfaces';
import { ICDNUris, IRTCReqHeader } from './service/interface';
import { RCRemoteAudioTrack, RCRemoteVideoTrack } from './tracks/RCRemoteTrack';
import { RTCMode } from './enums/RTCMode';
import { IJoinRTCRoomData } from './codec/interface';
import { RCLoggerTag } from './enums/RCLoggerTag';

export const StoreEvent = {};

export abstract class ReadableStore {
  /**
   * 房间资源数据
   */
  protected _roomResources: RoomData = {}

  /**
   * 远端 track
   */
  protected _remoteTracks: { [trackId: string]: RCRemoteAudioTrack | RCRemoteVideoTrack } = {}

  /**
   * 已订阅参数
   */
  protected _subscribedList: ISubscribeAttr[] = []

  protected _collectSubscribeList: ISubscribeAttr[] = []

  /**
   * 与 MediaServer 交互需要的 token 信息
   */
  protected _token!: string

  /**
   * 每次加入房间后都会改变
   */
  protected _sessionId!: string

  /**
   * cdn_uris 信令扩散数据
   */
  protected _CDNUris: ICDNUris | null = null

  protected _CDNEnable: boolean = false

  protected _destroyed: boolean = false

  /**
   * 存储 ssrc-trackId 的 map
   */
  protected _trackIdSSRCMap: Map<number, string> = new Map()

  protected _roomStatusVersion: number = 0

  protected _supportNtf: boolean = true

  constructor(
    protected readonly _logger: BasicLogger,
    public readonly clientSessionId: string,
    public readonly appkey: string,
    public readonly roomId: string,
    public readonly crtUserId: string,
    public readonly roomMode: RTCMode,
    public readonly isUpgrade?: boolean,
    public readonly isMainRoom?: boolean,
  ) {}

  getResourcesByUserId(userId: string): IPublishedResource[] | undefined {
    return this._roomResources[userId];
  }

  getRemoteTrack(trackId: string): RCRemoteAudioTrack | RCRemoteVideoTrack | undefined {
    return this._remoteTracks[trackId];
  }

  getRemoteTracksByUserId(userId: string): (RCRemoteAudioTrack | RCRemoteVideoTrack)[] {
    const tracks: (RCRemoteAudioTrack | RCRemoteVideoTrack)[] = [];
    for (const trackId in this._remoteTracks) {
      const track = this._remoteTracks[trackId];
      if (track.getUserId() === userId) {
        tracks.push(track);
      }
    }
    return tracks;
  }

  getRemoteTracks() {
    return this._remoteTracks;
  }

  getSessionId(): string {
    return this._sessionId;
  }

  getAllUserIds(): string[] {
    return Object.keys(this._roomResources);
  }

  getRemoteUserIds(): string[] {
    return this.getAllUserIds().filter((userId) => userId !== this.crtUserId);
  }

  getSubscribedList(): ISubscribeAttr[] {
    return this._subscribedList;
  }

  getCollectSubscribeList(): ISubscribeAttr[] {
    return this._collectSubscribeList;
  }

  getPublishedResourceByTrackId(trackId: string): IPublishedResource | undefined {
    const { userId } = parseTrackId(trackId);
    return this._roomResources[userId].find((item) => getTrackId(item) === trackId);
  }

  getToken(): string {
    return this._token;
  }

  getCDNEnable(): boolean {
    return this._CDNEnable;
  }

  getCDNUris(): ICDNUris | null {
    return this._CDNUris;
  }

  getRoomStatusVersion(): number {
    return this._roomStatusVersion;
  }

  getTrackIdBySSRC(ssrc: number): string | undefined {
    return this._trackIdSSRCMap.get(ssrc);
  }

  getRTCRequestHeader(): IRTCReqHeader {
    return {
      'App-Key': this.appkey,
      RoomId: this.roomId,
      Token: this._token,
      RoomType: this.roomMode,
      UserId: this.crtUserId,
      'Client-Session-Id': this.clientSessionId,
    };
  }

  getSupportNtf(): boolean {
    return this._supportNtf;
  }
}

export class Store extends ReadableStore {
  /**
   * 更新房间内 ssrc-trackId 的 map
   */
  private _updateTrackIdSSRCMap() {
    Object.values(this._roomResources).forEach((resources) => {
      resources.forEach((res) => {
        const { ssrc, resourceId } = JSON.parse(res.uri);
        this._trackIdSSRCMap.set(ssrc, resourceId);
      });
    });
  }

  public setTrackIdSSRCMap(ssrc: number, trackId: string) {
    this._trackIdSSRCMap.set(ssrc, trackId);
  }

  private _initRemoteTracks() {
    for (const userId in this._roomResources) {
      const resArr = this._roomResources[userId];
      if (userId === this.crtUserId || resArr.length === 0) {
        continue;
      }

      resArr.forEach((item) => {
        const resourceId = getTrackId(item);
        const { tag, userId, mediaType } = parseTrackId(resourceId);
        const remoteTrack = mediaType === RCMediaType.AUDIO_ONLY ? new RCRemoteAudioTrack(this._logger, tag, userId) : new RCRemoteVideoTrack(this._logger, tag, userId);
        remoteTrack.__innerSetRemoteMuted(item.state === 0);
        this._remoteTracks[resourceId] = remoteTrack;
      });
    }
  }

  initWithRoomData(data: IJoinRTCRoomData, traceId: string) {
    this._sessionId = data.sessionId;
    this._token = data.token;
    this._roomStatusVersion = int64ToTimestamp(data.version);

    this._roomResources = parseRoomData(data, this.roomId, this._logger, traceId);

    this._updateTrackIdSSRCMap();

    // 根据解析出来的数据构建远端流
    this._initRemoteTracks();

    this.setResourcesByUserId(this.crtUserId, this.getResourcesByUserId(this.crtUserId) || []);
    this._logger.info(RCLoggerTag.L_PARSE_ROOMDATA_URIS_R, `room data -> ${JSON.stringify(this._roomResources)}`, traceId);
  }

  assignRoomData(data: RoomData) {
    Object.assign(this._roomResources, data);

    this._updateTrackIdSSRCMap();
  }

  setResourcesByUserId(userId: string, arr: IPublishedResource[]) {
    this._roomResources[userId] = arr;

    this._updateTrackIdSSRCMap();
  }

  removeRemoteTrack(trackId: string) {
    delete this._remoteTracks[trackId];
  }

  removeResourcesByUserId(userId: string) {
    delete this._roomResources[userId];

    this._updateTrackIdSSRCMap();
  }

  addRemoteTrack(track: RCRemoteAudioTrack | RCRemoteVideoTrack) {
    this._remoteTracks[track.getTrackId()] = track;
  }

  removeRemoteTracks() {
    const remoteTracks = Object.values(this._remoteTracks);
    if (!remoteTracks.length) {
      return;
    }
    remoteTracks.forEach((track) => {
      track.isAudioTrack() && track.__releaseMediaElement();
    });
    this._remoteTracks = {};
  }

  setCDNEnabel(bool: boolean) {
    this._CDNEnable = bool;
  }

  setCDNUris(uris: ICDNUris | null) {
    this._CDNUris = uris;
  }

  resetSubscribedList(subscribeList: ISubscribeAttr[]): void {
    this._subscribedList.splice(0, this._subscribedList.length, ...subscribeList);
    this.resetCollectSubscribeList(subscribeList);
  }

  resetCollectSubscribeList(collectSubscribeList: ISubscribeAttr[]): void {
    this._collectSubscribeList.splice(0, this._collectSubscribeList.length, ...collectSubscribeList);
  }

  setRoomStatusVersion(version: number) {
    this._roomStatusVersion = int64ToTimestamp(version);
  }
}
