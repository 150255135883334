/* eslint no-dupe-class-members: 'off' */
import { BasicLogger, isNumber, validate } from '@rongcloud/engine';
import { getDynamicBitrate } from '../../helper';
import { RCRTCCode } from '../enums/RCRTCCode';
import { IRCTrackBitrate } from '../interfaces';
import { RCTrack, RCTrackKind } from './RCTrack';
import { RCLoggerStatus, RCLoggerTag } from '../enums/RCLoggerTag';
import {
  RCAudioBitrate, getAudioBitrate, getVideoBitrate, RCVideoBitrate,
} from '../enums/RCBitrate';
import { RC3ANoiseWorklet } from './RC3ANoiseWorklet';

export abstract class RCLocalTrack extends RCTrack {
  /**
   * 本地流结束事件通知
   * @description
   * 该事件为 MediaStreamTrack 实例的 'ended' 事件触发
   */
  public static EVENT_LOCAL_TRACK_END = 'local-track-end'

  /**
   * muted 状态变更通知常量定义
   */
  public static __INNER_EVENT_MUTED_CHANGE__ = 'inner-muted-change'

  /**
   * 本地流已销毁
   */
  public static __INNER_EVENT_DESTROY__ = 'inner-destroy'

  /**
   * track 是否被销毁
   */
  private _isDestroyed = false

  constructor(logger: BasicLogger, tag: string, userId: string, kind: 'audio' | 'video', track: MediaStreamTrack) {
    super(logger, tag, userId, kind, true);
    this.__innerSetMediaStreamTrack(track);

    // 监听流结束事件
    track.onended = () => {
      track.onended = null;
      this.emit(RCLocalTrack.EVENT_LOCAL_TRACK_END, this);
      this.removeAll(RCLocalTrack.EVENT_LOCAL_TRACK_END);
    };
  }

  /**
   * @override 重写 RCTrack 父类方法
   * @param bool
   */
  public _setLocalMuted(bool: boolean) : Promise<RCRTCCode> {
    return new Promise((resolve) => {
      const changed = this._localMuted !== bool;
      super._setLocalMuted(bool);
      // 本端流，remoteMuted 与 localMuted 始终保持一致
      this._remoteMuted = this._localMuted;
      /**
       * 派发事件以通知房间内其他成员
       * 本端禁用资源时需等待 signal 的扩散结果
       */
      if (changed && this._isPublished) {
        this.emit(RCLocalTrack.__INNER_EVENT_MUTED_CHANGE__, this, resolve);
      } else {
        resolve(RCRTCCode.SUCCESS);
      }

      this._logger.info(RCLoggerTag.L_LOCAL_TRACK_SET_LOCAL_MUTED_O, JSON.stringify({
        enable: bool,
        trackId: this._id,
      }));
    });
  }

  private _isPublished: boolean = false

  public __innerSetPublished(bool: boolean) {
    this._isPublished = bool;
  }

  /**
   * 检测本地资源是否已发布
   */
  public isPublished(): boolean {
    return this._isPublished;
  }

  /**
   * 销毁本地流
   */
  public destroy() {
    this._isDestroyed = true;
    this._msTrack?.stop();
    super.__innerDestroy();
    this.isAudioTrack() && super.__releaseMediaElement();
    // 需要通知房间流已销毁，取消发布流
    this.emit(RCLocalTrack.__INNER_EVENT_DESTROY__, this);

    this._logger.info(RCLoggerTag.L_LOCAL_TRACK_DESTROY_O, JSON.stringify({
      status: RCLoggerStatus.SUCCESSED,
      trackId: this._id,
    }));
  }

  /**
   * 判断 track 是否被销毁
   */
  public isDestroyed(): boolean {
    return this._isDestroyed;
  }

  private _bitrateInfo?: IRCTrackBitrate

  /**
   * 为本地流设定上行码率，仅视频流有效，音频默认 15 kbps，不支持修改
   * @description 当 `max` 或 `min` 值为 `0` 时，取动态码率计算结果
   * @param max 最大码率
   * @param min 最小码率
   * @param start 起始码率
   */
  public setBitrate(max: number = 0, min: number = 0, start: number = 0) {
    if (!isNumber(max) || !isNumber(min) || !isNumber(start) || max <= 0 || min <= 0 || max < min) {
      this._logger.error(RCLoggerTag.L_LOCAL_TRACK_SET_BITRATE_O, JSON.stringify({
        status: RCLoggerStatus.FAILED,
        msg: `params error -> max: ${max},min: ${min}, start: ${start}`,
        trackId: this._id,
      }));
      return;
    }

    this._logger.info(RCLoggerTag.L_LOCAL_TRACK_SET_BITRATE_O, JSON.stringify({
      status: RCLoggerStatus.SUCCESSED,
      max,
      min,
      start,
      trackId: this._id,
    }));

    this._bitrateInfo = { max, min, start };
  }

  /**
   * 获取码率配置，当未指定码率时，将取得动态码率计算值
   * @returns
   */
  public getBitrate(): IRCTrackBitrate {
    // 收集默认值
    let { min, max, start } = { min: 0, max: 0, start: 0 };
    if (this._msTrack?.kind === RCTrackKind.VIDEO) {
      // 视频动态码率
      ({ min, max } = getDynamicBitrate(this._msTrack));
    } else if (this._msTrack?.kind === RCTrackKind.AUDIO) {
      // 音频动态码率
      ({ min, max } = { min: 32, max: 32 });
    }
    if (this._bitrateInfo?.max) {
      start = this._bitrateInfo?.max * 0.7;
    }

    return { min: this._bitrateInfo?.min || min, max: this._bitrateInfo?.max || max, start: this._bitrateInfo?.start || start };
  }
}

export class RCLocalAudioTrack extends RCLocalTrack {
  constructor(logger: BasicLogger, tag: string, userId: string, track: MediaStreamTrack) {
    super(logger, tag, userId, 'audio', track);
  }

  /**
   * 它设置推荐的音频码率。
   * @param {RCAudioBitrate} audio - RCA音频比特率
   */
  public setRecommendBitrate(audio: RCAudioBitrate): void {
    if (!validate('audioBitrate', audio, (value) => value in RCAudioBitrate)) {
      this._logger.warn(RCLoggerTag.L_RTC_SET_RECOMMEND_BITRATE_O, JSON.stringify({
        status: RCLoggerStatus.FAILED,
        msg: 'params error -> params type is RCAudioBitrate',
      }));
      return;
    }
    const audioBitrate = getAudioBitrate[audio];
    this.setBitrate(audioBitrate.max, audioBitrate.min, audioBitrate.start || audioBitrate.max * 0.7);
  }

  /**
   * 它设置视频的比特率。
   * @param {number} max - 客户端将使用的最大比特率。
   * @param {number[]} args - [分钟，开始]
   */
  public setBitrate(max: number, ...args:number[]) {
    const [min = 1, start = max * 0.7 || 1] = args;
    super.setBitrate(max, min, start);
  }
}

export class RCLocalVideoTrack extends RCLocalTrack {
  constructor(logger: BasicLogger, tag: string, userId: string, track: MediaStreamTrack, private _isTiny: boolean = false) {
    super(logger, tag, userId, 'video', track);
  }

  __isTiny(): boolean {
    return this._isTiny;
  }

  getStreamId() {
    const msid = super.getStreamId();
    return this._isTiny ? `${msid}_tiny` : msid;
  }

  getTrackId() {
    const trackId = super.getTrackId();
    return this._isTiny ? `${trackId}_tiny` : trackId;
  }

  /**
   * 它设置推荐的音频码率
   * @param {RCVideoBitrate} video - RC视频比特率
   */
  public setRecommendBitrate(video: RCVideoBitrate): void {
    if (!validate('videoBitrate', video, (value) => value in RCVideoBitrate)) {
      this._logger.warn(RCLoggerTag.L_RTC_SET_RECOMMEND_BITRATE_O, JSON.stringify({
        status: RCLoggerStatus.FAILED,
        msg: 'params error -> params type is RCVideoBitrate',
      }));
      return;
    }

    const audioBitrate = getVideoBitrate[video];
    this.setBitrate(audioBitrate.max, audioBitrate.min, audioBitrate.start || audioBitrate.max * 0.7);
  }

  /**
   * 它设置视频的比特率。
   * @param {number} max - 视频可以编码的最大比特率。
   * @param {number[]} args - [最大、最小、开始]
   */
  public setBitrate(max: number, ...args: number[]) {
    const [min = 1, start = max * 0.7 || 1] = args;
    super.setBitrate(max, min, start);
  }
}

export abstract class RCLocalFileTrack extends RCLocalTrack {
  private static readonly _mapping: Map<HTMLVideoElement, string[]> = new Map()

  /**
   * 建立 trackId 与宿主播放元素的映射关系
   * @param trackId
   * @param video
   */
  private static __innerSetMapping(trackId: string, video: HTMLVideoElement) {
    const ids: string[] = this._mapping.get(video) || [];
    ids.push(trackId);
    this._mapping.set(video, ids);
  }

  private static __innerRemoveMapping(trackId: string, video: HTMLVideoElement) {
    const ids = this._mapping.get(video)?.filter((id) => id !== trackId);
    if (ids && ids.length > 0) {
      this._mapping.set(video, ids);
      return;
    }
    this._mapping.delete(video);
    video.pause();
    video.src = '';
    video.parentNode?.removeChild(video);
  }

  constructor(
    logger: BasicLogger,
    tag: string,
    userId: string,
    kind: 'audio' | 'video',
    track: MediaStreamTrack,
    /**
     * 自定义文件流的播放宿主原生，该类型流所持有的 MediaStreamTrack 实例是由该宿主元素 `captureStream` 获取
     */
    protected _resource: HTMLVideoElement,
  ) {
    super(logger, tag, userId, kind, track);
    RCLocalFileTrack.__innerSetMapping(this.getTrackId(), _resource);
  }

  destroy() {
    this.isAudioTrack() && this.mute();
    RCLocalFileTrack.__innerRemoveMapping(this.getTrackId(), this._resource);
    super.destroy();
  }
}

export class RCLocalFileVideoTrack extends RCLocalFileTrack {
  constructor(logger: BasicLogger, tag: string, userId: string, track: MediaStreamTrack, element: HTMLVideoElement) {
    super(logger, tag, userId, 'video', track, element);
  }
}

export class RCLocalFileAudioTrack extends RCLocalFileTrack {
  constructor(logger: BasicLogger, tag: string, userId: string, track: MediaStreamTrack, element: HTMLVideoElement) {
    super(logger, tag, userId, 'audio', track, element);
  }

  async _setLocalMuted(bool: boolean): Promise<RCRTCCode> {
    if (this._resource) {
      this._resource.muted = bool;
    }
    return super._setLocalMuted(bool);
  }

  async play(): Promise<{ code: RCRTCCode }> {
    // 自定义文件中的声音播放只需要修改 video 标签的 muted 属性
    const code = await this._setLocalMuted(false);
    return { code };
  }
}

export class RCMicphoneAudioTrack extends RCLocalAudioTrack {
  private _originTrack: MediaStreamTrack | null = null;

  /**
   * 应用 3A 降噪模块对麦克风音频流进行处理
   */
  async apply3ANoiseWorklet(): Promise<{ code: RCRTCCode }> {
    this._logger.info(RCLoggerTag.A_APPLY_3A_NOISE_WORKLET_T);

    if (this._originTrack) {
      this._logger.warn(RCLoggerTag.A_APPLY_3A_NOISE_WORKLET_R, JSON.stringify({
        status: RCLoggerStatus.FAILED,
        msg: '3A noise module already applied',
      }));
      return { code: RCRTCCode.SUCCESS };
    }

    if (!this._msTrack || this.isDestroyed()) {
      this._logger.warn(RCLoggerTag.A_APPLY_3A_NOISE_WORKLET_R, JSON.stringify({
        status: RCLoggerStatus.FAILED,
        msg: 'track not ready',
      }));
      return { code: RCRTCCode.TRACK_NOT_READY };
    }

    if (this.isPublished()) {
      this._logger.warn(RCLoggerTag.A_APPLY_3A_NOISE_WORKLET_R, JSON.stringify({
        status: RCLoggerStatus.FAILED,
        msg: 'track already published',
      }));
      // 已经发布的流不允许再次应用 3A 降噪模块
      return { code: RCRTCCode.APPLY_3A_NOISE_WORKLET_BEFORE_PUBLISH };
    }

    const { code, track } = await RC3ANoiseWorklet.apply3ANoiseWorklet(this._msTrack);
    if (code !== RCRTCCode.SUCCESS || !track) {
      this._logger.warn(RCLoggerTag.A_APPLY_3A_NOISE_WORKLET_R, JSON.stringify({
        status: RCLoggerStatus.FAILED,
        msg: `apply 3A noise module failed: ${code}`,
      }));
      return { code };
    }

    this._originTrack = this._msTrack;
    this._msTrack = track;

    this._logger.info(RCLoggerTag.A_APPLY_3A_NOISE_WORKLET_R, JSON.stringify({
      status: RCLoggerStatus.SUCCESSED,
    }));

    return { code: RCRTCCode.SUCCESS };
  }

  __innerSetMediaStreamTrack(track: MediaStreamTrack | undefined): void {
    if (!track && this._originTrack) {
      // 释放 3A 降噪模块内存
      RC3ANoiseWorklet.release3ANoiseWorklet(this._originTrack);
      this._originTrack.stop();
      this._originTrack = null;
    }
    super.__innerSetMediaStreamTrack(track);
  }
}

export class RCCameraVideoTrack extends RCLocalVideoTrack {
}

export class RCScreenVideoTrack extends RCLocalVideoTrack {}

export class RCScreenAudioTrack extends RCLocalAudioTrack {}
