import { BasicLogger } from '@rongcloud/engine';
import { RCCommandKind } from '../enums/RCCommandKind';
import { RCLoggerTag } from '../enums/RCLoggerTag';
import { RCRTCCode } from '../enums/RCRTCCode';
import { RCRTCLiveRole } from '../enums/RCRTCLiveRole';
import { Invoker } from '../Invoker';
import { Store } from '../Store';
import { BaseCommand, CommandPriority, ICommandResult } from './BaseCommand';
import { CommandExecuteContext } from './CommandExecuteContext';
import { dealLeftUsers } from './helper';

export type StateUser = {
  userId: string,
  /**
   * 状态值，其中
   * * `0`: 进入房间
   * * `1`: 退出房间
   * * `2`: 用户离线，当作离开房间处理
   */
  state: '0' | '1' | '2',
  /**
   * 角色切换类型，0 代表非切换身份发生的加入、退出房间行为
   */
  switchRoleType: RCRTCLiveRole | 0,
  /**
   * 加房间的身份标识，保存主房间 roomId
   */
  extra?: {[key: string]: string}
}

export type StateMsgContent = { users: StateUser[] }

/**
 * RCRTC:State 消息解析结果
 */
export interface IParseUserStateRes {
  /**
   * 新加入人员 ID 列表
   */
  joined: string[]
  /**
   * 退出人员 ID 列表
   */
  left: string[]
  /**
   * 通过观众升级成为主播的主播 ID 列表
   */
  upgrade: string[]
  /**
   * 由主播降级为观众的人员 ID 列表
   */
  downgrade: string[]
}

export class ParseUserStateCommand extends BaseCommand<IParseUserStateRes> {
  constructor(
    private msgContent: StateMsgContent,
    private traceId: string,
  ) {
    super();
  }

  get kind(): RCCommandKind {
    return RCCommandKind.PARSE_USERSTATE;
  }

  get priority(): CommandPriority {
    return CommandPriority.NORMAL;
  }

  /**
   * 判断是否是副房间人员
   */
  private _isPKRoomUser(store: Store, users: StateUser[]) {
    for (const user of users) {
      // 加入房间时
      if (user.extra && user.extra.roomId !== store.roomId) {
        return true;
      }
      // 退出房间时
      if (Number(user.state) === 1 && !store.getRemoteUserIds().includes(user.userId)) {
        return true;
      }
    }
    return false;
  }

  private _dealAddUser(store: Store, logger: BasicLogger, res: IParseUserStateRes, switchRoleType: RCRTCLiveRole | 0, userId: string) {
    // 对端 im 重连之后调加入房间信令获取最新数据，服务会给本端下发“对端加入房间”的消息，本端内存已包含对端人员，所以需过滤掉
    const resArr = store.getResourcesByUserId(userId);
    if (!resArr) {
      switchRoleType ? res.upgrade.push(userId) : res.joined.push(userId);
      logger.info(RCLoggerTag.L_PARSE_USERSTATE_COMMAND_R, JSON.stringify({
        userId,
        msg: `user ${switchRoleType ? 'upgrade' : 'joined'}`,
      }), this.traceId);
    }
    store.setResourcesByUserId(userId, resArr || []);
  }

  private _dealReduceUser(store: Store, logger: BasicLogger, res: IParseUserStateRes, switchRoleType: RCRTCLiveRole | 0, userId: string) {
    logger.info(RCLoggerTag.L_PARSE_USERSTATE_COMMAND_R, JSON.stringify({
      userId,
      msg: `user ${switchRoleType ? 'downgrade' : 'left'}`,
    }), this.traceId);
    switchRoleType ? res.downgrade.push(userId) : res.left.push(userId);
  }

  async execute(executeCtx: CommandExecuteContext, store: Store, invoker: Invoker): Promise<ICommandResult<IParseUserStateRes>> {
    const { logger } = executeCtx;

    const res = {
      /**
       * 主动加入房间
       */
      joined: [],
      /**
       * 主动退出房间
       */
      left: [],
      /**
       * 观众升级为主播加入房间
       */
      upgrade: [],
      /**
       * 主播降级为观众退出房间
       */
      downgrade: [],
    };

    const { users } = this.msgContent;
    if (users.length === 0) {
      return { code: RCRTCCode.SUCCESS, data: res };
    }

    /**
     * 过滤掉副房间身份的人员
     */
    if (this._isPKRoomUser(store, users)) {
      return { code: RCRTCCode.SUCCESS, data: res };
    }

    users.forEach((item) => {
      const { userId } = item;
      if (Number(item.state) === 0) {
        this._dealAddUser(store, logger, res, item.switchRoleType, userId);
      } else {
        this._dealReduceUser(store, logger, res, item.switchRoleType, userId);
      }
    });

    const allLeft = [...res.left, ...res.downgrade];

    // 用户离开房间时，自动退订对方资源
    if (allLeft.length) {
      await dealLeftUsers(allLeft, executeCtx, store, invoker, this.traceId);
    }

    return { code: RCRTCCode.SUCCESS, data: res };
  }
}
