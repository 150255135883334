import {
  ConversationType, ILogger, IReceivedMessage, IRuntime, RTCPluginContext,
} from '@rongcloud/engine';
import { eventEmitter, getCallDeviceId } from '../helper';
import { MsgCallStatus } from './enums/MsgCallStatus';
import { RCCallErrorCode } from './enums/RCCallCode';
import { CallRemoteEndReason, RCCallEndReason } from './enums/RCCallEndReason';
import { RCCallMediaType } from './enums/RCCallMediaType';
import { RCCallMessageType } from './enums/RCCallMessageType';
import { RCCallSessionState } from './enums/RCCallSessionState';
import { RCCallUserState } from './enums/RCCallUserState';
import {
  IExistedUserPofiles, IHungupMsgContent, IInviteMsgContent, IMemberModifyMsgContent, IRingingMsgContent,
} from './interfaces/IMessageHandler';
import {
  ICallStateMachineWatchers, IEndSummary, IInvitedUsers, IUserData, IUserProfile, IInviteOptions,
} from './interfaces/IStateMachine';
import { CallMessageHandler } from './MessageHandler';
import { RCCrossCallType } from './enums/RCCrossCallType';
import { Timer } from './Timer';
import { IPushConfig } from '../../src/interface';

export class RCCallStateMachine {
  /**
   * 房间状态
   */
  private _sessionState: RCCallSessionState | null = null

  /**
   * 用户状态及信息
   */
  private _userInfo: { [userId: string]: IUserData } = {}

  /**
   * 用户计时器映射
   */
  private _userTimers: { [userId: string]: Timer } = {}

  /**
   * 监听器
   */
  private _watchers!: ICallStateMachineWatchers

  /**
   * 呼叫超时时间 (单位：毫秒)
   */
  private _callTimeout: number = 60 * 1000

  /**
   * 通话建立开始时间
   */
  private _beginTimestamp: number = 0

  /**
   * 通话结束时间
   */
  private _endTimestamp: number = 0

  /**
   * 通话结束原因
   */
  private _endReason: RCCallEndReason | null = null

  /**
   * 主叫 ID
   * 发起邀请为当前用户 ID
   * 收到邀请为 senderUserId
   * 收到人员变更邀请为消息体中 callerId
   */
  private _callerId: string | null = null

  /**
   * 当次通话邀请者 ID
   * 发起邀请为当前用户 ID、收到邀请为 senderUserId、收到人员变更邀请为消息体中 senderUserId
   */
  private _inviterId: string | null = null

  /**
   * 是否是跨 appkey
   */
  private _isCrossAppkey: boolean = false

  private _hungupPushConfig?: IPushConfig

  constructor(
    private readonly _context: RTCPluginContext,
    private readonly _runtime: IRuntime,
    private readonly _logger: ILogger,
    private readonly _callMsgHandler: CallMessageHandler,
    private readonly _channelId: string,
    private readonly _conversationType: ConversationType,
    private readonly _targetId: string,
    private _mediaType: RCCallMediaType,
    private readonly _callId: string,
  ) {
    this._callMsgHandler.registerStateMachineEvent(this._callId, 'onRinging', this._onRinging.bind(this));
    this._callMsgHandler.registerStateMachineEvent(this._callId, 'onAccept', this._onAccept.bind(this));
    this._callMsgHandler.registerStateMachineEvent(this._callId, 'onMediaModify', this._onMediaModify.bind(this));
    this._callMsgHandler.registerStateMachineEvent(this._callId, 'onHungup', this._onHungup.bind(this));
  }

  /**
   * 获取校正后超时时间
   */
  private _getTimeout(sentTime: number): number {
    let delayTime = this._context.getServerTime() - sentTime;
    if (delayTime < 0) {
      delayTime = 500; // 假设延迟时间为 500 ms
    }
    const timeout = this._callTimeout - delayTime;
    this._logger.warn('_', `_getTimeout -> timeout: ${timeout}`);
    return timeout;
  }

  private _clearTimerById(userId: string) {
    this._logger.debug('_', `[RCCallStateMachine] before _clearTimerById  -> userId: ${userId} userTimers: ${JSON.stringify(this._userTimers)}`);
    if (this._userTimers[userId]) {
      this._userTimers[userId].stop();
      delete this._userTimers[userId];
    }
    this._logger.debug('_', `[RCCallStateMachine] after _clearTimerById -> userTimers: ${JSON.stringify(this._userTimers)}`);
  }

  /**
   * 通知 call 层房间状态变更及原因
   */
  private _notifyStateChange(state: RCCallSessionState, reason?: RCCallEndReason) {
    this._logger.warn('_', `[RCCallStateMachine] notifyStateChange -> info: ${JSON.stringify({
      state, reason,
    })}`);

    this._endReason = reason || null;
    if (this._sessionState !== state) {
      this._sessionState = state;
      this._watchers?.onStateChange({ state, reason });
    }
    if (state === RCCallSessionState.END) {
      // 当状态机结束时，需在 CallEngine 清除
      eventEmitter.emit('onStateMachineClose', this._callId);
      this._callMsgHandler.unregisterStateMachineEvent(this._callId);
    }
  }

  /**
   * 通知 call 层人员状态变更及原因
   */
  private _notifyUserStateChange(user: IUserData, reason?: RCCallEndReason) {
    this._logger.warn('_', `[RCCallStateMachine] notifyUserStateChange -> info: ${JSON.stringify({
      user, reason,
    })}`);
    this._watchers?.onUserStateChange({ user, reason });
  }

  private _otherClientHandle(message: IReceivedMessage) {
    const { senderUserId, content: { user: userProfile, reason: hungupReason }, messageType } = message;
    this._userInfo[senderUserId] = {
      userId: senderUserId,
      state: RCCallUserState.NONE,
      isCaller: false,
      isRemote: false,
    };
    // 多端接听、多端拒绝，清除收到邀请后起的计时器
    for (const userId in this._userTimers) {
      this._clearTimerById(userId);
    }
    // 其他端接听
    let endReason = RCCallEndReason.ACCEPT_BY_OTHER_CLIENT;
    if (messageType === RCCallMessageType.VCHangup) {
      if (hungupReason === RCCallEndReason.BUSY_LINE) {
        endReason = RCCallEndReason.OTHER_CLIENT_IN_CALL;
      } else if (hungupReason === RCCallEndReason.NO_RESPONSE) {
        endReason = RCCallEndReason.NO_RESPONSE;
      } else {
        endReason = RCCallEndReason.HANGUP_BY_OTHER_CLIENT;
      }
    }
    // 添加用户简要信息
    Object.assign(this._userInfo[senderUserId], <IUserProfile>userProfile);
    this._notifyUserStateChange(this._userInfo[senderUserId], endReason);
    this._notifyStateChange(RCCallSessionState.END, endReason);
  }

  /**
   * 正在通话中，且不是当前已接通用户设备（deviceId）发来的消息
  */
  private _isRemoteInvalidMsg(remoteUserId: string, msgDeviceId: string): boolean {
    if (!this._userInfo[remoteUserId]) {
      return false;
    }

    if (!this._userInfo[remoteUserId].deviceId || !msgDeviceId) {
      return false;
    }

    if ((this._userInfo[remoteUserId].state === RCCallUserState.KEEPING && this._userInfo[remoteUserId].deviceId !== msgDeviceId)) {
      return true;
    }

    return false;
  }

  private _onRinging(message: IReceivedMessage) {
    const { senderUserId: suid, content: { user: userProfile, deviceId } } = message;
    const senderUserId = this._isCrossAppkey ? suid.split('_')[1] : suid;
    // 正在通话中，远端多端发的消息直接过滤
    if (this._isRemoteInvalidMsg(senderUserId, deviceId)) {
      this._logger.debug('_', '[RCCallStateMachine] onRinging -> not the remote device that is currently talking');
      return;
    }

    if (this._context.getCurrentId() === senderUserId) {
      return; // 多端处理
    }
    this._watchers.onRinging({ userId: senderUserId, ...<IUserProfile>userProfile });
  }

  private _onAccept(message: IReceivedMessage) {
    const { senderUserId: suid, content: { user: userProfile, deviceId: senderDeviceId }, sentTime } = message;
    const senderUserId = this._isCrossAppkey ? suid.split('_')[1] : suid;
    const currentUserId = this._context.getCurrentId();
    // 正在通话中，远端多端发的消息直接过滤
    if (this._isRemoteInvalidMsg(senderUserId, senderDeviceId)) {
      this._logger.debug('_', '[RCCallStateMachine] _onAccept -> not the remote device that is currently talking');
      return;
    }

    if (currentUserId === senderUserId) {
      // 多端处理
      this._otherClientHandle(message);
      return;
    }

    // 群组通话时： A、B 通话 A邀请C, C同意接听，这时B没有C的userId对应的计时器，所以这里判断一下
    if (this._userTimers[senderUserId]) {
      // 清除呼叫超时计时器
      this._clearTimerById(senderUserId);
    }

    // 修改并通知房间人员状态
    const ids = (this._conversationType === ConversationType.PRIVATE) ? [currentUserId, senderUserId] : [senderUserId];
    ids.forEach((userId) => {
      const isCurrentUserId = userId === currentUserId;
      this._userInfo[userId] = {
        userId,
        state: RCCallUserState.KEEPING,
        isCaller: isCurrentUserId,
        isRemote: isCurrentUserId,
        deviceId: isCurrentUserId ? getCallDeviceId(this._runtime) : senderDeviceId,
      };
      if (!isCurrentUserId) {
        this._beginTimestamp = Date.now();
        // 添加用户简要信息
        Object.assign(this._userInfo[senderUserId], <IUserProfile>userProfile);
      }
      this._notifyUserStateChange(this._userInfo[userId]);
    });
    if (this.getCallerId() === currentUserId) {
      this._notifyStateChange(RCCallSessionState.KEEPING);
    }
    // 抛出onAccept时状态已经就绪
    this._watchers.onAccept({ userId: senderUserId });
  }

  private _onMediaModify(message: IReceivedMessage) {
    const { senderUserId, content: { mediaType, user: userProfile, deviceId } } = message;
    // 正在通话中，远端多端发的消息直接过滤
    if (this._isRemoteInvalidMsg(senderUserId, deviceId)) {
      this._logger.debug('_', '[RCCallStateMachine] _onMediaModify -> not the remote device that is currently talking');
      return;
    }

    if (this._context.getCurrentId() === senderUserId) { // 多端处理
      return;
    }

    // 更新 mediaType
    this._mediaType = mediaType;
    this._watchers.onMediaModify({
      sender: { userId: senderUserId, ...<IUserProfile>userProfile },
      mediaType,
    });
  }

  private _onHungup(message: IReceivedMessage) {
    const { senderUserId: suid, content } = message;
    const senderUserId = this._isCrossAppkey ? suid.split('_')[1] : suid;
    const { reason, user: userProfile, deviceId } = content as IHungupMsgContent;
    const currentUserId = this._context.getCurrentId();
    // 正在通话中，远端多端发的消息直接过滤
    if (this._isRemoteInvalidMsg(senderUserId, deviceId)) {
      this._logger.debug('_', '[RCCallStateMachine] _onHungup -> not the remote device that is currently talking');
      return;
    }

    if (currentUserId === senderUserId) { // 多端处理 抛出多端已处理 reason
      this._otherClientHandle(message);
      return;
    }

    if (this._sessionState === RCCallSessionState.END) {
      // 如果己方房间状态已结束，再收到 hungup 消息不再处理
      this._logger.info('_', `[RCCallStateMachine] Invalid hang up message, current room status has ended -> sessionState: ${this._sessionState}`);
      return;
    }

    if (this._userInfo[senderUserId]) {
      // 修改内存态数据并通知
      this._userInfo[senderUserId].state = RCCallUserState.NONE;
      this._endTimestamp = Date.now();
      // 添加用户简要信息
      Object.assign(this._userInfo[senderUserId], <IUserProfile>userProfile);
      this._notifyUserStateChange(this._userInfo[senderUserId], CallRemoteEndReason[reason]);
      delete this._userInfo[senderUserId];
    }

    // timer 清除
    if (CallRemoteEndReason[reason] === RCCallEndReason.REMOTE_CANCEL) {
      // 远端取消通话，没有远端用户，清除己端的接听超时计时器
      this.getRemoteUserIds().length < 1 && this._clearTimerById(currentUserId);
    } else if (this.getInviterId() === currentUserId) {
      // 远端拒绝、忙碌、未接听等，通过自己是否是主叫来判断要清除呼叫超时计时器 或 接听超时计时器
      this._clearTimerById(senderUserId);
    } else {
      this.getRemoteUserIds().length < 1 && this._clearTimerById(currentUserId);
    }

    // 房间人员信息少于两个，通知房间状态结束
    const isLessThanTwo = Object.keys(this._userInfo).length < 2;
    // 群呼中邀请者挂断（非呼叫发起者）且房间中被邀请者都未接听，通知房间状态结束
    const isInviteUser = this._inviterId === senderUserId;
    const isNoOneAnswered = Object.values(this._userInfo).every((user) => user.state !== RCCallUserState.KEEPING);
    if (isLessThanTwo || (isInviteUser && isNoOneAnswered)) {
      this._notifyStateChange(RCCallSessionState.END, CallRemoteEndReason[reason]);
    }

    // 抛出 onHungup 时状态已经就绪
    this._watchers.onHungup({ userId: senderUserId, ...<IUserProfile>userProfile }, CallRemoteEndReason[reason]);
  }

  /**
   * 注册事件监听
   * @params watchers
   */
  registerEventListener(watchers: ICallStateMachineWatchers) {
    this._watchers = watchers;
  }

  /**
   * 收到 invite 消息时状态机更新（CallEngine 内部调用）
   * @param message 接收消息
   */
  __onInvite(message: IReceivedMessage) {
    const { senderUserId: suid, content, sentTime } = message;
    const {
      inviteUserIds: inids, user: userProfile, deviceId, roomType,
    } = content as IInviteMsgContent;
    // 处理跨 appkey 邀请 senderUserId 带有 appkey 与本地 currentUserId 匹配问题
    // 同时记录本地是否为跨 appkey 状态
    let senderUserId: string;
    if (roomType === RCCrossCallType.RCCallRoomTypeAcrossCall) {
      [, senderUserId] = suid.split('_');
      this._watchers.crossAppkey(true);
      this._isCrossAppkey = true;
    } else {
      senderUserId = suid;
    }
    const currentUserId = this._context.getCurrentId();
    // 正在通话中，远端多端发的消息直接过滤
    if (this._isRemoteInvalidMsg(senderUserId, deviceId)) {
      this._logger.debug('_', '[RCCallStateMachine] __onInvite -> not the remote device that is currently talking');
      return;
    }

    if (currentUserId === senderUserId) {
      // 多端处理
      return;
    }

    this._callerId = this._inviterId = senderUserId;
    const userIdList = [suid, ...inids];
    // 收到邀请后，内部回应响铃消息, userIds 传除自己的所有人
    this._callMsgHandler.sendRinging({
      conversationType: this._conversationType,
      targetId: this._targetId,
      channelId: this._channelId,
      callId: this._callId,
      userIds: userIdList.filter((id) => {
        if (this._isCrossAppkey) {
          return id.split('_')[1] !== currentUserId;
        }
        return id !== currentUserId;
      }),
    });

    const inviteUserIds = this._isCrossAppkey ? [inids[0].split('_')[1]] : inids;

    // 同步本地用户列表需要使用拆分后的 UserId 保证本地其他方法匹配 userid
    const allUserIds = [senderUserId, ...inviteUserIds];

    // 修改并通知房间人员状态
    allUserIds.forEach((userId) => {
      this._userInfo[userId] = {
        userId,
        state: RCCallUserState.WAITING,
        isCaller: userId === senderUserId,
        isRemote: userId !== currentUserId,
      };
      if (userId === senderUserId) {
        // 给 senderUser 添加用户简要信息及 deviceId
        Object.assign(this._userInfo[userId], <IUserProfile>userProfile, { deviceId });
      }
      this._notifyUserStateChange(this._userInfo[userId]);
      // 给所有被邀请人启动接听计时器
      if (userId !== senderUserId) {
        this._userTimers[userId] = new Timer(() => {
          const reason = userId === currentUserId ? RCCallEndReason.NO_RESPONSE : RCCallEndReason.REMOTE_NO_RESPONSE;
          if (userId === currentUserId) { // 群聊中己方超时无需发挂断消息，只需修改人员、通话挂断状态
            this._hungupHandle(reason, false);
          } else { // 其他人员超时只通知人员状态变更
            this._userInfo[userId] && (this._userInfo[userId].state = RCCallUserState.NONE);
            this._notifyUserStateChange(this._userInfo[userId]);
            this._watchers.onHungup(this._userInfo[userId], reason);
            delete this._userInfo[userId];
          }
          /**
           * 超时后，应清除启动的超时定时器
           */
          this._clearTimerById(userId);
        }, this._getTimeout(sentTime));
      }
    });
    // 房间状态通知
    this._notifyStateChange(RCCallSessionState.WAITING);
  }

  /**
   * 收到 memberModify 消息时状态机更新（CallEngine 内部调用）
   * @param message 接收消息
   */
  __onMemberModify(message: IReceivedMessage) {
    const { senderUserId, content, sentTime } = message;
    const {
      user: userProfile, existedUserPofiles, caller, deviceId, inviteUserIds, mediaType,
    } = content as IMemberModifyMsgContent;
    const currentUserId = this._context.getCurrentId();
    // 正在通话中，远端多端发的消息直接过滤
    // this._userInfo[senderUserId] && !this._userInfo[senderUserId].deviceId && !deviceId &&
    if (this._isRemoteInvalidMsg(senderUserId, deviceId)) {
      this._logger.debug('_', '[RCCallStateMachine] __onMemberModify -> not the remote device that is currently talking');
      return;
    }
    if (currentUserId === senderUserId) {
      // 多端处理
      return;
    }
    this._callerId = caller;
    this._inviterId = senderUserId;
    inviteUserIds.forEach((id) => {
      existedUserPofiles.push({ userId: id, mediaType, callStatus: MsgCallStatus.INCOMING });
    });
    // 己方状态为 NONE (未存在己方用户信息) 说明自己为被邀请者, 需发送 ringing
    const isNewInvitedUser = inviteUserIds.includes(currentUserId);
    if (isNewInvitedUser) {
      const needRingingUserIds: string[] = [];
      existedUserPofiles.forEach((userInfo) => {
        if (userInfo.userId !== currentUserId) {
          needRingingUserIds.push(userInfo.userId);
        }
      });
      this._callMsgHandler.sendRinging({
        conversationType: this._conversationType,
        targetId: this._targetId,
        channelId: this._channelId,
        callId: this._callId,
        userIds: needRingingUserIds,
      });

      // 被邀请者通知 call 层房间状态变更
      this._notifyStateChange(RCCallSessionState.WAITING);
    } else {
      // 已在通话流程中用户，抛出人员变更监听
      this._watchers.onMemberModify({
        sender: { userId: senderUserId, ...userProfile },
        invitedUsers: inviteUserIds.map((id) => ({ userId: id })),
      });
    }

    // 更新全量用户状态
    existedUserPofiles.forEach((userInfo) => {
      const { userId, callStatus } = userInfo;
      // 人员为结束状态时，不再更新
      if (callStatus === MsgCallStatus.IDLE) return;
      this._userInfo[userId] = {
        userId,
        state: callStatus !== MsgCallStatus.CONNECTED ? RCCallUserState.WAITING : RCCallUserState.KEEPING,
        isCaller: senderUserId === userId,
        isRemote: currentUserId !== userId,
      };
      // 给 senderUser 添加用户简要信息 及 deviceId
      if (userId === senderUserId) {
        Object.assign(this._userInfo[userId], <IUserProfile>userProfile, { deviceId });
      }
      // 通知人员变更
      this._notifyUserStateChange(this._userInfo[userId]);
      // 除了 callMsgStatus.connected ,其他人都起计时器

      if (callStatus !== MsgCallStatus.CONNECTED && !this._userTimers[userId]) {
        /**
         * 仅给被邀请成员启动定时器
         */
        if (!inviteUserIds.includes(userId)) {
          return;
        }
        // 启动计时器
        this._userTimers[userId] = new Timer(() => {
          // 呼叫超时处理状态、并通知 call 层
          this._userInfo[userId] && (this._userInfo[userId].state = RCCallUserState.NONE);
          const reason = userId === currentUserId ? RCCallEndReason.NO_RESPONSE : RCCallEndReason.REMOTE_NO_RESPONSE;
          // 通知 call 层人员状态变更
          this._notifyUserStateChange(this._userInfo[userId], reason);
          try {
            // 呼叫超时未接抛出onHungup
            this._watchers.onHungup(this._userInfo[userId], reason);
          } catch (error: any) {
            this._logger.error('_', `[RCCallStateMachine] call onhungup error -> ${error?.stack}`);
          }

          delete this._userInfo[userId];
          // 房间人员信息少于两个 或 未接听的是自己，通知房间状态结束
          if (Object.keys(this._userInfo).length < 2 || userId === currentUserId) {
            this._notifyStateChange(RCCallSessionState.END, reason);
          }

          /**
           * 超时后，应清除启动的超时定时器
           */
          this._clearTimerById(userId);
        }, this._getTimeout(sentTime));
      }
    });
  }

  /**
   * 处理已有 session ，不允许再接听新会话情况
   */
  __handleInviteInSession() {
    this._logger.info('_', 'StateMachine -> __handleInviteInSession');
    // 修改所有用户状态并抛出，清空计时器
    for (const userId in this._userInfo) {
      this._userInfo[userId].state && (this._userInfo[userId].state = RCCallUserState.NONE);
      this._notifyUserStateChange(this._userInfo[userId]);
      // 将已存在的计时器都清掉
      this._clearTimerById(userId);
    }
    // 直接终止当前 session 状态
    this._notifyStateChange(RCCallSessionState.END, RCCallEndReason.BUSY_LINE);
    // 发送挂断消息，并携带 己方忙碌 原因
    this._callMsgHandler.sendHungup({
      channelId: this._channelId,
      conversationType: this._conversationType,
      targetId: this._targetId,
      callId: this._callId,
      reason: RCCallEndReason.BUSY_LINE,
      userIds: this.getRemoteUserIds(),
    });
  }

  /**
   * 主动呼叫 (CallEngine 内部调用)
   * @param userIds 被邀请用户 ID 列表
   * @param extra 消息的扩展信息
   * @param pushConfig 移动端推送配置
   */
  async __call(userIds: string[], extra: string = '', pushConfig: IPushConfig, isCrossAppkey = false) {
    this._logger.debug('_', `[RCCallStateMachine] invite -> userIds: ${JSON.stringify(userIds)}`);
    // 发送邀请消息
    const currentUserId = this._callerId = this._inviterId = this._context.getCurrentId();
    const { code, message } = await this._callMsgHandler.sendInvite({
      roomType: isCrossAppkey ? RCCrossCallType.RCCallRoomTypeAcrossCall : RCCrossCallType.RCCallRoomTypeNormalCall,
      channelId: this._channelId,
      conversationType: this._conversationType,
      targetId: this._targetId,
      callId: this._callId,
      extra,
      pushConfig,
      mediaType: this._mediaType,
      inviteUserIds: userIds.filter((id) => id !== currentUserId),
    });

    this._isCrossAppkey = isCrossAppkey;

    if (code === RCCallErrorCode.SUCCESS) {
      const { sentTime } = message!;

      /**
       * 跨 appkey 仅存在单聊，对端 userId 需取下划线后面的部分
       */
      const ids = isCrossAppkey ? [currentUserId, ...[userIds[0].split('_')[1]]] : [currentUserId, ...userIds];

      // 遍历更新房间所有人员状态、并给被叫启动计时器
      ids.forEach((userId) => {
        const isCaller = userId === currentUserId;
        this._userInfo[userId] = {
          userId,
          state: RCCallUserState.WAITING,
          isCaller,
          isRemote: !isCaller,
        };
        // 通知 call 层人员状态变更
        this._notifyUserStateChange(this._userInfo[userId]);

        // 启动呼叫超时计时器
        if (!isCaller) {
          this._userTimers[userId] = new Timer(() => {
            // 呼叫超时处理状态、并通知 call 层
            this._userInfo[userId] && (this._userInfo[userId].state = RCCallUserState.NONE);
            // 通知 call 层人员状态变更
            this._notifyUserStateChange(this._userInfo[userId], RCCallEndReason.REMOTE_NO_RESPONSE);

            // 呼叫超时未接抛出onHungup
            this._watchers.onHungup(this._userInfo[userId], RCCallEndReason.REMOTE_NO_RESPONSE);

            delete this._userInfo[userId];
            // 房间人员信息少于两个，通知房间状态
            if (Object.keys(this._userInfo).length < 2) {
              this._notifyStateChange(RCCallSessionState.END, RCCallEndReason.REMOTE_NO_RESPONSE);
            }
            // 远端人员大于 1 且己方不为正在通话时，发送 hungup, 告诉远端自己退出通话
            const isNeedSendHungup = this.getRemoteUserIds().length === 0 && (this._userInfo[currentUserId].state !== RCCallUserState.KEEPING);
            if (isNeedSendHungup) {
              this._hungupHandle(RCCallEndReason.REMOTE_NO_RESPONSE);
            }
          }, this._getTimeout(sentTime));
        }
      });

      // 通知 call 层房间状态变更
      this._notifyStateChange(RCCallSessionState.WAITING);
    } else {
      const endCode = code === RCCallErrorCode.REJECTED_BY_BLACKLIST ? RCCallEndReason.ADDED_TO_BLACKLIST : RCCallEndReason.NETWORK_ERROR;
      this._notifyStateChange(RCCallSessionState.END, endCode);
    }

    return { code };
  }

  /**
   * 接听
   */
  async accept(): Promise<{ code: RCCallErrorCode }> {
    this._logger.debug('_', '[RCCallStateMachine] accept');

    // 发送接听消息
    const currentUserId = this._context.getCurrentId();
    const { code, message } = await this._callMsgHandler.sendAccept({
      channelId: this._channelId,
      conversationType: this._conversationType,
      targetId: this._targetId,
      callId: this._callId,
      mediaType: this._mediaType,
      userIds: this.getRemoteUserIds(),
    });

    // 清除接听超时计时器
    this._clearTimerById(currentUserId);
    if (code === RCCallErrorCode.SUCCESS) {
      const { sentTime } = message!;
      this._userInfo[currentUserId] && (this._userInfo[currentUserId].state = RCCallUserState.KEEPING);
      this._beginTimestamp = Date.now();
      // 通知 call 层人员状态变更
      this._notifyUserStateChange(this._userInfo[currentUserId]);
      // 修改并通知 call 层房间状态变更
      this._notifyStateChange(RCCallSessionState.KEEPING);
    } else {
      this._userInfo[currentUserId] && (this._userInfo[currentUserId].state = RCCallUserState.NONE);
      // 通知 call 层人员状态变更
      this._notifyUserStateChange(this._userInfo[currentUserId]);
      // 修改并通知 call 层房间状态变更
      const endReason = code === RCCallErrorCode.REJECTED_BY_BLACKLIST ? RCCallEndReason.ADDED_TO_BLACKLIST : RCCallEndReason.NETWORK_ERROR;
      this._notifyStateChange(RCCallSessionState.END, endReason);
    }

    return { code };
  }

  /**
   * 群呼叫中继续邀请
   * @param userIds 被邀请用户 ID 列表
   */
  async invite(userIds: string[], options: IInviteOptions = {}): Promise<{ code: RCCallErrorCode }> {
    if (this._conversationType !== ConversationType.GROUP) {
      return { code: RCCallErrorCode.CONVERSATION_NOT_GROUP_ERROR };
    }

    this._logger.debug('_', `[RCCallStateMachine] invite -> userIds: ${JSON.stringify(userIds)}`);
    const currentUserId = this._context.getCurrentId();
    const existedUserIds = Object.keys(this._userInfo);
    const existedUserPofiles: IExistedUserPofiles[] = existedUserIds.map((userId) => {
      let callStatus = MsgCallStatus.CONNECTED;
      // 包含被邀请者，或者用户状态为 wating 时，状态为 响铃
      if (userIds.includes(userId) || this._userInfo[userId].state === RCCallUserState.WAITING) {
        callStatus = MsgCallStatus.RINGING;
      }
      return {
        userId,
        mediaType: this._mediaType,
        callStatus,
        mediaId: userId,
      };
    });

    const { extra = '', pushConfig } = options;

    // 发送人员变更消息
    const { code, message } = await this._callMsgHandler.sendMemeberModify({
      channelId: this._channelId,
      conversationType: this._conversationType,
      targetId: this._targetId,
      callId: this._callId,
      extra,
      pushConfig,
      mediaType: this._mediaType,
      // 除自己，其他所有人均需收到 modify 消息，以此更新状态
      inviteUserIds: userIds.filter((id) => id !== currentUserId),
      callerId: this.getCallerId(),
      existedUserPofiles,
      directionalUserIdList: [...existedUserIds, ...userIds].filter((id) => id !== currentUserId),
    });

    if (code === RCCallErrorCode.SUCCESS) {
      const { sentTime } = message!;
      // 修改被邀请人状态，并通知
      userIds.forEach((userId) => {
        this._userInfo[userId] = {
          userId,
          state: RCCallUserState.WAITING,
          isCaller: false,
          isRemote: true,
        };
        // 通知 call 层人员状态变更
        this._notifyUserStateChange(this._userInfo[userId]);
        // 启动呼叫计时器
        this._userTimers[userId] = new Timer(() => {
          // 呼叫超时处理状态、并通知 call 层
          this._userInfo[userId] && (this._userInfo[userId].state = RCCallUserState.NONE);
          // 通知 call 层人员状态变更
          this._notifyUserStateChange(this._userInfo[userId], RCCallEndReason.REMOTE_NO_RESPONSE);

          // 呼叫超时未接抛出onHungup
          this._watchers.onHungup(this._userInfo[userId], RCCallEndReason.REMOTE_NO_RESPONSE);

          delete this._userInfo[userId];
          // 房间人员信息少于两个，通知房间状态
          if (Object.keys(this._userInfo).length < 2) {
            this._notifyStateChange(RCCallSessionState.END, RCCallEndReason.REMOTE_NO_RESPONSE);
          }
        }, this._getTimeout(sentTime));
      });
    } else {
      // 通知人员变更
      userIds.forEach((userId) => {
        this._userInfo[userId] = {
          userId,
          state: RCCallUserState.NONE,
          isCaller: false,
          isRemote: true,
        };
        // 通知 call 层人员状态变更

        const endReason = code === RCCallErrorCode.REJECTED_BY_BLACKLIST ? RCCallEndReason.ADDED_TO_BLACKLIST : RCCallEndReason.NETWORK_ERROR;
        this._notifyUserStateChange(this._userInfo[userId], endReason);
      });
    }

    return { code };
  }

  private async _hungupHandle(reason: RCCallEndReason, isSendHangupMessage: boolean = true): Promise<{ code: RCCallErrorCode }> {
    const currentUserId = this._context.getCurrentId();

    // 发送挂断类型消息
    let code = RCCallErrorCode.SUCCESS;
    if (isSendHangupMessage) {
      const hangupParams = {
        channelId: this._channelId,
        conversationType: this._conversationType,
        targetId: this._targetId,
        callId: this._callId,
        reason,
        userIds: this.getRemoteUserIds(),
        pushConfig: this._hungupPushConfig,
      };

      if (reason === RCCallEndReason.OTHER_CLIENT_JOINED_CALL) {
        // 己端被多端加入 RTC 房间挤掉后，不再等发挂断消息的结果，防止走 userLeave 导致挂断原因不准确
        this._callMsgHandler.sendHungup(hangupParams);
      } else {
        const { code: msgCode } = await this._callMsgHandler.sendHungup(hangupParams);
        code = msgCode;
      }
    }

    this._endTimestamp = Date.now();
    // 更新通话人员状态、通话结束时间 并通知
    for (const userId in this._userInfo) {
      this._userInfo[userId].state = RCCallUserState.NONE;
      if (userId === currentUserId) {
        this._notifyUserStateChange(this._userInfo[userId], reason);
      } else {
        this._notifyUserStateChange(this._userInfo[userId]);
      }
      delete this._userInfo[userId];
    }

    if (Object.keys(this._userInfo).length < 2) {
      // 清空内存态数据，并通知
      this._notifyStateChange(RCCallSessionState.END, reason);
    }

    return { code };
  }

  /**
   * 挂断
   */
  async hungup(): Promise<{ code: RCCallErrorCode }> {
    this._logger.debug('_', '[RCCallStateMachine] hungup');
    const currentUserId = this._context.getCurrentId();
    // 默认挂断 reason 为通话过程中，己方正常取消通话
    let reason = RCCallEndReason.HANGUP;
    /**
     * 若超时计时器存在, 己方为 caller 原因为己方取消通话,
     * 己方为 callee 且未进入通话中，原因为己方拒绝通话
     */
    if (Object.keys(this._userTimers).length > 0) {
      if (this._userInfo[currentUserId].isCaller) {
        reason = RCCallEndReason.CANCEL;
      } else if (this._userInfo[currentUserId].state === RCCallUserState.WAITING) {
        reason = RCCallEndReason.REJECT;
      }
    }

    // 清除所有超时计时器
    for (const userId in this._userTimers) {
      this._clearTimerById(userId);
    }
    return this._hungupHandle(reason);
  }

  /**
   * 修改通话媒体类型
   * @param mediaType RCCallMediaType.AUDIO 改为音频通话 | RCCallMediaType.AUDIO_VIDEO 改为音视频通话
   */
  async changeMediaType(mediaType: RCCallMediaType): Promise<{ code: RCCallErrorCode }> {
    this._logger.debug('_', `[RCCallStateMachine] changeMediaType -> mediaType: ${mediaType}`);
    const { code } = await this._callMsgHandler.sendMediaModify({
      channelId: this._channelId,
      conversationType: this._conversationType,
      targetId: this._targetId,
      callId: this._callId,
      mediaType,
      userIds: this.getRemoteUserIds(),
    });
    if (code === RCCallErrorCode.SUCCESS) {
      this._mediaType = mediaType;
    }
    return { code };
  }

  /**
   * 用户加入通话补偿机制（rtc userJoin 事件触发）
   * 主叫呼叫后，未收到被叫 accept 消息，但收到了 userJoin 同样补偿更新用户、房间状态、呼叫计时器
   */
  userJoin(userIds: string[]) {
    this._logger.debug('_', `[RCCallStateMachine] userJoin -> userIds: ${JSON.stringify(userIds)}`);
    // 延迟 300ms 防止 userJion 和 accept 消息都有的情况下， userJoin 比 accept 先到
    setTimeout(() => {
      userIds.forEach((userId) => {
        const userInfo = this._userInfo[userId];
        // 更新人员状态 (// 群组通话时： A向B、C发起通话 B先接听，C后接听，这时B没有C的userId对应的_userInfo，所以这里判断一下)
        if (userInfo && userInfo.state !== RCCallUserState.KEEPING) {
          userInfo.state = RCCallUserState.KEEPING;
          this._notifyUserStateChange(userInfo);
        }
        // 更新房间状态
        if (this._sessionState !== RCCallSessionState.KEEPING) {
          this._notifyStateChange(RCCallSessionState.KEEPING);
        }
        // 停止并清除呼叫计时器
        this._clearTimerById(userId);
      });
    }, 300);
  }

  /**
   * 用户离开通话补偿机制（rtc userLeave、kickOff 事件触发）
   * 通话中远端用户挂断，挂断消息未到，但是监听到 rtc userLeave 同样补偿更新用户、房间状态
   */
  userLeave(userIds: string[]) {
    this._logger.debug('_', `[RCCallStateMachine] userLeave -> userIds: ${JSON.stringify(userIds)}`);
    // 延迟 300ms 防止 userLeave 和 hungup 消息都有的情况下，userLeave 比 hungup 先到
    setTimeout(() => {
      userIds.forEach((userId) => {
        const userInfo = this._userInfo[userId];
        // 更新人员状态
        if (userInfo && userInfo.state !== RCCallUserState.NONE) {
          userInfo.state = RCCallUserState.NONE;
          this._notifyUserStateChange(userInfo, RCCallEndReason.REMOTE_HANGUP);
          this._watchers.onHungup(userInfo, RCCallEndReason.REMOTE_HANGUP);
          delete this._userInfo[userId];
        }
        // 主叫方群聊邀请被叫方后离线，主叫方此时为等待状态后不再上线，被叫方接听后挂断至剩余一人时等待一分钟后挂断
        const remoteUsersTimer = new Timer(() => {
          const remoteUsers = this.getRemoteUsers();
          // 远端只有一个用户且是等待状态，等待一分钟后挂断
          if (remoteUsers.length === 1 && remoteUsers[0].state === 1) {
            this._hungupHandle(RCCallEndReason.REMOTE_NETWORK_ERROR);
          }
        }, 60000);
        // 更新房间状态
        if (Object.keys(this._userInfo).length < 2 && this._sessionState !== RCCallSessionState.END) {
          this._endTimestamp = Date.now();
          this._notifyStateChange(RCCallSessionState.END, RCCallEndReason.REMOTE_HANGUP);
        }
      });
    }, 300);
  }

  /**
   * Call 层己方异常失败后调用的方法
   * 触发时机：音视频服务异常、获取资源失败、加入 RTC 房间失败、发布|订阅失败
   */
  close(reason: RCCallEndReason) {
    this._hungupHandle(reason);
  }

  setHungupPushConfig(pushConfig: IPushConfig) {
    this._hungupPushConfig = pushConfig;
  }

  /**
   * 通话唯一标识
   */
  getCallId(): string {
    return this._callId;
  }

  /**
   * 多组织 ID
   */
  getChannelId(): string {
    return this._channelId;
  }

  /**
   * 目标 ID，单呼对方人员 Id, 群呼群组 Id
   */
  getTargetId(): string {
    return this._targetId;
  }

  /**
   * 获取会话类型
   */
  getConversationType(): ConversationType {
    return this._conversationType;
  }

  /**
   * 获取远端成员 ID 列表
   */
  getRemoteUserIds(): string[] {
    const allUserIds = Object.keys(this._userInfo);
    const remoteUserIds = allUserIds.filter((id) => this._context.getCurrentId() !== id);
    return remoteUserIds;
  }

  /**
   * 获取远端成员信息列表
   */
  getRemoteUsers(): IUserData[] {
    const remoteUser: IUserData[] = [];
    const currentUserId = this._context.getCurrentId();
    for (const uid in this._userInfo) {
      const { userId } = this._userInfo[uid];
      if (userId !== currentUserId) {
        remoteUser.push(this._userInfo[uid]);
      }
    }
    return remoteUser;
  }

  /**
   * 获取房间状态
   */
  getState(): RCCallSessionState {
    return this._sessionState === null ? RCCallSessionState.END : this._sessionState;
  }

  /**
   * 获取人员状态
   */
  getUserState(userId: string): RCCallUserState {
    return this._userInfo[userId]?.state;
  }

  /**
   * 获取会话发起者 Id
   */
  getCallerId(): string {
    return this._callerId!;
  }

  /**
   * 获取当次会话邀请者 Id
   */
  getInviterId(): string {
    return this._inviterId!;
  }

  /**
   * 获取当前通话媒体类型
   */
  getMediaType(): RCCallMediaType {
    return this._mediaType;
  }

  /**
   * 通话挂断后可调用
   */
  getSummary(): IEndSummary {
    // 通话时间计算
    const beginTimestamp = this._beginTimestamp;
    const endTimestamp = this._endTimestamp;
    let duration = 0;
    if (endTimestamp > beginTimestamp && beginTimestamp !== 0) {
      duration = endTimestamp - beginTimestamp;
    }

    const summary = {
      conversationType: this._conversationType,
      channelId: this._channelId,
      targetId: this._targetId,
      mediaType: this._mediaType,
      beginTimestamp,
      endTimestamp,
      duration,
      endReason: this._endReason!,
    };

    this._logger.debug('_', `[RCCallStateMachine] getSummary -> summary: ${JSON.stringify(summary)}`);
    return summary;
  }
}
