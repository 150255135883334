export type AsyncTask<T = void> = () => Promise<T>

class AsyncTaskQueue {
  private readonly queue: {
    task: AsyncTask<any>,
    resolve: (value?: any) => void,
    reject: (reason?: any) => void
  }[] = []

  private locked = false

  private async checkToStart() {
    if (this.queue.length === 0 || this.locked) {
      return;
    }
    this.locked = true;
    const { resolve, task, reject } = this.queue.shift()!;
    let result;
    try {
      result = await task();
      resolve(result);
    } catch (error) {
      reject(error);
    }

    this.locked = false;
    // 递归执行
    this.checkToStart();
  }

  private taskCount: number = 0

  public push <T>(task: AsyncTask<T>, _name: string = '') {
    const promise = new Promise<T>((resolve, reject) => {
      this.queue.push({ resolve, task, reject });
    });
    this.checkToStart();
    return promise;
  }
}

const defQueeu = new AsyncTaskQueue();

/**
 * 将异步任务推送到异步队列，队列内任务先进先出，依次执行，执行完成后通过
 * Promise.resolve 返回执行结果
 * @param task 传参不能是 `async () => {}` 所定义的异步函数，
 * 只能使用明确的 `() => Promise<T> | T` 形式，避免转义时微任务被提前执行
 */
export const push = <T>(task: AsyncTask<T>, name: string = '') => defQueeu.push(task, name);
