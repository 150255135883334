export enum R2Status {
  /**
   * 开始
   */
  BEGIN = 'begin',
  /**
   * 结束
   */
  END = 'end'
}
