import { RCCommandKind } from '../enums/RCCommandKind';
import { RCRTCCode } from '../enums/RCRTCCode';
import { Invoker } from '../Invoker';
import { IMCUConfig, ISetEnableCDN } from '../service';
import { IMCUReqHeaders } from '../service/interface';
import { Store } from '../Store';
import { BaseCommand } from './BaseCommand';
import { CommandExecuteContext } from './CommandExecuteContext';
import { sendCDNInfoSignal } from './helper';

// TODO: 返回值 any?
export class MCUConfigFlushCommand extends BaseCommand<any> {
  constructor(
    private data: IMCUConfig | ISetEnableCDN,
    private cdnValues: IMCUConfig,
  ) {
    super();
  }

  get kind(): RCCommandKind {
    return RCCommandKind.MCUCONFIG_FLUSH;
  }

  async execute(executeCtx: CommandExecuteContext, store: Store, invoker: Invoker): Promise<{ code: RCRTCCode, data?: any }> {
    const { context, service } = executeCtx;
    const headers: IMCUReqHeaders = {
      'App-Key': context.getAppkey(),
      Token: store.getToken(),
      RoomId: store.roomId,
      UserId: context.getCurrentId(),
      SessionId: store.getSessionId(),
    };

    const { code, res } = await service.setMcuConfig(headers, this.data);
    if (code !== RCRTCCode.SUCCESS) {
      return { code };
    }

    res.pull_url && store.setCDNUris(JSON.parse(res.pull_url));

    /**
     * 合流布局中包含分辨率设置时，需扩散 cdn_uris
     */
    const values = this.cdnValues;
    if (code === RCRTCCode.SUCCESS && (values.output?.video.normal?.width || values.output?.video.normal?.fps)) {
      sendCDNInfoSignal(context, store);
    }

    return { code, data: res };
  }
}
