/**
 * Firefox stats
 * 取不到分辨率、音量
 * candidate 无本地网络类型、往返时延、可用上行带宽、可用下行带宽
 */
import { STAT_NONE } from '../../constants';
import { IInnerRCRTCStateReport } from '../../interfaces';
import { senderHasRemoteData } from '../helper';
import AbstractStatParser from './AbstractStatParser';

export default class RTCReportParser extends AbstractStatParser {
  private _reportsData: IInnerRCRTCStateReport = {
    senders: [],
    receivers: [],
    timestamp: +new Date(),
  }

  // 总丢包数
  private _totalPacketsLost: number = 0;

  // 上行码率总和
  private _bitrateSend: number = 0;

  // 下行码率总和
  private _bitrateRecv: number = 0;

  /**
   * 取上行数据
   */
  private _pickSenderData(value: any, stats: {[key: string]: any}, type: any) {
    if (this._sdpSemantics === 'unified-plan' && !this.isValidTranceiver(value, type)) {
      return;
    }

    const {
      kind, bytesSent, packetsSent, bitrateMean: bitrate,
      framerateMean: frameRate,
      nackCount, pliCount, remoteId,
    } = value;
    const { jitter, roundTripTime, packetsLost } = remoteId
      ? stats[remoteId]
      : {
        jitter: null,
        roundTripTime: null,
        packetsLost: 0,
      };
    const resourceId = this._store?.getTrackIdBySSRC(value.ssrc)!;
    this._totalPacketsLost += packetsLost;

    let packetsLostRate = 0;
    !this._latestPacketsSent[resourceId] && (this._latestPacketsSent[resourceId] = {});
    if (remoteId) {
      packetsLostRate = this.updateSenderPacketsLost(resourceId, packetsLost, packetsSent);
    } else {
      // 无 remoteId 时，需记录 packetsSent
      this._latestPacketsSent[resourceId].crtPacketsSent = packetsSent;
    }

    let calcBitrate = 0;
    if (kind === 'video') {
      bitrate && (calcBitrate = Math.floor(bitrate / 1000));
    } else {
      // 音频无码率值，需客户端计算
      calcBitrate = this.updateBytesSent(resourceId, bytesSent, this._reportsData.timestamp);
    }

    calcBitrate < 0 && (calcBitrate = 0);

    // 总和累加
    this._bitrateSend += calcBitrate;

    this._reportsData.senders.push({
      trackId: resourceId,
      kind,
      packetsLostRate,
      remoteResource: false,
      audioLevel: null,
      frameWidth: null,
      frameHeight: null,
      frameRate: Math.floor(frameRate),
      bitrate: calcBitrate,
      jitter: jitter ? Math.round(jitter * 1000) : null,
      rtt: roundTripTime,
      encoderImplementation: null,
      pliCount,
      nackCount,
      googFirsSent: STAT_NONE,
      samplingRate: STAT_NONE,
      googRenderDelayMs: STAT_NONE,
      trackState: STAT_NONE,
    });
  }

  /**
   * 补充上行部分字段数据
   */
  private _addSenderData(stats: {[key: string]: any}, value: any) {
    const { localId } = value;
    const resourceId = this._store?.getTrackIdBySSRC(stats[localId].ssrc)!;
    const sender = this._reportsData.senders.filter((item) => item.trackId === resourceId)[0];

    if (sender && senderHasRemoteData(sender)) {
      sender.jitter = Math.round(value.jitter * 1000);
      sender.rtt = value.rtt;
      sender.packetsLostRate = this.updateSenderPacketsLost(resourceId, value.packetsLost, this._latestPacketsSent[resourceId].crtPacketsSent!);
    }
  }

  /**
   * 取下行数据
   */
  private _pickReceiverData(value: any, type: any) {
    if (this._sdpSemantics === 'unified-plan' && !this.isValidTranceiver(value, type)) {
      return;
    }
    const {
      packetsLost, bytesReceived, packetsReceived, jitter,
      framerateMean: frameRate, kind, bitrateMean: bitrate,
      nackCount, pliCount,
    } = value;

    const resourceId = this._store?.getTrackIdBySSRC(value.ssrc)!;

    this._totalPacketsLost += packetsLost;

    const packetsLostRate = this.updateReceiverPacketsLost(resourceId, packetsLost, packetsReceived);

    let calcBitrate = 0;
    if (kind === 'video') {
      bitrate && (calcBitrate = Math.floor(bitrate / 1000));
    } else {
      calcBitrate = this.updateBytesRecv(resourceId, bytesReceived, this._reportsData.timestamp);
    }

    calcBitrate < 0 && (calcBitrate = 0);

    this._bitrateRecv += calcBitrate;

    this._reportsData.receivers.push({
      trackId: resourceId,
      kind,
      packetsLostRate,
      remoteResource: true,
      audioLevel: null,
      frameWidth: null,
      frameHeight: null,
      frameRate: Math.floor(frameRate),
      bitrate: calcBitrate,
      jitter: jitter ? Math.round(jitter * 1000) : null,
      codecImplementationName: null,
      nackCount,
      pliCount,
      rtt: null,
      samplingRate: STAT_NONE,
      googFirsReceived: STAT_NONE,
      googRenderDelayMs: STAT_NONE,
      trackState: STAT_NONE,
    });
  }

  /**
   * 取 ice 数据
   */
  private _pickIceCandidatePair(stats: {[key: string]: any}, value: any) {
    const localCandidate = stats[value.localCandidateId];
    const { address: IP, port } = localCandidate;
    const remoteCandidate = stats[value.remoteCandidateId];
    const { address: remoteIP, port: remotePort, protocol } = remoteCandidate;

    this._reportsData.iceCandidatePair = {
      IP,
      port,
      networkType: null,
      remoteIP,
      remotePort,
      protocol,
      bitrateRecv: this._bitrateRecv,
      bitrateSend: this._bitrateSend,
      rtt: null,
      availableOutgoingBitrate: null,
      availableIncomingBitrate: null,
      totalPacketsLost: this._totalPacketsLost,
    };
  }

  public formatRCRTCStateReport(stats: { [key: string]: any }): IInnerRCRTCStateReport {
    const timestamp = +new Date();
    this._reportsData = {
      senders: [],
      receivers: [],
      timestamp,
    } as any;

    this._totalPacketsLost = 0;
    this._bitrateRecv = 0;
    this._bitrateSend = 0;

    for (const key in stats) {
      const value = stats[key];
      const { type } = value;

      /**
       * 上行资源解析
       */
      if (type === 'outbound-rtp') {
        this._pickSenderData(value, stats, type);
      }

      /**
       * outbound-rtp 存在无 remoteId 的情况，导致取不到有效的 jitter、rtt、packetsLost，
       * 可拿到 remote-inbound-rtp 的 localId，补充 senders 中的 jitter、rtt、packetsLost 数据，重新计算丢包率
       */
      if (type === 'remote-inbound-rtp') {
        this._addSenderData(stats, value);
      }

      /**
       * 下行流数据解析
       */
      if (type === 'inbound-rtp') {
        this._pickReceiverData(value, type);
      }

      /**
       * 解析本端/远端 IP、Port 数据
       */
      if (type === 'candidate-pair' && value.state === 'succeeded') {
        this._pickIceCandidatePair(stats, value);
      }
    }

    return this._reportsData;
  }

  public getAudioLevelList(stats: { [key: string]: any }) {
    const audioLevelList: {
      trackId: string,
      audioLevel: number | null
    }[] = [];

    for (const key in stats) {
      const value = stats[key];
      const { type, kind } = value;

      if (!kind || kind === 'video') {
        continue;
      }
      /**
       * 上、下行资源解析
       */
      if (type === 'outbound-rtp' || type === 'inbound-rtp') {
        if (this._sdpSemantics === 'unified-plan' && !this.isValidTranceiver(value, type)) {
          continue;
        }

        const resourceId = this._store?.getTrackIdBySSRC(value.ssrc)!;

        audioLevelList.push({
          trackId: resourceId,
          audioLevel: null,
        });
      }
    }

    return audioLevelList;
  }
}
