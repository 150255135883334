/**
 * 音视频模式
 */
export enum RTCMode {
  /**
   * 普通音视频模式
   */
  RTC = 0,
  /**
   * 直播模式
   */
  LIVE = 2,
  /**
   * 跨应用多人房间
   */
  CROSS_MUTI = 7,
  /**
   * sip呼叫
   */
  SIP = 4,
  /**
   * 内部 Callplus 呼叫房间类型，请勿使用
   * @hidden
   */
  CALL = 5,
}
