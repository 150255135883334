/**
 * 直播布局模式定义
 */
export enum MixLayoutMode {
  /**
   * 自定义布局
   */
  CUSTOMIZE = 1,
  /**
   * 悬浮布局（默认）
   */
  SUSPENSION = 2,
  /**
   * 自适应布局
   */
  ADAPTATION = 3
}
