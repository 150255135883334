/**
 * chrome 73 无 type 为 remote-inbound-rtp 的数据，上行拿不到 jetter、rtt、packetsLost 数据
 */
import { STAT_NONE } from '../../constants';
import { IInnerRCRTCStateReport, IRecvTrackState, ISendTrackState } from '../../interfaces';
import { senderHasRemoteData, handleAudioLevel } from '../helper';
import AbstractStatParser, { ReportData, StatsData } from './AbstractStatParser';

export default class RTCReportParser extends AbstractStatParser {
  /**
   * 该函数用于解析getStats()方法返回的RTCStatsReport对象的数据，然后以RCRTCStateReport对象的格式返回数据
   * @param stats - getStats 方法返回的数据
   * @returns 返回值是一个具有以下属性的对象：IInnerRCRTCStateReport
   */
  public formatRCRTCStateReport(stats: { [key: string]: any }): IInnerRCRTCStateReport {
    const {
      outbound, remoteInbound, inbound, transport, connection,
    }: StatsData = this.formatStateData(stats);

    const reports: IInnerRCRTCStateReport = {
      senders: [],
      receivers: [],
    } as any;

    // 当次报告创建时的时间戳
    const timestamp: number = Math.floor(connection[0].timestamp);
    reports.timestamp = timestamp;

    // 总丢包数
    let totalPacketsLost = 0;

    // 上行码率总和
    let bitrateSend = 0;

    // 解析上行媒体流数据: RTCOutboundRTPVideoStream | RTCOutboundRTPAudioStream
    ({ totalPacketsLost, bitrateSend, senders: reports.senders } = this.pickOutbound(outbound, stats, totalPacketsLost, timestamp, bitrateSend));

    /**
     * outbound-rtp 存在无 remoteId 的情况，导致取不到有效的 jitter、rtt、packetsLost，
     * 可拿到 remote-inbound-rtp 的 localId，补充 senders 中的 jitter、rtt、packetsLost 数据，重新计算丢包率
    */
    if (remoteInbound.length > 0 && reports.senders.length > 0) {
      this.pickRemoteInbound(remoteInbound, reports.senders, stats);
    }

    // 下行码率总和
    let bitrateRecv = 0;

    // 下行流数据解析
    ({ totalPacketsLost, bitrateRecv, receivers: reports.receivers } = this.pickInbound(inbound, stats, totalPacketsLost, timestamp, bitrateRecv));

    // 解析本端/远端 IP、Port 数据
    if (transport.length > 0) {
      const rtcTransport = transport[0];
      this.pickRtcTransport(rtcTransport, stats, reports, bitrateRecv, bitrateSend, totalPacketsLost);
    }

    return reports;
  }

  /**
   * 选择 RTC 传输的函数。
   * @param {ReportData[]} transport - 报告中的传输对象
   * @param stats - 当前 RTCPeerConnection 的统计信息
   * @param {IInnerRCRTCStateReport} reports - IInnerRCRTCStateReport
   * @param {number} bitrateRecv - 接收流的总比特率。
   * @param {number} bitrateSend - 传出流的比特率。
   * @param {number} totalPacketsLost - 当前会话中丢失的数据包总数。
   */
  private pickRtcTransport(rtcTransport: ReportData, stats: { [key: string]: any; }, reports: IInnerRCRTCStateReport, bitrateRecv: number, bitrateSend: number, totalPacketsLost: number) {
    const { selectedCandidatePairId } = rtcTransport;

    if (selectedCandidatePairId) {
      const iceCandidatePair = stats[selectedCandidatePairId];
      const {
        availableOutgoingBitrate,
        // 下行带宽只在有下行资源时有值
        availableIncomingBitrate, currentRoundTripTime: rtt, localCandidateId, remoteCandidateId,
      } = iceCandidatePair;

      const localCandidate = stats[localCandidateId];
      const { ip: IP, port, networkType } = localCandidate;
      const remoteCandidate = stats[remoteCandidateId];
      const { ip: remoteIP, port: remotePort, protocol } = remoteCandidate;

      reports.iceCandidatePair = {
        IP,
        port,
        networkType,
        remoteIP,
        remotePort,
        protocol,
        bitrateRecv,
        bitrateSend,
        rtt: rtt * 1000,
        availableOutgoingBitrate,
        availableIncomingBitrate,
        totalPacketsLost,
      };
      // 给下行 rtt 赋值
      reports.receivers.forEach((item) => {
        item.rtt = rtt;
      });
    }
  }

  /**
   * 它接收一个 ReportData 对象数组、一个 ISendTrackState 对象数组和一个 stats 对象。然后，它遍历 ReportData 对象，并为每个对象过滤
   * ISendTrackState 对象以找到与 ReportData 对象的 localId
   * 匹配的对象。如果找到匹配项，则检查发送方是否有远程数据。如果没有，则通过解析SDP获取resourceId，设置发送者的jitter和rtt，获取packetsSent，然后更新发送者的packetsLostRate
   * @param {ReportData[]} remoteInbound - 远程入站轨迹的数据。
   * @param {ISendTrackState[]} senders - 本地流的发送者列表。
   * @param stats - 本地流的统计信息。
   */
  private pickRemoteInbound(remoteInbound: ReportData[], senders: ISendTrackState[], stats: { [key: string]: any }) {
    remoteInbound.forEach((remoteInboundItem) => {
      const {
        localId, jitter, roundTripTime: rtt, packetsLost,
      } = remoteInboundItem;
      const sender = senders.filter((item) => item.id === localId)[0];

      if (sender && !senderHasRemoteData(sender)) {
        const resourceId = this._store?.getTrackIdBySSRC(stats[sender.id!].ssrc)!;
        sender.jitter = Math.round(jitter * 1000);
        sender.rtt = rtt;
        const packetsSent = this._latestPacketsSent[resourceId].crtPacketsSent!;
        sender.packetsLostRate = this.updateSenderPacketsLost(resourceId, packetsLost, packetsSent);
      }
    });
  }

  /**
   * 取上行视频属性
   */
  private _getOutboundVideoAttrs(outboundInfo: ReportData, stats: { [key: string]: any }, kind: string, trackId: string, mediaSourceId: string) {
    // outboundInfo 中取不到 frameWidth, frameHeight, frameRate 时，
    // chrome 73、80 可从 type 为 media-source 中取 frameWidth, frameHeight
    // chrome 80 从 type 为 media-source 中取 frameRate(chrome 73 无 mediaSourceId)
    let { framesPerSecond: frameRate, frameWidth, frameHeight } = outboundInfo;
    if (kind === 'video' && !frameWidth && !frameHeight && !frameRate) {
      frameWidth = stats[trackId]?.frameWidth || stats[mediaSourceId].width;
      frameHeight = stats[trackId]?.frameHeight || stats[mediaSourceId].height;
      frameRate = stats[mediaSourceId]?.framesPerSecond || null;
    }

    return {
      frameRate,
      frameWidth,
      frameHeight,
    };
  }

  /**
   * 该函数用于解析发送到远程端的本地流的数据
   * @param {ReportData[]} outbound - 本端发送的数据。
   * @param stats - 当前流的统计信息。
   * @param {number} totalPacketsLost - 本地和远程端丢失的数据包总数。
   * @param {number} timestamp - 当前报告的时间戳。
   * @param {number} bitrateSend - 本地流的总比特率。
   */
  private pickOutbound(outbound: ReportData[], stats: { [key: string]: any }, totalPacketsLost: number, timestamp: number, bitrateSend: number) {
    const senders: ISendTrackState[] = [];
    outbound.forEach((outboundInfo) => {
      // 本端输出数据
      if (this._sdpSemantics === 'unified-plan' && !this.isValidTranceiver(outboundInfo, 'outbound-rtp')) {
        return;
      }

      const {
        id, kind, mediaSourceId, remoteId, packetsSent, bytesSent, encoderImplementation, pliCount, nackCount, mid,
      } = outboundInfo;

      let { trackId } = outboundInfo;

      if (mid !== undefined) {
        // chrome 112 开始，outbound 数据中移除了 trackId，新增了 mid，active
        // 需要根据 mid 反查下 trackId
        trackId = this.getTrackIdByMid(mid);
      }

      if (!trackId) {
        return;
      }

      const { frameRate, frameWidth, frameHeight } = this._getOutboundVideoAttrs(outboundInfo, stats, kind, trackId, mediaSourceId);

      // 远端接收数据
      const remoteStreamInfo = stats[remoteId];
      let jitter = null;
      let rtt = null;
      let packetsLost: number = 0;
      // 远端流有可能尚未建立
      if (remoteStreamInfo) {
        jitter = remoteStreamInfo.jitter;
        rtt = remoteStreamInfo.roundTripTime;
        packetsLost = remoteStreamInfo.packetsLost;
      }
      totalPacketsLost += packetsLost;

      const resourceId = this._store?.getTrackIdBySSRC(outboundInfo.ssrc);
      if (!resourceId) {
        return;
      }
      const audioLevel = mediaSourceId ? stats[mediaSourceId].audioLevel : stats[trackId].audioLevel;

      let packetsLostRate = 0;

      !this._latestPacketsSent[resourceId] && (this._latestPacketsSent[resourceId] = {});
      if (remoteStreamInfo) {
        packetsLostRate = this.updateSenderPacketsLost(resourceId, packetsLost, packetsSent);
      } else {
        // 无 remoteId 时，需记录 packetsSent
        this._latestPacketsSent[resourceId].crtPacketsSent = packetsSent;
      }

      // 计算码率
      let bitrate = this.updateBytesSent(resourceId, bytesSent, timestamp);

      if (bitrate < 0) {
        bitrate = 0;
      }

      // 总和累加
      bitrateSend += bitrate;

      senders.push({
        id,
        trackId: resourceId,
        kind,
        packetsLostRate,
        remoteResource: false,
        audioLevel: (audioLevel || audioLevel === 0) ? handleAudioLevel(audioLevel) : null,
        frameWidth,
        frameHeight,
        frameRate,
        bitrate,
        jitter: jitter ? Math.round(jitter * 1000) : jitter,
        rtt: rtt ? Math.round(rtt * 1000) : rtt,
        encoderImplementation,
        pliCount,
        nackCount,
        googFirsSent: STAT_NONE,
        samplingRate: STAT_NONE,
        googRenderDelayMs: STAT_NONE,
        trackState: STAT_NONE,
      });
    });
    return { totalPacketsLost, bitrateSend, senders };
  }

  /**
   * 取下行视频属性
   */
  private _getInboundMediaAttrs(inboundInfo: ReportData, stats: {[key: string]: any}, trackId: string, id: string, kind: string) {
    // inboundInfo 中取不到 frameWidth, frameHeight, audioLevel 时，需从 type 为 track 中取
    let { frameWidth, frameHeight, audioLevel } = inboundInfo;
    if (kind === 'video') {
      if (!frameWidth && !frameHeight) {
        frameWidth = stats[trackId]?.frameWidth || stats[id].width;
        frameHeight = stats[trackId]?.frameHeight || stats[id].height;
      }
    } else if (!audioLevel) {
      audioLevel = stats[trackId]?.audioLevel || stats[id].audioLevel;
    }

    return {
      frameWidth,
      frameHeight,
      audioLevel,
    };
  }

  /**
   * 解析报表的入站数据的函数。
   * @param {ReportData[]} inbound - 报表的入站数据
   * @param stats - 当前 RTCPeerConnection 的统计信息。
   * @param {number} totalPacketsLost - 丢包总数
   * @param {number} timestamp - 当前报告的时间戳。
   * @param {number} bitrateRecv - 接收流的总比特率。
   */
  private pickInbound(inbound: ReportData[], stats: { [key: string]: any; }, totalPacketsLost: number, timestamp: number, bitrateRecv: number) {
    let receivers: IRecvTrackState[] = [];
    inbound.forEach((inboundInfo) => {
      if (this._sdpSemantics === 'unified-plan' && !this.isValidTranceiver(inboundInfo, 'inbound-rtp')) {
        return;
      }

      const {
        packetsLost, packetsReceived, jitter, bytesReceived, framesPerSecond: frameRate, kind, codecImplementationName, nackCount, pliCount,
        mid, id,
      } = inboundInfo;

      let { trackId } = inboundInfo;

      if (mid !== undefined) {
        // chrome 112 开始，outbound 数据中移除了 trackId，新增了 mid，active
        // 需要根据 mid 反查下 trackId
        trackId = this.getTrackIdByMid(mid);
      }

      if (!trackId) {
        return;
      }

      const { frameWidth, frameHeight, audioLevel } = this._getInboundMediaAttrs(inboundInfo, stats, trackId, id, kind);

      totalPacketsLost += packetsLost;

      const resourceId = this._store?.getTrackIdBySSRC(inboundInfo.ssrc)!;

      const packetsLostRate = this.updateReceiverPacketsLost(resourceId, packetsLost, packetsReceived);
      let bitrate = this.updateBytesRecv(resourceId, bytesReceived, timestamp);

      if (bitrate < 0) {
        bitrate = 0;
      }

      bitrateRecv += bitrate;

      receivers.push({
        trackId: resourceId,
        kind,
        packetsLostRate,
        remoteResource: true,
        audioLevel: (audioLevel || audioLevel === 0) ? handleAudioLevel(audioLevel) : null,
        frameWidth,
        frameHeight,
        frameRate,
        bitrate,
        jitter: jitter ? Math.round(jitter * 1000) : 0,
        codecImplementationName,
        nackCount,
        pliCount,
        rtt: null,
        samplingRate: STAT_NONE,
        googFirsReceived: STAT_NONE,
        googRenderDelayMs: STAT_NONE,
        trackState: STAT_NONE,
      });
    });
    return { totalPacketsLost, bitrateRecv, receivers };
  }

  /**
   * 该函数用于获取当前流的音频电平
   * @param stats - getStats 方法返回的数据
   * @returns 具有以下属性的对象数组：
   */
  public getAudioLevelList(stats: { [key: string]: any }) {
    const audioLevelList: {
      trackId: string,
      audioLevel: number | null
    }[] = [];

    // 解析上行媒体流数据: RTCOutboundRTPVideoStream | RTCOutboundRTPAudioStream
    const { outbound, inbound }: StatsData = this.formatStateData(stats);
    const outboundList = outbound.filter((item) => item?.kind === 'audio');
    outboundList.forEach((outboundInfo) => {
      // 本端输出数据
      if (this._sdpSemantics === 'unified-plan' && !this.isValidTranceiver(outboundInfo, 'outbound-rtp')) {
        return;
      }

      const { mediaSourceId, trackId } = outboundInfo;
      const resourceId = this._store?.getTrackIdBySSRC(outboundInfo.ssrc)!;
      const audioLevel = mediaSourceId && stats[mediaSourceId] ? stats[mediaSourceId].audioLevel : (stats[trackId]?.audioLevel || null);

      resourceId && audioLevelList.push({
        trackId: resourceId,
        audioLevel: (audioLevel || audioLevel === 0) ? handleAudioLevel(audioLevel) : null,
      });
    });

    // 下行流数据解析
    const inboundList = inbound.filter((item) => item?.kind === 'audio');
    inboundList.forEach((inboundInfo) => {
      if (this._sdpSemantics === 'unified-plan' && !this.isValidTranceiver(inboundInfo, 'inbound-rtp')) {
        return;
      }

      const { trackId } = inboundInfo;

      // inboundInfo 中取不到 audioLevel 时，需从 type 为 track 中取
      const audioLevel = inboundInfo.audioLevel || stats[trackId].audioLevel;
      const resourceId = this._store?.getTrackIdBySSRC(inboundInfo.ssrc)!;

      resourceId && audioLevelList.push({
        trackId: resourceId,
        audioLevel: (audioLevel || audioLevel === 0) ? handleAudioLevel(audioLevel) : null,
      });
    });
    return audioLevelList;
  }
}
