import { ErrorCode } from '@rongcloud/engine';
import {
  buildPlusMessage, buildTotalURIMessageContent, diffPublishResources, getTrackId,
} from '../../helper';
import { RCRTCMessageType } from '../enums/inner/RCRTCMessageType';
import { RCCommandKind } from '../enums/RCCommandKind';
import { RCLoggerStatus, RCLoggerTag } from '../enums/RCLoggerTag';
import { RCRTCCode } from '../enums/RCRTCCode';
import { IPublishedResource, IResource } from '../interfaces';
import { Invoker } from '../Invoker';
import { Store } from '../Store';
import RCRTCPeerConnection from '../webrtc/RCRTCPeerConnection';
import { BaseCommand, CommandPriority, ICommandResult } from './BaseCommand';
import { CommandExecuteContext } from './CommandExecuteContext';
import { ExchangeCommand } from './ExchangeCommand';
import { createExchangeParams } from './helper';

export class RetryExchangeCommand extends BaseCommand {
  get kind(): RCCommandKind {
    return RCCommandKind.RETRY_EXCHANGE;
  }

  get priority(): CommandPriority {
    return CommandPriority.HIGH;
  }

  async execute(executeCtx: CommandExecuteContext, store: Store, invoker: Invoker): Promise<ICommandResult> {
    const { logger, peer, context } = executeCtx;
    const traceId = logger.createTraceId()!;
    logger.info(RCLoggerTag.L_RETRY_EXCHANGE_COMMAND_T);

    const userId = context.getCurrentId();

    // 区分发布、订阅的 PeerC
    const subscribeList = store.getSubscribedList();
    const reqBody = await createExchangeParams(subscribeList, true, peer, store);
    /**
     * 直播房间需携带 pushOtherRooms 信息
     */
    reqBody.pushOtherRooms = executeCtx.getPushOtherRooms();

    // 发送 /exchange 请求
    const resp = await new ExchangeCommand(reqBody, traceId).execute(executeCtx, store, invoker);

    if (resp.code !== RCRTCCode.SUCCESS) {
      return { code: resp.code };
    }

    const { sdp: answer, resultCode } = resp.data!.data!;
    if (resultCode !== RCRTCCode.SUCCESS) {
      return { code: resultCode };
    }

    // 请求成功，清除 ice 断线重连的定时器
    peer.clearReTryExchangeTimer();

    const code = await peer.setRemoteAnswer(answer.sdp);

    if (code !== RCRTCCode.SUCCESS) {
      return { code };
    }

    /**
     * 对比 ice 恢复后,发布的资源 uri 是否有变化,
     * 有则更新内存、扩散给房间内其他人
     */
    const publishList = resp.data!.data!.publishList || [];
    const { plus, minus } = this._diffPublishListChange(publishList, store, peer, userId);
    if (plus.length || minus.length) {
      await this._spreadPublishListUri(executeCtx, userId, plus, minus, traceId);
    }

    return { code: RCRTCCode.SUCCESS };
  }

  /**
   * 对比新增、取消发布的资源
   */
  private _diffPublishListChange(publishResource: IResource[], store: Store, peer: RCRTCPeerConnection, userId: string) {
    const oldPublisheList = store.getResourcesByUserId(userId)!;

    let newPublishList: IPublishedResource[] = JSON.parse(JSON.stringify(oldPublisheList));

    // 覆盖发布列表
    publishResource.forEach((pubItem) => {
      const { mediaType, msid } = pubItem;
      const index = newPublishList.findIndex((oldItem) => {
        const { mediaType: oldMediaType, msid: oldMsid } = oldItem;
        return (mediaType === oldMediaType && msid === oldMsid);
      });
      if (index === -1) {
        const newItem: IPublishedResource = {
          tag: msid.split('_').pop()!,
          state: peer.getLocalTrack(getTrackId(pubItem))?.isLocalMuted ? 0 : 1,
          ...pubItem,
        };
        newPublishList.push(newItem);
      } else {
        newPublishList[index].uri = pubItem.uri;
      }
    });

    const { publishedList: plus, unpublishedList: minus } = diffPublishResources(oldPublisheList, newPublishList, true);

    store.setResourcesByUserId(userId, newPublishList);

    return { plus, minus };
  }

  /**
   * 扩散新增、取消发布资源
   * @param plus 新增发布
   * @param minus 减少发布
   */
  private async _spreadPublishListUri(context: CommandExecuteContext, userId: string, plus: IPublishedResource[], minus: IPublishedResource[], traceId: string) {
    const { store, logger } = context;
    const allPublishList = store.getResourcesByUserId(userId)!;

    logger.info(RCLoggerTag.L_RETRY_EXCHANGE_SPREAD_T, JSON.stringify({
      plus,
      minus,
      allPublishList,
    }), traceId);

    const oldSdkSpreadMsg = [];
    minus.length && oldSdkSpreadMsg.push(buildPlusMessage(RCRTCMessageType.UNPUBLISH, minus));
    plus.length && oldSdkSpreadMsg.push(buildPlusMessage(RCRTCMessageType.PUBLISH, plus));

    const code = await context.context.setRTCTotalRes(
      store.roomId,
      oldSdkSpreadMsg,
      buildTotalURIMessageContent(allPublishList),
      RCRTCMessageType.TOTAL_CONTENT_RESOURCE,
    );

    if (code === ErrorCode.SUCCESS) {
      logger.info(RCLoggerTag.L_RETRY_EXCHANGE_SPREAD_R, `status: ${RCLoggerStatus.SUCCESSED}`, traceId);
    } else {
      logger.error(RCLoggerTag.L_RETRY_EXCHANGE_SPREAD_R, `status: ${RCLoggerStatus.FAILED}`, traceId);
    }
  }
}
