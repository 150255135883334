import { IAudioLevelChangeHandler } from '../interfaces';
import { RCLocalAudioTrack } from '../tracks/RCLocalTrack';
import { RCRemoteAudioTrack } from '../tracks/RCRemoteTrack';
import { parseTrackId } from '../../helper';
import { ReadableStore } from '../Store';
import RCRTCPeerConnection from '../webrtc/RCRTCPeerConnection';

export default class RCAudioLevelReport {
  // 音量上报事件
  private _audioLevelChangeHandler: IAudioLevelChangeHandler | null = null

  constructor(
    private readonly _store: ReadableStore,
    private readonly _peer: RCRTCPeerConnection,
  ) {}

  /**
   * 通知业务端音量 > 0 的数据，数组每一项包含 track、audioLevel
   */
  audioLevelReport(audioLevelData: {trackId: string, audioLevel: number}[]) {
    if (!this._audioLevelChangeHandler) {
      return;
    }

    const audioLevelList: {
      track: RCLocalAudioTrack | RCRemoteAudioTrack;
      audioLevel: number;
    }[] = [];

    audioLevelData.forEach((item) => {
      const { userId } = parseTrackId(item.trackId);
      const isLocal = this._store.crtUserId === userId;
      const track = isLocal ? this._peer.getLocalTrack(item.trackId) as RCLocalAudioTrack : this._store.getRemoteTrack(item.trackId) as RCRemoteAudioTrack;

      if (track) {
        audioLevelList.push({
          track,
          audioLevel: item.audioLevel || 0,
        });
      }
    });

    this._audioLevelChangeHandler(audioLevelList);
  }

  onAudioLevelChange(handler: IAudioLevelChangeHandler | null) : void {
    this._audioLevelChangeHandler = handler;
  }
}
