/**
 * 资源大小流标识枚举
 */
export enum RCStreamType {
  /**
   * 普通流（大流）
   */
  NORMAL = 1,
  /**
   * 小流
   */
  TINY = 2,
}
