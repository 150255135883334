interface Resource {
  ssrc?: number,
  id?: string,
  msid?: string,
  kind?: string,
}

export default class SdpResource {
  private static instance: SdpResource;

  private constructor() {}

  /* 一个私有变量，它是字符串到资源的映射。 */
  private __streams: Map<string, Resource> = new Map();

  /**
   * 如果实例不存在，则创建它。如果存在，则返回
   * @returns SdpResource 类的实例。
   */
  static getInstance(): SdpResource {
    if (!SdpResource.instance) {
      SdpResource.instance = new SdpResource();
    }
    return SdpResource.instance;
  }

  /**
   * 它将资源添加到流中。
   * @param {string} streamId - 流的 ID。
   * @param {resource} resource - 要推送到流的资源。
   * @returns 被推送到流中的资源。
   */
  public push(streamId: string, resource: Resource): Resource {
    this.__streams.set(streamId, resource);
    return resource;
  }

  /**
   * 它返回与给定 streamId 关联的资源
   * @param {string} streamId - 您要提取的流的 streamId。
   * @returns 与 streamId 关联的资源对象。
   */
  public pickup(streamId: string): Resource | undefined {
    return this.__streams.get(streamId);
  }

  /**
   * 它从流映射中删除流。
   * @param {string} streamId - 要删除的流的 ID。
   * @returns 一个布尔值，指示 streamId 是否已成功删除。
   */
  public delete(streamId: string): boolean {
    return this.__streams.delete(streamId);
  }

  /**
   * 它返回房间中所有流的地图。
   * @returns 房间内所有溪流的地图。
   */
  public get streams(): Map<string, Resource> {
    const streams: Map<string, Resource> = new Map();
    for (const streamId of this.__streams.keys()) {
      streams.set(streamId, <Resource> this.pickup(streamId));
    }
    return streams;
  }
}
