import {
  ConversationType, ILogger, IReceivedMessage, RTCPluginContext,
} from '@rongcloud/engine';
import { CallRemoteEndReason, RCCallEndReason } from './enums/RCCallEndReason';
import { RCCallMediaType } from './enums/RCCallMediaType';
import { RCCallMessageType } from './enums/RCCallMessageType';
import { IHungupMsgContent, IInviteMsgContent, IMediaModifyMsgContent } from './interfaces/IMessageHandler';

export interface IOfflineRecord {
  channelId: string,
  conversationType: ConversationType,
  targetId: string,
  mediaType: RCCallMediaType,
  callId: string,
  inviterId: string,
  endReason: RCCallEndReason,
  beginTimestamp: number,
  endTimestamp: number,
  duration: number
}

/**
 * 离线通话记录器
 */
export class OfflineRecorder {
  private _messages: IReceivedMessage[] | [] = []

  private _channelId!: string

  private _conversationType!: ConversationType

  private _targetId!: string

  private _mediaType!: RCCallMediaType

  private _callId!: string

  private _callerId!: string

  private _inviterId!: string

  private _endReason!: RCCallEndReason

  private _beginTimestamp: number = 0

  private _endTimestamp: number = 0

  constructor(
    private readonly _context: RTCPluginContext,
    private readonly _logger: ILogger,
    private readonly _onRecord: (record: IOfflineRecord) => void,
  ) {

  }

  /**
   * 到 invite | memberModify 结束
   * 原因根据己方是否为主叫，主叫为远端未接听 被叫被己端未接听
   */
  private _doInvite(message: IReceivedMessage) {
    const currentUserId = this._context.getCurrentId();
    const {
      channelId, conversationType, targetId, senderUserId, content,
    } = message;
    const { callId, mediaType } = content as IInviteMsgContent;
    this._channelId = channelId!;
    this._conversationType = conversationType;
    this._targetId = targetId;
    this._callId = callId;
    this._mediaType = mediaType;
    const isCaller = senderUserId === currentUserId;
    this._inviterId = senderUserId;
    this._endReason = isCaller ? RCCallEndReason.REMOTE_NO_RESPONSE : RCCallEndReason.REMOTE_NO_RESPONSE;
    this._canGenRecord();
  }

  /**
   * 到 invite | memberModify 结束
   * 原因根据己方是否为主叫，主叫为远端未接听 被叫被己端未接听
   */
  private _doMemberModify(message: IReceivedMessage) {
    this._doInvite(message);
  }

  /**
   * 用 invite | memberModify 计算的离线记录
   */
  private _doRinging(message: IReceivedMessage) {
    this._canGenRecord();
  }

  /**
   * 到 accept 说明通话已建立
   * 原因默认己方正常挂断
   */
  private _doAccept(message: IReceivedMessage) {
    this._endReason = RCCallEndReason.HANGUP;
    this._beginTimestamp = message.sentTime;
    this._canGenRecord();
  }

  /**
   * 到 hungup 说明为正常挂断
   * 原因取消息体里挂断原因
   */
  private _doHungup(message: IReceivedMessage) {
    const { content, sentTime, senderUserId } = message;
    const { reason } = content as IHungupMsgContent;
    const currentUserId = this._context.getCurrentId();
    const isSelfSend = senderUserId === currentUserId;
    this._endReason = isSelfSend ? reason : CallRemoteEndReason[reason];
    this._endTimestamp = sentTime;

    this._canGenRecord();
  }

  /**
   * 只修改通话类型
   */
  private _doMediaModify(message: IReceivedMessage) {
    const { content } = message;
    const { mediaType } = content as IMediaModifyMsgContent;
    this._mediaType = mediaType;
    this._canGenRecord();
  }

  private _canGenRecord() {
    if (this._messages.length === 0) {
      let duration = 0;
      const isNeedDurationReason = [
        RCCallEndReason.HANGUP,
        RCCallEndReason.REMOTE_HANGUP,
        RCCallEndReason.OTHER_CLIENT_JOINED_CALL,
        RCCallEndReason.REMOTE_OTHER_CLIENT_JOINED_CALL,
        RCCallEndReason.KICKED_BY_SERVER,
        RCCallEndReason.REMOTE_KICKED_BY_SERVER,
        RCCallEndReason.ACCEPT_SYSTEM_CALL,
        RCCallEndReason.REMOTE_ACCEPT_SYSTEM_CALL,
      ].includes(this._endReason);
      if (isNeedDurationReason) {
        duration = this._endTimestamp - this._beginTimestamp;
      }
      this._onRecord({
        channelId: this._channelId,
        conversationType: this._conversationType,
        targetId: this._targetId,
        callId: this._callId,
        inviterId: this._inviterId,
        mediaType: this._mediaType,
        endReason: this._endReason,
        beginTimestamp: this._beginTimestamp,
        endTimestamp: this._endTimestamp,
        duration,
      });
    }
  }

  onRecvOfflineMsgs(messages: IReceivedMessage[]) {
    this._messages = messages;
    do {
      const msg = this._messages.shift()!;
      const { messageType, content: { callId } } = msg;
      switch (messageType) {
        case RCCallMessageType.VCInvite:
          this._doInvite(msg);
          break;
        case RCCallMessageType.VCRinging:
          this._doRinging(msg);
          break;
        case RCCallMessageType.VCAccept:
          this._doAccept(msg);
          break;
        case RCCallMessageType.VCModifyMem:
          this._doMemberModify(msg);
          break;
        case RCCallMessageType.VCModifyMedia:
          this._doMediaModify(msg);
          break;
        case RCCallMessageType.VCHangup:
          this._doHungup(msg);
          break;
        default:
          this._logger.debug('_', `[OfflineRecorder] onRecvOfflineMsgs -> unexpected message: ${JSON.stringify(msg)}`);
          break;
      }
    } while (this._messages.length > 0);
  }
}
