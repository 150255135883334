import {
  ErrorCode, isObject, isString, notEmptyString, validate, RCConnectionStatus, BasicLogger,
} from '@rongcloud/engine';
import { RCRTCCode } from '../enums/RCRTCCode';
import { BaseCommand } from './BaseCommand';
import { Store } from '../Store';
import { RCLivingType } from '../enums/RCLivingType';
import { isIllegalConnection } from '../../helper';
import { Invoker } from '../Invoker';
import { UnpublishPrevCommand } from './UnpublishPrevCommand';
import { RCLoggerStatus, RCLoggerTag } from '../enums/RCLoggerTag';
import { RCCommandKind } from '../enums/RCCommandKind';
import { RTCMode } from '../enums/RTCMode';
import { RTCJoinType } from '../enums/RTCJoinType';
import { IJoinRTCRoomData, IRTCUserData } from '../codec/interface';
import { CommandExecuteContext } from './CommandExecuteContext';
import { RTCContext } from '../codec/RTCContext';

/**
 * 资源发布命令
 */
export class JoinRoomCommand extends BaseCommand<IJoinRTCRoomData> {
  constructor(
    private readonly roomId: string,
    private readonly roomType: RTCMode,
    private readonly joinType?: RTCJoinType,
    private readonly livingType?: RCLivingType,
    private readonly innerUserDatas?: IRTCUserData,
    private readonly outerUserDatas?: IRTCUserData,
    private readonly traceId?: string,
  ) {
    super();
  }

  get kind(): RCCommandKind {
    return RCCommandKind.JOINROOM;
  }

  /**
   * 获取加房间的日志 tag 标识
   */
  private _getLoggerTag() {
    let loggerTag = RCLoggerTag.L_RTC_CLIENT_JOIN_RTC_ROOM_R;
    if (this.roomType === RTCMode.LIVE) {
      loggerTag = RCLoggerTag.L_RTC_CLIENT_JOIN_LIVING_ROOM_R;
    } else if (this.roomType === RTCMode.CROSS_MUTI) {
      loggerTag = RCLoggerTag.L_RTC_CLIENT_JOIN_CROSS_RTC_ROOM_R;
    }
    return loggerTag;
  }

  /**
   * 处理加房间前的参数、连接检测
   */
  private _validateParams(context: RTCContext, logger: BasicLogger, loggerTag: string) {
    if (context.getConnectionStatus() !== RCConnectionStatus.CONNECTED) {
      logger.error(loggerTag, JSON.stringify({
        status: RCLoggerStatus.FAILED,
        code: RCRTCCode.SIGNAL_DISCONNECTED,
        msg: 'im not connected',
      }), this.traceId);
      return { code: RCRTCCode.SIGNAL_DISCONNECTED };
    }

    if (isIllegalConnection(context.getNaviInfo()!)) {
      logger.error(loggerTag, JSON.stringify({
        status: RCLoggerStatus.FAILED,
        code: RCRTCCode.PACKAGE_ENVIRONMENT_ERROR,
        msg: 'package environment error',
      }), this.traceId);
      return { code: RCRTCCode.PACKAGE_ENVIRONMENT_ERROR };
    }

    if (!validate('roomId', this.roomId, notEmptyString, true)) {
      logger.error(loggerTag, JSON.stringify({
        status: RCLoggerStatus.FAILED,
        code: RCRTCCode.PARAMS_ERROR,
        msg: 'params error -> roomId',
      }), this.traceId);
      return { code: RCRTCCode.PARAMS_ERROR };
    }

    if (!validate('roomType', this.roomType, (value :RTCMode) => RTCMode[value] !== undefined)) {
      logger.error(loggerTag, JSON.stringify({
        status: RCLoggerStatus.FAILED,
        code: RCRTCCode.PARAMS_ERROR,
        msg: 'params error -> roomType',
      }), this.traceId);
      return { code: RCRTCCode.PARAMS_ERROR };
    }

    if (!validate('outerUserDatas', this.outerUserDatas, isObject, false)) {
      logger.error(loggerTag, JSON.stringify({
        status: RCLoggerStatus.FAILED,
        code: RCRTCCode.PARAMS_ERROR,
        msg: 'params error -> outerUserDatas',
      }), this.traceId);
      return { code: RCRTCCode.PARAMS_ERROR };
    }

    if (this.outerUserDatas) {
      const valid = Object.keys(this.outerUserDatas).every((key) => validate(`outerUserDatas.${key}`, this.outerUserDatas![key], isString, true));
      if (!valid) {
        logger.error(loggerTag, JSON.stringify({
          status: RCLoggerStatus.FAILED,
          code: RCRTCCode.PARAMS_ERROR,
          msg: 'params error -> outerUserDatas',
        }), this.traceId);

        return { code: RCRTCCode.PARAMS_ERROR };
      }
    }
    return { code: RCRTCCode.SUCCESS };
  }

  async execute(executeContext: CommandExecuteContext, store: Store, invoker: Invoker): Promise<{code: ErrorCode | RCRTCCode, data?: IJoinRTCRoomData}> {
    const startTime = Date.now();
    const {
      context, service, logger, reportMediaActionLogger,
    } = executeContext;

    const loggerTag = this._getLoggerTag();
    /**
     * 校验参数
     */
    const { code: validateCode } = this._validateParams(context, logger, loggerTag);
    if (validateCode !== RCRTCCode.SUCCESS) {
      return { code: validateCode };
    }

    // 加入房间前调用探测逻辑
    service.detectorMediaSever();

    const urls = service.getNaviMS();
    if (!urls.length) {
      logger.error(loggerTag, JSON.stringify({
        status: RCLoggerStatus.FAILED,
        code: RCRTCCode.NOT_OPEN_VIDEO_AUDIO_SERVER,
        msg: 'No audio / video server address available',
      }), this.traceId);
      return { code: RCRTCCode.NOT_OPEN_VIDEO_AUDIO_SERVER };
    }
    const supportNtf = store.getSupportNtf();
    const { code, data } = await context.joinRTCRoom(this.roomId, this.roomType, this.livingType, this.joinType, this.innerUserDatas, this.outerUserDatas, supportNtf);

    reportMediaActionLogger.reportQualityJoinRoomData(startTime, code, this.roomType, data?.sessionId);

    if (code !== ErrorCode.SUCCESS || !data) {
      const errorCode = code as number === 40032 ? RCRTCCode.SIGNAL_JOIN_RTC_ROOM_REFUSED : code;
      logger.error(loggerTag, JSON.stringify({
        status: RCLoggerStatus.FAILED,
        code: errorCode,
        msg: 'joinRoom failed',
      }), this.traceId);
      return { code: errorCode };
    }

    store.initWithRoomData(data, this.traceId!);

    const selfRes = store.getResourcesByUserId(store.crtUserId)!;
    /*
     * 加入房间后，若房间中已存在己方发布的资源，表示之前未能完成正常退出流程
     * 需先清除房间内的己方资源，通知房间内其他人己方已取消当前资源的发布
     * 该步骤没有必要与 MediaServer 的交互，因后续资源变更交互为全量交互
     */
    selfRes.length > 0 && invoker.push(new UnpublishPrevCommand());

    const CDNUris = data.roomInfo.filter((item) => item.key === 'cdn_uris')[0]?.value;
    CDNUris && store.setCDNUris(JSON.parse(CDNUris)[0]);

    logger.info(loggerTag, JSON.stringify({
      status: RCLoggerStatus.SUCCESSED,
      userId: store.crtUserId,
      roomId: this.roomId,
      // data,
    }), this.traceId);

    return { code: RCRTCCode.SUCCESS, data };
  }
}
