import {
  ErrorCode,
} from '@rongcloud/engine';
import { RCCommandKind } from '../enums/RCCommandKind';
import { RCLoggerStatus, RCLoggerTag } from '../enums/RCLoggerTag';
import { RCRTCCode } from '../enums/RCRTCCode';
import { IEndPKContent, IPKInfo } from '../interfaces';
import { Invoker } from '../Invoker';
import { RCLivingPKHandler } from '../room/RCLivingPKHandler';
import RCLivingRoom from '../room/RCLivingRoom';
import { Store } from '../Store';
import { BaseCommand, ICommandResult } from './BaseCommand';
import { ExchangeWithPushOtherRoomCommand } from './ExchangeWithPushOtherRoomCommand';
import { IEndRoomPKOptions } from '../codec/interface';
import { CommandExecuteContext } from './CommandExecuteContext';

export class LeaveOtherRoomCommand extends BaseCommand {
  constructor(
    private pkHandler: RCLivingPKHandler,
    private room: RCLivingRoom,
    private _PKInfo: IPKInfo,
    private _joinedPKRooms: {[roomId: string]: RCLivingRoom},
    private isQuitPK?: boolean,
    private traceId?: string,
  ) {
    super();
  }

  get kind(): RCCommandKind {
    return RCCommandKind.LEAVE_OTHER_ROOM;
  }

  /**
 * 结束跨房间连麦
 * @param roomId 需要结束连麦的房间 roomId
 */
  private async quitRoomPK(executeCtx: CommandExecuteContext, roomId: string, store: Store, traceId: string) {
    const { logger, context } = executeCtx;
    logger.info(RCLoggerTag.L_QUIT_PK_T, `roomId: ${roomId}`, traceId);

    if (!this._PKInfo[roomId]) {
      // TODO: 需确认，直接加入连麦的情况下，不退出是否有问题
      logger.warn(RCLoggerTag.L_QUIT_PK_R, `status: ${RCLoggerStatus.FAILED}, msg: pkinfo is empty`, traceId);
      return;
    }

    const {
      inviterRoomId, inviteeRoomId, inviterUserId, inviteSessionId,
    } = this._PKInfo[roomId];

    const content: IEndPKContent = {
      inviteeRoomId,
      inviterRoomId,
      userId: store.crtUserId,
    };

    const params: IEndRoomPKOptions = {
      roomId: store.roomId,
      endRoomId: roomId,
      sessionId: inviteSessionId,
      content: JSON.stringify(content),
      keys: [`${inviterRoomId}|${inviterUserId}`],
    };

    const code = await context.endRoomPK(params);
    if (code !== ErrorCode.SUCCESS) {
      logger.error(RCLoggerTag.L_QUIT_PK_R, `status: ${RCLoggerStatus.FAILED}, code: ${code}`, traceId);
      return { code: RCRTCCode.SIGNAL_ERROR };
    }

    logger.info(RCLoggerTag.L_QUIT_PK_R, `status: ${RCLoggerStatus.SUCCESSED}`, traceId);
    delete this._PKInfo[roomId];
  }

  async execute(executeCtx: CommandExecuteContext, store: Store, invoker: Invoker): Promise<ICommandResult> {
    const { logger } = executeCtx;
    const { room, isQuitPK } = this;
    const roomId = room.getRoomId();

    logger.info(RCLoggerTag.L_LIVING_ROOM_LEAVE_PK_ROOM_T, JSON.stringify({
      roomId,
      isQuitPK,
    }), this.traceId);

    await room.__destroy(true);

    // 回调主直播房间离开 PK 房间
    delete this._joinedPKRooms[roomId];
    await new ExchangeWithPushOtherRoomCommand(roomId, this.pkHandler, this.traceId).execute(executeCtx, store, invoker);

    // isQuitPK 为 true 时，结束连麦
    isQuitPK && this.quitRoomPK(executeCtx, roomId, store, this.traceId!);

    logger.info(RCLoggerTag.L_LIVING_ROOM_LEAVE_PK_ROOM_R, JSON.stringify({
      status: RCLoggerStatus.SUCCESSED,
    }), this.traceId);

    return { code: RCRTCCode.SUCCESS };
  }
}
