export enum RCCrossCallType {
  /*!
  同App通话
  */
  RCCallRoomTypeNormalCall = 0,
  /*!
  跨App通话
  */
  RCCallRoomTypeAcrossCall = 7
}
